import React, { createContext, useState, useContext, ReactNode } from 'react';

interface SigningUpContextProps {
  isSigningUp: boolean;
  setIsSigningUp: (isSigningUp: boolean) => void;
}

const SigningUpContext = createContext<SigningUpContextProps | undefined>(undefined);

export const SigningUpProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isSigningUp, setIsSigningUp] = useState(false);

  return (
    <SigningUpContext.Provider value={{ isSigningUp, setIsSigningUp }}>
      {children}
    </SigningUpContext.Provider>
  );
};

export const useSigningUp = (): SigningUpContextProps => {
  const context = useContext(SigningUpContext);
  if (!context) {
    throw new Error('useSigningUp must be used within a SigningUpProvider');
  }
  return context;
};
