import { FontAwesome } from "@expo/vector-icons";
import { COLORS, SCREEN_SIZE } from "../Constants";
import TouchableContainer from "./TouchableContainer"
import { Text } from "react-native";
import scaleFontSize from "../helpers/scaleFontSize";

const AcceptRequestButton = (props: any)=>{
    const { onPress } = props;
    return(
        <TouchableContainer
            onPress={()=>onPress()}
            style={{
                flexDirection: 'row',
                backgroundColor: COLORS.primaryGreen,
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                // padding: 10,
                borderRadius: 2.5,
                width: SCREEN_SIZE.width*.30,
                height: SCREEN_SIZE.height*.05,
            }}>
            <Text style={{marginRight: 10, color: 'white', fontSize: scaleFontSize(18), fontWeight: '500'}}> Accept </Text>
            <FontAwesome name="thumbs-up" size={25} color="white" />
        </TouchableContainer>
    )
}

export default AcceptRequestButton