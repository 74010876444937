import { Gig } from "../types";
import dayjs from 'dayjs';

// Check if the current time is earlier than the gig's end time, considering end times extending to the next day
const isCurrentTimeEarlierThanGigEndTime = (currentTime: Date, gigStartTime: Date, gigEndTime: Date): boolean => {
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();
  const gigStartHour = gigStartTime.getHours();
  const gigEndHour = gigEndTime.getHours();
  const gigEndMinute = gigEndTime.getMinutes();

  // If end time is earlier than start time, it means the gig ends the next day
  if (gigEndHour < gigStartHour || (gigEndHour === gigStartHour && gigEndMinute < gigStartHour)) {
    if (currentHour < gigStartHour) {
      return true; // It's still before the gig started
    } else if (currentHour === gigStartHour && currentMinute < gigStartTime.getMinutes()) {
      return true; // It's within the same hour before the gig started
    } else if (currentHour > gigEndHour || (currentHour === gigEndHour && currentMinute > gigEndMinute)) {
      return false; // It's after the gig ended the next day
    }
    return true; // It's within the gig time extending to the next day
  } else {
    // Normal same-day time comparison
    return currentTime < gigEndTime;
  }
};

// Function to check if a gig has not passed
function gigHasNotPassed(gig: Gig): boolean {
  const currentDateTime = new Date();
  const gigStartDateTime = new Date(gig.start);
  const gigEndDateTime = new Date(gig.end);

  const currentDate = dayjs(currentDateTime);
  const gigDate = dayjs(gig.date);

  // Check if gig date is strictly after the current date (ignoring time)
  if (gigDate.isAfter(currentDate, 'day')) {
    return true; // The gig date is in the future
  } else if (gigDate.isSame(currentDate, 'day')) {
    // If the gig date is today, check the time
    return isCurrentTimeEarlierThanGigEndTime(currentDateTime, gigStartDateTime, gigEndDateTime);
  }

  // If the gig date is not strictly after today and not today, the gig has passed
  return false;
}

export default gigHasNotPassed;
