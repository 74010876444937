import React, { useState, useEffect } from 'react';
import { Image, View, Platform } from 'react-native';
import shorthash from 'shorthash';
import * as FileSystem from 'expo-file-system';
import isVarDefined from '../helpers/isVarDefined';
import catchErrorForSentry from '../helpers/catchSentryError';

const CacheImage = (props: any) => {
  const { uri, placeholderType, resizeMode, style } = props;

  const [source, setSource] = useState<any>(null);
  const [error, setError] = useState(false);
  const placeholder = placeholderType === 'logo'
    ? require('../assets/alwayslocal-logo.png')
    : require('../assets/placeholder-picture.png');

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (isVarDefined(uri) && uri) {
        setSource({ uri });
      } else {
        setSource(placeholder);
      }
    } else {
      if (Number.isInteger(uri) || !isVarDefined(uri) || !uri) {
        setSource(placeholder);
      } else {
        const name = shorthash.unique(uri);
        const path = `${FileSystem.cacheDirectory}${name}`;
        FileSystem.getInfoAsync(path).then((image) => {
          if (image.exists) setSource({ uri: image.uri });
        });

        FileSystem.downloadAsync(uri, path).then((newImage) => {
          if (newImage) setSource({ uri: newImage.uri });
        });
      }
    }
  }, []);

  if (error) {
    // console.log("JSON stringified format: ", JSON.stringify(error)); 
    catchErrorForSentry(error);
    return (
      <Image
        //@ts-ignore
        source={placeholder}
        //@ts-ignore
        style={style}
        //@ts-ignore
        resizeMode={'contain'}
      />
    );
  }
  return (
    <Image
      //@ts-ignore
      resizeMode={resizeMode}
      //@ts-ignore
      style={style}
      source={source}
      onError={() => setError(true)}
    />
  );
};

export default CacheImage;

// import React, { useState, useEffect } from 'react';
// import { Image, View } from 'react-native';
// import shorthash from 'shorthash';
// import * as FileSystem from 'expo-file-system';
// import isVarDefined from '../helpers/isVarDefined';


// const CacheImage = (props: any)=>{
//   const {
//     uri,
//     placeholderType,
//     resizeMode,
//     style,
//   } = props

//   const [ source, setSource] = useState<any>(null);
//   const [ error, setError] = useState(false);
//   const placeholder = placeholderType === 'logo' ?  require('../assets/alwayslocal-logo.png') :  require('../assets/placeholder-picture.png');

//   useEffect(()=>{
//     if(Number.isInteger(uri) || !isVarDefined(uri) || !uri) setSource(placeholder)
//     else{
//         const name = shorthash.unique(uri);
//         const path = `${FileSystem.cacheDirectory}${name}`;
//         FileSystem.getInfoAsync(path)
//         .then((image)=>{

//           if (image.exists) setSource({uri: image.uri})
//         })
    
//         FileSystem.downloadAsync(uri, path)
//         .then((newImage)=>{
//           if(newImage) setSource({uri: newImage.uri})
      
//         })
//     }

//   }, [])

//   if(error){
//     return(
//       <Image
//         //@ts-ignore
//         source={placeholder}
//         //@ts-ignore
//         style={style}
//         //@ts-ignore
//         resizeMode={'contain'}
//       />
//     )
//   }
//   return(
//     <Image
//       //@ts-ignore
//       resizeMode={resizeMode}
//       //@ts-ignore
//       style={style}
//       source={source}
//       onError={()=>setError(true)}
//     />
//   )

// }

// export default CacheImage

// export default class CacheImage extends React.Component {
//   _isMounted = false;

//   state = {
//     source: null,
//     error: false,
//   };
  
//   componentWillUnmount = ()=>{

//     this._isMounted = false;
//   }

//   componentDidMount = async () => {
//     //@ts-ignore
//     let { uri, placeholder } = this.props;

//     this._isMounted = true;
//     //@ts-ignore
//     this._placeholder = 'test' ? '../assets/images/test' : DEFAULT_PHOTO_PATH
//     //@ts-ignore
//     if(Number.isInteger(this.props.uri) || !isVarDefined(this.props.uri) || !uri){
//         if(this._isMounted){
//           this.setState({
//               //@ts-ignore
//               source: require( this._placeholder ),
//           })
//         }
//         return;
//     }
//     else{

//         const name = shorthash.unique(uri);
//         const path = `${FileSystem.cacheDirectory}${name}`;
//         const image = await FileSystem.getInfoAsync(path);
//         if (image.exists) {
//           // console.log('read image from cache: ', name);
//           if(this._isMounted){
//             this.setState({
//               source: {
//                 uri: image.uri,
//               },
//             });
//           }
//           return;
//         }
    
//         const newImage = await FileSystem.downloadAsync(uri, path);
//         if(this._isMounted){
//           this.setState({
//             source: {
//               uri: newImage.uri,
//             },
//           });
//         }

//         // FileSystem.downloadAsync(uri, path)
//         // .then((newImage)=>{
//         //   console.log("Image downloaded successfully");
//         //   console.log("Name: ", name);
//         //   if(this._isMounted){
//         //     this.setState({
//         //       source: {
//         //         uri: newImage.uri,
//         //       },
//         //     });
//         //   }
//         // })
//         // .catch((error)=>{
//         //   console.log("Error: ", error);
//         // })
//     }
//   };



//   render() {
//     // console.log("Placeholder: ", this.placeholder);
//     if(this.state.error){
//       return(
//         <Image
//           //@ts-ignore
//           source={require(this.placeholder)}
//           //@ts-ignore
//           style={this.props.style}
//           //@ts-ignore
//           resizeMode={this.props.resizeMode}
//         />
//       )
//       //@ts-ignore
//       // return <View style={{...this.props.style,backgroundColor: '#000',}}/>
//     }
//     return(
//       <Image
//         //@ts-ignore
//         resizeMode={this.props.resizeMode}
//         //@ts-ignore
//         style={this.props.style}
//         source={this.state.source}
//         onError={(error)=>{
//           this.setState((prevState)=>{
//             //@ts-ignore
//             prevState.error = true;
//             return prevState
//           })
          
//         }}
//       />
//     )
//   }
// }

