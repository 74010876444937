import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../../Interfaces';

export const initialState: {[key: string]: UserData} = {}

const allUserDataSlice = createSlice({
  name: 'allUserData',
  initialState,
  reducers: {
    setAllUserData: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    clearAllUserData: () => {
      return initialState;
    },
  },
});

export const { setAllUserData, clearAllUserData } = allUserDataSlice.actions;

export default allUserDataSlice.reducer;