import { Switch, Text, View } from "react-native";
import { updateClosingHours, updateOpeningHours, updateUserScheduleHasHours } from "../store/slices/userData";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import React, { useEffect, useState } from "react";
import TimePicker from "./TimePicker";
import { COLORS, SCREEN_SIZE, daysOfTheWeek } from "../Constants";
import Fire from "../Fire";
import isWeb from "../helpers/isWeb";
import { NavigationContext } from '@react-navigation/native';
import scaleFontSize from "../helpers/scaleFontSize";

const ScheduleEditor = (props: any)=>{
    const {
        userData,
    } = props;
    const dispatch = useDispatch();
    const [schedule, setSchedule] = useState<any>(userData && userData.schedule ? userData.schedule : null);
    const [refresh, setRefresh] = useState("");
    const isStaff = userData.accountType === 'staff';
    useEffect(()=>{

        const onScheduleDataChange = (schedule: any)=>{
            if(!schedule) return;
            setSchedule(schedule)
            setRefresh(`${Math.random()}`)
        }
        let scheduleDataRef = Fire.listenToRoute(`publicUserData/${userData.uid}/schedule`, onScheduleDataChange)
        return () =>{
            Fire.off(scheduleDataRef, "value", onScheduleDataChange)
        }
    },[])

    const onToggleHours = (day: string, hasHours: boolean)=>{
        // console.log("Updating database -  toggle hours");
        dispatch(updateUserScheduleHasHours({ day, hasHours }));
        Fire.setUserDataRoute(`${userData.uid}/schedule/${day}/hasHours`, hasHours);
        const openingTime = new Date().setHours(9, 0);
        Fire.setUserDataRoute(`${userData.uid}/schedule/${day}/hours/openingHours`, hasHours ? openingTime : "");
        const closingTime = new Date().setHours(17, 0);
        Fire.setUserDataRoute(`${userData.uid}/schedule/${day}/hours/closingHours`, hasHours ? closingTime : "");
    }

    const onOpeningHoursChange = (day: string, hours: Date)=>{
        const hoursTimestamp = hours.getTime();
        //@ts-ignore
        if(!userData.schedule && !userData.schedule[day] && userData.schedule[day].hours.openingHours !== hoursTimestamp) return;
        // console.log("Opening Hours: ", userData.schedule[day].hours.openingHours );
        // console.log("New Opening Hours: ", hoursTimestamp );
        // console.log("Updating database - opening hours");
        dispatch(updateOpeningHours({ day, hours: hoursTimestamp }));
        Fire.setUserDataRoute(`${userData.uid}/schedule/${day}/hours/openingHours`, hoursTimestamp);
    }
    
    const onClosingHoursChange = (day: string, hours: Date)=>{
        const hoursTimestamp = hours.getTime();
        //@ts-ignore
        if(!userData.schedule && !userData.schedule[day] && userData.schedule[day].closingHours !== hoursTimestamp) return;
        // console.log("closing Hours: ", userData.schedule[day].hours.closingHours );
        // console.log("New Closing Hours: ", hoursTimestamp );
        // console.log("Updating database - closing hours");
        dispatch(updateClosingHours({ day, hours: hoursTimestamp }));
        Fire.setUserDataRoute(`${userData.uid}/schedule/${day}/hours/closingHours`, hoursTimestamp);
    }

    const isBusinessEverOpen = React.useMemo(()=>{
        if(!userData.schedule) return false;
        return daysOfTheWeek.some((day: string)=>{
            const {hasHours} = schedule[day];
            if(hasHours) return true;
            else return false;
        })
    }, [daysOfTheWeek, schedule])

    const DaysOfTheWeek = ()=>{
        return(
            daysOfTheWeek.map((day: any)=>{
                const {hasHours, hours} = schedule ? schedule[day] : {hasHours: false, hours: null};
                let openingTime: Date;
                let closingTime: Date;
                if (hours && hours.openingHours) openingTime = new Date(hours.openingHours);
                else {
                    openingTime = new Date();
                    openingTime.setHours(9, 0);
                }
                if (hours && hours.closingHours) closingTime = new Date(hours.closingHours);
                else {
                    closingTime = new Date();
                    closingTime.setHours(17, 0);
                }
                return(
                    <View style={{width: '100%', flexDirection: 'row', alignItems: 'center', marginVertical:10}} key={day}>
                        <View style={{flex: 2/5, width: '100%', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                            <Text style={{
                                width: isWeb() ? 100 : 40,
                                // borderColor: 'red', borderWidth: 1,
                                fontWeight: '500'
                            }}>
                                {day.slice(0,3)}
                            </Text>
                            <Switch
                                //@ts-ignore
                                activeThumbColor={COLORS.greenAccent}
                                thumbColor={hasHours ? '#f5dd4b' : '#f4f3f4'}
                                trackColor={{false: '#767577', true: '#847792'}}
                                onValueChange={(val: boolean)=>onToggleHours(day, val)}
                                value={hasHours}
                                style={{
                                    // borderColor: 'blue',
                                    // borderWidth: 1,
                                    // height: 5,
                                    // width: 5,
                                }}
                            />
                            {/* <Text style={{width: 75, textAlignVertical: 'center', textAlign: 'center'}}>{hasHours ? "Open" : "Closed"}</Text> */}
                        </View>
                        <View style={{flex: 3/5, height: '100%', width: '100%', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row'}}>
                            { hasHours ? 
                                <>
                                    <TimePicker
                                        day={day}
                                        initialTime={openingTime}
                                        onChange={(day: string, newTime: any)=> onOpeningHoursChange(day, newTime)}
                                    />
                                    <TimePicker
                                        day={day}
                                        initialTime={closingTime}
                                        onChange={(day: string, newTime: any)=>onClosingHoursChange(day, newTime)}
                                    />
                                </>
                            : 
                                <View style={{flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                    {/* <Text style={{color: '#999'}}>Closed</Text> */}
                                </View>
                            }
                        </View>
                    </View>
                )
            })
        )
    }


    return(
        <>
            <View key={refresh} style={{
                width: '100%', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', marginVertical:10}}>
                <View style={{
                    // flex: 2.25/5,
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: isBusinessEverOpen ? 'flex-start' : 'space-around',
                    // borderWidth: 1,
                    // borderColor: 'black',
                }}>
                    <Text style={{
                        flex: .85,
                        width: isBusinessEverOpen ? 150 : 125,
                        // width: SCREEN_SIZE.width,
                        textAlign: isBusinessEverOpen ? 'left' : 'center',
                        // borderWidth: 1,
                        // borderColor: 'red',
                        fontWeight: '500',
                        fontSize: scaleFontSize(20)
                    }}>
                        {isBusinessEverOpen ? 'Day' : 'Set your hours'}
                    </Text>
                    <View style={{flex: 1.15, flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={{textAlign: 'left', width: 125, fontWeight: '500', fontSize: scaleFontSize(18)}}>
                            {isWeb() ? 'Opening Time' : (isStaff ? 'Start at' : 'Open at') }
                        </Text>
                        <Text style={{textAlign: 'left', width: 125, fontWeight: '500', fontSize: scaleFontSize(18)}}>
                            {isWeb() ? 'Closing Time' : (isStaff ? 'End at' : 'Close at')}
                        </Text>
                    </View>
                </View>
                {/* {isBusinessEverOpen && <View style={{flex: 2.75/5, height: '100%', width: '100%', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row'}}>
                    <Text style={{textAlign: 'center', width: 125, fontWeight: '500', fontSize: scaleFontSize(18)}}>
                        {isWeb() ? 'Opening Time' : (isStaff ? 'Start at' : 'Open at') }
                    </Text>
                    <Text style={{textAlign: 'center', width: 125, fontWeight: '500', fontSize: scaleFontSize(18)}}>
                        {isWeb() ? 'Closing Time' : (isStaff ? 'End at' : 'Close at')}
                    </Text>
                </View>} */}
                <DaysOfTheWeek/>
            </View>
           
        </>
    )
}


export default ScheduleEditor