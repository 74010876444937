import { Ionicons } from "@expo/vector-icons";
import TouchableContainer from "./TouchableContainer";
import { Text } from "react-native";
import { COLORS } from "../Constants";
import { useSelector } from "react-redux";
import { RootState } from "../store";


const MessageButton = (props: any)=>{
    const {navigation, profileData} = props;
    const userData = useSelector((state: RootState)=>state.userData)
    const handleOnMessage = ()=>{

        if(!userData.uid) return alert("Sorry, you must create an account to message users");
        if(profileData.uid === userData.uid){
            alert("You can't message yourself!");
            return;
        }


        navigation.push('ChatScreen', {
            uid: profileData.uid,
            email: profileData.email,
            name: profileData.displayName || profileData.username,
            chatRoomType: 'chatRooms',
            isDating: false,
        })

    }
    return(
        <TouchableContainer
            style={{
                // alignSelf: 'center',
                // borderRadius: 5,
                // padding: 5,
                width: '100%',
                // backgroundColor: COLORS.primaryBlue,
                backgroundColor: 'transparent',
                flexDirection: 'row',
                justifyContent: 'center',
                // alignContent: 'center',
            }}
            onPress={()=>{
                handleOnMessage();
        }}
        >
            <Ionicons name={"chatbubble-ellipses-sharp"} size={25} color={'#000'} />
            {/* <Text style={{fontSize: scaleFontSize(15), color: 'white', textAlign: 'center'}}> Message </Text> */}
        </TouchableContainer> 
    )
}


export default MessageButton