import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, Platform, ImageBackgroundBase, ImageBackground } from 'react-native';
// import Card from '../../components/Card';
// import Input from '../../components/Input';
import CacheImageBackground from './CacheImageBackground';
// import TouchableCard from '../../components/TouchableCard';
// import { cloudFrontUrl, SCREEN_SIZE, MAX_NUMBER_OF_DATING_PICTURES } from '../../Constants';
// import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from '@expo/vector-icons';
// import { useEffect } from 'react';
// import { connect } from 'react-redux';
// import Animated, { runOnJS, useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withSpring } from 'react-native-reanimated';
// import { PanGestureHandler } from 'react-native-gesture-handler';
import Fire from '../Fire'
// import deleteFromStorage from '../../helpers/deleteFromStorage';
import UploadPictureModal from './UploadPictureModal';
import { COLORS, IMAGEKIT_URL, SCREEN_SIZE } from '../Constants';
import TouchableContainer from './TouchableContainer';
import getUserDataAsync from '../helpers/getUserDataAsync';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import deleteFromStorage from '../helpers/deleteFromStorage';
import { updateUserDataProperty } from '../store/slices/userData';
import CustomButton from './CustomButton';
import scaleFontSize from '../helpers/scaleFontSize';

const MAX_NUMBER_OF_PICTURES = 15;

const AccountSelectProfilePicture = (props: any)=>{

    const { onPictureSelected, profilePictureKey } = props;

    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);

    const defaultUploadedPictures = (()=>{
        return userData.pictureKeys ? userData.pictureKeys : [];
    })()
    const [uploadedPictures, setUploadedPictures] = useState<any>(defaultUploadedPictures);
    const [ currentIndex, setCurrentIndex ] = useState<any>(null)
    const [ selectedImageKey, setSelectedImageKey ] = useState<string>("")


    useEffect(()=>{
        const onPictureKeysChange = (pictureKeys: any)=>{
            if (pictureKeys) {
                // const pictureData = Object.values(pictureKeys).map((key)=>{return `${IMAGEKIT_URL}/${key}`});
                setUploadedPictures(pictureKeys);
            }
            else setUploadedPictures([])
        }
        let pictureKeysRef = Fire.listenToRoute(`publicUserData/${userData.uid}/pictureKeys`, onPictureKeysChange)

        return () =>{
            Fire.off(pictureKeysRef, "value", onPictureKeysChange)
        }
    }, [])

    const Picture = (props: any)=>{
        const {pictureKey, index} = props;
        let key = pictureKey
        if(!isNaN(key)) return <></>
        const isCurrentlySelected = currentIndex === index;
        const isProfilePicture = key === profilePictureKey
        return(
            <TouchableContainer
                onPress={()=>{
                    setCurrentIndex(index)
                    setSelectedImageKey(key);
                }}
                key={index}
                style={{
                    borderStyle: 'dashed',
                    borderColor: COLORS.primaryBlue,
                    borderRadius: isNaN(key) ? 0 : 1,
                    borderWidth: isNaN(key) ? 0 : 2,
                    backgroundColor: isNaN(key) ? 'transparent' : '#CCCCCC',

                    height: SCREEN_SIZE.height * .20,
                    width: SCREEN_SIZE.height * 0.14,
                    margin: 5
                }}>
                <ImageBackground
                    // key={Date.now()}
                    //@ts-ignore
                    resizeMode={'cover'}
                    //@ts-ignore
                    style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                    }}
                    //@ts-ignore
                    // imageStyle={this.props.imageStyle}
                    // source={require('../assets/alwayslocal-logo.png')}
                    source={isNaN(key) ? {uri: `${IMAGEKIT_URL}/${key}`} : require('../assets/alwayslocal-logo.png')}
                    // imageRef={((imgBackground)=>{
                    //     imgBackground.queryCache(this.state.source.uri);
                    // })}
                >
                    {
                        (isCurrentlySelected || (isProfilePicture && currentIndex === null)) && 
                            <AntDesign
                                name="checkcircleo"
                                size={30} 
                                color={COLORS.primaryRed}
                                style={{
                                    alignItems: 'center',
                                    alignSelf: 'flex-end',
                                    position: 'relative',
                                    top: '6%',
                                    left: '6%'
                                }}
                            />
                    }
                </ImageBackground>
            </TouchableContainer>
        )
    }


    const onSelectProfilePicture = (event: any)=>{
        event.preventDefault();
        //update database with new profile picture key
        Fire.updateUserDataRoute(`${userData.uid}`, {profilePictureKey: selectedImageKey});
        //update local data with new profile picture key
        dispatch(updateUserDataProperty({property: 'profilePictureKey', value: selectedImageKey}))
        onPictureSelected(selectedImageKey);
    }


    const renderUploadedPictures = ()=>{
        let pictureComponents = [];
        for (let index=0; index<MAX_NUMBER_OF_PICTURES; index+=1){
            const key = uploadedPictures[index];
            if(key) pictureComponents.push(<Picture key={key} pictureKey={key} index={index}/>)
            else pictureComponents.push(<Picture key={index} pictureKey={Math.random()} index={index}/>)
        }
        return pictureComponents
    }


    return(
        <View style={{flex: 1, backgroundColor: COLORS.primaryBgColor, borderRadius: 10}}>
            <ScrollView key={uploadedPictures} contentContainerStyle={{flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap', paddingBottom: 20}}>
                {renderUploadedPictures()}
            </ScrollView>
            <View style={{             
                    marginVertical: 10,
            }}>
            <CustomButton
                onPress={(e: any)=>onSelectProfilePicture(e)}
                title={
                    <Text style={{
                        color: 'white',
                        fontSize: scaleFontSize(16),
                        fontWeight: '500',
                        textAlignVertical: 'center',
                        // alignSelf: 'center',
                        textAlign: 'center',
                    }}>
                            Set Profile Picture
                    </Text>
                }
                style={{
                    backgroundColor: COLORS.primaryBlue,
                    padding: 10,
                    borderRadius: 10,
                    width: '50%',
                    minWidth: 165,
                    alignSelf: 'center',
                }}
            />
            </View>
        </View>
    )

}

  

export default AccountSelectProfilePicture;
// export default connect(mapStateToProps, null )(DatingChoosePictures);