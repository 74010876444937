import { View, Text, TextInput, StyleSheet } from 'react-native';
import { COLORS } from '../Constants';

const Input = (props: any)=>{

    const { defaultValue, onInputChange, containerStyle, inputStyle, showLabel=true, label } = props

    return(
        <View style={{width: '100%', marginBottom: 10, ...containerStyle}}>
            {(showLabel && <Text style={{...styles.label, ...props.labelStyle}}>{props.label}</Text>)}
            <TextInput
                {...props}
                placeholder={props.placeholder ? props.placeholder : ''}
                defaultValue={defaultValue}
                onChangeText={(value: string)=>onInputChange(value)}
                style={{
                    paddingHorizontal: 2,
                    paddingVertical: 5,
                    borderBottomColor: '#ccc',
                    borderBottomWidth: 1,
                    ...inputStyle
                }}
            />
    </View>
    )
}

const styles = StyleSheet.create({
    label: {
        marginVertical: 8
      },

})


export default Input