import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, ScrollView, Image, View, Text } from 'react-native';
import Card from '../components/Card';
import { COLORS, IMAGEKIT_URL, SCREEN_SIZE } from '../Constants/';
import confirmDecision from '../helpers/confirmDecision';
import day from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { connect, useDispatch, useSelector } from 'react-redux';
import Fire from '../Fire';
import sendToProfileScreen from '../helpers/sendToProfileScreen';
import CacheImage from './CacheImage';
import catchErrorForSentry from '../helpers/catchSentryError';
import CustomModal from './CustomModal';
import { FontAwesome } from '@expo/vector-icons';
import TouchableContainer from './TouchableContainer';
import { RootState } from '../store';
import addNotificationToUser from '../helpers/addNotificationToUser';
import isObjEmpty from '../helpers/isObjEmpty';
import scaleFontSize from '../helpers/scaleFontSize';
import AcceptRequestButton from './AcceptRequestButton';
import DenyRequestButton from './DenyRequestButton';
import { Gig } from '../types';
import { UserData } from '../Interfaces';
import formatBusinessHours from '../helpers/formatBusinessHours';
import { updateUidsUserAdmins, updateUserDataProperty } from '../store/slices/userData';
// import i18n from 'i18n-js';
const firebase = Fire.getFirebase();
day.extend(relativeTime);

const formatDate = (date: any)=>{
    return day(date).format('MM/DD/YY');
}


const Notification = (props: any)=>{
    const {
        currentUserId,
        id,
        image,
        content,
        timeCreated,
        read,
        type,
        push,
    } = props;

    const userData = useSelector((state: RootState)=>state.userData);
    const requesterUid = `${type.request}`;
    const notiUid = type.user;
    const dispatch = useDispatch();
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [requestType, setRequestType] = useState("");
    // const [showGigRequestModal, setShowGigRequestModal] = useState(false);
    const [gigData, setGigData] = useState<Gig>();
    const [previousGigData, setPreviousGigData] = useState<Gig>();
    const timeSinceCreated = day(timeCreated).fromNow();
    const [requesterData, setRequesterData] = useState({});
    const [employeeData, setEmployeeData] = useState<UserData>();
    const [notificationUserData, setNotificationUserData] = useState();
    // const timeSinceCreated = moment(timeCreated).fromNow();

    useEffect(()=>{
        (()=>{
            if(!notiUid) return;
            Fire.getUserData(notiUid)
            .then((data: any)=>{
                if(data) setNotificationUserData(data);
            })
            .catch((error: any)=>{
                catchErrorForSentry(error);
            })
        }
        )();
        (()=>{
            if(!requesterUid) return;
            Fire.getUserData(requesterUid)
            .then((data: any)=>{
                if(data) setRequesterData(data);
            })
            .catch((error: any)=>{
                catchErrorForSentry(error);
            })
        }
        )();
    }, [])

    const handleNotificationPress = async ()=>{
        if(!read){
            Fire.updateRoute(`userNotifications/${currentUserId}/${id}`, {read: true})
        }

        if(!type) return;

        console.log("Type.type: ", type.type);
        console.log("Type: ", type);
        if(type.gig){
            if(type.type === 'gig-modify-request') setRequestType("gig-modify")
            else setRequestType("gig");
            // console.log("Setting gig");
            // console.log("type: ", type.type);
            const employeeData = await (await Fire.getDataAtRoute(`publicUserData/${type.gig.employee}`)).val()
            if(employeeData) setEmployeeData(employeeData);
            setGigData(type.gig);
            type.type === 'gig-modify-request' && setPreviousGigData(type.previousGig);
            setShowRequestModal(true);
            return;
        }
        if(type.request){
            if(type.type === 'employee-request' || type.type === 'employer-request'){
                setRequestType(type.type)
                setShowRequestModal(true);
            }
            if(type.type === 'request-denied' || type.type === 'request-accepted') sendToProfileScreen(requesterUid, push)
            if(type.type === 'admin-request'){
                setShowRequestModal(true);
                setRequestType("admin");
            }
        }
        else if(type.user){
            sendToProfileScreen(type.user, push)
        }
        else if(type.notificationType ==='screen'){
            push(type.screenName)
        }
        else{
            console.log("This is not set up yet!");
            catchErrorForSentry(new Error(`Notification type is not setup yet - ${type}`))
        }
    }

    const handleDeleteNotification = async ()=>{

        let response = await confirmDecision(`Deleting notification` ,`Are you sure you'd like to delete this notification?`);
        if(response){
            Fire.deleteDataAtRoute(`userNotifications/${currentUserId}/${id}`)
        }
    }

    const requestTypeText = ()=>{
        if(type.type === 'employer-request') return 'employee'
        if(type.type === 'employee-request') return 'employer'
    }

    const closeRequestModal = ()=>{
        setShowRequestModal(false);
        setRequestType("");
    }

    const onAcceptAdminRequest = ()=>{
        Fire.updateRoute(`publicUserData/${userData.uid}/uidsUserAdmins/`, {[`${requesterUid}`]: true})
        .then(()=>{
            //Updating local user data
            dispatch(updateUidsUserAdmins({property: requesterUid, value: true}));
            //Adding user to admins of the requester
            Fire.updateRoute(`publicUserData/${requesterUid}/admins/`, {[`${userData.uid}`]: true})
            .then(()=>{
                const request = addNotificationToUser(requesterUid, {
                    id: `${userData.uid}-${userData.accountType}-request-accepted`,
                    //@ts-ignore
                    image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
                    content: `${userData.displayName} accepted your admin request!`,
                    timeCreated: new Date().getTime(),
                    read: false,
                    //When notification is pressed it won't direct user to post screen.
                    type: {request: userData.uid, type: `admin-request-accepted`},
                })
                closeRequestModal();
                sendToProfileScreen(requesterUid, push);
            })
            .catch((error: any)=>{
                console.log("Error updating employee/employer for requester");
                console.log("Failed to accept request");
                alert("Failed to accept request");
                catchErrorForSentry(error);
                closeRequestModal();
            })
        })
        .catch((error: any)=>{
            console.log("Error updating employee/employer for user");
            console.log("Error updating employee/employer for requester");
            console.log("Failed to accept request");
            alert("Failed to accept request");
            catchErrorForSentry(error);
            closeRequestModal()
        })
        
        
    }
  
    const onDenyAdminRequest = ()=>{
        const request = addNotificationToUser(requesterUid, {
            id: `${userData.uid}-${userData.accountType}-request-denied`,
            //@ts-ignore
            image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
            content: `${userData.displayName} denied your admin request`,
            timeCreated: new Date().getTime(),
            read: false,
            //When notification is pressed it won't direct user to post screen.
            type: {request: userData.uid, type: `admin-request-denied`},
        })
        .then(()=>{
            closeRequestModal()
        })
        .catch((error: any)=>{
            console.log("Failed to admin request: ")
            closeRequestModal()
        })
        
    }
    
    const onAcceptRequest = ()=>{
        const oppositeRequestType = requestTypeText() === 'employee' ? 'employer' : 'employee';
        //Adding employee/employer to the user accepting request
        Fire.updateRoute(`publicUserData/${userData.uid}/${requestTypeText()}s/`, {[`${requesterUid}`]: true})
        .then(()=>{
            //Adding employee/employer to the user who sent request
            Fire.updateRoute(`publicUserData/${requesterUid}/${oppositeRequestType}s/`, {[`${userData.uid}`]: true})
            .then(()=>{
                const request = addNotificationToUser(requesterUid, {
                    id: `${userData.uid}-${userData.accountType}-request-accepted`,
                    //@ts-ignore
                    image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
                    content: `${userData.displayName} accepted your ${requestTypeText()} request!`,
                    timeCreated: new Date().getTime(),
                    read: false,
                    //When notification is pressed it won't direct user to post screen.
                    type: {request: userData.uid, type: `request-accepted`},
                })
                setShowRequestModal(false);
                sendToProfileScreen(requesterUid, push);
            })
            .catch((error: any)=>{
                console.log("Error updating employee/employer for requester");
                console.log("Failed to accept request");
                alert("Failed to accept request");
                catchErrorForSentry(error);
                setShowRequestModal(false);
            })
        })
        .catch((error: any)=>{
            console.log("Error updating employee/employer for user");
            console.log("Error updating employee/employer for requester");
            console.log("Failed to accept request");
            alert("Failed to accept request");
            catchErrorForSentry(error);
            setShowRequestModal(false);
        })
        
        
    }
  
    const onDenyRequest = ()=>{
        const request = addNotificationToUser(requesterUid, {
            id: `${userData.uid}-${userData.accountType}-request-denied`,
            //@ts-ignore
            image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
            content: `${userData.displayName} denied your ${requestTypeText} request`,
            timeCreated: new Date().getTime(),
            read: false,
            //When notification is pressed it won't direct user to post screen.
            type: {request: userData.uid, type: `request-denied`},
        })
        .then(()=>{
            setShowRequestModal(false);
        })
        .catch((error: any)=>{
            console.log("Failed to request ")
            setShowRequestModal(false);
        })
        
    }

    const onAcceptGig = ()=>{
        if(!gigData) return;
        const {date,start,end,employer,employee,type} = gigData;
        const gigKey = `${date}${start}${end}${employer}${employee}${type}`
        const newGigData = {
            ...gigData,
            id: gigKey,
        }
        //Adding gig to current user
        Fire.updateRoute(`publicUserData/${userData.uid}/${type}Schedule/`, {[`${gigKey}`]: newGigData})
        .then(()=>{
            //Adding gig data to the entertainer who sent request
            Fire.updateRoute(`publicUserData/${requesterUid}/${type}Schedule`, {[`${gigKey}`]: newGigData})
            .then(()=>{
                const request = addNotificationToUser(requesterUid, {
                    id: `${userData.uid}-${userData.accountType}-gig-request-accepted`,
                    //@ts-ignore
                    image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
                    content: `${userData.displayName} accepted your gig request!`,
                    timeCreated: new Date().getTime(),
                    read: false,
                    //When notification is pressed it won't direct user to post screen.
                    type: {request: userData.uid, type: `gig-request-accepted`},
                })
                setShowRequestModal(false);
                sendToProfileScreen(requesterUid, push);
            })
            .catch((error: any)=>{
                console.log("Error updating gigSchedule for requester");
                console.log("Failed to accept gig request");
                alert("Failed to accept gig request");
                catchErrorForSentry(error);
                setShowRequestModal(false);
            })
        })
        .catch((error: any)=>{
            // console.log("Error updating gigSchedule for user");
            console.log("Error updating entertainmentSchedule for account");
            console.log("Failed to accept gig request");
            alert("Failed to accept gig request");
            catchErrorForSentry(error);
            setShowRequestModal(false);
        })
    }

    const onDenyGig = ()=>{
        const request = addNotificationToUser(requesterUid, {
            id: `${userData.uid}-${userData.accountType}-gig-request-denied`,
            //@ts-ignore
            image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
            content: `${userData.displayName} denied your gig request`,
            timeCreated: new Date().getTime(),
            read: false,
            //When notification is pressed it won't direct user to post screen.
            type: {request: userData.uid, type: `gig-request-denied`},
        })
        .then(()=>{
            setShowRequestModal(false);
        })
        .catch((error: any)=>{
            console.log("Failed to send deny gig notification")
            setShowRequestModal(false);
        })
    }

    const onAcceptGigModification = ()=>{
        if(!gigData || !previousGigData) return;
        const {date,start,end,employer,employee,type} = gigData;
        const newGigData = {
            ...gigData,
            id: previousGigData.id,
        }
        //Adding gig to current user
        Fire.updateRoute(`publicUserData/${userData.uid}/${type}Schedule/`, {[`${previousGigData.id}`]: newGigData})
        .then(()=>{
            //Adding gig data to the entertainer who sent request
            Fire.updateRoute(`publicUserData/${requesterUid}/${type}Schedule`, {[`${previousGigData.id}`]: newGigData})
            .then(()=>{
                const request = addNotificationToUser(requesterUid, {
                    id: `${userData.uid}-${userData.accountType}-gig-modification-accepted`,
                    //@ts-ignore
                    image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
                    content: `${userData.displayName} accepted your gig modification request!`,
                    timeCreated: new Date().getTime(),
                    read: false,
                    //When notification is pressed it won't direct user to post screen.
                    type: {request: userData.uid, type: `gig-modification-accepted`},
                })
                setShowRequestModal(false);
                sendToProfileScreen(requesterUid, push);
            })
            .catch((error: any)=>{
                console.log("Error updating gigSchedule for requester");
                console.log("Failed to accept gig request");
                alert("Failed to accept gig request");
                catchErrorForSentry(error);
                setShowRequestModal(false);
            })
        })
        .catch((error: any)=>{
            // console.log("Error updating gigSchedule for user");
            console.log("Error updating entertainmentSchedule for account");
            console.log("Failed to accept gig request");
            alert("Failed to accept gig request");
            catchErrorForSentry(error);
            setShowRequestModal(false);
        })
    }

    const onDenyGigModification = ()=>{
        const request = addNotificationToUser(requesterUid, {
            id: `${userData.uid}-${userData.accountType}-gig-modification-denied`,
            //@ts-ignore
            image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
            content: `${userData.displayName} denied your gig modification request`,
            timeCreated: new Date().getTime(),
            read: false,
            //When notification is pressed it won't direct user to post screen.
            type: {request: userData.uid, type: `gig-modification-denied`},
        })
        .then(()=>{
            setShowRequestModal(false);
        })
        .catch((error: any)=>{
            console.log("Failed to send deny gig notification")
            setShowRequestModal(false);
        })
    }
 
    const getModalTitle = ()=>{
        if(requestType === "gig") return "Gig Request";
        if(requestType === "admin") return "Admin Request"
        requestType === "gig" ? "Gig Request" : "Business Request"
    }
    const renderModalContent = ()=>{
        if(requestType === "gig-modify") return <GigModifyContent/>
        if(requestType === "gig") return <GigRequestContent/>
        if(requestType === "admin") return <AdminRequestContent/>
        if(requestType === "employer-request" || requestType === "employee-request") return <WorkRelationshipRequest/>
    }

    const workRelationshipDescription = ()=>{
        //@ts-ignore
        const nameToShow = requesterData.displayName || requesterData.username || "Null";
        const foodTruckDesc = `If you accept, then ${nameToShow} will be able to request to vend their food truck at your location\n`
        const accountDesc = `If you accept, then ${nameToShow} will be able to request events with you\n`
        const entertainmentDesc = `If you accept, then ${nameToShow} will be able to request events with you\n`
        const staffDesc = `If you accept, then ${nameToShow} will be listed on your profile as staff\n`
        if(requesterData.accountType === 'account' && requesterData.establishmentType === 'Food Truck') return foodTruckDesc
        if(requesterData.accountType === 'account') return accountDesc
        if(requesterData.accountType === 'entertainment') return entertainmentDesc
        if(requesterData.accountType === 'staff') return staffDesc
    }

    const WorkRelationshipRequest = ()=>{
        //@ts-ignore
        const nameToShow = requesterData.displayName || requesterData.username || "Null";
        const requestText = requestType === 'employee-request' ? "employee" : "employer"
        return(
            <Card style={{
                padding: 10,
                flexDirection: 'column',
                justifyContent: 'space-around',
                
            }}>

                <Text
                    style={{
                        // alignContent: 'center',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: scaleFontSize(18),
                    }}>
                        
                    {`${nameToShow} has requested you as their ${requestText}\n`}
                </Text>
                <Text style={{fontSize: scaleFontSize(15), fontWeight: '300'}}>
                    {workRelationshipDescription()}
                </Text>
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                    <AcceptRequestButton onPress={onAcceptRequest} />
                    <DenyRequestButton onPress={onDenyRequest}/>
                </View>
            </Card>
        )
    }
    const AdminRequestContent = ()=>{
        return(
            <Card style={{
                padding: 10,
                flexDirection: 'column',
                justifyContent: 'space-around',
                
            }}>

                <Text
                    style={{
                        // alignContent: 'center',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: scaleFontSize(18),
                    }}>
                    {`You've been requested to act as an admin for ${requesterData.displayName}\n`}
                </Text>
                <Text style={{fontSize: scaleFontSize(15), fontWeight: '300'}}>
                    {`If you accept, you will be able to switch to between their account from the menu in the top right corner of the home page\n`}
                </Text>
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                    <AcceptRequestButton onPress={onAcceptAdminRequest} />
                    <DenyRequestButton onPress={onDenyAdminRequest}/>
                </View>
            </Card>
        )
    }
    const GigRequestContent = ()=>{
        return(
            <Card style={{
                padding: 10,
                flexDirection: 'column',
                justifyContent: 'space-around',
                
            }}>

                {requestType !== "gig" &&<Text
                    style={{
                        alignContent: 'center',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: scaleFontSize(18),
                        marginBottom: 20
                    }}>
                    {content}
                </Text>}
                {requestType === "gig" && employeeData && gigData &&
                    <>
                        <Text style={{fontSize: scaleFontSize(14), marginBottom: 10}}>
                            Please accept or deny the following {employeeData.accountType === 'entertainment' ? 'entertainer' : 'food truck'} request from {employeeData.displayName || employeeData.username}
                        </Text>
                        <Text style={{fontSize: scaleFontSize(12)}}>
                            Date: {formatDate(gigData.date)}
                        </Text>
                        <Text style={{fontSize: scaleFontSize(12)}}>
                            Start Time: {formatBusinessHours(gigData.start)}
                        </Text>
                        <Text style={{fontSize: scaleFontSize(12), marginBottom: 10}}>
                            End Time: {formatBusinessHours(gigData.end)}
                        </Text>
                    </>
                
                }
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                    <AcceptRequestButton onPress={onAcceptGig} />
                    <DenyRequestButton onPress={onDenyGig}/>
                </View>
            </Card>
        )
    }
    const GigModifyContent = ()=>{
        return(
            <Card style={{
                padding: 10,
                flexDirection: 'column',
                justifyContent: 'space-around',
                
            }}>

                {requestType !== "gig" &&<Text
                    style={{
                        alignContent: 'center',
                        textAlign: 'center',
                        fontWeight: '500',
                        fontSize: scaleFontSize(18),
                        marginBottom: 20
                    }}>
                    {content}
                </Text>}
                {requestType === "gig-modify" && employeeData && gigData && previousGigData &&
                    <>
                        <Text style={{fontSize: scaleFontSize(14), marginBottom: 10}}>
                            Please accept or deny the following {employeeData.accountType === 'entertainment' ? 'entertainer' : 'food truck'} modification request from {employeeData.displayName || employeeData.username}
                        </Text>
                        <Text style={{fontSize: scaleFontSize(12)}}>
                            {
                                previousGigData.date !== gigData.date ?
                                `Old Date: "${formatDate(previousGigData.date)}"\nNew Date: "${formatDate(gigData.date)}"\n\n` :
                                `Date unchanged: "${formatDate(previousGigData.date)}"\n\n`
                            }
                        </Text>
                         <Text style={{fontSize: scaleFontSize(12)}}>
                            {
                                previousGigData.start !== gigData.start ?
                                `Old Start Time: ${formatBusinessHours(previousGigData.start)}\nNew Start Time: ${formatBusinessHours(gigData.start)}\n\n`:
                                `Start time unchanged: "${formatBusinessHours(previousGigData.start)}"\n\n`
                            }
                        </Text>
                        <Text style={{fontSize: scaleFontSize(12), marginBottom: 10}}>
                            {
                                previousGigData.end !== gigData.end ?
                                `Old End Time: ${formatBusinessHours(previousGigData.end)}\nNew End Time: ${formatBusinessHours(gigData.end)}\n\n` :
                                `End time unchanged: "${formatBusinessHours(previousGigData.date)}"\n\n`
                            }
                        </Text>
                    </>
                }
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                    <AcceptRequestButton onPress={onAcceptGigModification} />
                    <DenyRequestButton onPress={onDenyGigModification}/>
                </View>
            </Card>
        )
    }

    return(
        <TouchableOpacity onPress={handleNotificationPress} onLongPress={handleDeleteNotification}>
            <CustomModal
                    onBack={()=>{
                        // setPostContentInput("");
                        setShowRequestModal(false);
                        // setShowCommentsModal(true)
                    }}
                    title={getModalTitle()}
                    visibilityFlag={showRequestModal}
                    visibilityControlFunc={setShowRequestModal}
                    showSubmit={false}
                    scroll={false}
                    containerStyle={{
                        alignSelf: 'center',
                        justifyContent: 'center',
                        height: '80%',
                        width: '80%',
                        // borderWidth: 1,
                        // borderColor: 'red',
                    }}
                    cardStyle={{
                        // borderWidth: 1,
                        // borderColor: 'red',
                        // height: modalSize,
                        width: SCREEN_SIZE.width,
                        // position: (Platform.OS === 'ios' ? 'absolute' : 'relative'),
                        // top: (Platform.OS === 'ios' ? Header : 0),
                    }}
                >
                    {renderModalContent()}
            </CustomModal>
            <Card style={{
                flexDirection: 'row',
                height: SCREEN_SIZE.height * .15,
                width: SCREEN_SIZE.width * .90,
                marginTop: 10,
                padding: 10,
                backgroundColor: (!read ? 'rgba(22,128,144, 0.03)' : '#fff')
            }}>
                <View
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        // borderColor: 'pink',
                        // borderWidth: 2,
                    }}
                >
                    {(
                        image && !image.match('false')
                        ?
                        <CacheImage
                            resizeMode={'cover'}
                            //@ts-ignore
                            uri={(notificationUserData && notificationUserData.profilePictureKey) ? `${IMAGEKIT_URL}/${notificationUserData.profilePictureKey}` : image}
                            style={styles.notificationImage}
                        />
                        :
                        <Image
                            resizeMode={'cover'}
                            source={(notificationUserData && notificationUserData.profilePictureKey) ? {uri: `${IMAGEKIT_URL}/${notificationUserData.profilePictureKey}`} : require('../assets/alwayslocal-logo.png')}
                            style={styles.notificationImage}
                        />
                    )}
                </View>
                <View style={{
                    flexDirection: 'column',
                    flex: 1,
                    padding: 10,
                }}>
                    <Text style={{

                        // borderColor: 'yellow',
                        // borderWidth: 2,

                        flexWrap: 'wrap',
                        fontSize: scaleFontSize(15)
                        
                        }}>
                        {content}
                    </Text>
                    <Text style={{
                        // borderColor: 'red',
                        // borderWidth: 2,
                        alignSelf: 'flex-start',
                        marginTop: 5,
                        color: (!read ? COLORS.primaryBlue : '#000' ),
                        fontWeight: (!read ? 'bold' : 'normal'),
                    }}>
                        {timeSinceCreated}
                    </Text>
                </View>
                {(!read && <View style={{
                    height: 20,
                    width: 20,
                    borderRadius: 10,
                    marginRight: 10,
                    backgroundColor: COLORS.primaryBlue,
                    alignSelf: 'flex-end',
                    // position: 'absolute',
                }}/>)}
            </Card>
        </TouchableOpacity>
    )

}

const styles = StyleSheet.create({
    notificationImage: {
        height: 90,
        width: 90,
        alignSelf: 'center',
        borderColor: '#000',
        borderRadius: 45,
    }
})

export default Notification