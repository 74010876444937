import { useEffect, useState } from "react";
import { Screen } from "./Screen"
import KeyboardController from "../KeyboardController";
import { SCREEN_SIZE, isDeviceOld } from "../Constants";
import { Platform } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const DEFAULT_SCREEN_SIZE = SCREEN_SIZE.height

const KeyboardSafeScreen = (props: any)=>{
    const { isHeaderShown, children } = props;
    const [screenSize, setScreenSize] = useState(DEFAULT_SCREEN_SIZE);
    const [screenPadding, setScreenPadding] = useState(0);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    useEffect(()=>{
        const keyboardController = new KeyboardController(handleKeyboardHidden, handleKeyboardShown)
    }, [])

  

    const handleKeyboardHidden = (e: any)=>{
        // Keyboard.
        // console.log("Hiding keyboard");
        // console.log("E from hide: ", e);
        // return;
        setScreenSize(DEFAULT_SCREEN_SIZE);
        // setKeyboardShown(false);
        setScreenPadding(0);
        setKeyboardHeight(0);
    }

    const handleKeyboardShown = (e: any)=>{
        if(e){
            // console.log("E: ", e);
            // console.log("Keyboard shown");
            //@ts-ignore
            setScreenSize( (DEFAULT_SCREEN_SIZE - e.endCoordinates.height) );
            setKeyboardHeight(e.endCoordinates.height);
            // setKeyboardShown(true);
            //TODO Screen padding should be size of the CHAT INPUT!
            // setScreenPadding(Header/15);
        }
    }

    const { bottom } = useSafeAreaInsets();
    return(
        <Screen
            isHeaderShown={isHeaderShown}
            style={{
                flex: 1,
            height: screenSize,
            marginBottom: (Platform.OS === 'ios' ? ( isDeviceOld ? (keyboardHeight+bottom) : (keyboardHeight) ) : 0),
            paddingBottom: (Platform.OS === 'ios' ? ( isDeviceOld ? (screenPadding+bottom) : (screenPadding) ) : 0),
            ...props.style

        }}>
            {children}
        </Screen>
    )
}

export default KeyboardSafeScreen