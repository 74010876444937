import React, { useState } from 'react';
import { StyleSheet, View, Text, Modal, TouchableWithoutFeedback, Image, ActivityIndicator } from 'react-native';
import { IMAGEKIT_URL, SCREEN_SIZE } from '../Constants';
// import { cloudFrontUrl, SCREEN_SIZE } from '../Constants';
import getCameraRollPermissionAsync from '../helpers/getCameraRollPermissionAsync';
import * as ImagePicker from 'expo-image-picker';
import CustomButton from './CustomButton';
import { Platform } from 'react-native';
// import uploadToStorage from '../helpers/uploadToStorage';
// import i18n from 'i18n-js';
import { COLORS } from '../Constants';
// import { connect } from 'react-redux';
// import Fire from '../Fire';
import scaleFontSize from '../helpers/scaleFontSize';
import { scale } from 'react-native-size-matters/extend';
import uploadToStorage from '../helpers/uploadToStorage';
import isMobile from '../helpers/isMobile';
import catchErrorForSentry from '../helpers/catchSentryError';
// const firebase = Fire.getFirebase()


const UploadPictureModal = (props: any)=>{

    const [uploading, setUploading] = useState(false)
    // const [selectedImageRemoved, setSelectedImageRemoved] = useState(false)
    const [imagePreview, setImagePreview] = useState("")
    const [imageKey, setImageKey] = useState("")

    const [error, setError] = useState("");

    const {
        SelectedPictureChildren,
        selectedImageKey,
        onRemoveSelectedImage,
        picturePrefix,
        onPictureUploaded,
        onCloseModal,
        setShowModal,
        showModal
    } = props

    const handleCapturePicture = async ()=>{
        let cameraPermissionGranted = Platform.OS !== 'web' ? await getCameraRollPermissionAsync() : true;
        // console.log("Camera permission granted? ", cameraPermissionGranted);
        if(!cameraPermissionGranted){
            console.log("Permission not granted");
            return;
        }
        else{
            //@ts-ignore
            let result: any = await ImagePicker.launchImageLibraryAsync({
                //@ts-ignore
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                quality: 1
            });

            let imageKey = `${picturePrefix}-${new Date().getTime()}`;
            setImageKey(imageKey);
            setImagePreview(result.assets[0].uri)
        }
        return;
    }

    const onUploadPictureFailure = (error: any)=>{
        alert("Sorry, but.. your image failed to upload 🥺");
        setError(error);
        console.log("Error: ", error);
        console.log("Error uploading image (onUploadPictureFailure): ", JSON.stringify(error)); 
        catchErrorForSentry(error);
    }

    const onUploadPictureSuccess = ()=>{
        onPictureUploaded(imageKey);
        setImagePreview("");
    }

    const uploadPicture = async ()=>{
        setUploading(true);
        try{
            await uploadToStorage(imagePreview, imageKey, "image", onUploadPictureSuccess, onUploadPictureFailure)
        }
        catch(error){
            catchErrorForSentry("Something went wrong when uploading media");
        }
        setUploading(false);
    }

    const renderUploadImageContent = ()=>{
        return(
            <>
                <Image style={{height: 150, width: 150, alignSelf: 'center', borderRadius: 10, marginBottom: 10}}
                    source={imagePreview ? {uri: imagePreview} : require('../assets/alwayslocal-logo.png')}
                    resizeMode={imagePreview ? 'cover' : 'contain'}
                />
                {/* <Text style={{height: 150, width: 150, alignSelf: 'center', borderRadius: 10, marginBottom: 10}}>Placeholder picture</Text> */}
                <CustomButton
                    onPress={imagePreview ? uploadPicture : handleCapturePicture}
                    style={{...styles.uploadPictureBtn,}}
                    textStyle={{...styles.btnTextStyle, fontSize: scaleFontSize(15)}}
                    title={imagePreview ? 'Upload Picture' : 'Choose Picture'}
                />
                {(imagePreview && <CustomButton
                    onPress={()=>setImagePreview(null)}
                    style={{...styles.uploadPictureBtn, marginTop: 10}}
                    textStyle={{...styles.btnTextStyle, fontSize: scaleFontSize(15)}}
                    title={'Cancel'}
                />)}
            </>
        )
    }

    const removeSelectedImage = async ()=>{
        await onRemoveSelectedImage();
        setImagePreview(null);
    }

    const renderSelectedImage = ()=>{
        return(
            <>
                <Image style={{height: 150, width: 150, alignSelf: 'center', borderRadius: 10, marginBottom: 10}}
                    source={selectedImageKey ? {uri: `${IMAGEKIT_URL}/${selectedImageKey}`} : require('../assets/alwayslocal-logo.png')}
                    resizeMode={'cover'}
                />
                {SelectedPictureChildren && <SelectedPictureChildren selectedImageKey={selectedImageKey}/>}
                <CustomButton
                    onPress={removeSelectedImage}
                    style={{...styles.uploadPictureBtn,}}
                    textStyle={{...styles.btnTextStyle, fontSize: scaleFontSize(15)}}
                    title={'Remove Picture'}
                />
            </>
        )
    }

    const renderModalContent = ()=>{
        if(uploading) return <ActivityIndicator size={'large'} color={'red'}/>
        else return (selectedImageKey ? renderSelectedImage() : renderUploadImageContent())
    }

    return(
        <Modal
        style={{zIndex: 9999}}
        animationType="fade"
        transparent={true}
        visible={showModal}
        onRequestClose={()=>{
            console.log("Closing modal");
        }}
        >
        <View style={{
            // zIndex: 9999,
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, .6)',
            alignContent: 'center',
            justifyContent: 'center',
            height: SCREEN_SIZE.height,
            width: SCREEN_SIZE.width,
            }}>
                <TouchableWithoutFeedback
                    onPress={()=>{
                        if(onCloseModal) onCloseModal();
                        setShowModal(false)
                    }}
                >
                    <View style={{
                        height: '55%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                    }}>

                    </View>
                </TouchableWithoutFeedback>
                <View style={Platform.OS === 'web' ? {...styles.webModalCard, width: isMobile() ? '90%' : '50%'} : styles.mobileModalCard}>
                    <View
                        style={{
                            // borderWidth: 2,
                            // borderColor: 'red',
                            width: '100%',
                        }}
                    >
                        {renderModalContent()}
                    </View>

                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    btnTextStyle:{
        fontSize: scaleFontSize(25),
        color: '#fff',
        textAlign: 'center',
    },
    uploadPictureBtn:{
        width: '50%',
        borderRadius: 10,
        padding: 10,
        alignSelf: 'center',
    },
    mobileModalCard: {
        borderTopLeftRadius: 50,
        borderTopRightRadius: 50,
        backgroundColor: '#fff',
        height: '45%',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    webModalCard: {
        borderRadius: 50,
        backgroundColor: '#fff',
        height: '65%',
        position:'relative',
        bottom: 'default',
        alignSelf: 'center',
        // alignItems: 'center',
        justifyContent: 'center',
    },
})

// const mapStateToProps = (state: any) =>{
//     return {
//       globalState: state,
//     };
// }
  
export default UploadPictureModal
// export default connect(mapStateToProps, null )(UploadPictureModal);