import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import ensureProtocol from "../helpers/ensureProtocol";
import Fire from "../Fire";
import Toast from "react-native-toast-message";
import { updateUserDataProperty } from "../store/slices/userData";
import ValidateInput from "./ValidateInput";
import { View } from "react-native";
import scaleFontSize from "../helpers/scaleFontSize";
import { COLORS, errorMessages, regexPatterns } from "../Constants";
import CustomButton from "./CustomButton";
import { useState } from "react";
import VisibilitySlider from "./VisibilitySlider";
import showToast from "../helpers/showToast";
import catchErrorForSentry from "../helpers/catchSentryError";

const UrlEditor = (props: any) => {
    const { isVisible=true, platformName, state, setState, onSuccess=false, onFailure=false, showVisibilitySlider=false} = props;
    const urlKey = `${platformName.toLowerCase()}Url`;
    const userData = useSelector((state: RootState) => state.userData);
    //@ts-ignore
    const [url, setUrl] = useState(userData[`${urlKey}`] || "");
    const [isUrlVisible, setIsUrlVisible] = useState(isVisible);
    const dispatch = useDispatch();

    const onChangeUrl = () => {
        // const website = ensureProtocol(state[urlKey]);
        const website = url ? ensureProtocol(url) : "";

        //Updating database
        Fire.updateUserDataRoute(`${userData.uid}`, { [urlKey]: website })
        .then((success: any) => {
            // Display success toast
            onSuccess && onSuccess();
            showToast(`Your ${platformName} URL has been updated!`, true);
        })
        .catch((error) => {
            // Display error toast
            onFailure && onFailure();
            showToast(`Failed to update ${platformName} URL. Please try again.`, false);
        });

        //Updating Local Data
        dispatch(updateUserDataProperty({ property: urlKey, value: website }));
    };

    const onToggleVisiblity=async (isVisible: boolean)=>{
        const originalVisibility = isVisible; // Backup the original email
        try {
            // Attempt to update the database
            await Fire.setUserDataRoute(`${userData.uid}/is${platformName}Visible`, isVisible);

            // If successful, update the local data
            dispatch(updateUserDataProperty({ property: `is${platformName}Visible`, value: isVisible }));
            showToast(`${platformName} is now ${!isVisible ? 'hidden' : 'visible'}`, true);
        } catch (error) {
            // If there's an error, rollback local data to its original state
            dispatch(updateUserDataProperty({ property: `is${platformName}Visible`, value: originalVisibility }));
            showToast('Failed to toggle visibility', false);
            catchErrorForSentry(error);
        }
        setIsUrlVisible(isVisible);
    }

    return (
        <View style={{ width: '100%' }}>
            <ValidateInput
                inputStyle={{
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    borderRadius: 10,
                }}
                style={{marginBottom: 10}}
                errorMessage={errorMessages.websiteError}
                errorName={'websiteErrorFlag'}
                // onInputChange={(value: string)=>setState((prevState: any)=>{prevState.website = value; return prevState;})}
                // onInputChange={(value: string)=>setUrl(value)}
                regex={regexPatterns.url}
                placeholder={`Enter your ${platformName} url`}
                defaultValue={url}
                onInputChange={setUrl}
                // defaultValue={state[urlKey]}
                label={`${platformName}`}
            />
            <View style={{marginTop: 10, width: '100%', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between'}}>
                {showVisibilitySlider && <VisibilitySlider isVisible={isUrlVisible} setUpdating={()=>{}} onToggle={onToggleVisiblity}/>}
                <CustomButton
                    onPress={onChangeUrl}
                    title={"Submit"}
                    textStyle={{
                        color: 'white',
                        fontWeight: '500', 
                        fontSize: scaleFontSize(12.5),
                    }}
                    style={{
                        backgroundColor: COLORS.primaryBlue,
                        alignSelf:'flex-end',
                        // height: '25%',
                        padding:10,
                        width: '25%',
                        minWidth: 100,
                        borderRadius: 10,
                    }}
                />
            </View>
            
        </View>
    );
};

export default UrlEditor;
