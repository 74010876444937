import { useRef, useState } from "react";
import React from "react";
import { Vibration, TouchableWithoutFeedback, TouchableHighlight, Modal, StyleSheet, ScrollView, View, Text, Platform } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import Message from "./Message";
import { COLORS, SCREEN_SIZE } from "../Constants";
import { MessageInterface } from "../Interfaces";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import scaleFontSize from "../helpers/scaleFontSize";



let key = 0;

const Messages = (props: any)=>{
    const userData = useSelector((state: RootState)=>state.userData)
    const {containerStyle, messages} = props;
    const [showOptionsModal, setShowOptionsModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState();
    const scrollViewRef = useRef(null);
    const getTime = ()=> {
        let today = new Date();
        return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      }
    const renderMessages = ()=> {
        if(!messages) return <Text style={{fontSize: scaleFontSize(15), textAlign: 'center'}}> There are no messages here yet!</Text>
          
          // const {name: receiverName, email: receiverEmail, messages} = this.props.messages;
    
          // console.log("Messages: ", messages);
          let messageArray = [];
          for ( let [messageId, message] of Object.entries(messages) ){
            // console.log("messageID from messageObj: ", messageId);
            // console.log("message from messageObj: ", message);
            //@ts-ignore
            messageArray.push(renderMessageFromHistory(message, messageId));
          }
          return messageArray;
    }
    const renderMessageFromHistory = (messageData: MessageInterface, key: any)=> {
        // const messageFromMe = messageData.sender.toLowerCase() === this.props.currentMember.email.toLowerCase();
        const messageFromMe = messageData.senderUID === userData.uid
        
        // console.log("messageData: ", messageData);
    
        // console.log("Message from me: ", messageFromMe);
        const message = messageData.message
        const sender = messageData.sender

        // return <Text>Message from history</Text>
        
        if(messageFromMe){
          return(
            <Message
              key={messageData.id}
              messageData={messageData}
              isMessageFromUser={messageFromMe}
              text={messageData.message}
              onLongPress={()=>onMessageLongPress(messageData.message)}
            />
          )
        }
        else{
          return(
            <Message
              key={messageData.id}
              messageData={messageData}
              isMessageFromUser={messageFromMe}
              text={messageData.message}
              onLongPress={()=>onMessageLongPress(messageData.message)}
            />
          )
        }
    }
    const onMessageLongPress = (message: any)=>{
        setShowOptionsModal(true);
        setSelectedMessage(message);
        (Platform.OS === 'android' && Vibration.vibrate(10))
      }
      
    return (
        // <View className="Messages-list">
        <ScrollView 
        ref={scrollViewRef}
        onContentSizeChange={(contentWidth, contentHeight)=>{   
          scrollViewRef.current?.scrollToEnd({animated: true});}}
        contentContainerStyle={{...containerStyle}}
        >
          <Modal
              animationType="slide"
              transparent={true}
              visible={showOptionsModal}
              onRequestClose={()=>{
                  // console.log("Closing modal");
              }}
              >
              <View style={{
                  // ...styles.screen,
                  flex: 1,
                  // backgroundColor: 'rgba(0, 0, 0, .6)',
                  alignContent: 'center',
                  justifyContent: 'center',
                  // alignSelf: 'center',
                  height: SCREEN_SIZE.height,
                  width: SCREEN_SIZE.width,
                  // borderColor: 'yellow',
                  // borderWidth: 2
                  }}>
                      <TouchableWithoutFeedback
                          onPress={()=>{
                              setShowOptionsModal(false);
                          }}
                      >
                          <View style={{
                              // backgroundColor: 'rgba(0, 0, 0, .6)',
                              height: '85%',
                              width: '100%',
                              position: 'absolute',
                              top: 0,
                          }}>
  
                          </View>
                      </TouchableWithoutFeedback>
                      <View style={{
                        // md-copy
                        // borderTopLeftRadius: 50,
                        // borderTopRightRadius: 50,
                        shadowColor: 'black',
                        shadowOpacity: 0.26,
                        shadowOffset: { width: 0, height: 2 },
                        shadowRadius: 8,
                        elevation: 5,
  
                        paddingTop: 10,
                        // borderTopWidth: 2,
                        // borderColor: COLORS.primaryBlue,
                        backgroundColor: '#fff',
                        height: '15%',
                        width: '100%',
                        position: 'absolute',
                        bottom: 0,
                        // justifyContent: 'center',
                        // alignContent: 'center'
                      }}>
                        <TouchableHighlight onPress={async ()=>{
                            //TODO Clipboard deprecated
                        //   await Clipboard.setString(this.state.selectedMessage);
                          setShowOptionsModal(false);
                        }}>
                          <View style={{ alignSelf: 'center', alignContent: 'center' }}>
                            <Ionicons style={{alignSelf: 'center'}} name={'ios-copy'} size={50} color={COLORS.primaryBlue} />
                            <Text style={{textAlign: 'center', fontSize: scaleFontSize(15), color: COLORS.primaryBlue}}>Copy</Text>
                          </View>
                        </TouchableHighlight>
                      </View>
              </View>
          </Modal>
  
          {renderMessages()}
        </ScrollView>
        // </View>
      );
}

// class Messages extends Component {
//   constructor(props){
//     super(props);
//     this.state = {
//       imageUrl: "",
//       returnPlaceholder: false,
//       showOptionsModal: false,
//       selectedMessage: "",
//     }
//   }

//   getTime = ()=> {
//     let today = new Date();
//     return today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
//   }

//   renderMessageFromHistory = (messageData, key)=> {
//     // const messageFromMe = messageData.sender.toLowerCase() === this.props.currentMember.email.toLowerCase();
//     const messageFromMe = messageData.senderUID === this.props.currentMember.uid;
    
//     // console.log("messageData: ", messageData);
//     // console.log("this.props.currentMember: ", this.props.currentMember);

//     // console.log("Message from me: ", messageFromMe);
//     const message = messageData.message
//     const sender = messageData.sender

//     if(messageFromMe){
//       return(
//         <Message
//           key={messageData.id}
//           messageData={messageData}
//           isMessageFromUser={messageFromMe}
//           text={messageData.message}
//           onLongPress={()=>this.onMessageLongPress()}
//         />
//       )
//     }
//     else{
//       return(
//         <Message
//           key={messageData.id}
//           messageData={messageData}
//           isMessageFromUser={messageFromMe}
//           text={messageData.message}
//           onLongPress={()=>this.onMessageLongPress()}
//         />
//       )
//     }

//   }

//   renderAllMessageHistory = ()=> {
//     if(this.props.messages){
      
//       let messages = this.props.messages

//       // const {name: receiverName, email: receiverEmail, messages} = this.props.messages;

//       if(!messages) return <Text style={{fontSize: scaleFontSize(15), textAlign: 'center'}}> There are no messages here yet!</Text>
        
//       let messageArray = [];
//       for ( let [messageId, message] of Object.entries(messages) ){
//         // console.log("messageID from messageObj: ", messageId);
//         // console.log("message from messageObj: ", message);
//         messageArray.push(this.renderMessageFromHistory(message, messageId));
//       }
//       return messageArray;

//     }
//   }


//   onMessageLongPress = (message)=>{
//     this.setState((prevState)=>{
//       prevState.showOptionsModal = true;
//       prevState.selectedMessage = message;
//       return prevState;
//     }, console.log("Set options modal true"));
//     (Platform.OS === 'android' && Vibration.vibrate(10))
//   }

//   //TODO: Stop using scaledrone for live messages
//   renderLiveMessage = (message, receiverUid, receiverEmail, key) => {

//     // console.log("rendering live message");
//     // console.log(`Current User:  ${this.props.currentUserData.email}`);
//     // console.log("Message: ", message);
//     const text = message.text;
//     const member = message.member;
//     const to = message.newTo || message.to;

//     const messageFromMe = member.id === this.props.currentMember.id;

//     // console.log("Text is: ", text);
//     // console.log("Is message to me? ", (to === this.props.currentUserData.email.toLowerCase()));
//     // console.log("Client data: ", message.member.clientData);
//     // console.log("to: ", to);
//     // console.log("receiverUid", receiverUid);
//     // console.log("Member cleintData username: ", member.clientData.username);
//     // console.log("Is message from the receiver in my chat room? ", (receiverUid === member.clientData.username));

//     // console.log("Receiver Email: ", receiverEmail);
//     // console.log("Member: ", member);

//     //Second part of the condition is to ensure that a message sent to you, from another chatroom, isn't rendered here.
//     let emailBasedCondition = (to === this.props.currentUserData.email.toLowerCase()) && (receiverEmail.toLowerCase() === member.clientData.username.toLowerCase());
//     let uidBasedCondition = (to === this.props.currentUserData.uid) && (receiverUid === member.clientData.uid);

//     // console.log("Email Condition: ", emailBasedCondition);
//     // console.log("Uid Condition: ", uidBasedCondition);

//     if(messageFromMe){
//       return(
//         <Message
//           messageData={message}
//           isMessageFromUser={messageFromMe}
//           text={text}
//           onLongPress={()=>onMessageLongPress()}
//         />
//       )
//     }
//     else if( uidBasedCondition || emailBasedCondition ){
//       return(
//         <Message
//           messageData={message}
//           isMessageFromUser={messageFromMe}
//           text={text}
//           onLongPress={()=>onMessageLongPress()}
//         />
//       )
//     }
    
//     // if(messageFromMe){
//     //   return (
//     //     <TouchableHighlight key={key} style={{...styles.touchable, flex: 0, alignSelf: 'flex-end'}} onLongPress={()=>{
//     //       onMessageLongPress()
//     //     }}>
//     //       <View style={{...styles.chatBubble, backgroundColor: COLORS.primaryBlue, flex: 0, alignSelf: 'flex-end'}}  key={key}  >
//     //         {/* <View style={{backgroundColor: member.clientData.color}}/> */}
//     //         <View style={{ flex: 0, flexDirection: 'column', justifyContent: 'flex-end', maxWidth: '92.5%' }}>
//     //           {/* <View>
//     //             <Text style={{textAlign: "right"}}> :{this.props.currentMember.username}</Text>
//     //           </View> */}
//     //           <View /* style={{flex: 1}} */ >
//     //             <Text style={{textAlign: "left", color: '#fff'}}>{text}</Text>
//     //           </View>
//     //         </View>
//     //       </View>
//     //     </TouchableHighlight>
//     //   );
//     // }
//     // // else if( (to === this.props.currentUserData.email.toLowerCase()) && (receiverEmail === member.clientData.username.toLowerCase()) ){
//     // else if( uidBasedCondition || emailBasedCondition ){
//     //   return (
//     //     <TouchableHighlight key={key} style={{...styles.touchable, flex: 0, alignSelf: 'flex-start'}} onLongPress={()=>{
//     //       onMessageLongPress()
//     //     }}>
//     //       <View style={{...styles.chatBubble, flex: 0, alignSelf: 'flex-start'}} key={key}  >
//     //         {/* <View style={{backgroundColor: member.clientData.color}}/> */}
//     //         <View style={{  flex: 0, flexDirection: 'row', justifyContent: 'flex-start'}}>
//     //           <View>
//     //             {/* <Text style={{textAlign: "right"}}>{receiverName}: </Text> */}
//     //             <UserImage
//     //                   style={{width: 40, height: 40, borderRadius: 20, marginRight: 5}}
//     //                   // imageUrl={this.state.imageUrl}
//     //                   // returnPlaceholder={this.state.returnPlaceholder}
//     //                   uid={this.props.messengerData.uid}
//     //                   />
//     //             {/* <UserImage
//     //                   style={{width: 25, height: 25, marginRight: 5}}
//     //                   imageUrl={this.state.imageUrl}
//     //                   returnPlaceholder={this.state.returnPlaceholder}
//     //               /> */}
//     //           </View>
//     //           <View style={{ maxWidth: '92.5%' }}>
//     //             <Text style={{textAlign: "left"}}>{text}</Text>
//     //           </View>
//     //         </View>
//     //       </View>
//     //     </TouchableHighlight>
//     //   );
//     // }
//   }


//   render() {
//     // console.log("Messages rendered");
//     // const {messages} = this.props;
//     // const {name: receiverName, email: receiverEmail, uid: receiverUid} = this.props.messengerData;
//     return (
//       // <View className="Messages-list">
//       <ScrollView 
//       ref={ref => this.scrollView = ref}
//       onContentSizeChange={(contentWidth, contentHeight)=>{        
//         this.scrollView.scrollToEnd({animated: true});}}
//       style={{...this.props.style}}
//       >
//         <Modal
//             animationType="slide"
//             transparent={true}
//             visible={this.state.showOptionsModal}
//             onRequestClose={()=>{
//                 // console.log("Closing modal");
//             }}
//             >
//             <View style={{
//                 // ...styles.screen,
//                 flex: 1,
//                 // backgroundColor: 'rgba(0, 0, 0, .6)',
//                 alignContent: 'center',
//                 justifyContent: 'center',
//                 // alignSelf: 'center',
//                 height: Dimensions.get("window").height,
//                 width: Dimensions.get("window").width,
//                 // borderColor: 'yellow',
//                 // borderWidth: 2
//                 }}>
//                     <TouchableWithoutFeedback
//                         onPress={()=>{
//                             // setShowOptionsModal(false);
//                             this.setState((prevState)=>{
//                               prevState.showOptionsModal = false;
//                               return prevState;
//                             })
//                         }}
//                     >
//                         <View style={{
//                             // backgroundColor: 'rgba(0, 0, 0, .6)',
//                             height: '85%',
//                             width: '100%',
//                             position: 'absolute',
//                             top: 0,
//                         }}>

//                         </View>
//                     </TouchableWithoutFeedback>
//                     <View style={{
//                       // md-copy
//                       // borderTopLeftRadius: 50,
//                       // borderTopRightRadius: 50,
//                       shadowColor: 'black',
//                       shadowOpacity: 0.26,
//                       shadowOffset: { width: 0, height: 2 },
//                       shadowRadius: 8,
//                       elevation: 5,

//                       paddingTop: 10,
//                       // borderTopWidth: 2,
//                       // borderColor: COLORS.primaryBlue,
//                       backgroundColor: '#fff',
//                       height: '15%',
//                       width: '100%',
//                       position: 'absolute',
//                       bottom: 0,
//                       // justifyContent: 'center',
//                       // alignContent: 'center'
//                     }}>
//                       <TouchableHighlight onPress={async ()=>{
//                         await Clipboard.setString(this.state.selectedMessage);
//                         this.setState((prevState)=>{
//                           prevState.showOptionsModal = false;
//                           return prevState;
//                         })
//                       }}>
//                         <View style={{ alignSelf: 'center', alignContent: 'center' }}>
//                           <Ionicons style={{alignSelf: 'center'}} name={'ios-copy'} size={50} color={COLORS.primaryBlue} />
//                           <Text style={{textAlign: 'center', fontSize: scaleFontSize(15), color: COLORS.primaryBlue}}>Copy</Text>
//                         </View>
//                       </TouchableHighlight>
//                     </View>
//             </View>
//         </Modal>

//         {this.renderAllMessageHistory()}

//         {/* {
//           messages.map((m) =>{
//               key+=1;
//               return this.renderLiveMessage(m, receiverUid, receiverEmail, key);
//           })
//         } */}



//       </ScrollView>
//       // </View>
//     );
//   }


// }



export default Messages;
