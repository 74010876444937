import { ActivityIndicator, StyleSheet, View } from "react-native"
import AlwaysLocalLogo from "./AlwaysLocalLogo"
import Card from "./Card"
import TouchableBackButton from "./TouchableBackButton"
import { COLORS, regexPatterns } from "../Constants"
import SignUpSheet from "../navigation/components/SignUpSheet"
import CustomButton from "./CustomButton"
import Fire from "../Fire"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { setUserData } from "../store/slices/userData"
import catchErrorForSentry from "../helpers/catchSentryError"


const LoginCard = (props: any)=>{
    const dispatch = useDispatch();
    const {
        setShowLoginButton,
        setShowSignUpCard,
        showLoginButton,
        navigation
    } = props;
    const [state, setState] = useState({
        email: "",
        // email: "tyfooods@gmail.com",
        emailError: 'Email must be valid',
        
        password: "",
        // password: "alwayslocal",
        passwordError: 'Your password must be 6 characters or longer',
        
        usernameErrorFlag: false,
        nameFlag: false,
        emailErrorFlag: false,
        phoneNumberErrorFlag: false,
        passwordErrorFlag: false,
    })
    const [processing, setProcessing] = useState(false);
    const signUpSheetInputs = [
        {
            name: 'Email',
            errorMessage: state.emailError,
            errorName: 'emailErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;}),
            regex: regexPatterns.email,
            defaultValue: state.email || '',
        },
        {
            name: 'Password',
            errorMessage: state.passwordError,
            errorName: 'passwordErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.password = value; return prevState;}),
            regex: regexPatterns.password,
            defaultValue: state.password || '',
            props: {
                secureTextEntry: true
            },
        },
    ]

    const onLoginPress = (event: any)=> {
        setProcessing(true);
        event.preventDefault();
        const email = state.email.trim();
        const password = state.password;
        Fire.signInWithEmailAndPassword(email, password)
        .then(async (userCredential)=>{

          setProcessing(false);
          //@ts-ignore
          const userData = await Fire.getUserData(userCredential.user.uid);
          dispatch(setUserData(userData))
          navigation.push('WebBackEndPage');
          //Maybe set user data here globally for user later?
        })
        .catch((error)=>{
          console.log("Error: ", error);
          console.log("Error signing in user JSON: ", JSON.stringify(error)); 
          catchErrorForSentry(error);
          // Sentry.captureException("Error signing in user: ", error);

          if(error.code === 'auth/user-not-found'){
            alert("User email not found!");
          }
          else if(error.code === 'auth/wrong-password'){
            alert("Your password is incorrect");
          }
          else if(error.code === 'auth/invalid-email'){
            alert("Your email is invalid");
          }
          else if(error === 'Error: Location provider is unavailable. Make sure that location services are enabled.'){
            alert("Location provider is unavailable. Make sure that your location services are enabled.");
          }
          else{
            alert("Strange error while trying to sign you in occurred - Please email ty@tyfoodsforthought.com for assistance");
          }
          console.log(`Authentication failed with this error: ${error}`)
          setProcessing(false);
        })

    }
    return(
        <Card style={{
        
            // height: '80%',
            width:'80%',
            justifyContent: 'center',
            alignItems: 'center', 
            padding: 10,
            ...props.style
        }}>
            <TouchableBackButton
                onPress={()=>{
                    setShowLoginButton(true);
                    setShowSignUpCard(true);
                }}
                containerStyle={{
                    margin: 20,
                    position: 'absolute',
                    left: '0',
                    top: '0'
                }}
                size={30}
            />
            <AlwaysLocalLogo style={{}}/>
            {
                processing
                ?
                <ActivityIndicator color={COLORS.primaryRed} size="large"/>
                :
                <View style={{width: '80%'}}>
                    <SignUpSheet inputs={signUpSheetInputs} state={state} setState={setState} />
                    <CustomButton
                        onPress={(event: React.MouseEvent<HTMLButtonElement>)=>onLoginPress(event)}
                        style={{
                            ...styles.accTypeBtn,
                            alignSelf: 'center',
                            marginTop: 10
                        }}
                        textStyle={styles.accTypeBtnTxt}
                        title={"Login"}
                    />
                </View>
            }
        </Card>
    )
}

const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#F8FAFC',
        alignItems: 'center',
        justifyContent: 'center',
    },
    signUpForm: {
      
    },
    accTypeBtn:{
        borderRadius: 10,
        backgroundColor: '#DD222E',
        width: 125,
        height: 50,
        justifyContent: 'center',
    },
    accTypeBtnTxt:{
        fontSize: 20,
        alignSelf: 'center',
        color: 'white',
        fontWeight: "500",
    },
})

export default LoginCard