import isWeb from "../helpers/isWeb"
import AccountTypeEditor from "../components/AccountTypeEditor"
import BirthdayEditor from "../components/BirthdayEditor"
import FavoriteDrinksEditor from "../components/FavoriteDrinksEditor"
import ScheduleEditor from "../components/ScheduleEditor"
import ChangeAccountName from "../components/ChangeAccountName"
import ChangeAddress from "../components/ChangeAddress"
import PhoneNumberEditor from "../components/PhoneNumberEditor"
import EmailEditor from "../components/EmailEditor"
import { RootState } from "../store"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { ActivityIndicator, Keyboard, Platform, ScrollView, Text, View } from "react-native"
import { Screen } from "../components/Screen"
import UrlEditor from "../components/UrlEditor"
import DescriptionEditor from "../components/DescriptionEditor"
import KeyboardController from "../KeyboardController"
import { COLORS, SCREEN_SIZE, isDeviceOld } from "../Constants"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import EntertainmentScheduler from "../components/EntertainmentScheduler"
import DisplayedPhoneNumberEditor from "../components/DisplayedPhoneNumberEditor"
import ZipCodeEditor from "../components/ZipCodeEditor"
import GenreEditor from "../components/GenreEditor"
import TouchableContainer from "../components/TouchableContainer"
import scaleFontSize from "../helpers/scaleFontSize"
import FoodGigScheduler from "../components/FoodGigScheduler"
import { updateUserDataProperty } from "../store/slices/userData"
import Fire from "../Fire"
import ToggleSwitch from "toggle-switch-react-native"
import KeyboardSafeScreen from "../components/KeyboardSafeScreen"
import Scheduler from "../components/Scheduler"

const AccountDataEditor = (props: any)=>{
    const { navigation, route } = props;
    const noBirthday = route.params && route.params.noBirthday;
    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);
    const [entertainmentHidden, setEntertainmentHidden] = useState(userData.entertainmentHidden);
    const [updatingToggleEntertainment, setUpdatingToggleEntertainment] = useState(false);

    const isStaff = userData.accountType === 'staff';
    const isAccount = userData.accountType === 'account';
    const isUser = userData.accountType === 'user';
    const isEntertainer = userData.accountType === 'entertainment';
    const isFoodTruck = userData.accountType === 'account' && userData.establishmentType === 'Food Truck';
    
  
    const onBirthdayPress = ()=>{
        console.log("Birthday pressed!");
        //Probably just take them to the birthday screen, no?
    }
    const toggleEntertainmentSection = async (isOn: boolean)=> {

        // setUpdatingToggleEntertainment(true);

        // if(entertainmentHidden){
            console.log("Updating hidden value to: ", isOn);
            Fire.updateRoute(`publicUserData/${userData.uid}/`, {entertainmentHidden: isOn});
            dispatch(updateUserDataProperty({property: 'entertainmentHidden', value: isOn}));
            setEntertainmentHidden(isOn);
        // }
        // else{
        //     Fire.setUserDataRoute(`publicUserData/${userData.uid}/entertainmentHidden`, false);
        //     dispatch(updateUserDataProperty({property: 'entertainmentHidden', value: false}));
        //     setEntertainmentHidden(false);
        // }
   
        // setUpdatingToggleEntertainment(false);

    }
    const ToggleEntertainmentButton = (props: any)=>{
        const {updating} = props;
        if(updating){
            return <ActivityIndicator size="large" color={COLORS.primaryBlue} />
        }
        else{
            return(
                <View
                    style={{
                        width: '85%',
                        alignSelf: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        marginBottom: 15,
                        backgroundColor: 'white',
                        padding: 15,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: COLORS.primaryBlue,
                        shadowColor: 'black',
                        shadowOpacity: 0.26,
                        shadowOffset: { width: 0, height: 2 },
                        shadowRadius: 8,
                        elevation: 5,
                    }}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                            <Text style={{color: COLORS.primaryBlue, width: '75%', fontSize: scaleFontSize(14)}}>
                                Tap to {entertainmentHidden ? 'show' : 'hide'} entertainment section from profile
                            </Text>
                            <ToggleSwitch
                                isOn={entertainmentHidden}
                                onColor="#00D768"
                                offColor={COLORS.primaryRed}
                                size="small"
                                onToggle={(isOn: boolean) => {
                                    // console.log("Switch: ", isOn);
                                    setUpdatingToggleEntertainment(true);
                                    // console.log("Is On? ", isOn);
                                    toggleEntertainmentSection(isOn);
                                }}
                            />
                        </View>
                </View>
            )
        }
    }
    return(
        <KeyboardSafeScreen style={{
        }} isHeaderShown={false}>
            <ScrollView
                nestedScrollEnabled={true}
                keyboardShouldPersistTaps='always'
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                // flex: 1,
                width: '90%',
                maxWidth: isWeb() ? 500 : SCREEN_SIZE.width,
                alignSelf: 'center',
                alignItems: 'flex-start',
                justifyContent: 'center',
            }}>
                <AccountTypeEditor/>
                {!isAccount && <BirthdayEditor
                    onPress={onBirthdayPress}
                    navigation={navigation}
                    screenName={route && route.name ? route.name : ""}
                    noBirthday={noBirthday}
                />}
                { (isStaff || isUser) && <FavoriteDrinksEditor userData={userData} />}
                { (isStaff || isAccount && userData.establishmentType !== 'Food Truck') && <ScheduleEditor userData={userData} />}
                {/* {isFoodTruck && <FoodGigScheduler containerStyle={{marginVertical: 20}} navigation={navigation} />} */}
                { (isAccount || isEntertainer) && <Scheduler containerStyle={{marginVertical: 20}} navigation={navigation}/>}
                { (isAccount || isEntertainer) && !isWeb() && <TouchableContainer
                    style={{
                        padding: 10,
                        backgroundColor: COLORS.primaryBlue,
                        borderRadius: 5,
                        alignSelf:'center',
                        marginVertical: 20,
                    }}
                    onPress={()=>
                        navigation.navigate("UserSelectScreen")
                    }
                >
                    <Text style={{color: 'white', fontSize: scaleFontSize(18)}}>
                        Request new account admin
                    </Text>
                </TouchableContainer>}
                {(isAccount && !isFoodTruck) && <ToggleEntertainmentButton/>}
                {isEntertainer && <GenreEditor/>}
                {/* {isEntertainer && <EntertainmentScheduler navigation={navigation} />} */}
                <ChangeAccountName />
                { isAccount && <ChangeAddress/> }
                { isAccount && <DisplayedPhoneNumberEditor/>}
                <PhoneNumberEditor/>
                <EmailEditor/>
                { !isAccount && <ZipCodeEditor/>}
                {isAccount && <DescriptionEditor/>}
                <UrlEditor platformName={'Website'} isVisible={userData.isWebsiteVisible} showVisibilitySlider={true}/>
                <UrlEditor platformName={'TikTok'} />
                <UrlEditor platformName={'Facebook'} />
                <UrlEditor platformName={'Instagram'} />
            </ScrollView>
        </KeyboardSafeScreen>
    )
}

export default AccountDataEditor;