import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet, View, Text } from 'react-native';
import ChatInput from '../components/ChatInput';
import Messages from '../components/Messages';
import Fire from '../Fire';
import isObjEmpty from '../helpers/isObjEmpty';
import { COLORS, SCREEN_SIZE } from '../Constants/';
import CustomButton from '../components/CustomButton';
import blockConfirmationPrompt from '../helpers/blockConfirmationPrompt';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import KeyboardController from '../KeyboardController';
import createNewMessageData from '../helpers/createNewMessageData';
import catchErrorForSentry from '../helpers/catchSentryError';
import scaleFontSize from '../helpers/scaleFontSize';

const DEFAULT_SCREEN_SIZE = SCREEN_SIZE.height

const ChatScreen = (props: any)=>{
    const { route } = props;
    const { uid: receiverUID, chatRoomType, toFollowers } = route.params;
    // console.log("Route params: ", route.params);
    const userData = useSelector((state: RootState)=>state.userData);
    const [followerData, setFollowerData] = useState(userData.uidsFollowingUser ? userData.uidsFollowingUser : {});
    // const Header = useHeaderHeight()
    const [screenSize, setScreenSize] = useState(DEFAULT_SCREEN_SIZE);

    // const [chatRoomData, setChatRoomData] = useState(route.params.messages);
    const [chatRoomData, setChatRoomData] = useState();
    const [screenPadding, setScreenPadding] = useState(0);
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    useEffect(()=>{
        const keyboard = new KeyboardController(handleKeyboardHidden, handleKeyboardShown);

        const onFollowersDataChange = (followerData: any)=> followerData && setFollowerData(followerData);
        const onChatRoomDataChange = (newChatRoomData: any)=> newChatRoomData && setChatRoomData(newChatRoomData);
        let chatDataRef = Fire.listenToRoute(`chatRooms/${userData.uid}/${receiverUID}/`, onChatRoomDataChange)
        let followerDataRef = Fire.listenToRoute(`publicUserData/${userData.uid}/uidsFollowingUser/`, onFollowersDataChange)
        return () =>{
            keyboard.removeAllListeners();
            Fire.off(chatDataRef, "value", onChatRoomDataChange)
            Fire.off(followerDataRef, "value", onFollowersDataChange)
        }

    }, [])

    const handleKeyboardHidden = ()=>{
        setScreenSize(DEFAULT_SCREEN_SIZE);
        // setKeyboardShown(false);
        setScreenPadding(0);
        setKeyboardHeight(0);
    }

    const handleKeyboardShown = (e: any)=>{
        if(e){
            setScreenSize( (DEFAULT_SCREEN_SIZE - e.endCoordinates.height) );
            // setKeyboardShown(true);
            //TODO Screen padding should be size of the CHAT INPUT!
            // setScreenPadding(Header/15);
            setKeyboardHeight(e.endCoordinates.height);
        }
    }

    const isUserBlocked = ()=>{
        const {uid: receiverUID } = route.params;
        if(userData.blockList){
            if(Object.keys(userData.blockList).includes(receiverUID)){
                return true;
            }
            else{
                return false;
            }
        }
        else{
            return false;
        }
    }

    const renderMessages = ()=> {
      if(!isObjEmpty(route.params) && !isObjEmpty(chatRoomData)){
        return(
            <Messages
            
              style={styles.messages}
              //@ts-ignore
              messages={chatRoomData.messages}
              currentMember={{
                uid: userData.uid,
                username: userData.email,
                email: userData.email,
              }}
            />
        )
      }
      // else console.log("(Chat Screen) Route: ", route.params, chatRoomData);
    }

    const onSubmitMessage = (message: any) => {
      if(!message || message === "") return;
      if(toFollowers && isObjEmpty(followerData)) return console.log("No followers to send to: ", userData.uidsFollowingUser)
      
      const {name: receiverName, email: receiverEmail, uid: receiverUID } = route.params;


      let currentUserId = userData.uid;
      let currentUserEmail = userData.email;

      let receiverChatRoomMetaData = {
        email: userData.email.toLowerCase() || null,
        name: userData.username,
        status: "unread",
        uid: userData.uid,
      }

      let senderChatRoomMetaData = {
        email: receiverEmail || null,
        name: receiverName,
        status: "read",
        uid: receiverUID,
      }

      //SENDER STUFF BELOW

      //Update metadata for username chatroom
      let senderChatRoomRoute = `${chatRoomType}/${currentUserId}/${receiverUID}/`;
      // console.log("Sender chatRoomRoute to chat room: ", senderChatRoomRoute);
      // console.log("senderChatRoomMetaData: ", senderChatRoomMetaData);
      Fire.updateRoute(`${senderChatRoomRoute}/`, senderChatRoomMetaData)
      .then(()=>{
        //Adding new message to sender (i.e. Current User)
        let senderChatRoomMessagesRoute = `${chatRoomType}/${currentUserId}/${receiverUID}/messages`;
        const senderNewMessageId = Fire.getUniqueKeyForRoute(senderChatRoomMessagesRoute);
        if(!senderNewMessageId) return;
        let newMessageData = createNewMessageData(message, currentUserId, currentUserEmail, senderNewMessageId);
        // console.log("Sender message data: ", newMessageData);
        Fire.updateRoute(`${senderChatRoomMessagesRoute}/${senderNewMessageId}`, newMessageData);
        // console.log("senderChatRoomMessagesRoute: ", senderChatRoomMessagesRoute);
      })
      .catch((error: any)=>{
        catchErrorForSentry(error);
      })

      //RECEIVER STUFF BELOW
      if(toFollowers){
        if(!userData.uidsFollowingUser)return console.log("No followers to send to: ", userData.uidsFollowingUser)
        const uidsFollowingUser = Object.keys(userData.uidsFollowingUser);
        for (let receiverUid of uidsFollowingUser){
          let receiverChatRoomRoute = `${chatRoomType}/${receiverUid}/${currentUserId}/`;
          // console.log("Recipient chatRoomRoute to chat room", receiverChatRoomRoute);
          // firebase.database().ref(`${receiverChatRoomRoute}/`).update(receiverChatRoomMetaData);
          Fire.updateRoute(`${receiverChatRoomRoute}/`, receiverChatRoomMetaData)
          .then(()=>{
              //Adding new message to receiver
              let receiverChatRoomMessagesRoute = `${chatRoomType}/${receiverUid}/${currentUserId}/messages`;
              const receiverNewMessageId = Fire.getUniqueKeyForRoute(receiverChatRoomMessagesRoute);
              if(!receiverNewMessageId) return console.log("No ");
              let newMessageData = createNewMessageData(message, currentUserId, currentUserEmail, receiverNewMessageId);
              Fire.updateRoute(`${receiverChatRoomMessagesRoute}/${receiverNewMessageId}`, newMessageData);
          })
          .catch((error: any)=>{
            catchErrorForSentry(error);
          })
        }
        return;
      }
      //Update metadata for receiver chatroom
      let receiverChatRoomRoute = `${chatRoomType}/${receiverUID}/${currentUserId}/`;
      // console.log("Recipient chatRoomRoute to chat room", receiverChatRoomRoute);
      // firebase.database().ref(`${receiverChatRoomRoute}/`).update(receiverChatRoomMetaData);
      Fire.updateRoute(`${receiverChatRoomRoute}/`, receiverChatRoomMetaData)
      .then(()=>{
          //Adding new message to receiver
          let receiverChatRoomMessagesRoute = `${chatRoomType}/${receiverUID}/${currentUserId}/messages`;
          const receiverNewMessageId = Fire.getUniqueKeyForRoute(receiverChatRoomMessagesRoute);
          if(!receiverNewMessageId) return;
          let newMessageData = createNewMessageData(message, currentUserId, currentUserEmail, receiverNewMessageId);
          Fire.updateRoute(`${receiverChatRoomMessagesRoute}/${receiverNewMessageId}`, newMessageData);
      })
      .catch((error: any)=>{
        catchErrorForSentry(error);
      })

    }

    const renderChatInput = ()=> {
      if(!isObjEmpty(route.params)){
        const {name: receiverName, email: receiverEmail, uid: receiverUID } = route.params;
        if(isUserBlocked()){
            return(
            <View style={{backgroundColor: COLORS.primaryGray}}>
                <Text style={{textAlign: 'center', fontSize: scaleFontSize(25)}}>
                    You've blocked this user
                </Text>
                <CustomButton
                  title="Unblock"
                  // style={{width: '50%', alignSelf: 'center', borderRadius: 10, margin: 10}}
                  textStyle={{fontSize: scaleFontSize(15), color: '#fff', textAlign: 'center'}}
                  onPress={async ()=>{
                      let blockConfirmationResponse = await blockConfirmationPrompt(receiverName, 'unblock');
                      if(blockConfirmationResponse){
                          Fire.unblockUser(receiverUID);
                      }
                  }}
                />
            </View>
            )
        }
        else return <ChatInput onSubmit={onSubmitMessage} />
      }
    }

    return (
        <View style={{flex: 1,
          backgroundColor: '#fff',
          height: screenSize,
          marginBottom: (Platform.OS === 'ios' ? keyboardHeight : 0),
          paddingBottom: (Platform.OS === 'ios' ? screenPadding : 0),
        }}>
          <View 
              style={{...styles.screen,
                paddingBottom: (Platform.OS === 'android' ? screenPadding : 0),
                padding: 10,
              }}>
                {renderMessages()}
          </View>
          {renderChatInput()}
        </View>
      );
}

const styles = StyleSheet.create({
  keyboardAvoidingView:{
    flex: 1,
  },
  screen: {
    flex: 1,
    padding: 10,
  },
  messages: {
    flex: 1,
    alignContent: 'flex-start',
    backgroundColor: '#fff',
  },
  notChattingMessageContainer:{
    flex: 1,
    alignContent: 'center',
    justifyContent: 'center',
  },
  notChattingText: {
    fontSize: scaleFontSize(15),
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20
  }

})


export default ChatScreen

