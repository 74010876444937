import { Linking, Platform } from "react-native";
import isWeb from "./isWeb";

const openMapsApp = (latitude: number, longitude: number, label = '') => {
    const scheme = isWeb()
      ? `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
      : Platform.select({
        ios: `maps://?q=${label}@${latitude},${longitude}`,
        android: `geo:${latitude},${longitude}?q=${label}`,
      });
      //@ts-ignore
    Linking.openURL(scheme);
};

export default openMapsApp