import React from 'react';
import { StyleSheet, TouchableHighlight, View, Text } from 'react-native'
import UserImage from './UserImage';
import { COLORS } from '../Constants';

const Message = (props: any)=>{

    const {
        messageData,
        isMessageFromUser,
        text,
        onLongPress
    } = props

    const {
        sender,
        senderUID,
        date,
        time,
        message,
        timestamp,
        id,
    } = messageData



    return(
        <TouchableHighlight style={{...styles.touchable, flex: 0, alignSelf: isMessageFromUser ? 'flex-end' : 'flex-start'}} onLongPress={()=>{
            onLongPress(message)
          }}>
            <View style={{...styles.chatBubble, backgroundColor: isMessageFromUser ? COLORS.primaryBlue : COLORS.messengerGray, flex: 0, alignSelf: isMessageFromUser ? 'flex-end' : 'flex-start', flexDirection: 'row', alignItems: 'center'}}>

              {!isMessageFromUser &&
                //@ts-ignore
                <UserImage
                    style={{width: 40, height: 40, borderRadius: 20, marginRight: 5}}
                    uid={senderUID}
                />
              }

              <View style={{ flex: 0, flexDirection: 'column', justifyContent: isMessageFromUser ? 'flex-end' : 'flex-start', maxWidth: '92.5%', alignItems: 'center'}}>
                  <Text style={{
                    textAlign: "left",
                    color: isMessageFromUser ? '#fff' : '#000'
                  }}>
                    {message}
                  </Text>
              </View>

            </View>
        </TouchableHighlight>
    )
}

const styles = StyleSheet.create({
    chatBubble: {
      padding: 10,
      backgroundColor: '#f2f2f2',
      borderRadius: 20,
      borderWidth: 1,
      borderColor: 'white',
      maxWidth: '99%'
    },
    touchable: {
      marginTop: 5,
      borderRadius: 20,
      // padding: 10,
      // borderRadius: 20,
      // borderWidth: 1,
      // borderColor: 'yellow',
      // marginTop: 5,
    }
  })

export default Message