import { useDispatch, useSelector } from "react-redux";
import Fire from "../Fire";
import { useState } from "react";
import isObjEmpty from "../helpers/isObjEmpty";
import { RootState } from "../store";
import { updateUserDataProperty } from "../store/slices/userData";
import { View } from "react-native";
import { COLORS, SCREEN_SIZE } from "../Constants";
import scaleFontSize from "../helpers/scaleFontSize";
import CustomButton from "./CustomButton";
import Input from "./Input";
import isWeb from "../helpers/isWeb";

const InTheBizEditor = (props: any)=>{
    const dispatch = useDispatch();
    const userData = useSelector((state: RootState)=>state.userData);

    const [inTheBiz, setInTheBiz] = useState(isObjEmpty({...userData.inTheBiz}) ? {0: "", 1: ""} : {...userData.inTheBiz});
    

      
    const onModifyInTheBiz = ()=>{
        console.log("InTheBiz: ", inTheBiz);
        //Updating database
        Fire.setUserDataRoute(`${userData.uid}/inTheBiz`, inTheBiz);
        //Updating local data
        dispatch(updateUserDataProperty({property: `inTheBiz`, value: inTheBiz}))
        alert("InTheBiz Specials have been updated have been updated.");
    }

    // const Specials = ()=>{
    //     return()
    // }
    return(
        <View style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            // borderColor: 'red',
            // borderWidth: 1,
        }}>
            <View style={{
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                // borderColor: 'blue',
                // borderWidth: 1,
            }}>
                <Input
                    multiline={true}
                    label={`Column 1`}
                    style={{width: '48%', minWidth: 350}}
                    labelStyle={{
                        textAlign: 'center',
                        alignSelf: 'center',
                    }}
                    inputStyle={{
                        height: SCREEN_SIZE.height *.25,
                        minWidth: 350,
                        borderColor: COLORS.primaryBlue, 
                        borderRadius: 10,
                        borderWidth: 1,
                    }}
                    defaultValue={inTheBiz[0] ? inTheBiz[0] : ""}
                    onInputChange={(value:string)=>setInTheBiz((prevState: any)=>{prevState[0] = value; return prevState})}
                />
                <Input
                  
                    multiline={true}
                    label={`Column 2`}
                    labelStyle={{
                        textAlign: 'center',
                        alignSelf: 'center',
                    }}
                    style={{width: '48%', minWidth: 350}}
                    inputStyle={{
                        // height: '50%',
                        height: SCREEN_SIZE.height *.25,
                        flexGrow: 1,
                        minWidth: 350,
                        borderColor: COLORS.primaryBlue,
                        borderRadius: 10,
                        borderWidth: 1,
                    }}
                    defaultValue={inTheBiz[1] ? inTheBiz[1] : ""}
                    onInputChange={(value:string)=>setInTheBiz((prevState: any)=>{prevState[1] = value; return prevState})}
                />
            </View>
            <CustomButton
                onPress={onModifyInTheBiz}
                title={"Submit"}
                textStyle={{
                    color: 'white',
                    fontWeight: '500', 
                    fontSize: scaleFontSize(12.5),
                }}
                style={{
                    backgroundColor: COLORS.primaryBlue,
                    alignSelf:'center',
                    // height: '25%',
                    padding:10,
                    width: 100,
                    // minWidth: 100,
                    borderRadius: 10,
                }}
            />
        </View>
    )
}

export default InTheBizEditor