// import formatBusinessHours from "./formatBusinessHours";

const isBusinessOpenNow = (openingTime: number, closingTime: number, now = new Date()) => {
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();

    const openingDate = new Date(openingTime);
    const openingHours = openingDate.getHours();
    const openingMinutes = openingDate.getMinutes();

    const closingDate = new Date(closingTime);
    const closingHours = closingDate.getHours();
    const closingMinutes = closingDate.getMinutes();

    // const currentTime = formatBusinessHours(now.getTime());
    // console.log("Current time: ", currentTime);
    // console.log("Opening time: ", formatBusinessHours(openingTime));
    // console.log("Closing time: ", formatBusinessHours(closingTime));
    // If closingHours is less than openingHours, it means the business stays open past midnight
    if (closingHours < openingHours || (closingHours === openingHours && closingMinutes < openingMinutes)) {
        // console.log("Business is open past midnight");
        if ((currentHours < openingHours || (currentHours === openingHours && currentMinutes < openingMinutes)) && 
            (currentHours > closingHours || (currentHours === closingHours && currentMinutes >= closingMinutes))) {
            return false;
        }
    } else {
        // console.log("Business doesn't open past midnight");
        if (currentHours < openingHours || (currentHours === openingHours && currentMinutes < openingMinutes)) {
            return false;
        }

        if (currentHours > closingHours || (currentHours === closingHours && currentMinutes >= closingMinutes)) {
            return false;
        }
    }

    return true;
}



export default isBusinessOpenNow