import React, { useState, useEffect } from 'react';
import { View, ScrollView, Platform, ImageBackground } from 'react-native';
import { Ionicons } from "@expo/vector-icons";
import Fire from '../Fire'
import UploadPictureModal from './UploadPictureModal';
import { COLORS, IMAGEKIT_URL, SCREEN_SIZE } from '../Constants';
import TouchableContainer from './TouchableContainer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import deleteFromStorage from '../helpers/deleteFromStorage';
import { updateUserDataProperty } from '../store/slices/userData';
import isVarDefined from '../helpers/isVarDefined';
import isWeb from '../helpers/isWeb';


const MAX_NUMBER_OF_PICTURES = 15;

const AccountChoosePictures = (props: any)=>{
    const { PinCoverPhotoButton, mode="account" } = props;

    const isAccount = mode === "account" ? true : false;

    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);

    const [uploadedPictures, setUploadedPictures] = useState<any>(userData.pictureKeys ? userData.pictureKeys : []);
    const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
    const [ currentIndex, setCurrentIndex ] = useState<any>(null)


    // const [moving, setMoving]  = useState(false);

    useEffect(()=>{

        const onPictureKeysChange = (pictureKeys: any)=>{
            if (pictureKeys) {
                // const pictureData = Object.values(pictureKeys).map((key)=>{return `${IMAGEKIT_URL}/${key}`});
                setUploadedPictures(pictureKeys);
            }
            else setUploadedPictures([])
        }
        let pictureKeysRef = Fire.listenToRoute(`publicUserData/${userData.uid}/pictureKeys`, onPictureKeysChange)

        return () =>{
            Fire.off(pictureKeysRef, "value", onPictureKeysChange)
        }
    }, [])

    const onPictureUploaded = (imageKey: string)=>{
        const route = isAccount ? 'pictureKeys' : 'entertainmentPictureKeys';
        const value = isAccount ? userData.pictureKeys : userData.entertainmentPictureKeys;
        // console.log("Current index on picture upload: ", currentIndex);
        Fire.setRoute(`publicUserData/${userData.uid}/${route}/${currentIndex}/`, imageKey);
        dispatch(updateUserDataProperty({property: route, value: value}))
        //Update local state
        setUploadedPictures((prevState: any)=>{
            const updatedPictures = Array.isArray(prevState) ? [...prevState] : {...prevState};
            updatedPictures[currentIndex] = imageKey;
            return updatedPictures;
        });
        // console.log("Picture Keys: ", userData.pictureKeys);
        setShowUploadPictureModal(false);
    }

    const onRemoveSelectedImage = ()=>{
        const route = isAccount ? 'pictureKeys' : 'entertainmentPictureKeys';
        Fire.deleteDataAtRoute(`publicUserData/${userData.uid}/${route}/${currentIndex}/`);
        const isImageProfilePicture = uploadedPictures[currentIndex] === userData.profilePictureKey
        const isImageCoverPhoto = uploadedPictures[currentIndex] === userData.coverPhotoKey

        deleteFromStorage(uploadedPictures[currentIndex], "image");
        if(isImageProfilePicture) Fire.updateUserDataRoute(`${userData.uid}`, {profilePictureKey: ""});
        if(isImageCoverPhoto) Fire.updateUserDataRoute(`${userData.uid}`, {coverPhotoKey: ""});
        //Update local state
        setUploadedPictures((prevState: any)=>{
            const updatedPictures = Array.isArray(prevState) ? [...prevState] : {...prevState};
            updatedPictures[currentIndex] = "";
            return updatedPictures;
        });
        //Update local state
        dispatch(updateUserDataProperty({property: route, value: uploadedPictures}))
        setShowUploadPictureModal(false);
    }

    const Picture = (props: any)=>{
        const {pictureKey, index, isPlaceholder} = props;
        // if(!isPlaceholder){
        //     console.log("Picture key: ", pictureKey);
        //     console.log("Picture key: ", IMAGEKIT_URL+'/'+pictureKey);
        // }
        // console.log("Key: ", key);
        // console.log("isNan(Key): ", isNaN(key));
        const uri = `${IMAGEKIT_URL}/${pictureKey}`;
        return(
            <TouchableContainer
                onPress={()=>{
                    // console.log("Selected index: ", index);
                    setCurrentIndex(index)
                    setShowUploadPictureModal(true);
                }}
                key={ isPlaceholder ? Math.random() : index}
                style={{
                    borderStyle: 'dashed',
                    borderColor: COLORS.primaryBlue,
                    borderRadius: isPlaceholder ? 1 : 0,
                    borderWidth: isPlaceholder? 2 : 0,
                    backgroundColor: isPlaceholder ? 'white' : 'transparent',
    
                    height: SCREEN_SIZE.width / 4, // change to width / 5 to make sure 5 items fit per row
                    width: SCREEN_SIZE.width / 5, // change to width / 5
                    margin: 5,
                    marginBottom: SCREEN_SIZE.width / 20,
                }}>
                <ImageBackground
                    // key={Date.now()}
                    //@ts-ignore
                    resizeMode={'cover'}
                    //@ts-ignore
                    style={{
                        flex: 1,
                        justifyContent: 'flex-end',
                    }}
                    source={isPlaceholder ? require('../assets/alwayslocal-logo.png') : isWeb() ? uri : {uri}}
                    // imageRef={((imgBackground)=>{
                    //     imgBackground.queryCache(this.state.source.uri);
                    // })}
                >
                    <View
                        //@ts-ignore
                        style={{
                            height: 30,
                            width: 30,
                            borderRadius: 15,
                            backgroundColor: COLORS.primaryRed,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'flex-end',
                            position: 'relative',
                            top: '6%',
                            left: '6%'
                        }}
                    >
                        <Ionicons
                            name={(Platform.OS === 'ios' ? 'ios-add' : 'md-add')}
                            size={15} 
                            color={'white'}
                            style={{alignSelf: 'center'}}
                        />
                    </View>
                </ImageBackground>
            </TouchableContainer>
        )
    }
    const renderUploadedPictures = () => {
        let pictureComponents = [];
        for (let index = 0; index < MAX_NUMBER_OF_PICTURES; index += 1) {
            const pictureKey = uploadedPictures[index];
            const isPlaceholder = !isVarDefined(pictureKey) || pictureKey === "" ? true : false;
            pictureComponents.push(
                <Picture
                    key={isPlaceholder ? Math.random() : pictureKey}
                    pictureKey={pictureKey}
                    index={index}
                    isPlaceholder={isPlaceholder}
                />
            );
        }
        return pictureComponents;
    }

    return(
        <View style={{flex: 1, backgroundColor: COLORS.primaryGray, borderRadius: 10}}>
            <UploadPictureModal
                showModal={showUploadPictureModal}
                setShowModal={setShowUploadPictureModal}
                SelectedPictureChildren={PinCoverPhotoButton || false}
                onPictureUploaded={onPictureUploaded}
                picturePrefix={isAccount ? `${userData.uid}-account-picture` : `${userData.uid}-account-entertainment-picture`}
                selectedImageKey={uploadedPictures[currentIndex]}
                onRemoveSelectedImage={onRemoveSelectedImage}
            />
            <ScrollView
                key={uploadedPictures.length}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{
                    backgroundColor: COLORS.primaryBgColor,
                    flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                {renderUploadedPictures()}
            </ScrollView>
            {/* <View key={uploadedPictures.length} style={{flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                {renderUploadedPictures()}
            </View> */}
        </View>
    )

}
  
export default AccountChoosePictures;