import Fire from '../Fire';

const getUsersForUserList = async(uidsList: string[])=>{

  //Courtesy: https://stackoverflow.com/questions/42610264/querying-by-multiple-keys-in-firebase 
  var promises = uidsList.map(function(uid) {
      return Fire.query(`publicUserData/${uid}`)
  });

  //Build Data From Promises
  let allUserData = [];
  let snapshots = await Promise.all(promises)

  //Get posts from snapshots
  for (let snapshot of snapshots){
    let userData = await snapshot.val();
    if(!userData) continue;
    allUserData.push(userData)
  }

  return allUserData
}

export default getUsersForUserList