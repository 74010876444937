import { StyleSheet, View, Text, Image, ActivityIndicator, TextInput, ScrollView, useWindowDimensions } from "react-native";
import { useState, useRef, memo } from 'react';
import Card from "../components/Card";
import SignUpSheet from "../navigation/components/SignUpSheet";
import HomeHeader from "../navigation/components/HomeHeader";
import { InputInterface, ValidateInputInterface } from "../Interfaces";
import { COLORS, SCREEN_SIZE, errorMessages, regexPatterns } from "../Constants";
import UploadPictureModal from "../components/UploadPictureModal";
import CustomButton from "../components/CustomButton";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import TouchableBackButton from "../components/TouchableBackButton";
import AccountChoosePictures from "../components/AccountChoosePictures";
import ElementSlideshow from "../components/ElementSlideshow";
import isPhoneNumberTaken from "../helpers/isPhoneNumberTaken";
import Fire from "../Fire";
import isAccountInformationTaken from "../helpers/IsAccountInformationTaken";
import ConfirmSMSBox from "../components/ConfirmSMSBox";
import isMobile from "../helpers/isMobile";
import AlwaysLocalLogo from "../components/AlwaysLocalLogo";
import { setUserData } from "../store/slices/userData";
import { useDispatch } from "react-redux";
import catchErrorForSentry from "../helpers/catchSentryError";
import cleanPhoneNumber from "../helpers/cleanPhoneNumber";
import scaleFontSize from "../helpers/scaleFontSize";
import useScreenSize from "../helpers/getScreenSize";
import TouchableContainer from "./TouchableContainer";
import generateInitUserData from "../helpers/generateInitUserData";
import isWeb from "../helpers/isWeb";
import isZipCodeInFlorida from "../helpers/isZipCodeInFlorida";



const SignUpCard = (props: any)=>{
    // const window = useWindowDimensions();
    const { showLoginButton, setShowSignUpCard, setShowLoginButton, showSignUpCard } = props;
    const windowSize = useWindowDimensions();
    const screenSize = useScreenSize();
    const dispatch = useDispatch();
    const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
    const [funnelState, setFunnelState] = useState<"Brand" | "User" | "Account" | "">("Account");
    const [pictureMode, setPictureMode] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [state, setState] = useState<any>({
        username: "",
        // username: "bar1",
        // username: "tyfoods",
        usernameError: errorMessages.usernameError,
    
        firstName: "",
        firstNameError: errorMessages.firstNameError,
    
        lastName: "",
        lastNameError: errorMessages.lastNameError,
    
        phoneNumber: "",
        // phoneNumber: "+15617565951",
        // phoneNumber: "+15616742253",
        phoneNumberError: errorMessages.phoneNumberError,
        countryCode: "+1",
    
        email: "",
        // email: "anthonecuthill@gmail.com",
        // email: "tyfooods@gmail.com",
        emailError: errorMessages.emailError,
        
        password: "",
        // password: "alwayslocal",
        passwordError: errorMessages.passwordError,

        zipcode: "",
        zipcodeError: errorMessages.zipcodeError,
    
        smsCode: "",
        error: "",
        loading: false,
    
        usernameErrorFlag: false,
        nameFlag: false,
        emailErrorFlag: false,
        phoneNumberErrorFlag: false,
        passwordErrorFlag: false,
    });

    const inputStyle = {
        // width: isMobile() ? '100%' : '80%',
        width: '100%',
        alignSelf: 'center',
        marginVertical: 10,
    }
    const containerStyle = {
        width: isMobile() ? '100%' : '80%',
        alignSelf: 'center',
    }

    const signUpSheetInputs: (InputInterface | ValidateInputInterface)[] = [
        // {
        //     name: 'First Name',
        //     errorMessage: state.firstNameError,
        //     errorName: 'nameFlag',
        //     onInputChange: (value: string)=>setState((prevState: any)=>{prevState.firstName = value; return prevState;}),
        //     regex: regexPatterns.name,
        //     defaultValue: state.firstName || '',
        // },
        // {
        //     name: 'Last Name',
        //     errorMessage: state.firstNameError,
        //     errorName: 'nameFlag',
        //     onInputChange: (value: string)=>setState((prevState: any)=>{prevState.lastName = value; return prevState;}),
        //     regex: regexPatterns.name,
        //     defaultValue: state.lastName || '',
        // },
        {
            name: 'Zipcode',
            errorMessage: state.zipcodeError,
            inputStyle: inputStyle,
            containerStyle: containerStyle,
            placeholder: 'Zipcode',
            errorName: 'zipcodeErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.zipcode = value; return prevState;}),
            regex: regexPatterns.zipcode,
            defaultValue: state.zipcode || '',
            props: {
                secureTextEntry: false,
            },
            showLabel: false,
        },
        {
            name: 'Username',
            errorMessage: state.usernameError,
            errorName: 'nameFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.username = value; return prevState;}),
            regex: regexPatterns.username,
            defaultValue: state.username || '',
            placeholder: 'Username',
            showLabel: false,
            inputStyle: inputStyle,
            containerStyle: containerStyle,
        },
        {
            name: 'Phone Number',
            placeholder: 'Phone Number',
            inputStyle: inputStyle,
            containerStyle: containerStyle,
            errorMessage: state.phoneNumberError,
            errorName: 'phoneNumberErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.phoneNumber = value; return prevState;}),
            defaultValue: state.phoneNumber || '',
            regex: regexPatterns.phoneNumber,
            showLabel: false,

        },
        {
            name: 'Email',
            placeholder: 'Email',
            inputStyle: inputStyle,
            containerStyle: containerStyle,
            errorMessage: state.emailError,
            errorName: 'emailErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;}),
            regex: regexPatterns.email,
            defaultValue: state.email || '',
            showLabel: false,

        },
        {
            name: 'Password',
            placeholder: 'Password',
            inputStyle: inputStyle,
            containerStyle: containerStyle,
            errorMessage: state.passwordError,
            errorName: 'passwordErrorFlag',
            onInputChange: (value: string)=>setState((prevState: any)=>{prevState.password = value; return prevState;}),
            regex: regexPatterns.password,
            defaultValue: state.password || '',
            props: {
                secureTextEntry: true
            },
            showLabel: false,

        },
    ]

    const renderChooseAccountTitle = ()=>{
        return(
            <View style={{
                // flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                // borderColor: 'green',
                // borderWidth: 1
            }}>
                <AlwaysLocalLogo/>
                {/* <Text style={{fontSize: 25, alignSelf: 'center', fontWeight: '500', marginBottom: 10}}>
                    Create your profile early and get notified when we launch!
                </Text> */}
                <Text style={{fontSize: 15, alignSelf: 'center'}}>
                    What kind of account are you making?
                </Text>
            </View>
        )
    }

    const createAccountWithEmail = async () => {
        const { username, password, zipcode } = state;

        const isZipInFlorida = await isZipCodeInFlorida(zipcode);
        if(!isZipInFlorida) return alert("Sorry, this app is currently only available in Florida!")

        let { email, phoneNumber } = state;
        const accountType = funnelState.toLowerCase();
        Fire.createUserWithEmailAndPassword(email, password)
            .then((userCredential: any) => {
                // Sign up successful.
                var user = userCredential.user;
                email = email.trim();
                let userData = generateInitUserData(state, user, funnelState.toLowerCase())
                initDatabaseUserData(userData);
                //@ts-ignore
                dispatch(setUserData(userData));
                props.navigation.navigate('WebBackEndPage');
                setProcessing(false);
            })
            .catch((error: any) => {
                catchErrorForSentry(error);
            });
    }

    const initDatabaseUserData=(userData: any)=>{
        const {uid: userId, phoneNumber} = userData;
        let strippedUserData = {...userData}
        delete strippedUserData.phoneNumber;

        Fire.updateRoute(`publicUserData/`, {[`${userId}`]: strippedUserData});
        // Fire.updateRoute(`phoneNumbers/`, {[`${userId}`]: phoneNumber});
    }

    const isFormDataValid = ()=>{
        const {firstName, lastName, email, password, phoneNumber, emailErrorFlag, phoneNumberErrorFlag, passwordErrorFlag} = state;
        if(firstName || lastName || email || password || phoneNumber) return true;
        if(!emailErrorFlag || !passwordErrorFlag || !phoneNumberErrorFlag) return true;
    }

    const onCreateAccount = async (event: React.MouseEvent<HTMLButtonElement>)=>{
        event.preventDefault();
        const {username, email, password, zipcode} = state;

        const isZipInFlorida = await isZipCodeInFlorida(zipcode);
        if(!isZipInFlorida) return alert("Sorry, this app is currently only available in Florida!")

        let {phoneNumber} = state;
        if(!isFormDataValid()) return alert("There is a problem with your form information");
        if(!cleanPhoneNumber(phoneNumber)) return alert("Phone number is not a valid US phone number");
        phoneNumber = cleanPhoneNumber(phoneNumber);
        setProcessing(true);
        isAccountInformationTaken(phoneNumber, email, username)
        .then(async (response: any)=>{
            let { isPhoneNumberTaken, isUsernameTaken, isEmailTaken } = JSON.parse(response);

            if(isPhoneNumberTaken || isUsernameTaken || isEmailTaken){
              const alertText = (()=>{
                if(isPhoneNumberTaken) return 'There is already an account with that phone number'
                if(isUsernameTaken) return 'There is already an account with that username'
                if(isEmailTaken) return 'There is already an account with that email'
              })()
              setProcessing(false);
              alert(alertText);
              return;
            }
            else{
                createAccountWithEmail()
            }
        })
        .catch((error)=>{
            setProcessing(false);
            alert("There was an error checking if your credentials are already taken - Please try again");
            console.log("Error creating account SignUpCard: ", error);
            console.log("Error creating account SignUpCard: ", JSON.stringify(error)); 
            catchErrorForSentry(error);
        })
    }

    return(
            <Card style={{
                ...styles.signUpForm,
                // borderColor: 'red', 
                // borderWidth: 1,
                // flex: funnelState ? 3/5 : 2/5,
                ...props.style
            }}>
                {showLoginButton && <View style={{alignSelf: 'center', marginBottom: '2.5%',}}>
                    <Text style={{textAlign: 'center', color: 'white', fontWeight: '700', fontSize: scaleFontSize(30)}}>
                        Create An Account
                    </Text>
                    <Text style={{textAlign: 'center', color: 'white', fontWeight: '500', fontSize: scaleFontSize(20)}}>
                        Establishments only
                    </Text>
                </View>}
                {processing ? <ActivityIndicator color={COLORS.primaryRed} size="large"/> : <View
                    style={{
                        width: !isWeb() ? '95%' : '80%',
                        display: showLoginButton ? 'flex' : 'none'
                    }}
                >
                    <SignUpSheet inputs={signUpSheetInputs} state={state} setState={setState} />
                    <TouchableContainer
                        onPress={(event: React.MouseEvent<HTMLButtonElement>)=>onCreateAccount(event)}
                        style={{
                            ...styles.accTypeBtn,
                            width: windowSize.width * .15,
                            minWidth: 200,
                            alignSelf: 'center',
                            flexDirection: 'row',
                            alignContent: 'center',
                            justifyContent: 'space-around',
                        }}
                    >
                        <Text style={styles.accTypeBtnTxt}>Create Account</Text>
                        <FontAwesome5 style={{
                            // borderWidth: 1,
                            // borderColor: 'white',
                            marginRight: 5,
                            alignSelf: 'center',
                            transform: [{ rotate: '45deg' }]}}
                            name="location-arrow"
                            size={20}
                            color="white"
                        />
                    </TouchableContainer>
                </View>}
                {props.children}
            </Card>
    )
}

const styles = StyleSheet.create({
    signUpForm: {
        flex: 1,
        // height: '80%',
        width:'80%',
    },
    accTypeBtn:{
        borderRadius: 10,
        backgroundColor: '#DD222E',
        height: 50,
        justifyContent: 'center',
    },
    accTypeBtnTxt:{
        fontSize: scaleFontSize(15),
        alignSelf: 'center',
        color: 'white',
        fontWeight: "600",
    },
})

export default SignUpCard