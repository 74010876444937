import { Keyboard, KeyboardEventListener } from 'react-native';

class KeyboardController {
    private keyboardDidHideSubscription: any;
    private keyboardDidShowSubscription: any;

    constructor(onKeyboardHidden: KeyboardEventListener, onKeyboardShown: KeyboardEventListener) {
        this.keyboardDidHideSubscription = Keyboard.addListener(
            'keyboardDidHide',
            onKeyboardHidden
        );

        this.keyboardDidShowSubscription = Keyboard.addListener(
            'keyboardDidShow',
            onKeyboardShown
        );
    }

    removeKeyboardHide = () => {
        if (this.keyboardDidHideSubscription) {
            this.keyboardDidHideSubscription.remove();
        }
    }

    removeKeyboardShow = () => {
        if (this.keyboardDidShowSubscription) {
            this.keyboardDidShowSubscription.remove();
        }
    }

    removeAllListeners = () => {
        this.removeKeyboardHide();
        this.removeKeyboardShow();
    }
}

export default KeyboardController;
