import { Image, Linking, StyleSheet, Text, View, ScrollView, Platform, Dimensions, ActivityIndicator, Touchable, Button } from "react-native"
import TouchableContainer from "../components/TouchableContainer";
import Swiper from "react-native-web-swiper";
// import RNSwiper from "react-native-swiper";
import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { COLORS, IMAGEKIT_URL, SCREEN_SIZE, drinks, fakeGigData } from "../Constants";
import Fire from "../Fire";
import { AntDesign, Entypo, Feather, FontAwesome5, MaterialCommunityIcons, MaterialIcons, SimpleLineIcons } from "@expo/vector-icons";
import CustomModal from "../components/CustomModal";
import AccountChoosePictures from "../components/AccountChoosePictures";
import AccountSelectProfilePicture from "../components/AccountSelectProfilePicture";
import BusinessHours from "../components/BusinessHours";
import CustomButton from "../components/CustomButton";
import scaleFontSize from "../helpers/scaleFontSize";
import { clearUserData, setUserData, updateUserDataProperty, updateWhoUserFollows } from "../store/slices/userData";
import Card from "../components/Card";
import formatPhoneNumber from "../helpers/formatPhoneNumber";
import { Screen } from "../components/Screen";
import ChangeAccountName from "../components/ChangeAccountName";
import ChangeAddress from "../components/ChangeAddress";
import PhoneNumberEditor from "../components/PhoneNumberEditor";
import EmailEditor from "../components/EmailEditor";
import WebsiteEditor from "../components/WebsiteEditor";
import TiktokEditor from "../components/TiktokEditor";
import FacebookUrlEditor from "../components/FacebookUrlEditor";
import InstagramUrlEditor from "../components/InstagramUrlEditor";
import catchErrorForSentry from "../helpers/catchSentryError";
import isWeb from "../helpers/isWeb";
import SetDateModal from "../components/SetDateModal";
import calculateAge from "../helpers/calculateAge";
import confirmDecision from "../helpers/confirmDecision";
import day from 'dayjs';
import FavoriteDrinksEditor from "../components/FavoriteDrinksEditor";
import BirthdayEditor from "../components/BirthdayEditor";
import AccountTypeEditor from "../components/AccountTypeEditor";
import ScheduleEditor from "../components/ScheduleEditor";
import getBusinessStatus from "../helpers/getBusinessStatus";
import addNotificationToUser from "../helpers/addNotificationToUser";
import removeNotificationFromUser from "../helpers/removeNotificationFromUser";
import FollowButton from "../components/FollowButton";
import sendToProfileScreen from "../helpers/sendToProfileScreen";
import PlacesYouFollow from "../components/PlacesYouFollow";
import UserCard from "../components/UserCard";
import { UserData } from "../Interfaces";
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter";
import getUserDataPath from "../helpers/getUserDataPath";
import MessageButton from "../components/MessageButton";
import StaffYouFollow from "../components/StaffYouFollow";
import LocalsYouFollow from "../components/LocalsYouFollow";
import EntertainmentYouFollow from "../components/EntertainmentYouFollow";
import isObjEmpty from "../helpers/isObjEmpty";
import LoginPrompt from "../components/LoginPrompt";
import AccountDataEditor from "./AccountDataEditor";
import GigDisplayer from "../components/GigDisplayer";
import gigHasNotPassed from "../helpers/gigHasNotPassed";
import { Gig } from "../types";
import { useFocusEffect } from "@react-navigation/native";
import ProfilePictureModal from "../components/ExpandPictureModal";
import ExpandPictureModal from "../components/ExpandPictureModal";
import { updateSwitchDataProperty } from "../store/slices/switchData";

const firebase = Fire.getFirebase();

const UserProfile = (props: any)=>{

    const { navigation, route } = props;
    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);
    const switchData = useSelector((state: RootState) => state.switchData);
    // console.log("SwitchData: ", switchData.displayName);

    // console.log("navigation.getState().routes.length: ", navigation.getState().routes.length);
    const isHeaderShown = (route.params && route.params.pushed) ? false : true;
    // const isHeaderShown =  false;
    const userIsAccount = userData.accountType === 'account';
    const dataFromProfile = (route.params && !route.params.noBirthday) ? route.params.profileData : userData;
    // console.log("UserProfile (dataFromProfile): ", dataFromProfile.displayName);
    const [profileData, setProfileData] = useState(dataFromProfile);
    // console.log("UserProfile (profileData): ", profileData.displayName);
    const [uidsUserFollows, setUidsUserFollows] = useState(isObjEmpty(profileData.uidsUserFollows) ? [] : Object.values(profileData.uidsUserFollows));
    const noBirthday = route.params && route.params.noBirthday;
    const isProfileOwner = userData.uid === profileData.uid || !profileData.uid;
    const isStaff = profileData.accountType === 'staff';
    const isAccount = profileData.accountType === 'account';
    const isEntertainment = profileData.accountType === 'entertainment';
    const [state, setState] = useState({
        accountName: profileData.displayName,
        genre: profileData.genre,
        email: profileData.email,
        address: profileData.address,
        website: profileData.websiteUrl || "",
        phoneNumber: profileData.phoneNumber,
        usernameError: false,
        coords: profileData.coords,
        tiktokUrl: profileData.tiktokUrl || "",
        facebookUrl: profileData.facebookUrl || "",
        instagramUrl: profileData.instagramUrl || "",
    });
    
    const defaultUploadedPictures: string[] = (()=>{
        return profileData.pictureKeys ? Object.values(profileData.pictureKeys).map((key)=>{return `${IMAGEKIT_URL}/${key}`}) : [];
    })()
    const [uploadedPictures, setUploadedPictures] = useState<string[]>(defaultUploadedPictures);
    // const entertainmentSchedule = {};
    // const setEntertainmentSchedule = (stuff: any)=>{}
    const isEntertainmentScheduleEmpty = isObjEmpty(profileData.entertainmentSchedule);
    const [entertainmentSchedule, setEntertainmentSchedule] = useState<any>( isEntertainmentScheduleEmpty ? [] : Object.values(profileData.entertainmentSchedule))
    // const [entertainmentSchedule, setEntertainmentSchedule] = useState<any>(isObjEmpty({...profileData.entertainmentSchedule}) ? undefined : Object.values(profileData.entertainmentSchedule))
    // console.log("entertainmentSchedule: ", entertainmentSchedule);
    useEffect(()=>{
        let defaultFollowingValue = (()=>{
            let uidsUserFollows = userData.uidsUserFollows;
            if(!uidsUserFollows || isObjEmpty(uidsUserFollows)) return false;
            // console.log("UidsUserFollows: ", uidsUserFollows);
            let isFollowingProfile = false;
            for (let [uid, status] of Object.entries(uidsUserFollows)){
                const isIncluded = Object.keys(uidsUserFollows).includes(profileData.uid);
                const isFollowing = status;
                const isProfileId = profileData.uid === uid;
                if(isProfileId && isIncluded && isFollowing ){
                    // console.log("Uid: ", uid);
                    // console.log("Is Included: ", isIncluded);
                    // console.log("is Following: ", status);
                    isFollowingProfile = true;
                    return true;
                }
                continue;
            }
            return isFollowingProfile;
        })()
        setFollowing(defaultFollowingValue);
    }, [userData])

    const haveAllEntertainmentGigsPassed = ()=>{
        if(!entertainmentSchedule){
            // console.log("Entetainment schedule is empty");
            return true;
        }
        const availableGigs = entertainmentSchedule.filter((gig: Gig)=>{
            return gigHasNotPassed(gig) && gig.type === 'entertainment';
        })
        // console.log("Available gigs.length: ", availableGigs.length);
        return availableGigs.length ? false : true;
    }

    const [following, setFollowing] = useState<Boolean>(false);
    const [profilePictureKey, setProfilePictureKey] = useState(profileData.profilePictureKey);
    const [placesUserFollows, setPlacesUserFollows] = useState<UserData[]>([]);
    const [localsUserFollows, setLocalsUserFollows] = useState<UserData[]>([]);
    const [staffUserFollows, setStaffUserFollows] = useState<UserData[]>([]);
    const [entertainmentUserFollows, setEntertainmentUserFollows] = useState<UserData[]>([]);
    const [showModal, setShowModal] = useState(false)
    const [showProfilePictureModal, setShowProfilePictureModal] = useState(false)
    const [showCoverPictureModal, setShowCoverPictureModal] = useState(false)
    const [selectedCoverPhoto, setSelectedCoverPhoto] = useState<string>("")
    const [edittingMode, setEdittingMode] = useState
    <
        "" | "coverPictures" |
        "selectProfilePicture" |
        "nameAndAddressChange" |
        "dailySpecialsChange" |
        "weeklySpecialsChange" |
        "monthlySpecialsChange" | 
        "dealsChange" | 
        "entertainmentChange" |
        "showBusinessHours"
    >("")

    // if(noBirthday) setEdittingMode("nameAndAddressChange");
    const [employerData, setEmployerData] = useState(isObjEmpty({...profileData.employers}) ? {} : {...profileData.employers})
    const [schedule, setSchedule] = useState<any>(userData && userData.schedule ? userData.schedule : null);

    const setAllProfileData = (data: any)=>{
        if(!data) return;
        const pics = data.pictureKeys ? Object.values(data.pictureKeys).map((key)=>{return `${IMAGEKIT_URL}/${key}`}) : [];
        setUidsUserFollows(data.uidsUserFollows)
        setUploadedPictures(pics);
        setProfileData(data);
        setProfilePictureKey(data.profilePictureKey)
        setEntertainmentSchedule(data.entertainmentSchedule ? Object.values(data.entertainmentSchedule) : [])
        setState({
            accountName: data.displayName,
            genre: data.genre,
            email: data.email,
            address: data.address,
            website: data.website || "",
            phoneNumber: data.phoneNumber,
            usernameError: false,
            coords: data.coords,
            tiktokUrl: data.tiktokUrl || "",
            facebookUrl: data.facebookUrl || "",
            instagramUrl: data.instagramUrl || "",
        })
    }

    useFocusEffect(
        React.useCallback(() => {
            const {original, remote, switchedId} = switchData;
            // if(!)  
            const isSwitched = original.uid !== ""
            const isUserProfile = userData.uid === profileData.uid;
            if(!isSwitched && isUserProfile){
                console.log("Profile data is same as user data: ", userData.displayName);
                return;
            }
            if(!isSwitched && !isUserProfile){
                console.log("Profile Data Name: ", profileData.displayName); 
                // console.log("Switch Data: ", switchData);
                // console.log("UserData name: ", userData.displayName);
                // console.log("Uids User admins: ", userData.uidsUserAdmins)
                if(switchedId !== "" && switchedId === profileData.uid){
                    // console.log("Switching away from old profile to user data")
                    dispatch(updateSwitchDataProperty({property: "switchedId", value: ""}))
                    return setAllProfileData(userData);
                }
                console.log("Profile data is foreign from user data");
                return setAllProfileData(profileData);
            }
            if(isSwitched && isUserProfile){
                console.log("Setting profile data to remote");
                return setAllProfileData(remote);
            }
            console.warn("Failed to find condition");
            // else if(remote.uid === "" && userData.uid !== profileData.uid){
            //     // if(userData.uidsUserAdmins && Object.keys(userData.uidsUserAdmins).includes(profileData.uid)){
            //     //     console.log("Switching away from old profile to user data")
            //     //     return setAllProfileData(userData);
            //     // }
            //     // console.log("Switching to profile data: ", profileData.displayName);

            //     return setAllProfileData(profileData);
            // }
            
            // // const isSwitchDataSameAsProfile = (switchData.original.uid === profileData.uid)
            // setAllProfileData(remote);
            // console.log("Profile data updated with remote data!");
            // console.log("Remote: ", remote.displayName)

        }, [switchData])
    );

    useEffect(()=>{
        getUsersProfileFollows();
        // console.log("Effect firing");
        const onEmployersChange = (employers: any)=>{
            if(employers){
                const promises = Object.keys(employers).map((uid)=>{
                    return Fire.query(`publicUserData/${uid}`)
                })
                let employeeData: {[key: string]: UserData} = {};
                Promise.all(promises).then((snapshots: any)=>{
                    //Get userData from snapshots
                    snapshots.forEach(function(snapshot: any) {
                        let userData = snapshot.val();
                        if(!userData) return;
                        employeeData[userData.uid] = userData;
                    });
                    setEmployerData(employeeData);
                })
            }
            else setEmployerData({})
        }
        const onPictureKeysChange = (pictureKeys: any)=>{
            if(!pictureKeys) return;
            const pictureData = Object.values(pictureKeys).map((key)=>{return `${IMAGEKIT_URL}/${key}`});
            setUploadedPictures(pictureData);
        }
        const onProfileDataChange = (userData: any)=>{
            if(!userData) return;
            // console.log(`Profile data for ${userData.displayName} was updated`);
            setProfileData(userData)
        }
        const onUidsUserFollowsChange = (newuidsUserFollows: any)=>{
            if(!newuidsUserFollows) return setUidsUserFollows([]);
            console.log("New data: ", newuidsUserFollows);
            setUidsUserFollows(newuidsUserFollows)
        }
        const onScheduleChange = (schedule: any)=>{
            if(!schedule) return setSchedule([]);
            setSchedule(schedule)
        }
        const onPhoneNumberChange = (phoneNumber: any)=>{
            if(phoneNumber) setState((prevState)=>{prevState.phoneNumber = phoneNumber; return prevState;});
        }
        const onGigDataChange = (gigData: any)=>{
            if(!gigData) return !entertainmentSchedule.length && setEntertainmentSchedule([]);
            setEntertainmentSchedule(Object.values(gigData));
        }
        let uidsUserFollowsRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/uidsUserFollows`, onUidsUserFollowsChange)
        var gigDataRef = Fire.listenToRoute(`publicUserData/${dataFromProfile.uid}/entertainmentSchedule`, onGigDataChange)
        let profileDataRef = Fire.listenToRoute(`publicUserData/${dataFromProfile.uid}`, onProfileDataChange)
        let pictureKeysRef = Fire.listenToRoute(`publicUserData/${dataFromProfile.uid}/pictureKeys`, onPictureKeysChange)
        let employersRef = Fire.listenToRoute(`publicUserData/${dataFromProfile.uid}/employers`, onEmployersChange)
        let scheduleRef = Fire.listenToRoute(`publicUserData/${dataFromProfile.uid}/schedule`, onScheduleChange)
        let phoneNumberRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/phoneNumber`, onPhoneNumberChange)

        return () =>{
            Fire.off(gigDataRef, "value", onGigDataChange)
            Fire.off(phoneNumberRef, "value", onPhoneNumberChange)
            Fire.off(uidsUserFollowsRef, "value", onUidsUserFollowsChange)
            Fire.off(pictureKeysRef, "value", onPictureKeysChange)
            Fire.off(profileDataRef, "value", onProfileDataChange)
            Fire.off(employersRef, "value", onEmployersChange)
            Fire.off(scheduleRef, "value", onScheduleChange)
        }
    },[])


    useEffect(()=>{
        getUsersProfileFollows();
    }, [uidsUserFollows])

    const getUsersProfileFollows = async ()=>{
        if(!uidsUserFollows) return;
        let placesArray: any = [];
        let staffArray: any = [];
        let userArray: any = [];
        let entertainmentArray: any = [];
        // Courtesy: https://stackoverflow.com/questions/42610264/querying-by-multiple-keys-in-firebase 
        var promises = Object.keys(uidsUserFollows).map((uid: string)=>{
            return (
                Fire.query(`publicUserData/${uid}`)
            )
        });

        const snapshots = await Promise.all(promises);
        //Get userData from snapshots
        snapshots.forEach(function(snapshot) {
            let userData = snapshot.val();
            if(!userData) return;
            if(userData.accountType === 'account') placesArray.push(userData);
            if(userData.accountType === 'user') userArray.push(userData);
            if(userData.accountType === 'staff') staffArray.push(userData);
            if(userData.accountType === 'entertainment') entertainmentArray.push(userData);
        });

        setPlacesUserFollows(placesArray);
        setLocalsUserFollows(userArray);
        setStaffUserFollows(staffArray);
        setEntertainmentUserFollows(entertainmentArray);
    }

    const onPictureSelected = (newKey: string)=>{
        setProfilePictureKey(newKey);
        setShowModal(false);
    }
    const onModifyPictures = ()=>{
        setEdittingMode("coverPictures");
        setShowModal(true);
    }
    const onAccountDataEditorPress = ()=>{
        !isWeb() ?
        navigation.push('AccountDataEditor', noBirthday) : 
        (()=>{
            setEdittingMode('nameAndAddressChange');
            setShowModal(true);
        })()
    }
    const getCustomModalTitle = ()=>{
        if(edittingMode === "coverPictures") return <Text style={{fontSize: scaleFontSize(20)}}>Upload your pictures here</Text>;
        if(edittingMode === "selectProfilePicture") return <Text style={{fontSize: scaleFontSize(20)}}>Select your profile picture</Text>
        if(edittingMode === "nameAndAddressChange") return "Account Settings"
    }


    const BusinessStatus = ()=>{
        const employerUid = employerData? Object.keys(employerData)[0] : "";
        const [employerName, setEmployerName] = useState("");
        useEffect(()=>{
            if(!employerUid) return;
            Fire.getUserData(employerUid)
            .then((employer)=>{
                if(employer) setEmployerName(employer.displayName);
            })
            .catch((error: any)=>{
                console.log("Failed to fetch employer name");
                catchErrorForSentry(error);
            })
        }, [])
        const {status, text} = getBusinessStatus(schedule, true, employerName);
        // console.log("Schedule: ", schedule);
        // console.log("Business status: ", status);
        // console.log("Text: ", text);
        return(
            <Text onPress={()=>{
                !isWeb() ? navigation.push("BusinessHours", {profileData, displayName: profileData.displayName}) :
                (()=>{
                    setEdittingMode("showBusinessHours");
                    setShowModal(true);
                })()
            }}
                style={{
                    marginVertical: 5,
                    color: COLORS.primaryBlue,
                    // color: status ? COLORS.greenAccent : COLORS.primaryRed,
                    fontSize: scaleFontSize(12),
                }}
            >
                {text}
            </Text>
        )
    }

    const sendEmployeeRequest = async ()=>{
        const response = await confirmDecision(
            `Requesting ${profileData.displayName} as ${profileData.accountType}`,
            `Are you sure you would like to request that ${profileData.displayName} be listed as your employee?\n\nIf they accept your request you both will be added to eachother's profiles`
        )
        if(!response) return;
        const request = addNotificationToUser(profileData.uid, {
            id: `${userData.uid}-${userData.accountType}-request`,
            image: `${IMAGEKIT_URL}/${profileData.profilePictureKey}`,
            content: `${userData.displayName} requested you as their employee!`,
            timeCreated: new Date().getTime(),
            read: false,
            //When notification is pressed it won't direct user to post screen.
            type: {request: userData.uid, type: `employee-request`},
        });
        request.then((success)=>{
            alert("Request has been sent");
        })
        .catch((error)=>{
            alert("Failed to send your request - Please try again");
            catchErrorForSentry(error);
        })
    }

    const terminateEmployee = async ()=>{
        const response = await confirmDecision(
            `Would you like to terminate ${profileData.displayName}'s employment?`,
            `${profileData.displayName} currently works for you - Are you sure you would like to terminate their employment?\n\nThey will be notified of this event.`
        )
        if(!response) return;
        Fire.deleteDataAtRoute(`publicUserData/${userData.uid}/employees/${profileData.uid}`)
        .then(()=>{
            //Adding employee/employer to the user who sent request
            Fire.deleteDataAtRoute(`publicUserData/${profileData.uid}/employers/${userData.uid}`)
            .then(()=>{
                const request = addNotificationToUser(profileData.uid, {
                    id: `${userData.uid}-${userData.accountType}-termination`,
                    image: `${IMAGEKIT_URL}/${profileData.profilePictureKey}`,
                    content: `${userData.displayName} terminated their employment of you.`,
                    timeCreated: new Date().getTime(),
                    read: false,
                    //When notification is pressed it won't direct user to post screen.
                    type: {request: userData.uid, type: `employee-termination`},
                });
                request.then((success)=>{
                    // alert("Request has been sent");
                })
                .catch((error)=>{
                    alert("Failed to send notify user of employee termination - Please try again");
                    catchErrorForSentry(error);
                })
                
            })
            .catch((error: any)=>{
                console.log(`Failed to remove employer ${userData.uid} from user ${profileData.uid}`);
                catchErrorForSentry(error);
            })
        })
        .catch((error: any)=>{
            console.log(`Failed to remove employee ${profileData.uid} from employer ${userData.uid}`);
            catchErrorForSentry(error);
        })

      
    }

    const isEmployee = ()=>{
        if(!employerData) return false;
        return Object.keys(employerData).includes(userData.uid);
    }

    const RequestEmployeeButton = ()=>{
        return(
            <TouchableContainer
                onPress={isEmployee() ? ()=>terminateEmployee() : ()=>sendEmployeeRequest()}
                style={{
                    // alignSelf: 'center',
                    // borderRadius: 5,
                    // padding: 5,
                    width: '100%',
                    // backgroundColor: COLORS.primaryBlue,
                    backgroundColor: 'transparent',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    // alignContent: 'center',
                }}>
            {/* <TouchableContainer style={{
                flexDirection: 'row',
                backgroundColor: 'black',
                justifyContent: 'center',
                padding: 5,
                borderRadius: 2.5,
                width: 200,
                height: 35,
            }}> */}
                <MaterialIcons name="work" size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color="black" />
                {/* <Text style={{color: 'white', fontSize: scaleFontSize(15)}}> Request Employment </Text> */}
            </TouchableContainer>
        )
    }

    const ProfilePictureBar = memo((props: any)=>{
        // console.log("Rendering profile picture bar");
        const {
            onAccountNameAndAddressChange,
            onProfilePicturePress,
            profilePictureKey,
        } = props;
        return(
            <Card style={{
                // flex: 1,
                flexDirection: 'row',
                height: SCREEN_SIZE.height * .20,
                borderRadius: 0,
                // padding: 2.5
                // borderColor: 'black', borderWidth: 2,
            }}>
                <TouchableContainer
                    style={{
                        flex: .35,
                        // height: '100%',
                        // width: '50%',
                        alignItems: 'flex-start',
                        // borderColor: 'red',
                        // borderWidth: 1,
                    }}
                    onPress={isProfileOwner ? onProfilePicturePress : ()=>setShowProfilePictureModal(true)}>
                    {/* <Text>Just a test</Text> */}
                    <Image 
                        source={ profilePictureKey ? {uri: `${IMAGEKIT_URL}/${profilePictureKey}`} : require('../assets/placeholder-picture.png') } 
                        style={{
                            // flex: 1,
                            height: '100%',
                            width: '100%',
                            // borderWidth: 1,
                            // borderColor: 'blue',
                            alignSelf: 'flex-start',
                            // flex: 1,
                            // aspectRatio: 1.5/2,
                            // borderTopLeftRadius: 10,
                            // borderBottomLeftRadius: 10,
                        }} 
                        resizeMode="cover" 
                    />
                </TouchableContainer>

                <TouchableContainer
                    disabled={!isProfileOwner}
                    onPress={onAccountNameAndAddressChange}
                    style={{
                        flex: isProfileOwner ? .55 : .65,
                        padding: 10,
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        minHeight: 125,
                        // borderColor: 'blue',
                        // borderWidth:1,
                        
                    }}>
                    <View style={{height: '100%', width: '100%', justifyContent: 'space-around'}}>
                        <Text style={{
                            // borderColor: 'red',
                            // borderWidth:1,
                            marginBottom: 5, fontSize: scaleFontSize(16), fontWeight: '800', flexWrap: 'wrap'}}>
                            {state.accountName}
                        </Text>
                        {isEntertainment && <Text style={{marginVertical: 2, fontSize: scaleFontSize(12),width: '100%'}}>
                            {state.genre}
                        </Text>}
                        {isAccount && <Text style={{marginVertical: 2, fontSize: scaleFontSize(12),width: '100%'}}>
                            {state.address}
                        </Text>}
                        {(profileData.isPhoneNumberVisible && !!state.phoneNumber) &&
                            <TouchableContainer onPress={()=>Linking.openURL(`tel:${state.phoneNumber}`)}>
                                <Text style={{marginVertical: 2, fontSize: scaleFontSize(12),width: '100%', color: 'blue',}}>
                                    {formatPhoneNumber(state.phoneNumber)}
                                </Text>
                            </TouchableContainer>
                        }
                        <View key={state.website+state.facebookUrl+state.instagramUrl+state.tiktokUrl} style={{
                            marginTop: 5,
                            // flex: 1,
                            width: isWeb() ? '100%' : '50%',
                            alignSelf: 'flex-start',
                            flexDirection: 'row',
                            // alignItems: 'flex-end',
                            // alignContent: 'flex-start',
                            // alignItems: !state.website && !state.facebookUrl && !state.instagramUrl ? 'flex-start' : 'center',
                            // flexDirection: !state.website && !state.facebookUrl && !state.instagramUrl ? 'column' : 'row',
                            // flexDirection: 'column',
                            // justifyContent: 'space-around'
                        }}>
                            {profileData.isEmailVisible && <TouchableContainer style={{marginRight: SCREEN_SIZE.width * .01}} onPress={()=>Linking.openURL(`mailto:${state.email}`)}>
                                <MaterialIcons name="email" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="black" />
                            </TouchableContainer>}
                            {(profileData.isWebsiteVisible && !!state.website)&& <TouchableContainer style={{marginRight: SCREEN_SIZE.width*.01}} onPress={()=>{Linking.openURL(state.website)}}>
                                <MaterialCommunityIcons 
                                name="web" size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color="black" />
                            </TouchableContainer>}
                            {!!state.tiktokUrl && <TouchableContainer style={{marginRight: SCREEN_SIZE.width*.01}} onPress={()=>{Linking.openURL(state.tiktokUrl)}}>
                                <FontAwesome5 name="tiktok" size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color="black" />
                            </TouchableContainer>}
                            {!!state.facebookUrl && <TouchableContainer style={{marginRight: SCREEN_SIZE.width*.01}} onPress={()=>{Linking.openURL(state.facebookUrl)}}>
                                <FontAwesome5 name="facebook" size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color="black" />
                            </TouchableContainer>}
                            {!!state.instagramUrl && <TouchableContainer style={{marginRight: SCREEN_SIZE.width*.01}} onPress={()=>{Linking.openURL(state.instagramUrl)}}>
                                <AntDesign name="instagram" size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color="black" />
                            </TouchableContainer>}
                        </View>
                        {isStaff && <BusinessStatus/>}
                    </View>
                </TouchableContainer>

                <TouchableContainer
                    style={{
                        flex: .10,
                        height: '100%',
                        padding: 5,
                        alignItems: 'center',
                        justifyContent: isProfileOwner ? 'flex-start' : 'flex-end'
                }}>
                    {
                        isProfileOwner ?
                        <FontAwesome5 onPress={onAccountDataEditorPress} name="edit" size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color="black" /> :
                        <View style={{
                            flex: 1,
                            // borderColor: 'red', borderWidth: 1,
                            // padding: 10,
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: userIsAccount ? 'space-between' : 'flex-end'
        
                        }}>
                            {(userData.accountType === 'account' && !isProfileOwner) && <RequestEmployeeButton/>}
                            <MessageButton navigation={navigation} profileData={profileData} />
                        </View>
                    }
                 </TouchableContainer>

            </Card>
        )
    })

    const onFollowPress = (following: Boolean)=>{
        const { profilePictureKey } = userData;
        setFollowing(following);
        if(following){
            console.log(`Following ${profileData.displayName}`);
            //Add current user as a follower to profile owner
            Fire.updateRoute(`publicUserData/${profileData.uid}/uidsFollowingUser/`, {[`${userData.uid}`]: true})
            
            //Add profileData.uid to uidsUserFollows of current user.
            Fire.updateRoute(`publicUserData/${userData.uid}/uidsUserFollows/`, {[`${profileData.uid}`]: true})
            
            addNotificationToUser(profileData.uid, {
                id: `${userData.uid}-follow`,
                image: `${IMAGEKIT_URL}/${profilePictureKey}`,
                content: `${userData.displayName} started following you!`,
                timeCreated: new Date().getTime(),
                read: false,
                //When notification is pressed it won't direct user to post screen.
                type: {user: userData.uid},
            });

            dispatch(updateWhoUserFollows({property: profileData.uid, value: true}));

        }
        else{
            console.log(`Unfollowing ${profileData.displayName}`);

            Fire.updateRoute(`publicUserData/${profileData.uid}/uidsFollowingUser/`, {[`${userData.uid}`]: null});
            Fire.updateRoute(`publicUserData/${userData.uid}/uidsUserFollows/`, {[`${profileData.uid}`]: null});
            // //Remove current user as a follower to profile owner

            removeNotificationFromUser(profileData.uid, `${userData.uid}-follow`);

            //Modifying Local User Data
            dispatch(updateWhoUserFollows({property: profileData.uid, value: null}))
        }
    }

    const CoverPhoto = memo(()=>{
        // console.log("Rendering cover photo");
        const reOrderUploadedPictures = ()=>{
            if (!profileData || !profileData.coverPhotoKey) {
                return uploadedPictures;
            }
        
            let index = uploadedPictures.findIndex((url: string) => url.includes(profileData.coverPhotoKey));
        
            if (index === -1) {
                return uploadedPictures;
            }
        
            let reorderedPictures = [...uploadedPictures];
            let [selectedPicture] = reorderedPictures.splice(index, 1);
            reorderedPictures.unshift(selectedPicture);
            return reorderedPictures;
        }

        const NextButton = ({ onPress }: any) => (
            <TouchableContainer onPress={onPress}>
                <AntDesign name="rightcircleo" size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color="white" />
            </TouchableContainer>
        );
        
        const PrevButton = ({ onPress }: any) => (
            <TouchableContainer onPress={onPress}>
                <AntDesign name="leftcircleo" size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055} color="white" />
            </TouchableContainer>
        );
        // console.log("Uploaded Pictures: ", uploadedPictures);
        return(
            <View style={{
                height: SCREEN_SIZE.height * .35, 
                // borderWidth: 2, borderColor: 'red'
            }}>
                <Swiper
                    timeout={0}
                    //@ts-ignore 
                    key={uploadedPictures.length+profileData.coverPhotoKey} containerStyle={{flex: 1}}
                    controlsEnabled={true}
                    controlsProps={{
                        prevTitle: "",
                        nextTitle: "",
                        //TODO: Why does this throw an error without as any?
                        NextComponent: NextButton as any,
                        PrevComponent: PrevButton as any,

                    }}
                >
                    {
                    uploadedPictures.length
                    ?
                    reOrderUploadedPictures().map((item: any, index: number) => (
                            <TouchableContainer key={index} onPress={isProfileOwner ? onModifyPictures : ()=>{setSelectedCoverPhoto(item);setShowCoverPictureModal(true)}} style={{flex: 1}}>
                                <Image 
                                    source={{ uri: item }} 
                                    style={{
                                        flex: 1,
                                        // borderWidth: 2,
                                        // borderColor: 'red',
                                        // height: 50,
                                        // width: scrollViewWidth
                                    }} 
                                    resizeMode="cover" 
                                />
                            </TouchableContainer>
                    ))
                    :
                    <TouchableContainer disabled={!isProfileOwner}
                        onPress={onModifyPictures} style={{flex: 1}}>
                            <Image 
                                source={profilePictureKey ? {uri: `${IMAGEKIT_URL}/${profilePictureKey}`} : require('../assets/alwayslocal-logo.png')} 
                                style={{
                                    flex: 1,
                                    alignSelf: 'center',
                                    // borderWidth: 2,
                                    // borderColor: 'red',
                                    // height: 50,
                                    // width: 100,
                                }} 
                                resizeMode="contain" 
                            />
                    </TouchableContainer>
                    }
                </Swiper>
                {!isProfileOwner && <FollowButton containerStyle={{top: 20, right: 10}} following={following} onFollowPress={onFollowPress} />}
            </View>
        )
    })

    const PinCoverPhotoButton = (props: any)=>{
        const { selectedImageKey } = props;
        const onPinCoverPhoto = ()=>{
            Fire.setUserDataRoute(`${profileData.uid}/coverPhotoKey`, selectedImageKey);
            dispatch(updateUserDataProperty({property: 'coverPhotoKey', value: selectedImageKey}))
            setEdittingMode("");
            setShowModal(false);
        }
        return(
            <CustomButton
                onPress={onPinCoverPhoto}
                style={{
                    width: '50%',
                    borderRadius: 10,
                    padding: 10,
                    alignSelf: 'center',
                    backgroundColor: COLORS.primaryRed,
                    marginBottom: 10,
                }}
                textStyle={{
                    fontSize: scaleFontSize(15),
                    color: '#fff',
                }}
                title={'Pin as cover photo'}
            />
        )
    }
    const onProfilePicturePress = ()=>{
        if(!uploadedPictures.length) return alert("Click your cover photo above before selecting a profile picture!");
        setEdittingMode('selectProfilePicture');
        setShowModal(true);
    }
    const renderModalContent = ()=>{
        if(edittingMode === "coverPictures") return <AccountChoosePictures PinCoverPhotoButton={PinCoverPhotoButton}/>
        if(edittingMode === "selectProfilePicture") return <AccountSelectProfilePicture profilePictureKey={profilePictureKey} onPictureSelected={onPictureSelected}/>
        if(edittingMode === "nameAndAddressChange") return <AccountDataEditor navigation={props.navigation} route={{params: {noBirthday}}}/>
    }

    const onChangeEmailFailure = (error: any)=>{
        console.log("Error code: ", error.code);
        if(error.code === 'auth/requires-recent-login'){
            // setShowModal(false);
            // setShowReauthModal(true);
        }
        else{
            // catchErrorForSentry(error);
            alert("Sorry, we failed to update your email - Please try again");
        }
    }

    const onChangeEmailSuccess = (success: any)=>{
        //Updating database
        Fire.setUserDataRoute(`${profileData.uid}/email`, state.email);
        //Updating Local Data
        dispatch(updateUserDataProperty({property: 'email', value: state.email}));
    }

    const onBirthdayPress = ()=>{
        Platform.OS === 'ios' && setShowModal(!showModal);
    };

    const Entertainment = ()=>{
        const ifOwnerHasNoSchedule = isProfileOwner && !entertainmentSchedule;
        const allGigsHavePassed = haveAllEntertainmentGigsPassed();
        const displayGigs = !ifOwnerHasNoSchedule && !allGigsHavePassed;
        // console.log("No schdule? ", ifOwnerHasNoSchedule);
        // console.log("All gigs passed? ", allGigsHavePassed);
        // console.log("displaygigs: ", displayGigs);
        return(
            <Card style={{flex:1, alignItems: 'center', justifyContent: 'center', padding:5 }}>
                {!displayGigs && <Text style={{ fontSize: scaleFontSize(12), textAlign: 'center', fontWeight: '500' }}>
                    { (isProfileOwner && ifOwnerHasNoSchedule) ?
                    'This will be populated with your gigs once you request them and they get accepted!' :
                    'No shows listed yet'}
                </Text>}
                    { (
                        !displayGigs && (isProfileOwner && ifOwnerHasNoSchedule)
                      )
                      &&
                        <>
                            <Text style={{ fontSize: scaleFontSize(10), textAlign: 'center'}}>
                                Here are some examples
                            </Text>
                            <GigDisplayer push={navigation.push} onAccount={false} placeholder={true} gigs={fakeGigData} />
                        </>
                    }
                {displayGigs && <GigDisplayer push={navigation.push} onAccount={false} gigs={entertainmentSchedule}/>}
            </Card>
        )                      
        
    }
    if(!userData.uid) return <LoginPrompt navigation={props.navigation} prompt={"create your profile!"}/>
    return(
        <Screen style={
            {
                //@ts-ignore
                ...(Platform.isPad ? {marginTop: 0} : {})
            }
        }
        isHeaderShown={isHeaderShown}>
            {/* <TestComponent/> */}
            <ScrollView
                keyboardShouldPersistTaps='always' // It ensures that a tap on the scroll view won't dismiss the keyboard unless a touch outside the keyboard was made.
                keyboardDismissMode='none' // It dismisses the keyboard when a drag begins. You can also set it to 'none' if you don't want the keyboard to dismiss on drag.
                style={{
                    height: SCREEN_SIZE.height,
                    width: SCREEN_SIZE.width,
                }}
            contentContainerStyle={{...styles.container}}>
                <ExpandPictureModal
                    profilePictureKey={selectedCoverPhoto.replace(IMAGEKIT_URL, "")}
                    showProfilePictureModal={showCoverPictureModal}
                    setShowProfilePictureModal={setShowCoverPictureModal}
                />
                <ExpandPictureModal
                    profilePictureKey={profilePictureKey}
                    showProfilePictureModal={showProfilePictureModal}
                    setShowProfilePictureModal={setShowProfilePictureModal}
                />
                <CustomModal
                    onBack={()=>{
                        if(userData.uid && noBirthday){
                            alert("Must set birthday before precedding");
                            navigation.push('Birthday');
                            return;
                        }
                    }}
                    title={getCustomModalTitle()}
                    gradient={false}
                    visibilityFlag={showModal}
                    visibilityControlFunc={setShowModal}
                    showSubmit={false}
                    scroll={true}
                    containerStyle={{
                        alignSelf: 'center',
                        justifyContent: 'center',
                        height: '80%',
                        width: '100%',
                        // borderWidth: 1,
                        // borderColor: 'red',
                    }}
                    cardStyle={{
                        // height: modalSize,
                        width: SCREEN_SIZE.width,
                        // position: (Platform.OS === 'ios' ? 'absolute' : 'relative'),
                        // top: (Platform.OS === 'ios' ? Header : 0),
                    }}
                >  
                    {renderModalContent()}
                </CustomModal>
                <CoverPhoto/>
                <ProfilePictureBar
                    onAccountNameAndAddressChange={onAccountDataEditorPress}
                    onProfilePicturePress={onProfilePicturePress}
                    profilePictureKey={profilePictureKey || ""}
                />
                {
                    isEntertainment &&
                    <View style={{
                        flex: 1, padding: 10, backgroundColor: COLORS.slateGray,
                        // borderTopWidth: 1, borderTopColor: 'black'
                    }}>
                        <Text style={{fontSize: scaleFontSize(20), color: "white", textAlignVertical: 'center'}}>
                            <SimpleLineIcons name="people" color="white"  size={SCREEN_SIZE.width * .055 > 50 ? 50 : SCREEN_SIZE.width * .055} />
                            <Text style={{marginLeft: 10}}>
                                Gig Schedule
                            </Text>
                        </Text>
                        <Entertainment key={entertainmentSchedule}/>
                    </View>
                }
                 <PlacesYouFollow
                    darkMode={isEntertainment ? false: true}
                    key={placesUserFollows.length+Math.random()}
                    push={navigation.push}
                    isProfileOwner={isProfileOwner}
                    places={placesUserFollows} 
                />
                <StaffYouFollow
                     darkMode={isEntertainment ? true: false}
                     key={staffUserFollows.length+Math.random()}
                     push={navigation.push}
                     isProfileOwner={isProfileOwner}
                     staff={staffUserFollows} 
                />
                <LocalsYouFollow
                     darkMode={isEntertainment ? false: true}
                     key={localsUserFollows.length+Math.random()}
                     push={navigation.push}
                     isProfileOwner={isProfileOwner}
                     locals={localsUserFollows} 
                />
                <EntertainmentYouFollow
                     darkMode={isEntertainment ? true: false}
                     key={entertainmentUserFollows.length+Math.random()}
                     isProfileOwner={isProfileOwner}
                     entertainment={entertainmentUserFollows} 
                     push={navigation.push}
                />
            </ScrollView>
        </Screen>
)
}

const styles = StyleSheet.create({
    container: {
        alignContent: 'center',
        justifyContent: 'flex-start',
    }
});

export default UserProfile