import { Platform } from 'react-native';
// import i18n  from 'i18n-js';
import { Alert } from 'react-native';


const confirmDecision = (title: string, message: string = '') => {
    if (Platform.OS === 'web') {
      return new Promise((resolve) => {
        const options = [
          {
            text: 'Yes',
            onPress: () => {
              resolve(true);
            },
          },
          {
            text: 'No',
            onPress: () => {
              resolve(false);
            },
            style: 'cancel',
          },
        ];
  
        const result = window.confirm([title, message].filter(Boolean).join('\n'));
  
        if (result) {
          const confirmOption = options.find(({ style }) => style !== 'cancel');
          confirmOption && confirmOption.onPress();
        } else {
          const cancelOption = options.find(({ style }) => style === 'cancel');
          cancelOption && cancelOption.onPress();
        }
      });
    } else {
      return new Promise((resolve) => {
        Alert.alert(title, message, [
          {
            text: 'Yes',
            onPress: () => {
              resolve(true);
            },
          },
          {
            text: 'No',
            onPress: () => {
              resolve(false);
            },
            style: 'cancel',
          },
        ]);
      });
    }
  };

export default confirmDecision