import { memo, useEffect, useState } from "react";
import Fire from "../Fire";
import { updateUserDataProperty } from "../store/slices/userData";
import { COLORS, SCREEN_SIZE, drinks } from "../Constants";
import Card from "./Card";
import { View, Text, Image } from "react-native";
import TouchableContainer from "./TouchableContainer";
import { RootState } from "../store";
import { useSelector } from "react-redux";
//@ts-ignore
import bourbon from '../assets/drinkIcons/bourbon.png';
//@ts-ignore
import champagne_prosec from '../assets/drinkIcons/champagne-prosec.png';
//@ts-ignore
import craft_beer from '../assets/drinkIcons/craft-beer.png';
//@ts-ignore
import domestic_beer from '../assets/drinkIcons/domestic-beer.png';
//@ts-ignore
import gin from '../assets/drinkIcons/gin.png';
//@ts-ignore
import gluten_free from '../assets/drinkIcons/gluten-free.png';
//@ts-ignore
import import_beer from '../assets/drinkIcons/import-beer.png';
//@ts-ignore
import non_alcohol from '../assets/drinkIcons/non-alcohol.png';
//@ts-ignore
import red_wine from '../assets/drinkIcons/red-wine.png';
//@ts-ignore
import rum from '../assets/drinkIcons/rum.png';
//@ts-ignore
import selzter from '../assets/drinkIcons/seltzer.png';
//@ts-ignore
import tequila from '../assets/drinkIcons/tequila.png';
//@ts-ignore
import vodka from '../assets/drinkIcons/vodka.png';
//@ts-ignore
import whiskey from '../assets/drinkIcons/whiskey.png';
//@ts-ignore
import white_wine from '../assets/drinkIcons/white-wine.png';
import scaleFontSize from "../helpers/scaleFontSize";

const drinkImages: {[key: string]: any} = {
    'Vodka': vodka,
    'Tequila': tequila,
    'Rum': rum,
    'Whiskey': whiskey,
    'Bourbon': bourbon,
    'Scotch': gin,
    'Red Wine': red_wine,
    'White Wine': white_wine,
    'Prosecco': champagne_prosec,
    'Champagne': champagne_prosec,
    'Craft Beer': craft_beer,
    'Import Beer': import_beer,
    'Domestic Beer': domestic_beer,
    'Selzter': selzter,
    'Gin': vodka,
    'Gluten Free': gluten_free,
    'Non Alcoholic': non_alcohol,
}


const Drink = (props: any)=>{
    const {selected, setSelected, parentWidth, name} = props;
    // if(selected) console.log("Drink Selected: ", name);
    // const drinkWidth = parentWidth *.25;
    return(
        <TouchableContainer
            onPress={()=>setSelected(name)}
            style={{
                // flexBasis: '25%', // This will make the item take up 25% of the parent's width
                // maxWidth: '25%', // This will prevent the item from growing larger than 25% of the parent's width
                // height: 'width', // You might need to adjust this depending on your layout
                // flex: 1,
                width: SCREEN_SIZE.height * .1,
                height: SCREEN_SIZE.height * .1,
                borderRadius: 10,
                borderColor: selected ? COLORS.primaryBlue : 'transparent',
                alignItems: 'center',
                justifyContent: 'center',
                borderWidth: 1,

                // width: drinkWidth,
                // height: drinkWidth,
                // borderRadius: 10,
                // borderColor: selected ? COLORS.primaryBlue : 'transparent',
                // alignItems: 'center',
                // justifyContent: 'center',
                // // borderWidth: selected ? 1 : 0,
                // borderWidth: 1,
            }}
        >
            {drinkImages[name] && <Image
                style={{
                    height: '65%',
                    width: '65%',
                }}
                // source={{}}
                source={drinkImages[name]}
            />}
            <Text style={{textAlign: 'center'}}>
                {name}
            </Text>
        </TouchableContainer>
    )
}
const FavoriteDrinksEditor = (props: any)=>{
    const { userData } = props;
    // const userData = useSelector((state: RootState)=>state.userData)
    const INITIAL_DRINK_STATE = drinks.reduce((obj: {[key: string]: boolean}, drink: string) => {
        obj[drink] = false;
        return obj;
    }, {});
    const [selectedDrinks, setSelectedDrinks] = useState(userData.favoriteDrinks || INITIAL_DRINK_STATE);

    // const [parentWidth, setParentWidth] = useState(0);
    const setSelected = (drink: string)=>{
        setSelectedDrinks((prevState: any) => {
            const updatedDrinks = {
                ...prevState,
                [drink]: !prevState[drink]
            };
    
            // Use the updated state immediately after creating it.
            // updateUserDataProperty({ property: 'favoriteDrinks', value: updatedDrinks });
            // Fire.updateUserDataRoute(`${userData.uid}/favoriteDrinks/`, { [`${drink}`]: !prevState[drink] });
            Fire.updateUserDataRoute(`${userData.uid}`, { favoriteDrinks: updatedDrinks });
    
            // Return the updated state for the state update.
            return updatedDrinks;
        });
    }
    return(
        <Card
            style={{
                // borderColor: 'red',
                // borderWidth: 1,
                // height: SCREEN_SIZE.height * .4,
                width: '100%',
                justifyContent: 'center', // This will center the inner container
                alignItems: 'center', // This also helps in centering
            }}
            // onLayout={(event: any) => {
            //     const { width } = event.nativeEvent.layout;
            //     setParentWidth(width);
            // }}
        >
            <Text style={{
                textAlign: 'center',
                fontSize: scaleFontSize(14),
                fontWeight: '600',
                padding: 10,
            }}>
                Tap on your favorite drink icons to receive brand specific specials
            </Text>
            <View
                style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                }}
            >
                    {drinks.map((drink)=>{
                        return (
                            <Drink
                                key={drink}
                                name={drink}
                                // parentWidth={parentWidth}
                                setSelected={setSelected}
                                selected={selectedDrinks[drink]}
                            />
                        )
                    })}
            </View>
        </Card>
    )
}

export default FavoriteDrinksEditor;