// import { Text, View } from "react-native";
// import CustomButton from "./CustomButton";
// import { COLORS, GOOGLE_MAPS_API_KEY, SERVER_URL } from "../Constants";
// import getLatLngFromAddress from "../helpers/getLatLngFromAddress";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState } from "../store";
// import { updateUserDataProperty } from "../store/slices/userData";
// import Fire from "../Fire";
// import scaleFontSize from "../helpers/scaleFontSize";
// import showToast from "../helpers/showToast"; 
// import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
// import { useEffect, useState } from "react";
// import TouchableContainer from "./TouchableContainer";
// import Input from "./Input";
// import isWeb from "../helpers/isWeb";

// const ChangeAddress = () => {
//     const dispatch = useDispatch();
//     const userData = useSelector((state: RootState) => state.userData);
//     const [address, setAddress] = useState(userData.address);
//     const [coords, setCoords] = useState(userData.coords);
//     const [selected, setSelected] = useState(userData.address ? true : false);

//     // console.log("Selected: ", selected);
//     // console.log("UserDataAddress: ", userData.address);

//     useEffect(()=>{
//         console.log("Selected: ", selected);
//     }, [selected])

//     const onAddressSelected = (details: any)=>{
//         const selectedAddress = details.formatted_address;
//         const locationCoords = {
//             latitude: details.geometry.location.lat,
//             longitude: details.geometry.location.lng,
//         };
//         console.log("Setting selected true");
//         setSelected(true);
//         setAddress(selectedAddress);
//         setCoords(locationCoords);
//     }
//     const onChangeAddress = async () => {
//         return;
//         try {
//             // Updating database
//             await Fire.updateUserDataRoute(`${userData.uid}`, { address: address });
//             await Fire.updateUserDataRoute(`${userData.uid}`, { coords: coords });
            
//             // Updating Local Data
//             dispatch(updateUserDataProperty({ property: 'address', value: address }));
//             dispatch(updateUserDataProperty({ property: 'coords', value: coords }));
    
//             showToast("Your address has been updated", true);
//         } catch (error) {
//             showToast("Failed to update your address!", false);
//         }
//     };
    
//     return (
//         <View style={{ width: '100%' }}>
//             <Text>Address</Text>
//             {
//                 selected ? 
//                 <TouchableContainer onPress={()=>{
//                     console.log("Setting selected false");
//                     setSelected(false)}
//                     }>
//                     <View
//                         style={{
//                             borderWidth: 1,
//                             borderColor: COLORS.primaryBlue,
//                             borderRadius: 10,
//                             marginBottom: 10,
//                             height: 40,
//                             justifyContent: 'center',
//                         }}
//                     >
//                         <Text style={{}}>{address}</Text>
//                     </View>
//                 </TouchableContainer>
//                 :
//                 <GooglePlacesAutocomplete
//                     placeholder={userData.address ? 'Enter a new address...': 'Enter your address here...'}
//                     fetchDetails={true}
//                     disableScroll={true}
//                     onPress={(data, details = null) => {
//                         console.log("Details: ", details);
//                         console.log("Data: ", data);
//                         if (details) onAddressSelected(details);
//                     }}
//                     query={{
//                         key: GOOGLE_MAPS_API_KEY, // replace with your Google API key
//                         language: 'en',
//                     }}
//                     styles={{
//                         textInputContainer: {
//                             borderWidth: 1,
//                             borderColor: COLORS.primaryBlue,
//                             borderRadius: 10,
//                             marginVertical: 10,
//                             backgroundColor: 'white',
//                         },
//                         textInput: {
//                             backgroundColor: 'transparent',
//                             // borderColor: 'red',
//                             // borderWidth: 1,
//                             height: '100%',
//                             width: '100%',
//                         }
//                     }}
//                     requestUrl={isWeb() ? {
//                         useOnPlatform: 'all',
//                         url: `${SERVER_URL}/maps/api`,
//                         // headers: {
//                         //   Authorization: `an auth token`, // Only if required by your server
//                         // },
//                     } : undefined }
//                 />
//             }
//             <CustomButton
//                 onPress={onChangeAddress}
//                 title={"Submit"}
//                 textStyle={{
//                     color: 'white',
//                     fontWeight: '500',
//                     fontSize: scaleFontSize(12.5),
//                 }}
//                 style={{
//                     backgroundColor: COLORS.primaryBlue,
//                     alignSelf: 'flex-end',
//                     padding: 10,
//                     width: '25%',
//                     minWidth: 100,
//                     borderRadius: 10,
//                 }}
//             />
//         </View>
//     );
// }

// export default ChangeAddress;



import { Text, View } from "react-native";
import CustomButton from "./CustomButton";
import { COLORS, GOOGLE_MAPS_API_KEY, SERVER_URL } from "../Constants";
import getLatLngFromAddress from "../helpers/getLatLngFromAddress";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { updateUserDataProperty } from "../store/slices/userData";
import Fire from "../Fire";
import scaleFontSize from "../helpers/scaleFontSize";
import showToast from "../helpers/showToast"; 
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { useEffect, useRef, useState } from "react";
import isWeb from "../helpers/isWeb";
import CustomGooglePlacesAutocomplete from "./CustomGooglePlacesAutocomplete";
import isAddressInFlorida from "../helpers/isAddressInFlorida";

const ChangeAddress = () => {
    const dispatch = useDispatch();
    const ref = useRef();
    const userData = useSelector((state: RootState) => state.userData);
    const [address, setAddress] = useState(userData.address);
    const [coords, setCoords] = useState(userData.coords);

    useEffect(() => {
        //@ts-ignore
        ref.current?.setAddressText(address);
      }, [address]);

    const onAddressSelected = (details: any)=>{
        const selectedAddress = details.formatted_address;
        const locationCoords = {
            latitude: details.geometry.location.lat,
            longitude: details.geometry.location.lng,
        };
        setAddress(selectedAddress);
        setCoords(locationCoords);
    }
    const onChangeAddress = async () => {
        if(userData.address === address) return;
        const isInFlorida = await isAddressInFlorida(address);
        if(!isInFlorida) return alert("Sorry, this app is currently only available in Florida!")

        try {
            // Updating database
            await Fire.updateUserDataRoute(`${userData.uid}`, { address: address });
            await Fire.updateUserDataRoute(`${userData.uid}`, { coords: coords });
            
            // Updating Local Data
            dispatch(updateUserDataProperty({ property: 'address', value: address }));
            dispatch(updateUserDataProperty({ property: 'coords', value: coords }));
    
            showToast("Your address has been updated", true);
        } catch (error) {
            showToast("Failed to update your address!", false);
        }
    };
    
    return (
        <View style={{ width: '100%' }}>
            <Text>Address</Text>
            <CustomGooglePlacesAutocomplete  
                //@ts-ignore
                ref={ref}
                //@ts-ignore
                placeholder={userData.address ? userData.address : 'Enter your address here...'}
                fetchDetails={true}
                disableScroll={true}
                listViewDisplayed={false}
                onPress={(data = null, details = null) => {
                    if (details) onAddressSelected(details);
                }}
                query={{
                    key: GOOGLE_MAPS_API_KEY, // replace with your Google API key
                    language: 'en',
                }}
                styles={{
                    textInputContainer: {
                        borderWidth: 1,
                        borderColor: COLORS.primaryBlue,
                        borderRadius: 10,
                        marginVertical: 10,
                        backgroundColor: 'white',
                    },
                    textInput: {
                        backgroundColor: 'transparent',
                        // borderColor: 'red',
                        // borderWidth: 1,
                        height: '100%',
                        width: '100%',
                    }
                }}
                requestUrl={isWeb() ? {
                    useOnPlatform: 'web',
                    url: `${SERVER_URL}/maps/api`,}
                    : undefined }
            />
            <CustomButton
                onPress={onChangeAddress}
                title={"Submit"}
                textStyle={{
                    color: 'white',
                    fontWeight: '500',
                    fontSize: scaleFontSize(12.5),
                }}
                style={{
                    backgroundColor: COLORS.primaryBlue,
                    alignSelf: 'flex-end',
                    padding: 10,
                    width: '25%',
                    minWidth: 100,
                    borderRadius: 10,
                }}
            />
        </View>
    );
}

export default ChangeAddress;
