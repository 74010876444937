import GigCard from "./GigCard";
import { Gig } from "../types";
import { ScrollView, Text, View } from "react-native";
import gigHasNotPassed from "../helpers/gigHasNotPassed";
import { SCREEN_SIZE } from "../Constants";


const fakeGigData = [
    {
      id: '1',
      date: new Date('2024-12-10').getTime(),
      start: 14 * 60, // 2:00 PM in minutes since midnight
      end: 16 * 60,   // 4:00 PM in minutes since midnight
      employer: 'Company A',
      entertainer: 'Entertainer 1',
    },
    {
      id: '2',
      date: new Date('2024-12-15').getTime(),
      start: 18 * 60, // 6:00 PM in minutes since midnight
      end: 20 * 60,   // 8:00 PM in minutes since midnight
      employer: 'Company B',
      entertainer: 'Entertainer 2',
    },
    {
      id: '3',
      date: new Date('2024-12-20').getTime(),
      start: 10 * 60, // 10:00 AM in minutes since midnight
      end: 12 * 60,   // 12:00 PM in minutes since midnight
      employer: 'Company C',
      entertainer: 'Entertainer 3',
    },
    {
      id: '4',
      date: new Date('2024-12-25').getTime(),
      start: 16 * 60, // 4:00 PM in minutes since midnight
      end: 18 * 60,   // 6:00 PM in minutes since midnight
      employer: 'Company D',
      entertainer: 'Entertainer 4',
    },
    {
      id: '5',
      date: new Date('2024-12-28').getTime(),
      start: 12 * 60, // 12:00 PM in minutes since midnight
      end: 14 * 60,   // 2:00 PM in minutes since midnight
      employer: 'Company E',
      entertainer: 'Entertainer 5',
    },
    {
      id: '6',
      date: new Date('2024-12-30').getTime(),
      start: 20 * 60, // 8:00 PM in minutes since midnight
      end: 22 * 60,   // 10:00 PM in minutes since midnight
      employer: 'Company F',
      entertainer: 'Entertainer 6',
    },
    {
      id: '7',
      date: new Date('2024-12-31').getTime(),
      start: 13 * 60, // 1:00 PM in minutes since midnight
      end: 15 * 60,   // 3:00 PM in minutes since midnight
      employer: 'Company G',
      entertainer: 'Entertainer 7',
    },
];


const NoGigsText = ({onAccount}: any)=><Text style={{marginLeft: '5%',alignSelf: 'flex-start'}}> {onAccount ? '' : 'No gigs yet...'} </Text>
const GigDisplayer = (props: any)=>{
    const { style, gigs, onAccount, push, onEditGig, placeholder, } = props;
    // console.log("Gig Displayer")
    const Gigs = ()=>{
        if(!gigs || !gigs.length) return <NoGigsText onAccount={onAccount}/>
        const filteredGigs = gigs.filter((gig: Gig)=>{return placeholder ? placeholder : gigHasNotPassed(gig)});
        if(!filteredGigs || !filteredGigs.length) return <NoGigsText onAccount={onAccount}/> 
        const timesortedGigs = filteredGigs.sort((a: Gig, b: Gig)=>{
          return a.date - b.date;
        })
        // console.log("Time sorted gig: ", timesortedGigs);
        return timesortedGigs.map((gig: Gig) => (
        // return gigs.filter((gig: Gig)=>{console.log("Gig: ", gigHasNotPassed(gig)); return true;}).map((gig: Gig) => (
            <GigCard onEditGig={onEditGig} onAccount={onAccount} push={push} key={gig.id} gigData={gig} />
          ));
    }
    return(
        <ScrollView
            nestedScrollEnabled={true}
            style={{flex: 1, width: '100%', maxHeight: SCREEN_SIZE.height*.3, ...style}}
            // contentContainerStyle={{height: '100%'}}
        >
            <Gigs/>
        </ScrollView>
    )
}

export default GigDisplayer