import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GuestData } from '../../Interfaces';
import { INITIAL_REGION } from '../../Constants';

export const initialState: GuestData = {
  coords: {
    latitude: INITIAL_REGION.latitude,
    longitude: INITIAL_REGION.longitude
  }
}

const guestDataSlice = createSlice({
  name: 'guestData',
  initialState,
  reducers: {
    setGuestData: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    clearGuestData: () => {
      return initialState;
    },
    updateGuestDataProperty: (state: any, action: PayloadAction<{ property: string; value: any }>) => {
      const { property, value } = action.payload;
      state[property] = value;
    },
 
  },
});

export const { setGuestData, clearGuestData, updateGuestDataProperty, } = guestDataSlice.actions;

export default guestDataSlice.reducer;