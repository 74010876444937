import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { connect, useSelector } from 'react-redux';
import { COLORS, SCREEN_SIZE } from '../Constants'
import Fire from '../Fire';
import { RootState } from '../store';
const firebase = Fire.getFirebase();

interface ChatRoom {
  name: string,
  uid: string,
  email: string,
  status: string,
  messages: Object[],
}


const UnseenMessagesCount = (props: any)=>{
    const userData = useSelector((state: RootState)=>state.userData);
    const switchData = useSelector((state: RootState) => state.switchData);
    const [unseenMessagesCount, setUnseenMessagesCount] = useState(0)

    useEffect(()=>{

    const onChatRoomDataChange = (newChatRoomData: any)=>{
        if(!newChatRoomData) return;
        let count = 0;
        //@ts-ignore
        Object.values(newChatRoomData).forEach((chatRoom: ChatRoom)=>{
          if(chatRoom.status === 'unread'){
            count +=1;
          }
        });
        setUnseenMessagesCount(count);
    }
    let newMessengerRef = Fire.listenToRoute(`chatRooms/${userData.uid}/`, onChatRoomDataChange)
    return () =>{
        Fire.off(newMessengerRef, "value", onChatRoomDataChange)
    }

    },[userData])
  
    return(
        unseenMessagesCount !== 0 && 
        <View style={{
            backgroundColor: COLORS.primaryRed,
            height: 25, width: 25,
            borderRadius: 25/2,
            alignItems: 'center',
            justifyContent: 'center',
            left: SCREEN_SIZE.width * .15,
            bottom: SCREEN_SIZE.width * .05,
            position: 'absolute',
            zIndex: 1,
        }}>
            <Text style={{fontSize: (unseenMessagesCount>99 ? 10: 13), color: '#fff'}}>
                {unseenMessagesCount>99 ? '99+' : unseenMessagesCount}
            </Text>
        </View>
    )
}



export default UnseenMessagesCount;