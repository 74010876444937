import React, { useEffect, useState } from 'react';
import { ActivityIndicator, StyleSheet, Text, TouchableOpacity } from 'react-native';
import UserImage from './UserImage';
import sendToProfileScreen from '../helpers/sendToProfileScreen';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { COLORS, IMAGEKIT_URL, SCREEN_SIZE } from '../Constants';
import Fire from '../Fire';
import { UserData } from '../Interfaces';
import isObjEmpty from '../helpers/isObjEmpty';
import scaleFontSize from '../helpers/scaleFontSize';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import TouchableBackButton from './TouchableBackButton';


const ChatScreenHeaderTitle = (props: any)=>{
    const { navigation, route } = props;
    const {uid, name } = route.params;
    const userData = useSelector((state: RootState)=>state.userData);
    const [recipientData, setRecipientData ] = useState<UserData | {}>({});
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        Fire.getUserData(uid)
        .then((data)=>{
            setRecipientData(data)
            setLoading(false);
        })
        .catch((error)=>{
            setLoading(false);
        })

    }, [])
    if(loading) return <ActivityIndicator size="large" color={COLORS.primaryBlue}/>;
    const {top} = useSafeAreaInsets();
    return(
        <TouchableOpacity
            style={{...styles.headerContainer, marginTop: top}}
            // disabled={userData.isDating}
            onPress={()=>sendToProfileScreen(userData.uid, navigation.push)}
        >
            <TouchableBackButton
                size={SCREEN_SIZE.width * .065 > 50 ? 50 : SCREEN_SIZE.width * .065}
                onPress={()=>{
                    props.navigation.goBack();
                }}
            />
            {!isObjEmpty(recipientData) && <UserImage
                //@ts-ignore
                uri={recipientData.profilePictureKey ? `${IMAGEKIT_URL}/${recipientData.profilePictureKey}` : ""}
                cacheMode={true}
                style={{width: 50, height: 50, borderRadius: 20, alignSelf: 'center'}}
                uid={route.params.uid}
            />}
            <Text style={styles.headerText}>
                {recipientData && recipientData.displayName ? recipientData.displayName : name}
            </Text>
        </TouchableOpacity>
    )
   
}

const styles = StyleSheet.create({
    headerContainer: {
        flexDirection: 'row',
        height: SCREEN_SIZE.height * .1,
        alignItems: 'center',
        alignContent: 'center',
        padding: 5,
        width: '100%',
        flexWrap: 'wrap',

        shadowColor: 'black',
        shadowOpacity: 0.26,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 8,
        elevation: 5,
        backgroundColor: 'white'
    },
    headerText: {
        paddingLeft: 10,
        textAlign:'center',
        textAlignVertical: 'center',
        fontSize: scaleFontSize(20),
        color: '#000',
    },
})


export default ChatScreenHeaderTitle;