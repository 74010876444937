import React, { useState, useEffect } from 'react';
import { ActivityIndicator, Image } from 'react-native';
// import { Image } from 'expo-image';
import { COLORS, IMAGEKIT_URL } from '../Constants';
import CacheImage from './CacheImage';
import Fire from '../Fire';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import catchErrorForSentry from '../helpers/catchSentryError';
import { UserData } from '../Interfaces';
const firebase = Fire.getFirebase()

const blurhash =
  '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';

const UserImage = (props: {style: any, cacheMode: any, uri: any, uid: any, appLogo: boolean})=> {

    const userData = useSelector((state: RootState) => state.userData);

    const {
      style,
      cacheMode,
      uri,
      uid,
      appLogo
    } = props

    const [imageUrl, setImageUrl] = useState(uri);
    const [returnPlaceholder, setReturnPlaceholder] = useState(false);

    // const isImageAvailable = (givenImageUrl)=>{
    //   return Image.getSize(givenImageUrl, (width, height) => {
    //     return true
    //   }, err => {
    //     // console.log(`Unable to load image for ${username}`);
    //     return false;
    //   })
    // }

    useEffect(()=>{
      Fire.getUserDataProperty(uid, 'profilePictureKey')
      .then((profilePictureKey: string)=>{
        if(!profilePictureKey) return setReturnPlaceholder(true);
        setImageUrl(`${IMAGEKIT_URL}/${profilePictureKey}`);
      })
      .catch((error: any)=>{
        console.log("Failed to fetch profilePictureKey");
        setReturnPlaceholder(true);
        // catchErrorForSentry(error);
      })
    }, [])


    // useEffect(()=>{
    //         let mounted = true;
    //         if(props.globalState.imageUrls[uid] && props.globalState.imageUrls[uid].profilePictureKey){
    //           // console.log("Using profile picture key from state");
    //           let givenImageUrl = (uri ? uri : `${cloudFrontUrl}${props.globalState.imageUrls[uid].profilePictureKey}`);
    //           Image.getSize(givenImageUrl, (width, height) => {
    //             if(mounted) setImageUrl(givenImageUrl);
    //           }, err => {
    //               if(mounted) setReturnPlaceholder(true);
    
    //           })
    //         }
    //         else{
    //           // console.log("Making request for profile picture key");
    //           firebase.database().ref(`publicUserData/${uid}/profilePictureKey`).once('value')
    //           .then((data)=>{
    //             let profilePictureKey = data.val();
    //             let givenImageUrl = (uri ? uri : `${cloudFrontUrl}${profilePictureKey}`);
    //             Image.getSize(givenImageUrl, (width, height) => {
    //               if(mounted) setImageUrl(givenImageUrl);
    //             }, err => {
    //                 if(mounted) setReturnPlaceholder(true);
      
    //             })
    //           })
    //         }

    //         return ()=> mounted = false;
    // }, [])

    // if(cacheMode){
    //     // console.log("Cache Mode On");
    //     if(imageUrl){
    //         return (
    //           //@ts-ignore
    //           <CacheImage uri={imageUrl} style={{...style}} />
    //         )
    //       }
    //       else if(returnPlaceholder){
    //         return(
    //         //   <CacheImage resizeMode={'contain'} uri={imageKitUrl+'placeholder-picture.png'} style={{...style}} />
    //           <Image resizeMode={'contain'} source={require('../assets/placeholder-picture.png')} style={{...style}} />
    //         )
    //       }
    //       else{
    //         return (
    //           <ActivityIndicator style={{alignSelf: 'center'}} size="large" color={Colors.primaryPurple} />
    //         )
    //       }
    // }
    // else{
        if(returnPlaceholder){
          return(
            <Image
              // resizeMode={appLogo ? 'cover' : 'contain'}
              source={appLogo ? require('../assets/alwayslocal-logo.png') : require('../assets/placeholder-picture.png')}
              style={{...style}}
              />
          )
        }
        else if(imageUrl){
          return (
            <Image source={{uri: imageUrl}} style={{...style}} />
          )
        }
        else{
          return (
            <ActivityIndicator style={{alignSelf: 'center'}} size="large" color={COLORS.primaryPurple} />
          )
        }
    // }

}

export default UserImage