import { Platform, Image, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import Card from "../../components/Card"
import { COLORS, EDGES, SCREEN_SIZE } from "../../Constants"
// import Colors from "../../Constants/Colors"
import ActivityIndicator from '../../components/ActivityIndicator';
import CustomButton from "../../components/CustomButton";
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import Fire from "../../Fire";
import TouchableBackButton from "../../components/TouchableBackButton";
import catchErrorForSentry from "../../helpers/catchSentryError";
import { useDispatch } from "react-redux";
import { setUserData, updateUserDataProperty } from "../../store/slices/userData";
import { Ionicons } from "@expo/vector-icons";
import TouchableContainer from "../../components/TouchableContainer";
import NotificationCount from "../../components/NotificationCount";
import { DrawerActions } from "@react-navigation/native";
// import { useNavigation } from "@react-navigation/native";
const firebase = Fire.getFirebase();
// import Colors from "../../fonts/TT_Norms/TTNorms-"
const MobileHeader = (props: any) =>{
    // const liveNavigation = useNavigation();
    const dispatch = useDispatch();
    const window = useWindowDimensions();
    const {navigation, isLoggedIn, route, openDrawer} = props
    const isWebLoginPage = route.name === 'WebLoginPage';
    const [processing, setProcessing] = React.useState(false);
    // let [fontsLoaded] = useFonts({
    //     'TT-Norms': require('../../fonts/TT_Norms/TTNorms-Regular.otf'),
    // });
    // if(!fontsLoaded) return <ActivityIndicator/>;
    // return(
    //     <></>
    // )

    const {top} = useSafeAreaInsets();
    return(
        <View style={{ flex: 1, marginTop: top }}>
            <Card
            style={{
                // flex: 1,
                flexDirection: 'row',
                height: window.height * .1,
                // width: window.width,
                padding: 10,
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius:0,
                borderBottomRadius: 5,
                // backgroundColor: 'transparent'
                
                // borderWidth: 1, 
                // borderColor: 'red'

            }}>
                <Image
                    style={{
                        height: '100%',
                        width: SCREEN_SIZE.width * .25,
                        // borderWidth:1,
                        // borderColor: 'red',
                    }}
                    resizeMode='contain'
                    source={require('../../assets/alwayslocal-banner.png')}
                />
                <View style={{
                    flex: 1,
                    height: '100%',
                    flexDirection: 'row',
                    alignSelf: 'center',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    // borderWidth: 1,
                    // borderColor: 'black',
                }}>
                        <TouchableContainer
                            onPress={()=>{
                                navigation.push('NotificationScreen');
                            }}
                            style={{
                                marginBottom: 0,
                                marginRight: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                            <NotificationCount/>
                            <Ionicons
                                name={'notifications'}
                                size={SCREEN_SIZE.width*.065 > 50 ? 50 : SCREEN_SIZE.width*.065}
                                color={'#000'}
                            /> 
                        </TouchableContainer>
                    <TouchableContainer onPress={()=>navigation.dispatch(DrawerActions.openDrawer())}>
                        <Ionicons
                            name={"menu"}
                            size={SCREEN_SIZE.width*.065 > 50 ? 50 : SCREEN_SIZE.width*.065}
                            color={"black"}
                        />
                    </TouchableContainer>
                </View>
            </Card>
        </View>
    )
}

const styles = StyleSheet.create({
    ctaBtnStyle: {
      borderRadius: 5,
      backgroundColor: '#DD222E',
      width: 120,
      height: 50,
      justifyContent: 'center',
    }
  });

export default MobileHeader