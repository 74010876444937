import { Linking, Text, View, useWindowDimensions } from "react-native"
import { COLORS, SCREEN_SIZE } from "../Constants"
import TouchableContainer from "./TouchableContainer";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import useScreenSize from "../helpers/getScreenSize";
import isMobileListener from "../helpers/isMobileListener";

const WebFooter = (props: any)=>{
    // const screenSize = useWindowDimensions();
    const isMobile = isMobileListener();
    const windowSize = useWindowDimensions();
    const onInstagramPress = ()=>window.location.href = `https://www.instagram.com/AlwaysLocalApp/`;
    const onFacebookPress = ()=>window.location.href = `https://www.facebook.com/AlwaysLocalApp/`;

    return(
        <View style={{
            backgroundColor: COLORS.primaryBlue,
            width: windowSize.width,
            height: !isMobile ? windowSize.height * .070 : windowSize.height * .15,

            // borderWidth: 1,
            // borderColor: 'red',
            // position: 'absolute',
            // bottom: 0,
            flexDirection: !isMobile ? 'row' : 'column-reverse',
            alignContent: 'center',
            alignItems: 'center',
            // paddingVertical: 5,
            paddingHorizontal: windowSize.width * .035,
            justifyContent: 'space-around',
            ...props.style
      }}>

            <Text style={{marginLeft: 5, fontWeight: '500', color: 'white', textAlignVertical: 'center'}}>
              Copyright 2023 Always Local App | Powered By <Text style={{fontWeight: '700'}} onPress={()=>Linking.openURL(`https://cat60.com/`)}>Cat60 Designs</Text> | <Text style={{fontWeight: '700'}}  onPress={()=>Linking.openURL(`https://alwayslocal.net/privacy/`)}>Privacy Statement</Text>
            </Text>
            <View style={{flexDirection: 'row',}}>
              <TouchableContainer style={{marginLeft: SCREEN_SIZE.width * .01}} onPress={onFacebookPress}>
                  <FontAwesome name="facebook" size={25} color="white" />
              </TouchableContainer>
              <TouchableContainer style={{marginLeft: SCREEN_SIZE.width * .01}} onPress={onInstagramPress}>
                  <AntDesign name="instagram" size={25} color="white" />
              </TouchableContainer>
            </View>
    </View>
    )
}

export default WebFooter