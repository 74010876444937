import React, { useEffect, useState } from 'react';
import { StyleSheet, Image, ActivityIndicator } from 'react-native'
import CacheImage from './CacheImage';
import { IMAGEKIT_URL, COLORS } from '../Constants/';
import Fire from '../Fire';


const MapImageMarker = (props: any) =>{
    const {
        isVendor,
        username,
        hasDeals,
        style,
        markerData,
        forceStateChange,
        online,
        profilePictureKey,
        stopRendering
    } = props;

    let url = `${IMAGEKIT_URL}/${profilePictureKey}`

    const [imageUrl, setImageUrl] = useState(url);
    const [returnPlaceholder, setReturnPlaceholder] = useState(false);
    const [forceStateChangeValue, setForceStateChangeValue] = useState(null);
    
    //@ts-ignore
    // useEffect(()=>{

    //   let mounted = true;

    //   if(mounted) setForceStateChangeValue(forceStateChange);

    //   Image.getSize(url, (width, height) => {
    //     if(mounted) setImageUrl(url);
    //   }, err => {
    //       if(mounted) setReturnMarker(true);

    //   })

    //   return ()=> mounted = false

    // }, [forceStateChange])
    useEffect(()=>{
        //markerData.key is uid
        Fire.getUserDataProperty(markerData.key, 'profilePictureKey')
        .then((profilePictureKey: string)=>{
          if(!profilePictureKey) return setReturnPlaceholder(true);
          setImageUrl(`${IMAGEKIT_URL}/${profilePictureKey}`);
        })
        .catch((error: any)=>{
          console.log("Failed to fetch profilePictureKey");
          setReturnPlaceholder(true);
          // catchErrorForSentry(error);
        })
      }, [])
  
    if(returnPlaceholder){
        // console.log("Rendering placeholder image for: ", username);
        return (
            <Image onLoad={stopRendering} resizeMode={'cover'} source={require('../assets/placeholder-picture.png')} style={{...style, ...(hasDeals && styles.hasDeal)}} />
        )
    }
    else if(imageUrl){
        // console.log("Rendering cache image for : ", username);
        return(
            // <CacheImage resizeMode={'cover'} uri={imageUrl} style={{...style, ...(online && styles.userOnline) }} />
            // <Image resizeMode={'cover'} source={{uri: imageUrl}} style={{...style, ...(online && styles.userOnline) }} />
            <Image onLoad={stopRendering} resizeMode={'cover'} source={{uri: imageUrl}} style={{...style, ...(hasDeals && styles.hasDeal)}}/>
            // <Image resizeMode={'cover'} source={state.source} style={{...style, ...(online && styles.userOnline), borderWidth: 2, borderColor: 'yellow'}} />
        )
    }
    else{
      console.log("Rendering loading symbol for: ", username);
        return(
            <ActivityIndicator size="large" color={COLORS.primaryBlue} />
        )
    }
  }
  

  const styles = StyleSheet.create({
      userOnline: {
           borderColor: '#00FF2F',
           borderWidth: 2,
           borderRadius: 50
    },
      hasDeal: {
           borderColor: COLORS.primaryBlue,
           borderWidth: 2,
           borderRadius: 50
    }
  })

  export default MapImageMarker;