import { View } from "react-native";
import CustomButton from "./CustomButton";
import { COLORS, errorMessages, regexPatterns } from "../Constants";
import ValidateInput from "./ValidateInput";
import cleanPhoneNumber from "../helpers/cleanPhoneNumber";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import Fire from "../Fire";
import scaleFontSize from "../helpers/scaleFontSize";
import { useEffect, useState } from "react";
import showToast from "../helpers/showToast";
import removeUSCountryCode from "../helpers/removeUSCountryCode";
import VisibilitySlider from "./VisibilitySlider";
import { updateUserDataProperty } from "../store/slices/userData";
import catchErrorForSentry from "../helpers/catchSentryError";

const DisplayedPhoneNumberEditor = (props: any) => {
    const userData = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(userData.isPhoneNumberVisible);

    useEffect(() => {

        const onPhoneNumberChange = (phoneNumber: any) => {
            if(!phoneNumber) return;
            setPhoneNumber(removeUSCountryCode(phoneNumber));
        }

        let phoneNumberRef = Fire.listenToRoute(`publicUserData/${userData.uid}/phoneNumber/`, onPhoneNumberChange);
        return () => {
            Fire.off(phoneNumberRef, "value", onPhoneNumberChange);
        }
    }, []);

    const onChangePhoneNumber = async () => {
        if (!cleanPhoneNumber(phoneNumber)) {
            showToast("Phone number is not a valid US phone number", false); // Use showToast instead of alert
            return;
        }
        
        try {
            await Fire.updateRoute(`publicUserData/${userData.uid}`, { ['phoneNumber']: cleanPhoneNumber(phoneNumber) });
            showToast("Phone number updated successfully!", true);
        } catch (error) {
            console.log("Error: ", error);
            showToast("Failed to update displayed phone number!", false);
        }
    }

    const onToggleVisiblity=async (isVisible: boolean)=>{
        const originalVisibility = userData.isEmailVisible; // Backup the original email
        try {
            // Attempt to update the database
            await Fire.setUserDataRoute(`${userData.uid}/isPhoneNumberVisible`, isVisible);

            // If successful, update the local data
            dispatch(updateUserDataProperty({ property: 'isPhoneNumberVisible', value: isVisible }));
            showToast(`Phone number is now ${!isVisible ? 'hidden' : 'visible'}`, true);
        } catch (error) {
            // If there's an error, rollback local data to its original state
            dispatch(updateUserDataProperty({ property: 'isPhoneNumberVisible', value: originalVisibility }));
            showToast('Failed to toggle visibility', false);
            catchErrorForSentry(error);
        }
        setIsPhoneNumberVisible(isVisible);
    }

    return (
        <View style={{
            width: '100%',
        }}>
            <ValidateInput
                inputStyle={{
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    borderRadius: 10,
                }}
                style={{ marginBottom: 10 }}
                name={'Displayed Phone Number'}
                errorMessage={errorMessages.phoneNumberError}
                errorName={'phoneNumberErrorFlag'}
                onInputChange={setPhoneNumber}
                regex={regexPatterns.phoneNumber}
                defaultValue={phoneNumber}
                label={"Displayed Phone Number"}
            />
            <View style={{marginTop: 10, width: '100%', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between'}}>
                <VisibilitySlider isVisible={isPhoneNumberVisible} setUpdating={()=>{}} onToggle={onToggleVisiblity}/>
                <CustomButton
                    onPress={onChangePhoneNumber}
                    title={"Submit"}
                    textStyle={{
                        color: 'white',
                        fontWeight: '500',
                        fontSize: scaleFontSize(12.5),
                    }}
                    style={{
                        backgroundColor: COLORS.primaryBlue,
                        alignSelf: 'flex-end',
                        padding: 10,
                        width: '25%',
                        minWidth: 100,
                        borderRadius: 10,
                    }}
                />
            </View>
        </View>
    )
}


export default DisplayedPhoneNumberEditor;
