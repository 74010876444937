import { Text, View } from "react-native";
import TouchableContainer from "./TouchableContainer";
import Card from "./Card";
import { SCREEN_SIZE } from "../Constants";
import { FontAwesome } from "@expo/vector-icons";
import scaleFontSize from "../helpers/scaleFontSize";

const LoginPrompt = (props: any)=>{
    const {prompt, navigation} = props;
    return(
        <View style={{flex:1, justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
            <Card style={{
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                height: SCREEN_SIZE.height * .65,
                width: SCREEN_SIZE.width * .95
            }}>
                <View style={{justifyContent: 'space-around', height: '35%'}}>
                    <Text style={{fontSize: scaleFontSize(20), alignSelf: 'center', textAlign: 'center'}}>
                        Sorry, but you must create an account to {prompt}
                    </Text>
                    <TouchableContainer
                        onPress={()=>{
                            navigation.navigate("Entry");
                        }}
                        style={{
                            padding: 10,
                            alignSelf: 'center',
                            flexDirection: 'row',
                                borderRadius: 10,
                                backgroundColor: '#DD222E',
                                // width: 150,
                                height: 70,
                                justifyContent: 'center',
                        }}>
                            <FontAwesome style={{
                                // borderWidth: 1,
                                // borderColor: 'white',
                                marginRight: 5,
                                alignSelf: 'center',
                                transform: [{ rotate: '45deg' }]}}
                                name="location-arrow"
                                size={30}
                                color="white"
                            />
                            <Text style={{
                                fontSize: scaleFontSize(20),
                                alignSelf: 'center',
                                color: 'white',
                                fontWeight: "500",
                                marginLeft: 5,
                                textAlignVertical: 'center'
                            }}>
                                Create an account
                            </Text>
        
                    </TouchableContainer>
                </View>
            </Card>
        </View>
    )
}

export default LoginPrompt;