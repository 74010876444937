import { StyleSheet, Text, View, Image, useWindowDimensions, Linking, ImageBackground } from 'react-native';
import Card from '../components/Card';
import CustomButton from '../components/CustomButton';
import { AntDesign, FontAwesome5 } from '@expo/vector-icons'
import HomeHeader from '../navigation/components/HomeHeader'
import { COLORS, errorMessages, regexPatterns, SCREEN_SIZE } from '../Constants';
import { useState } from 'react';
import SignUpSheet from '../navigation/components/SignUpSheet';
import React from 'react';
import Fire from '../Fire';
import isAccountInformationTaken from '../helpers/IsAccountInformationTaken';
import TouchableContainer from '../components/TouchableContainer';
import scaleFontSize from '../helpers/scaleFontSize';
import WebFooter from '../components/WebFooter';
// import usewindowSize from '../helpers/getwindowSize';
//@ts-ignore
import restaurantsWebImage from '../assets/restaurantsWebImage.jpg';
//@ts-ignore
import barsAndBreweriesWebImage from '../assets/barsAndBreweriesWebImage.jpg';
//@ts-ignore
import foodTrucksWebImage from '../assets/foodTrucksWebImage.jpg';
//@ts-ignore
import staffWebImage from '../assets/staffWebImage.jpg';
//@ts-ignore
import customersWebImage from '../assets/customersWebImage.jpg';
//@ts-ignore
import entertainmentWebImage from '../assets/entertainmentWebImage.jpg';
import isMobileListener from '../helpers/isMobileListener';




export default function App({navigation, route}: any) {
    console.log("Rendering Email CTA Page");
    const isMobile = isMobileListener();
    // const windowSize = usewindowSize();
    const { isLoggedIn } = route.params;
    const windowSize = useWindowDimensions();

    const [state, setState] = useState({
      username: "",
      // username: "bar1",
      // username: "tyfoods",
      usernameError: errorMessages.usernameError,
  
      firstName: "",
      firstNameError: errorMessages.firstNameError,
  
      lastName: "",
      lastNameError: errorMessages.lastNameError,
  
      phoneNumber: "",
      // phoneNumber: "+15617565951",
      // phoneNumber: "+15616742253",
      phoneNumberError: errorMessages.phoneNumberError,
      countryCode: "+1",
  
      email: "",
      // email: "anthonecuthill@gmail.com",
      // email: "tyfooods@gmail.com",
      emailError: errorMessages.emailError,
      
      password: "",
      // password: "alwayslocal",
      passwordError: errorMessages.passwordError,
  
      error: "",
      
      firstNameFlag: false,
      lastNameFlag: false,
      usernameErrorFlag: false,
      nameFlag: false,
      emailErrorFlag: false,
      phoneNumberErrorFlag: false,
      passwordErrorFlag: false,
    });

    const signUpInputs = [{
          name: "First Name",
          regex: regexPatterns.username,
          errorMessage: state.firstName,
          errorName: "firstNameFlag",
          onInputChange: (value: string)=>setState((prevState: any)=>{return {...prevState, firstName: value}}),
          defaultValue: "",
        },
        {
          name: "Last Name",
          regex: regexPatterns.username,
          errorMessage: state.lastName,
          errorName: "lastNameFlag",
          onInputChange: (value: string)=>setState((prevState: any)=>{prevState.lastName = value; return prevState;}),
          defaultValue: "",
        },
        {
          name: "Email",
          regex: regexPatterns.email,
          errorMessage: state.emailError,
          errorName: "emailFlag",
          onInputChange: (value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;}),
          defaultValue: "",
          isRequired: true,
        },
        {
          name: "Phone Number",
          regex: regexPatterns.phoneNumber,
          errorMessage: state.phoneNumberError,
          errorName: "phoneNumber",
          onInputChange: (value: string)=>setState((prevState: any)=>{prevState.phoneNumber = value; return prevState;}),
          defaultValue: "",
        },
    ]

    const Banner = (props: any)=>{
        return(
            <ImageBackground
                style={{
                    height: windowSize.height,
                    width: windowSize.width,
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                resizeMode='cover'
                source={require('../assets/WebAlwaysLocalBanner.jpg')}
            >
              {props.children}
            </ImageBackground>
        )
    }

    const BannerText = ()=>{
      return(
        <View
          style={{
            padding: 10,
            borderRadius: 5,
            width: '75%',
            position: 'relative',
            top: '12.5%'
        }}>
            <Text style={{...styles.bannerText, marginBottom: windowSize.height*.040, fontWeight: '900'}}>
              ALWAYS LOCAL APP
            </Text>
            {/*
            //@ts-ignore */}
            <Text style={{...styles.bannerText, fontSize: scaleFontSize(19.75), fontWeight: '700', lineHeight: '1.5'}}>
              An easy to use social platform to promote & build all aspects of the hospitality industry, Bars, Restaurants, Breweries, Food Trucks, Staff, Entertainment, Customers and more.
            </Text>
            <Text style={{...styles.bannerText, marginTop: windowSize.height*.05, fontSize: scaleFontSize(30), alignSelf: 'center', marginBottom: windowSize.height*.040, fontWeight: '700'}}>
              BECOME A LOCAL TODAY!
            </Text>
        </View>
      )
    }

    const PerfectForSection = ()=>{
      // RESTAURANTS, BARS AND BREWERIES, FOOD TRUCKS, STAFF, CUSTOMERS, ENTERTAINMENT
      const data = [
        {title: "RESTAURANTS", image: restaurantsWebImage, text: "Always Local App helps drive traffic by promoting Happy Hours, Entertainment, Staff, Daily, Weekly, Monthly and in Real Time Specials."},
        {title: "BARS AND BREWERIES", image: barsAndBreweriesWebImage, text: "Always Local App connects Bars and Breweries with nearby customers, boosting visibility and attracting patrons."},
        {title: "FOOD TRUCKS", image: foodTrucksWebImage, text: "Always Local App connects Food Trucks to local customers, boosting visibility and sales by letting your customers know where you are. "},
        {title: "STAFF", image: staffWebImage, text: "Always Local App Connects Staff and Customers to learn about any Food and Drink Specials, Events and when staff is working."},
        {title: "CUSTOMERS", image: customersWebImage, text: "Always Local App connects users to nearby Restaurants, Bars and Food Trucks effortlessly. A great way to find specials, entertainment and friends."},
        {title: "ENTERTAINMENT", image: entertainmentWebImage, text: "Always Local App allows Musicians, Karaoke, DJ’s, Game Night Hosts and Event Planners to easily connect your schedule so followers can see what venues you will be at."},
      ]
      const Sections = ()=>{
        return data.map((feature: any)=>{
          const { image } = feature
          return(
            <View style={{
              // width: '30%', height: '25%',
              marginVertical: !isMobile ? 0 : 10,
              height: !isMobile ? windowSize.height * .3 : windowSize.height * .5,
              width: !isMobile ? windowSize.width * .20 : windowSize.width,
              // alignItems: 'flex-start', alignContent: 'flex-start',
              // borderColor: 'blue', borderWidth: 1
            }}>
                <Image
                  resizeMode='cover'
                  style={{
                    flexGrow: 1,
                    alignSelf: 'center',
                    // height: '100%',
                    width: '90%',
                    // objectFit: "cover",
                    // height: 'fill',
                    // height: windowSize.height * .4,
                    // width: windowSize.width * .20,
                    // height: 200,
                      // position: 'absolute',
                      // bottom: 0,
                      // width: '35%',
                      // height: '75%',
                      marginBottom: 20,
                      paddingVertical: 0,

                      // borderColor: 'red', 
                      // borderWidth: 1,

                      // width: '100%',
                      // height: '100%',
                      // alignSelf: 'center',
                  }}
                  source={image}
                />
                <Text style={{
                    // marginHorizontal: 2.5,
                    alignSelf: 'center',
                    fontSize: scaleFontSize(20),
                    fontFamily: "Open Sans,Arial,sans-serif",
                    fontWeight: '700',
                    width: '90%',
                    marginVertical: 2.5,
                  }}>
                  {feature.title}
                </Text>
                <Text style={{
                    width: '90%',
                    fontFamily: "Open Sans,Arial,sans-serif",
                    // fontWeight: '700',
                    color: '#666666',
                    alignSelf: 'center',
                    fontSize: scaleFontSize(15),
                    margin: 2.5,
                    lineHeight: windowSize.height*.025,
                }}>
                  {feature.text}
                </Text>
            </View>
          )
        })
      }
      return(
        <View style={{
          width: !isMobile ? '65%' : '100%',
          // height: '90%',
          // height: windowSize.height,
          flexDirection: !isMobile ? 'row' : 'column',
          flexWrap: !isMobile ? "wrap" : "nowrap",
          justifyContent: !isMobile ? 'space-around' : 'center',
          alignItems: 'center',
          marginBottom: windowSize.height*.1,

          // borderColor: 'blue',
          // borderWidth: 5,
        }}>

          <Sections/>
        </View>
      )
      
    }

    const AppDownloadButtons = ()=>{
      return(
        <View style={{
          alignSelf: 'center',
          width: '75%',
          flexDirection: !isMobile ? 'row' : 'column',
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'space-around',

          // borderColor: 'red',
          // borderWidth: 1,
        }}>
          <Image
            resizeMode='contain'
            style={{
                // position: 'absolute',
                // bottom: 0,
                width: windowSize.width * .25,
                height: windowSize.height * .15,
                minWidth: 175,
                minHeight: 125,

                maxWidth: 275,
                maxHeight: 125,
                alignSelf: 'center',
            }}
            source={require('../assets/appleDownloadIcon.png')}
          />
          <Image
            resizeMode='contain'
            style={{
                // position: 'absolute',
                // bottom: 0,
                width: windowSize.width * .25,
                height: windowSize.height * .15,
                minWidth: 175,
                minHeight: 125,

                maxWidth: 275,
                maxHeight: 125,
                alignSelf: 'center',
            }}
            source={require('../assets/googleDownloadIcon.png')}
          />
        </View>
      )
    }

    const CoverageArea = ()=>{
      return(
        <View style={{
          alignContent: 'center',
          alignItems: 'center',
          height: windowSize.height * .75,
          width: windowSize.width,

          // borderColor: 'red',
          // borderWidth: 5,
        }}>
          <Text style={{
            // flex: 1,
            fontFamily: "Open Sans,Arial,sans-serif",
            fontWeight: '700',
            alignSelf: 'center',
            fontSize: scaleFontSize(20),
            margin: 2.5,
            textAlign: 'center',
            // borderColor: 'blue',
            // borderWidth: 1,
          }}>
            For our launch we’re offering service in Florida from Orlando to the Keys.
          </Text>
          <View style={{
            // flex: 1,
            width: !isMobile ? '36.5%' : '90%',
            height: !isMobile ? '70%' : '80%',
            maxHeight: 575,
            // alignItems: 'flex-start',
            // justifyContent: 'flex-start',

            // borderColor: 'blue',
            // borderWidth: 10,
          }}>
            <Image
                resizeMode='stretch'
                style={{
                  // flex: 1,
                  width: '100%',
                  height: '100%',
                    // position: 'absolute',
                    // bottom: 0,
                    // width: '35%',
                    // height: '75%',
                    marginVertical: 0,
                    paddingVertical: 0,
                    // borderColor: 'green', 
                    // borderWidth: 5,
                    // width: '100%',
                    // height: '100%',
                    // alignSelf: 'center',
                }}
                source={require('../assets/coverageArea.jpg')}
              />
          </View>
        </View>
      )
    }

    return(
      <View style={{
          height: windowSize.height,
          width: windowSize.width,
          // flex:1,
          // flexDirection: windowSize..width < 1100 ? 'column' : 'row',
          
          // height: '100%',
          // padding: 10,
          // width: '100%',
          alignItems: 'center',
          
          // alignContent: 'center',
          // justifyContent: windowSize..width < 1100 ? 'flex-start' : 'center',
          // minHeight: windowSize.height, // Ensures the container takes up at least the full viewport height
          // backgroundColor: COLORS.primaryBgColor,
          // backgroundColor: 'rgba(0,0,0,.5)',
          // opacity: .7

          // borderWidth: 5,
          // borderColor: 'black',
      }}>
        <Banner>
          <View style={{
            height: windowSize.height,
            width: windowSize.width,
            backgroundColor: 'rgba(0,0,0,.35)',
            alignItems: 'center',
          }}>
            <BannerText/>
          </View>
        </Banner>
        {/* <View style={{
          height: windowSize.height,
          width: windowSize.width,
          justifyContent: 'center',
          alignItems: 'center',
          // borderColor: 'red',
          // borderWidth: 10,
        }}> */}
          <AppDownloadButtons/>
          <CoverageArea/>
          <Text style={{
                // height: 300,
                fontFamily: "Open Sans,Arial,sans-serif",
                fontWeight: '700',
                alignSelf: 'center',
                fontSize: scaleFontSize(30),
                marginVertical: 30, 
                textAlign: 'center',

                // borderColor: 'blue',
                // borderWidth: 1,
              }}>
                ALWAYS LOCAL APP IS PERFECT FOR:
          </Text>
          <PerfectForSection/>
        {/* </View> */}
        <WebFooter/>
      </View>
    )
  }
  
  const styles = StyleSheet.create({
    bannerText: {
      fontFamily: "'Albert Sans',Helvetica,Arial,Lucida,sans-serif",
      fontWeight: '500',
      fontSize: scaleFontSize(40),
      color: 'white',
    },
    container: {
      // flex: 1,
      // height: '100%',
      // width: '100%',
      backgroundColor: '#F8FAFC',
      // alignItems: 'center',
      // justifyContent: 'center',


      // borderColor: '#000',
      // borderWidth: 1,
    },
    formContainer: {
      // flex: 1,
      // height: '100%',
      // width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      // borderWidth: 10,
      // borderColor: 'purple'
    },
    signUpForm:{
      // flex: 1,
      // height: '100%',
      // width: '90%',
      // height: '90%',
      // height: SCREEN_SIZE.height * .85,
      // maxWidth: '40%',
      // flex: 1,
      // alignItems: 'center',
      // padding: 20,
// 
      // borderWidth: 1,
      // borderColor: 'red',
    },
    appStoreBtn: {
      justifyContent: 'center',
      borderRadius: 10,
      height: 40,
      minWidth: 100,
      width: '50%',
      backgroundColor: 'white',
      flexDirection: 'row',
    },
    socialMediaIcon: {
      width: 35,
      height: 35,
    },
    labelStyle:{
      fontSize: 20,
    },
    inputStyle: {
      height: 40,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: '#CED4DA',
      backgroundColor: '#F8FAFC',
    },
    ctaBtnStyle: {
      borderRadius: 5,
      alignSelf: 'flex-start',
      backgroundColor: '#DD222E',
      width: 120,
      height: 50,
      justifyContent: 'center',
    }
  });