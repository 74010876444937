import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import ensureProtocol from "../helpers/ensureProtocol";
import Fire from "../Fire";
import Toast from "react-native-toast-message";
import { updateUserDataProperty } from "../store/slices/userData";
import ValidateInput from "./ValidateInput";
import { View } from "react-native";
import scaleFontSize from "../helpers/scaleFontSize";
import { COLORS, errorMessages, regexPatterns } from "../Constants";
import CustomButton from "./CustomButton";
import { useState } from "react";

const GenreEditor = (props: any) => {
    const { platformName, state, setState, onSuccess=false, onFailure=false} = props;
    const userData = useSelector((state: RootState) => state.userData);
    //@ts-ignore
    const [genre, setGenre] = useState(userData.genre || "");
    const dispatch = useDispatch();

    const onUpdateGenre = () => {
        if (genre.length > 20) {
            Toast.show({
                type: 'error',
                position: 'bottom',
                text1: 'Error',
                text2: `Description can't be more than 100 characters.`,
                visibilityTime: 2000,
                autoHide: true,
                topOffset: 30,
                bottomOffset: 40,
            });
            return;
        }

        //Updating database
        Fire.updateUserDataRoute(`${userData.uid}`, { genre: genre })
        .then((success: any) => {
            // Display success toast
            onSuccess && onSuccess();
            Toast.show({
                type: 'success',
                position: 'bottom',
                text1: 'Success',
                text2: `Your genre has been updated!`,
                visibilityTime: 2000,
                autoHide: true,
                topOffset: 30,
                bottomOffset: 40,
            });
        })
        .catch((error) => {
            // Display error toast
            onFailure && onFailure();
            Toast.show({
                type: 'error',
                position: 'bottom',
                text1: 'Error',
                text2: `Failed to update your genre. Please try again.`,
                visibilityTime: 2000,
                autoHide: true,
                topOffset: 30,
                bottomOffset: 40,
            });
        });

        //Updating Local Data
        dispatch(updateUserDataProperty({ property: 'genre', value: genre }));
    };

    return (
        <View style={{ width: '100%' }}>
            <ValidateInput
                multiline={true}
                inputStyle={{
                    // height: 100,
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    borderRadius: 10,
                }}
                style={{marginBottom: 10}}
                errorMessage={"Your genre cannot be longer than 100 characters"}
                errorName={'genreErrorFlag'}
                // onInputChange={(value: string)=>setState((prevState: any)=>{prevState.website = value; return prevState;})}
                // onInputChange={(value: string)=>setUrl(value)}
                regex={regexPatterns.genre}
                placeholder={`What describes your work?`}
                defaultValue={genre}
                onInputChange={setGenre}
                // defaultValue={state[urlKey]}
                label={`Genre`}
            />
            <CustomButton
                onPress={onUpdateGenre}
                title={"Submit"}
                textStyle={{
                    color: 'white',
                    fontWeight: '500', 
                    fontSize: scaleFontSize(12.5),
                }}
                style={{
                    backgroundColor: COLORS.primaryBlue,
                    alignSelf:'flex-end',
                    // height: '25%',
                    padding:10,
                    width: '25%',
                    minWidth: 100,
                    borderRadius: 10,
                }}
            />
        </View>
    );
};

export default GenreEditor;
