import { Image } from "react-native";
import CustomModal from "./CustomModal"
import { IMAGEKIT_URL, SCREEN_SIZE } from "../Constants";

const ExpandPictureModal = (props: any)=>{
    const {profilePictureKey, showProfilePictureModal, setShowProfilePictureModal} = props;
    return(
        <CustomModal
            onBack={()=>{}}
            title={""}
            gradient={false}
            visibilityFlag={showProfilePictureModal}
            visibilityControlFunc={setShowProfilePictureModal}
            showSubmit={false}
            scroll={true}
            containerStyle={{
                alignSelf: 'center',
                justifyContent: 'center',
                height: '80%',
                width: '100%',
                // borderWidth: 1,
                // borderColor: 'red',
            }}
            cardStyle={{
                // height: modalSize,
                width: SCREEN_SIZE.width,
                // position: (Platform.OS === 'ios' ? 'absolute' : 'relative'),
                // top: (Platform.OS === 'ios' ? Header : 0),
            }}
        >  
            <Image 
                source={ profilePictureKey ? {uri: `${IMAGEKIT_URL}/${profilePictureKey}`} : require('../assets/placeholder-picture.png') } 
                style={{
                    // flex: 1,
                    height: '100%',
                    width: '100%',
                    // borderWidth: 1,
                    // borderColor: 'blue',
                    alignSelf: 'flex-start',
                    // flex: 1,
                    // aspectRatio: 1.5/2,
                    // borderTopLeftRadius: 10,
                    // borderBottomLeftRadius: 10,
                }} 
                resizeMode="contain" 
            />
        </CustomModal>  
    )
}

export default ExpandPictureModal;