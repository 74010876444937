const formatBusinessHours = (timestamp: number) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const isPM = hours >= 12;
  
    let formattedHours = hours % 12; // Convert to 12-hour format
    formattedHours = formattedHours === 0 ? 12 : formattedHours; // Handle midnight (0) as 12
  
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes; // Add leading zero if needed
  
    const period = isPM ? 'PM' : 'AM';
  
    const formattedTime = `${formattedHours}:${formattedMinutes}${period}`;
    return formattedTime;
};

export default formatBusinessHours