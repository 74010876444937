import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INITIAL_REGION } from '../../Constants';

export const initialState: any = {
  switchedId: "",
  original: {
    uid: "",
  },
  remote: {
    uid: "",
  }
}

const switchDataSlice = createSlice({
  name: 'switchData',
  initialState,
  reducers: {
    setSwitchData: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    clearSwitchData: (state: any) => {
      state["original"] = initialState.original
      state["remote"] = initialState.remote
    },
    updateSwitchDataProperty: (state: any, action: PayloadAction<{ property: string; value: any }>) => {
      const { property, value } = action.payload;
      state[property] = value;
    },
 
  },
});

export const { setSwitchData, clearSwitchData, updateSwitchDataProperty, } = switchDataSlice.actions;

export default switchDataSlice.reducer;