import React, { useState, useEffect } from 'react';
import { View, Text, Button } from 'react-native';
import { Entypo } from '@expo/vector-icons'; 
import TouchableContainer from './TouchableContainer';
import Card from './Card';
import { COLORS, SCREEN_SIZE } from '../Constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateIsHours } from '../store/slices/timePickers';
import getStandardHours from '../helpers/getStandardHours';
import addLeadingZero from '../helpers/addLeadingZero';
import DateTimePicker from '@react-native-community/datetimepicker';
import isWeb from '../helpers/isWeb';
import SetTimeModal from './SetTimeModal';
import scaleFontSize from "../helpers/scaleFontSize";

interface TimePickerProps {
    day: string;
    initialTime: Date;
    onChange: (day: string, newTime: Date) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({ day, initialTime, onChange }) => {
    const [firstRenderFlag, setFirstRenderFlag] = useState(true);
    const timePickers = useSelector((state: any)=>state.timePickers)
    const dispatch = useDispatch();
    const isHours = timePickers[day];
    const [hours, setHours] = useState(getStandardHours(initialTime.getHours()));
    const [minutes, setMinutes] = useState(initialTime.getMinutes());
    const [isPM, setIsPM] = useState(initialTime.getHours() >= 12);
    const [cycle, setCycle] = useState<any>(null);
    const [isButtonHeld, setIsButtonHeld] = useState(false);
    const [showTimeModal, setShowTimeModal] = useState(false);

    const setIsHours = (value: Boolean)=>{
        dispatch(updateIsHours({day, value}))
    }

    const handlePressIn = (increment: boolean) => {
        console.log("Handle press in");
        if(cycle) return;
        setIsButtonHeld(true);
        const newCycle = setInterval(() => {
            if(increment) isHours ? incrementHours() : incrementMinutes()
            else isHours ? decrementHours() : decrementMinutes()
        }, 100);
        setCycle(newCycle);
    };

    const handlePressOut = () => {
        if (cycle !== null) {
          setIsButtonHeld(false);
          clearInterval(cycle);
          setCycle(null);
        //   updateTime();
        }
    };

    const incrementHours = () => {
        setFirstRenderFlag(false);
        setHours((prevHours) => getStandardHours((prevHours % 12 + 1) % 12));
        // if(!isButtonHeld) updateTime();
    };

    const decrementHours = () => {
        setFirstRenderFlag(false);
        setHours((prevHours) => {
            if (prevHours === 1 || prevHours === 13) return 12;
            return prevHours - 1;
        });
        // if(!isButtonHeld) updateTime();
    };

    const incrementMinutes = () => {
        setFirstRenderFlag(false);
        setMinutes((prevMinutes) => (prevMinutes + 1) % 60);
        // if(!isButtonHeld) updateTime();
    };

    const decrementMinutes = () => {
        setFirstRenderFlag(false);
        setMinutes((prevMinutes) => {
            if (prevMinutes === 0) return 59;
            return prevMinutes - 1;
        });
        // if(!isButtonHeld) updateTime();
    };

    const togglePeriod = () => {
        setFirstRenderFlag(false);
        setIsPM((prevIsPM) => !prevIsPM);
        // if(!isButtonHeld) updateTime();
    };

    const updateTime = ()=>{
        const adjustedHours = isPM ? (hours % 12) + 12 : hours % 12;
        const newTime = new Date(initialTime);
        newTime.setHours(adjustedHours, minutes);
        onChange(day, newTime);
    }

    // Whenever hours, minutes, or isPM changes, call onChange with a new date object
    useEffect(() => {
        if(firstRenderFlag || !isWeb()) return;
        const adjustedHours = isPM ? (hours % 12) + 12 : hours % 12;
        const newTime = new Date(initialTime);
        newTime.setHours(adjustedHours, minutes);
        onChange(day, newTime);
    }, [day, hours, minutes, isPM, initialTime, onChange]);
    const ChangeTimeModal = ()=>{
        return(
            <SetTimeModal
                defaultTime={initialTime}
                containerStyle={{
                    position: 'relative',
                    height: SCREEN_SIZE.height * .15,
                    width: SCREEN_SIZE.width * .45,
                    // height: '12.5%',
                    // width: '100%',
                    // bottom: SCREEN_SIZE.height,
                    alignContent: 'flex-end',
                    top: 0,
                    backgroundColor: '#fff',

                    shadowColor: 'black',
                    shadowOpacity: 0.26,
                    shadowOffset: { width: 0, height: 2 },
                    shadowRadius: 8,
                    elevation: 5,
                    borderRadius: 10,

                    // borderColor: 'blue',
                    // borderWidth: 1,
                }}
                setShow={setShowTimeModal}
                onTimeSelected={(newTime: Date)=>{
                    console.log("Day: ", day);
                    console.log("Changing time to: ", newTime);
                    onChange(day, newTime);
                    // isWeb() ? onChange(day, newTime) : updateTime();
                }}
            />
        )
    }
    const TimeDisplayer = ()=>{
        return(
        <TouchableContainer
            disabled={isWeb()}
            onPress={()=>{
                // navigation.push("SetTime", (newTime: Date)=>onChange(day, newTime));
                setShowTimeModal(true);
                //Send to setTime screen
            }}
            style={{flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                
            <View style={{
                flex: 3/5,
                flexDirection: 'row',
                width: '50%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                position: 'relative', 
            }}>
                <View style={{
                    height: '100%',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center'
                }}>
                    <View style={{
                        height: '100%',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}>
                        <Text disabled={!isWeb()} onPress={()=>setIsHours(true)} style={{
                            textAlignVertical: 'center', textAlign: 'center', fontSize: scaleFontSize(20), fontWeight: '500',
                            // borderBottomWidth: isHours ? 1 : 0,
                            // borderBottomColor: 'black',
                            // paddingBottom: isHours ? 1 : 0,
                            // textDecorationLine: isHours ? 'underline' : 'none',
                        }}>
                            {isHours && isWeb() && <View style={{
                                position: 'absolute', 
                                bottom: 0, 
                                left: 0, 
                                right: 0, 
                                width: 10,
                                borderBottomWidth: 1, 
                                borderBottomColor: 'black',
                                borderRadius: 1,
                            }} />}
                            {hours}
                        </Text>
                        <Text style={{alignSelf: 'center', backgroundColor: COLORS.primaryBgColor}}>
                            :
                        </Text>
                    </View>
                    <Text disabled={!isWeb()} onPress={()=>setIsHours(false)} style={{
                        textAlignVertical: 'center', textAlign: 'center', fontSize: scaleFontSize(20), fontWeight: '500'}}>
                        {!isHours && isWeb() && <View style={{
                            position: 'absolute', 
                            bottom: 0, 
                            left: 0, 
                            right: 0, 
                            width: 20,
                            borderBottomWidth: 1, 
                            borderBottomColor: 'black',
                            borderRadius: 1,
                        }} />}
                        {addLeadingZero(minutes)}
                    </Text>
                    <Text disabled={!isWeb()} style={{fontSize: scaleFontSize(20), fontWeight: '500', textAlign: 'center', marginLeft: 0}}
                        onPress={togglePeriod}>
                        {isPM ? 'PM' : 'AM'}
                    </Text>
                </View>
            </View>
            {
                isWeb() &&
                <View style={{flex: 1/5, flexDirection: 'row', justifyContent: 'space-around',}}>
                    <View style={{
                        backgroundColor: 'white', alignItems: 'center', width: '100%',flexDirection:'column',
                    }}>
                        <TouchableContainer
                            onPressIn={handlePressIn}
                            onPressOut={handlePressOut}
                            onPress={isHours ? incrementHours : incrementMinutes}>
                                <Entypo name="chevron-thin-up" size={24} color="black" />
                        </TouchableContainer>
                        <TouchableContainer
                            onPressIn={handlePressIn}
                            onPressOut={handlePressOut}
                            onPress={isHours ? decrementHours : decrementMinutes}>
                                <Entypo name="chevron-thin-down" size={24} color="black" />
                        </TouchableContainer>
                    </View>
                </View>
            }
        </TouchableContainer>
        )
    }
    return (
        
        <Card style={{
            flex: .90/2,
            height: '100%',
            width: '100%',
            maxWidth: 200,
            backgroundColor: 'white',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            
            // borderWidth: 1,
            // borderColor: 'red',
        }}>
          
            {showTimeModal ? <ChangeTimeModal/> : <TimeDisplayer/>}
        </Card>
    );
};

export default TimePicker;