import React, { useEffect, useState } from 'react';
import { StyleSheet, View, TextInput, Text, Image, ScrollView, Dimensions, Alert } from 'react-native';
import Fire from '../Fire';
import isObjEmpty from '../helpers/isObjEmpty';
// import Advertisement from '../components/Advertisement';
// import { TouchableHighlight, ScrollView } from 'react-native-gesture-handler';
import { useDispatch, useSelector } from 'react-redux';
// import setGlobalChatData from '../store/actions/setChatDataAction';
// import setClearLiveMessagesFlag from '../store/actions/clearLiveMessagesAction';
import MessengerBox from '../components/MessengerBox';
// import  setGlobalUserData  from '../store/actions/setUserDataAction';
import { COLORS, SCREEN_SIZE } from '../Constants';
import { RootState } from '../store';
import { Screen } from '../components/Screen';
import LoginPrompt from '../components/LoginPrompt';
import scaleFontSize from '../helpers/scaleFontSize';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { setChatData } from '../store/slices/chatData';
import catchErrorForSentry from '../helpers/catchSentryError';
import showToast from '../helpers/showToast';

const firebase  = Fire.getFirebase();

const Messenger = (props: any)=>{

    const dispatch = useDispatch();

    const userData = useSelector((state: RootState) => state.userData);

    const chatData = useSelector((state: RootState) => state.chatData);

    const isStaff = userData.accountType === 'staff';
    const isEntertainer = userData.accountType === 'entertainment';
    const isAccount = userData.accountType === 'account';

    const switchData = useSelector((state: RootState) => state.switchData);


    // const getAndSetBlockedByList = (recipientUids)=>{
    //     var promises = recipientUids.map(function(uid) {
    //         return (
    //             firebase.database().ref(`publicUserData/${uid}/blockList/${props.globalState.currentUserData.uid}/`).once('value')
    //         )
    //     });
    //     Promise.all(promises).then(function(snapshots) {

    //         let blockedByList = {}
    //         let counter = 0;
    //         snapshots.forEach(function(snapshot) {
    //             //@ts-ignore
    //             let isBlocked = snapshot.val()
    //             blockedByList[recipientUids[counter]] = isBlocked || false
    //             counter+=1;
    //         });

    //         if(isObjEmpty(blockedByList)) setBlockedByList({})
    //         else setBlockedByList(blockedByList);
    //     });
    // }

    const [messengerData, setMessengerData] = useState({});
    const isMessengerDataEmpty = isObjEmpty(messengerData);
    const [searchQuery, setSearchQuery] = useState("");
    const [adsObj, setAdsObj] = useState({});
    const [adsLoaded, setAdsLoaded] = useState(false);


    const [blockedByList, setBlockedByList] = useState({})

    useEffect(()=>{

        const onMessengerDataChange = (newChatRoomData: any)=>{
            // console.log("NewChatRoomData: ", newChatRoomData);
            // console.log("chatRooms/${userData.uid}: ", `chatRooms/${userData.uid}`);
            if(!newChatRoomData || !userData.uid) return setMessengerData({});
            setMessengerData(newChatRoomData);
            dispatch(setChatData(newChatRoomData))
            // getAndSetBlockedByList(Object.keys(newChatRoomData));
        }
        var messengerDataRef = Fire.listenToRoute(`chatRooms/${userData.uid}`, onMessengerDataChange)

        return () =>{
           Fire.off(messengerDataRef, "value", onMessengerDataChange);
        }

    }, [userData])

    useEffect(()=>{
        if(!chatData) return;
        setMessengerData(chatData);
    }, [chatData])



    //Going to have a "recipientSeen" property that the recipient will edit when they open the chat.
    const renderNotRespondedNotification = (messengerBoxData)=>{
        return;
        const { messages } = messengerBoxData

        if(!messages) return;

        let messagesArray = Object.values(messages)
        let lastMessageIndex = messagesArray.length-1;
        let lastMessage = messagesArray[`${lastMessageIndex}`];

        if(!lastMessage) return;
        if(!lastMessage.senderUID) return;
    
        //TODO is there a way to do read receipts without querying database so often?
        // if( lastMessage.senderUID !== currentUserData.uid ){
            let text = '';
            if(lastMessage.senderUID !== userData.uid ) text = "You haven't responded yet!"
            else text = "They haven't responded yet!"
            return(
                <Text style={styles.standardMessengerBoxFont}>
                    {text}
                </Text>
            )
        // }
        // else{
        //     let text = ''
        //     if(lastMessage.status === 'read') text = `${messengerBoxData.name} saw your message`
        //     else text = `${messengerBoxData.name} hasn't seen your message`
        //     return(
        //         <Text style={styles.standardMessengerBoxFont}>
        //             {text}
        //         </Text>
        //     )
        // }
    }

    // const isDatingChatRoom = ()=>{
    //     return chatRoomType === 'datingChatRooms'
    // }

    const handleLongPress =(messengerBoxData)=>{
        let alertTitleText = `Deleting your chat with ${messengerBoxData.name}`
        return(
            Alert.alert(
                // `Deleting your chat with ${messengerBoxData.name}`,
                alertTitleText,
                "Are you sure you would like to continue?",
                [
                {
                    text: 'Cancel',
                    onPress: ()=>{
                        console.log("MessengerBoxData: ", messengerBoxData);
                    return;
                    },
                    // style: 'cancel',
                },
                {
                    text: 'Continue',
                    onPress: async () =>{
                        // console.log("MessengerBoxData.uid: ", messengerBoxData.uid);
                        try{
                            const result  = await Fire.deleteDataAtRoute(`chatRooms/${userData.uid}/${messengerBoxData.uid}`);
                            // console.log("Deleting ", messengerBoxData.uid);
                            let newChatData = {
                                ...chatData
                            }
                            delete newChatData[`${messengerBoxData.uid}`];
                            dispatch(setChatData(newChatData));
                            showToast("Successfully deleted messages", true);
                        }
                        catch(error){
                            console.log("Error deleting messenger box");
                            showToast("Failed to delete messages - please try again", false)
                            catchErrorForSentry(error);
                        }
                        // console.log("ChatData: ", chatData);
                    }
                },
                ],
            )
        )
    }

    const renderNoMessagesContainer = ()=>{
        if(props.isDating){
            return(
                <View style={styles.notChattingMessageContainer}>
                    <Text style={styles.notChattingText}> No matches right now 🥺👉👈 </Text>
                    <Text style={{textAlign: 'center', marginTop: 0, fontSize: scaleFontSize(13)}}>
                        Go do some swiping to change that! 😉
                    </Text>
                </View>
            )
        }
        return(
            <View style={styles.notChattingMessageContainer}>
                {/* <Text style={styles.notChattingText}>Find friends on the map and click them to start chatting!</Text> */}
                <Text style={styles.notChattingText}>Chats you have created will appear on here!</Text>
            </View>
        )
    }

    const renderSearchBar = ()=>{
        return (
            <View style={styles.searchBarContainer}>
                <TextInput
                    style={{
                        height: 50,
                        width: Dimensions.get('window').width * .95,
                        backgroundColor: 'white',
                        // borderColor: 'black',
                        // borderWidth: .25,
                        borderRadius: 5,
                        textAlign: 'center',

                        shadowColor: 'black',
                        shadowOpacity: 0.26,
                        shadowOffset: { width: 0, height: 2 },
                        shadowRadius: 8,
                        elevation: 5,
                    }}
                    placeholder={"Search..."}
                    placeholderTextColor='gray'
                    onChangeText={(value)=>{
                        setSearchQuery(value);   
                    }}
                />
            </View>
        )
    }

    const renderMessageBoxes = ()=>{
        // console.log("User: ", userData.displayName);
        // console.log("isMessengerDataEmpty: ", isMessengerDataEmpty);
        if(!isMessengerDataEmpty){
            //@ts-ignore
            let messengerBoxArray = Object.values(messengerData);
            // console.log("MessengerBoxArray: ", messengerBoxArray);

            if(messengerBoxArray.length > 0){
                // console.log("Messager Box Has Data! Rendering Messenger Boxes!");
                return(
                    messengerBoxArray.filter((messengerBox)=>{
                        //If current user is not being blocked by recipient
                        //then allow messenger box of recipient to be rendered.

                        //@ts-ignore
                        return !blockedByList[messengerBox.uid];
                    }).filter((messengerBox)=>{
                        //@ts-ignore
                        if(messengerBox.uid === 'to-followers') return false;
                        //@ts-ignore
                        if(!messengerBox.name) return false;
                        //@ts-ignore
                        return messengerBox.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1;
                    }).map((messengerBoxData)=>{
                        //messengerBoxData should be an object with two properties, messages and name
                        //messages should be a JSON string, while name should be a simple string.
                        let unread = false;
                        //@ts-ignore
                        if(messengerBoxData.status && messengerBoxData.status === 'unread'){
                            //@ts-ignore
                            // console.log(`Status of ${messengerBoxData.name} is unread!`);
                            unread = true;
                        }

                        //Email
                        //Messages
                        //Name
                        //uid
                        //status
                        // console.log("MessengerBoxData: ", Object.keys(messengerBoxData));
                        return(
                            <MessengerBox
                                chatRoomType={'chatRooms'}
                                //@ts-ignore
                                key={messengerBoxData.uid}
                                renderNotRespondedNotification={renderNotRespondedNotification}
                                handleLongPress={handleLongPress}
                                messengerBoxData={messengerBoxData}
                                unread={unread}
                                navigation={props.navigation}
                                currentUserData={userData}
                            />
                        )
                    })
                )
            }
            else{
                return(
                    renderNoMessagesContainer()
                )
            }
        }
        else{
            return(
                renderNoMessagesContainer()
            )
        }
    }

    const getToFollowersMessageData = ()=>{
        if(!messengerData) return {};
        const toFollowersData: any = Object.values(messengerData).filter((chatdata: any)=>{
            return chatdata.uid === 'to-followers'
        })[0];
        return toFollowersData ? toFollowersData.messages : {};
    }

    if(!userData.uid) return <LoginPrompt navigation={props.navigation} prompt={"chat with other users"}/>
    const top = useSafeAreaInsets();
    return(
        <Screen style={{...styles.screen, paddingTop: SCREEN_SIZE.height*.035}}>
            {renderSearchBar()}
            <ScrollView
                //TODO: Not sure if this is going to work out
                //Once I have actual content in here...
                contentContainerStyle={{
                    width: SCREEN_SIZE.width * .95,
                    // flex: isMessengerDataEmpty ? 0 : 1,
                    // borderColor: 'purple',
                    // borderWidth: 1,
                    alignContent: isMessengerDataEmpty ? 'center' : 'flex-start',
                    justifyContent: isMessengerDataEmpty ? 'center' : 'flex-start'
                }}>
                {/* {!props.isDating && <Advertisement
                    setGlobalUserData={props.setGlobalUserData}
                    //@ts-ignore
                    adsLoaded={adsLoaded}
                    //@ts-ignore
                    userUID={proFps.globalState.currentUserData.uid}
                    style={{width: '100%'}}
                    //@ts-ignore
                    adsObj={adsObj}
                />} */}
                { (isStaff || isEntertainer || isAccount) && <MessengerBox
                    toFollowers={true}
                    chatRoomType={'chatRooms'}
                    handleLongPress={handleLongPress}
                    renderNotRespondedNotification={renderNotRespondedNotification}
                    messengerBoxData={{
                        email: 'toFollowers@gmail.com',
                        messages: getToFollowersMessageData(),
                        name: 'To Followers',
                        status: true,
                        uid: 'to-followers',
                        senderUID: userData.uid,
                    }}
                    unread={false}
                    navigation={props.navigation}
                    currentUserData={userData}
                />}
                {renderMessageBoxes()}
            </ScrollView>
        </Screen>
    )
}


const styles = StyleSheet.create({
    screen: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        // borderColor: 'red',
        // borderWidth: 1,
    },
    messengerBox: {
        height: SCREEN_SIZE.height * .1,
        marginTop: 10,
        justifyContent: 'center',
        alignContent: 'center',
    },
    messengerBoxTitle: {
        textAlign: 'center',
        fontSize: scaleFontSize(15)
    },
    notChattingMessageContainer:{
        flex: 1,
        alignContent: 'center',
        justifyContent: 'center',
        // borderColor: 'red',
        // borderWidth: 1,
    },
    notChattingText: {
        fontSize: scaleFontSize(15),
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20
    },
    searchBarContainer: {
        // display: 'none',
        margin: 20,
        alignSelf: 'center',
        // borderColor: 'green',
        // borderWidth: 1,
    },
    standardMessengerBoxFont: {
        textAlign: 'center',
        color: '#000',
        fontSize: scaleFontSize(13),
    }
});

  
export default Messenger;