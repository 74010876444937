import dayjs from 'dayjs';
import { Gig } from "../types";
import formatBusinessHours from './formatBusinessHours';

const isTimeWithinRange = (current: Date, start: Date, end: Date): boolean => {
  return current >= start && current <= end;
};

const getNextAvailableGig = (gigs: Gig[], type: Gig["type"]): Gig | null => {
  if (!gigs || gigs.length === 0) return null;

  const upcomingGigs = gigs
    .sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf());
  return upcomingGigs[0]
};

const getFoodTruckStatus = (gigs: Gig[], type: Gig["type"], staff: boolean = false): {status: boolean | null, text: string} => {
  const currentDate = new Date();
  const todayGigs = gigs.filter(gig =>{
    return dayjs(gig.date).isSame(dayjs(), 'day')
  });
  const gigsAfterToday = gigs.filter(gig =>{
    return dayjs(gig.date).isAfter(currentDate)
  });

  if (todayGigs.length === 0) {
    // console.log("NO gigs today");
    const nextGig = getNextAvailableGig(gigsAfterToday, type);
    if(!nextGig) return { status: null, text: `No ${staff ? 'working' : 'food stops'} scheduled` };
    // console.log("Next Gig Date: ", dayjs(nextGig.date).format('MM/DD/YYYY'));
    // console.log("Current Date: ", dayjs(currentDate).format('MM/DD/YYYY'));
    if(!dayjs(nextGig.date).isAfter(dayjs(currentDate.getTime()))) return { status: null, text: `No ${staff ? 'working' : 'food stops'} scheduled` };
    return { status: false, text: `${staff ? 'Off' : 'No food stops'} until ${dayjs(nextGig.date).format('MM/DD/YYYY')} at ${formatBusinessHours(nextGig.start)}` }
  }

  //Checking for ongoing gigs
  for (let gig of todayGigs) {
    const gigStartTime = new Date(gig.start);
    const gigEndTime = new Date(gig.end);
    if (isTimeWithinRange(currentDate, gigStartTime, gigEndTime)) {
      return { status: true, text: `Food stop ongoing until ${dayjs(gig.date).format('MM/DD/YYYY')} at ${formatBusinessHours(gig.end)}` };
    }
  }

  if(todayGigs){
    const gig = getNextAvailableGig(todayGigs, type);
    if(gig) return { status: false, text: `No food stop until later today at ${formatBusinessHours(gig.start)}` };
  }

  //TODO: Pretty sure this stuff down here will never happen, but idk.
  const nextGig = getNextAvailableGig(gigsAfterToday, type);
  return nextGig
    ? { status: false, text: `${staff ? 'Off' : 'No food stops'} until ${dayjs(nextGig.date).format('MM/DD/YYYY')} at ${formatBusinessHours(nextGig.start)}` }
    : { status: null, text: `No ${staff ? 'working' : 'food stops'} scheduled` };
};

export default getFoodTruckStatus;
