import { useEffect, useState } from "react";
import Fire from "../Fire";
import TouchableContainer from "./TouchableContainer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import calculateAge from "../helpers/calculateAge";
import day from 'dayjs';
import { ActivityIndicator, Platform, Text, View } from "react-native";
import { COLORS, SCREEN_SIZE } from "../Constants";
import { updateUserDataProperty } from "../store/slices/userData";
import confirmDecision from "../helpers/confirmDecision";
import SetDateModal from './SetDateModal';
import promptUser from "../helpers/promptUser";
import scaleFontSize from "../helpers/scaleFontSize";


const BirthdayEditor = (props: any)=>{
    const userData = useSelector((state: RootState)=>state.userData)
    const { showModal = false, noBirthday, onPress, screenName = "", navigation = null } = props;
    // console.log("Screen name: ", screenName);
    // console.log("Show Modal: ", showModal);
    useEffect(()=>{
        if(noBirthday){
            (async ()=>{
                await promptUser('Choose a birthday', 'You must choose a birthdate before exploring!')
                setShowDateModal(true);
            })()
        }
    }, [])
    const [birthday, setBirthday] = useState<any>(userData.birthday);
    const [processing, setProcessing] = useState(false);
    const [showDateModal, setShowDateModal] = useState(showModal);
    const dispatch = useDispatch();

    const generateAmountOfCheckBirthdayChancesText = ()=>{
        if(userData.birthdayUpdateCount === 1){
            return `This is the last chance you have to change your birthday.`
        }
        else{
            return `You will only have one more chance to change it.`
        }
    }

    const onBirthdayChange = async (birthdate: Date)=>{
        setProcessing(true);
        let timestamp = birthdate.getTime();
        let age = calculateAge(timestamp);
        console.log("Timestamp: ", timestamp);
        console.log("Age: ", age);
        if(age < 18){
            alert("You must be 18 years or older to use this app");
            setProcessing(false);
            return;
        }
        let localDateString = day(birthdate).format('MMMM D YYYY');
        let result = await confirmDecision(
            `Are you sure you'd like to choose ${localDateString} as your birthday?`,
            `${generateAmountOfCheckBirthdayChancesText()}`
        )

        if(!result){
            console.log("setting show date modal true");
            setShowDateModal(true);
            return setProcessing(false);
        }

        console.log("Setting birthday");

        setBirthday(timestamp);
        dispatch(updateUserDataProperty({property: 'birthday', value: timestamp}));
        Fire.updateUserDataRoute(`${userData.uid}`, {birthday: timestamp});
        Fire.updateUserDataRoute(`${userData.uid}`, {birthdayUpdateCount: userData.birthdayUpdateCount+1});
        setProcessing(false);
        props.navigation.navigate('Home');
    }
    if(processing) return(
        <View style={{
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center'
        }}>
            <ActivityIndicator style={{alignSelf: 'center'}} color={COLORS.primaryRed} size='large'/>
        </View>
    )
    return(
        <TouchableContainer
        onPress={()=>{
            if(userData.birthdayUpdateCount > 1) return alert("You can no longer change your birthday");
            // console.log("Screen name: ", screenName);
            if(screenName !== 'Birthday' && Platform.OS === 'ios'){
                onPress && onPress();
                setShowDateModal(true);
                navigation.push('Birthday', {screenName});
                return;
            }
            setShowDateModal(true);
        }}
        style={{
            alignSelf: 'center',
            backgroundColor: 'white',
            borderColor: 'black',
            borderRadius: 12.5,
            flexDirection: 'row',
            width: '100%',
            height: SCREEN_SIZE.height * .05,
            marginVertical: 10,
            justifyContent: 'center',
            alignItems: 'center',
            shadowColor: 'black',
            shadowOpacity: 0.26,
            shadowOffset: { width: 0, height: 2 },
            shadowRadius: 8,
            elevation: 5,
        }}>
            {showModal &&<SetDateModal
                showModal={showDateModal}
                setShow={setShowDateModal}
                onDateChange={(birthday: any)=>setBirthday(birthday)}
                onDateSelected={(newDate: Date)=>{
                    // console.log("Changing birthday to: ", newDate);
                    onBirthdayChange(newDate);
                }}
            />}
            <Text style={{fontSize: scaleFontSize(18)}}>
                <Text style={{fontWeight: '500'}}>Birthday: </Text>{birthday ? day(birthday).format('MMMM D YYYY') : 'No birthday chosen yet!'}
            </Text>
        </TouchableContainer>
    )
}

export default BirthdayEditor;