import { NavigationContainer  } from '@react-navigation/native';
import * as React from 'react';
import { useState, useEffect } from 'react';
import Fire from '../Fire';
import { useSelector, useDispatch } from 'react-redux';
import { clearUserData, setUserData } from '../store/slices/userData';
import { RootState } from '../store';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { COLORS } from '../Constants';
import WebNavigator from './components/WebNavigator';
import MobileNavigator from './components/MobileNavigator';
import { useSigningUp } from '../screens/SigningUpContext'; // Import the useSigningUp hook
import isWeb from '../helpers/isWeb';


const linking = {
  prefixes: ['http://localhost:19006/', 'https://alwayslocal.net/'],
  config: {
    screens: {
      Entry: 'entry',
      Home: 'home',
      WebBackEndPage: 'backend',
      PrivacyPage: 'privacy',
      SupportPage: 'support',
      DataControlPage: 'datacontrol',
      WebAuthPage: 'signup'
    }
  }
};

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const dispatch = useDispatch();
  const userData = useSelector((state: RootState) => state.userData);
  const [processing, setProcessing] = useState<boolean>(true);
  const { isSigningUp } = isWeb() ? {isSigningUp: false} : useSigningUp()


  const signOutUser = () => {
    Fire.getAuth().signOut().then(function() {
      // Sign-out successful.
      console.log("Sign Out successful");
      dispatch(clearUserData());
    }).catch(function(error: any) {
        console.log("Error: ", error);
        console.log("Error signing out user JSON: ", JSON.stringify(error)); 
        alert("Error signing you out!");
    });
  };

  useEffect(() => {
    // console.log("Looking for auth on state change");
    // console.log("isSigningUp: ", isSigningUp);
    const unsubscribe = Fire.getAuth().onAuthStateChanged(async (user) => {
      if(isSigningUp) return console.log("User is signing up!");
      if (user) {
        console.log("User is logged in");

        try {
          const data = await Fire.getUserData(user.uid);
          dispatch(setUserData(data));
          setIsLoggedIn(true);
        } catch (error) {
          console.log("Error getting user data on login: ", error);
          alert("Error logging you in \n Please press back to login.");
        } finally {
          setProcessing(false);
        }
      } else {
        console.log("User is not logged in");
        setProcessing(false);
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, [isSigningUp]);

  return (
    <NavigationContainer linking={linking}>
      {
        processing
        ?
        <View style={{flex: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center'}}>
          <ActivityIndicator color={COLORS.primaryRed} size="large" />
        </View>
        :
        Platform.OS === 'web' ? <WebNavigator isLoggedIn={isLoggedIn}/> : <MobileNavigator userData={userData} isLoggedIn={isLoggedIn} />
      }
    </NavigationContainer>
  );
}
