import store, { RootState } from './store/index';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import ApiKeys from './Constants/ApiKeys';
import {
    sendPasswordResetEmail,
    UserCredential,
    createUserWithEmailAndPassword,
    getAuth,
    initializeAuth,
    signInWithEmailAndPassword,
    EmailAuthProvider,
    reauthenticateWithCredential,
    User,
    EmailAuthCredential,
    setPersistence,
    browserSessionPersistence,
    updateEmail
} from "firebase/auth"
import { getApps, initializeApp } from "firebase/app"
import { getDatabase, ref, set, child, remove, off, get, onValue, DatabaseReference, DataSnapshot, query, limitToLast, push, update } from "firebase/database"
import { AuthCredential, getReactNativePersistence, sendEmailVerification } from "firebase/auth/react-native"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { UserData } from './Interfaces';
import capitalizeFirstLetter from './helpers/capitalizeFirstLetter';
import { ACCOUNT_TYPE } from './Constants';

class Fire {

    app: any;
    auth: any;
    userData: UserData;
    root: string;
    constructor() {
        this.userData = store.getState().userData;
        // this.root = `public${capitalizeFirstLetter(this.userData.accountType)}Data`;
        this.root = 'publicUserData';
        // this.root = (()=>{
        //     if (this.userData.accountType === 'account') return `publicAccountData`;
        //     if (this.userData.accountType === 'user') return `publicUserData`;
        //     if (this.userData.accountType === 'staff') return `publicUserData`;
        //     if (this.userData.accountType === 'entertainment') return `publicUserData`;
        //     else return ""
        // })()
        Platform.OS === 'web' ?
        this.initFirebaseWeb() :
        this.initFirebaseMobile()
    }

    initFirebaseMobile = ()=>{
        if(getApps().length < 1){
            const app = initializeApp(ApiKeys.FirebaseConfig);
            const auth = initializeAuth(app, {
                persistence: getReactNativePersistence(AsyncStorage)
            });
            this.app = app;
            this.auth = auth;
        }
    }

    initFirebaseWeb = ()=>{
        if (!firebase.apps.length) {
            const app = initializeApp(ApiKeys.FirebaseConfig);
            this.app = app;
            this.auth = getAuth(app);
        }
    }

    isRootValid=()=>{
        if(this.root === 'publicUserData') return true;
        if(this.root === 'publicAccountData') return true;
        if(this.root === 'publicEntertainmentData') return true;
        if(this.root === 'publicStaffData') return true;
        return false;
    }
    getRoot=()=>{
        return 'publicUserData';
        if(this.isRootValid()) return this.root;
        else 'publicUserData';
        // else throw new Error(`Route is invalid: ${this.root}`)
    }

    listenToRoute=(route: string, onChange: Function): DatabaseReference=>{
        const db = this.getFirebase();
        const newRef = ref(db, `${route}`);
        onValue(newRef, (snapshot)=>{
            const data = snapshot.val();
            onChange(data);
        })
        return newRef;
    }
    query=async(route: string)=>{
        const db = this.getFirebase();
        return await get(query(ref(db, `${route}`)))
    }
    deleteDataAtRoute=(route: string)=>{
        const db = this.getFirebase();
        const newRef = ref(db, route)
        return remove(newRef)
    }
    getUniqueKeyForRoute=(route: string)=>{
        const db = this.getFirebase();
        const newRef = ref(db, route)
        const key = push(newRef).key;
        return key;
    }
    updateRoute=(route: string, value: any): Promise<void>=>{
        const db = this.getFirebase();
        return update(ref(db, `${route}`), value)
    }
    //Set
    setRoute=(route: string, value: any): Promise<void>=>{
        const db = this.getFirebase();
        return set(ref(db, `${route}`), value)
    }
    setUserDataRoute=(route: string, value: any): Promise<void>=>{
        const db = this.getFirebase();
        return set(ref(db, `${this.getRoot()}/${route}`), value)
    }
    //TODO Change all these to currentUser?
    //START
    updateUserLocation=(route: string, value: any): Promise<void>=>{
        const db = this.getFirebase();
        return update(ref(db, `userLocation/${route}`), value)
    }
    updateUserDataRoute=(route: string, value: any): Promise<void>=>{
        const db = this.getFirebase();
        return update(ref(db, `${this.getRoot()}/${route}`), value)
    }
    updateChatDataRoute=(route: string, value: any): Promise<void>=>{
        const db = this.getFirebase();
        return update(ref(db, `chatRooms/${route}`), value)
    }
    unblockUser=(uid: string)=>{
        const db = this.getFirebase();
        const newRef = ref(db, `${this.getRoot()}/${this.userData.uid}/blockList/${uid}`);
        return remove(newRef)
    }
    //END TODO
    getDataAtRoute=async(route: string)=>{
        const db = this.getFirebase();
        return await get(ref(db, `${route}`))
    }
    getUserDataProperty=async(uid: string, property: any)=>{
        const db = this.getFirebase();
        return await (await get(ref(db, `${this.getRoot()}/${uid}/${property}`))).val()
    }
    getUserDataRoute=async(route: string)=>{
        const db = this.getFirebase();
        return await get(ref(db, `${this.getRoot()}/${route}`))
    }
    getAllUserData=async ()=>{
        const db = this.getFirebase();
        return await (await get(ref(db, `publicUserData/`))).val()
    }
    getAllAccountData=async ()=>{
        const db = this.getFirebase();
        return await (await get(ref(db, `publicAccountData/`))).val()
    }
    getAccountData=async (uid: string)=>{
        const db = this.getFirebase();
        return await (await get(ref(db, `publicAccountData/${uid}`))).val()
    }
    getUserData=async (uid: string)=>{
        const db = this.getFirebase();
        return await (await get(ref(db, `publicUserData/${uid}`))).val()
    }
    off=(ref: DatabaseReference, eventType: string, callBack: ((snapshot: DataSnapshot, previousChildName?: string | null | undefined) => unknown) )=>{
        return ()=>off(ref, "value", );
    }
    getFirebase=()=>{
        return getDatabase(this.app);
    }
    getAuth=()=>{
        return this.auth;
    }
    getCurrentUser=()=>{
        return this.getAuth().currentUser
    }
    getEmailAuthProvider=()=>{
        return EmailAuthProvider;
    }
    setPersistence=async ()=>{
        console.log("Setting persistence");
        return await setPersistence(this.getAuth(), browserSessionPersistence)
        // .then(() => {
        //     // Existing and future Auth states are now persisted in the current
        //     // session only. Closing the window would clear any existing state even
        //     // if a user forgets to sign out.
        //     // ...
        //     // New sign-in will be persisted with session persistence.
        //     return persistentFunction
        // })
        // .catch((error) => {
        //     // Handle Errors here.
        //     const errorCode = error.code;
        //     const errorMessage = error.message;
        // });
    }
    sendPasswordResetEmail(email: string): Promise<void>{
        return sendPasswordResetEmail(this.getAuth(), email)
    }
    updateEmail(newEmail: string): Promise<void>{
        const user: any = this.getAuth().currentUser
        return updateEmail(user, newEmail)
    }
    signInWithEmailAndPassword(email: string, password: string): Promise<UserCredential>{
        return signInWithEmailAndPassword(this.getAuth(), email, password)
    }
    createUserWithEmailAndPassword(email: string, password: string): Promise<UserCredential>{
        return createUserWithEmailAndPassword(this.getAuth(), email, password);
    }
    reauthenticateWithCredential(user: User, credential: AuthCredential | EmailAuthCredential): Promise<UserCredential>{
        return reauthenticateWithCredential(user, credential)
    }
    sendEmailVerification(user: User){
        return sendEmailVerification(user);
        // return sendEmailVerification(user, {
        //     handleCodeInApp: false,
        //     url: 'net.alwayslocal://entry',
        //     // url: 'https://alwayslocal.net',
        //     iOS: {
        //         bundleId: 'com.tyfoods.alwayslocal'
        //     },
        //       android: {
        //         packageName: 'com.tyfoods.alwayslocal',
        //         installApp: true,
        //         minimumVersion: '12'
        //     }
        // });
    }


}

export default new Fire();