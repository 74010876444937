import { ActivityIndicator, StyleSheet, View } from "react-native"
import ValidateInput from "../../components/ValidateInput"
import { COLORS } from "../../Constants";
import { ValidateInputInterface } from "../../Interfaces";
import Input from "../../components/Input";
import scaleFontSize from "../../helpers/scaleFontSize";


const SignUpSheet = (props: any)=>{

   const {containerStyle, inputs, state, setState} = props;
    
    const flagError = (flagName: string, flag: boolean)=>{
        setState((prevState: any)=>{
            prevState[`${flagName}`] = flag;
            return prevState;
        })
    }

    const hyphenateName = (name: string)=>{
        return name.trim().replace(/\s+/g, '-').toLowerCase();
    }

    const generateInputs = ()=>{
        if(!inputs.length) return <ActivityIndicator color={COLORS.primaryRed} size="large"/>
        return inputs.map((input: ValidateInputInterface)=>{
            if(input.noValidate){
                return(
                    <Input
                        {...input.props}
                        key={input.name}
                        label={input.name}
                        defaultValue={input.defaultValue}
                        onInputChange={input.onInputChange}
                        containerStyle={{...input.containerStyle}}
                        inputStyle={{...styles.inputStyle, ...input.inputStyle}}
                        labelStyle={{...styles.labelStyle, ...input.labelStyle}}
                        placeholder={input.placeholder}
                        showLabel={input.showLabel}

                        id={hyphenateName(input.name)}
                        isRequired={input.isRequired}
                    />
                )
            }
            return(
                <ValidateInput
                    {...input.props}
                    key={input.name}
                    label={input.name}
                    defaultValue={input.defaultValue}
                    onInputChange={input.onInputChange}
                    containerStyle={{...input.containerStyle}}
                    inputStyle={{...styles.inputStyle, ...input.inputStyle}}
                    labelStyle={{...styles.labelStyle, ...input.labelStyle}}
                    placeholder={input.placeholder}
                    showLabel={input.showLabel}

                    id={hyphenateName(input.name)}
                    isRequired={input.isRequired}
                    regex={input.regex}
                    errorMessage={input.errorMessage}
                    flagError={(flag: boolean)=>flagError(input.errorName, flag)}
                    errorName={input.errorName}
                />
            )
        })
    }
    return (
        <View style={{...containerStyle}}>
            {generateInputs()}
        </View>
    )
}

const styles = StyleSheet.create({
    labelStyle:{
        fontSize: scaleFontSize(20),
    },
    inputStyle: {
        height: 40,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#CED4DA',
        backgroundColor: '#F8FAFC',
    },
})

export default SignUpSheet