import { RefreshControl, Dimensions, StyleSheet, ScrollView, TextInput, View, Text, TouchableOpacity, ActivityIndicator, } from "react-native"
import CustomButton from "../components/CustomButton";
import { COLORS, DEBUG_EMAILS, EDGES, INITIAL_REGION, SCREEN_SIZE, filterTypes } from "../Constants";
import { useCallback, useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import Input from "../components/Input";
import { Screen } from "../components/Screen";
import Fire from "../Fire";
import { UserData } from "../Interfaces";
import calculateDistance from "../helpers/calculateDistance";
import AccountCard from "../components/AccountCard";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Card from "../components/Card";
import catchErrorForSentry from "../helpers/catchSentryError";
import scaleFontSize from '../helpers/scaleFontSize';




const ExploreAccounts = (props: any)=>{
    const userData = useSelector((state: RootState) => state.userData);
    const guestData = useSelector((state: RootState)=>state.guestData);
    const [refreshing, setRefreshing] = useState(false);
    const [currentFilter, setCurrentFilter] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [allAccountData, setAllAccountData] = useState<UserData[]>([]);
    const [entertainmentData, setEntertainmentData] = useState<UserData[]>([]);
    const [brandData, setBrandData] = useState<UserData[]>([]);
    const [processing, setProcessing] = useState(false);
    const [isFilterBrandOrEntertainment, setIsFilterBrandOrEntertainment] = useState(false);

    useEffect(()=>{
        const isItBrand = currentFilter === 'Brand'
        const isItEntertainment = currentFilter === 'Entertainment'
        console.log("Is brand or entertainment: ", isItBrand || isItEntertainment)
        setIsFilterBrandOrEntertainment(isItBrand || isItEntertainment)
    }, [currentFilter])

    const onRefresh = useCallback(() => {
        setRefreshing(true);
  
        function wait(timeout: number) {
          return new Promise(resolve => {
            setTimeout(resolve, timeout);
          });
        }
  
      //   setEventsArrayFromDatabase();
        // Fire.getAllEvents(setEvents);
    
        wait(2000).then(() => setRefreshing(false));
      }, [refreshing]);

    useEffect(()=>{
        setProcessing(true);
        (async ()=>{
            const data = await Fire.getAllUserData()
            if(data){
                const entertainmentData: UserData[] = [];
                const brandData: UserData[] = [];
                const accountData: any[] = Object.values(data).filter((user: any)=>{
                    const userEmail = user && user.email ? user.email.toLowerCase() : "";
                    const isNotDebugEmail = !DEBUG_EMAILS.includes(userEmail);
                    if(user.accountType === 'brand' && isNotDebugEmail){
                        console.log("user is brand: ", user.username);
                        brandData.push(user);
                    }
                    if(user.accountType === 'entertainment' && isNotDebugEmail){
                        console.log("user is entertainment: ", user.username);
                        entertainmentData.push(user);
                    }
                    if(user.accountType === 'account' && isNotDebugEmail){
                        return true;
                    }
                })


                setBrandData(brandData)
                setEntertainmentData(entertainmentData)
                setAllAccountData(accountData)
                setProcessing(false);
                return;
            }
            setProcessing(false);
        })()

    }, [])

    const renderFilters = ()=> {
        return [...filterTypes, "Entertainment", "Brand"].map((filter)=>{
            return(
                    <TouchableOpacity
                        key={filter}
                        style={{
                            height: SCREEN_SIZE.height * .05,
                            width: SCREEN_SIZE.height * .15,
                            margin: 5,
                            padding: 5,
                            marginTop: SCREEN_SIZE.height * .040,
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: (filter === currentFilter ? COLORS.primaryRed : COLORS.primaryBlue),
                            borderRadius: 10,
                            shadowColor: 'black',
                            shadowOpacity: 0.26,
                            shadowOffset: { width: 0, height: 2 },
                            shadowRadius: 8,
                            elevation: 5,
                        }}
                        onPress={()=>{
                            console.log(`selected filter: ${filter}`);
                            if(filter === currentFilter){
                                setCurrentFilter("");
                                return;
                            }
                            setCurrentFilter(filter);
    
                        }}>
                            <Text style={{fontSize: scaleFontSize(12), fontWeight: "500", color: '#fff'}}>{filter}</Text>
                    </TouchableOpacity> 
            )
        })
    }
    const getUserCoords = ()=>{
        const defaultCoords = {latitude: INITIAL_REGION.latitude, longitude: INITIAL_REGION.longitude}
        if(userData.uid){
            const lastUserCoords = userData.lastLocation ? userData.lastLocation : defaultCoords;
            const currentUserCoords = userData.coords ? userData.coords : lastUserCoords
            const {latitude, longitude} = currentUserCoords
            return {
                userLatitude: latitude,
                userLongitude: longitude,
            }
        }
        else if(guestData.coords){
            const {latitude, longitude} = guestData.coords
            return {
                userLatitude: latitude,
                userLongitude: longitude,
            }
        }
        else{
            const {latitude, longitude} = defaultCoords;
            return {
                userLatitude: latitude,
                userLongitude: longitude,
            }
        }
    }
    const renderEntertainmentOrBrands = ()=> {
        if(currentFilter.toLowerCase() === 'brand') return(
            <View style={{
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}>
                <Text style={{fontSize: scaleFontSize(18)}}>Coming Soon!!</Text>
            </View>
        )
        let accounts: JSX.Element[] = [...brandData, ...entertainmentData].filter((account: UserData)=>{
            return account.displayName?.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        }).filter((account: UserData)=>{
            if(currentFilter === ""){
                return account;
            }
            else{
                // console.log("Account type: ", account.accountType);
                return account.accountType === currentFilter.toLowerCase();
            }
        }).map((account: UserData)=>{
            return(
              <AccountCard
                    key={Math.random()}
                    account={account}
                    showDistance={false}
                    distanceFromUser={0}
                    push={props.navigation.push}
                />
            )
        });
        return accounts;  
    }
    const renderAccounts = ()=> {

        const {userLatitude, userLongitude} = getUserCoords();
        console.log("Rendering accounts");
        let accounts: JSX.Element[] = allAccountData.filter((account: UserData)=>{
            return account.displayName?.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
        }).filter((account: UserData)=>{
            console.log("Filtering accounts");
            if(currentFilter === ""){
                console.log("NO current filter");
                return account;
            }
            else{
                console.log("Establishment type: ", account.establishmentType);
                return account.establishmentType === currentFilter;
            }
        }).map((account: UserData)=>{
            let eventLatitude: number;
            let eventLongitude: number;
            let distanceFromUser;
            if(account.coords){
                const {latitude, longitude} = account.coords;
                eventLatitude = latitude;
                eventLongitude = longitude;
                distanceFromUser = Math.round(calculateDistance(userLatitude, userLongitude, eventLatitude, eventLongitude));
            }
            else distanceFromUser = 25000;
            return(
                {
                    component:
                        <AccountCard
                            key={Math.random()}
                            account={account}
                            distanceFromUser={distanceFromUser}
                            push={props.navigation.push}
                        />,
                    distanceFromUser,
                }
            )
        }).sort(function(a: any, b: any){
            return a.distanceFromUser-b.distanceFromUser;
        }).map((event: any)=>{
            return event.component;
        })
        // accounts.splice(1, 0,
        //     <Advertisement
        //         key={Math.random()}
        //         adsLoaded={adsLoaded}
        //         globalUserData={props.globalState.currentUserData}
        //         setGlobalUserData={props.setGlobalUserData}
        //         userUID={props.globalState.currentUserData.uid}
        //         adsObj={adsObj}
        //     />
        // )
        return accounts;  
    }
    return(
        <Screen style={styles.screen}>
                <Card style={{
                    borderRadius: 0,
                    height: SCREEN_SIZE.height * .20,
                    paddingVertical: 10,
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                    //borderColor: 'red',
                    //borderWidth: 1,
                }}>
                    <ScrollView alwaysBounceHorizontal={true} horizontal={true}
                        // style={{width: '100%'}}
                        contentContainerStyle={{
                            // flex: 1,
                            // width:'100%',
                            backgroundColor: '#fff',
                            alignItems: 'center',
                            flexDirection: 'row',
                            alignSelf: 'center',
                            // borderColor: 'red',
                            // borderWidth: 1
                        }}>
                        {renderFilters()}
                    </ScrollView>
                    <View style={styles.searchBarContainer}>
                        <TextInput
                            style={{
                                height: 50,
                                width: Dimensions.get('window').width * .9,
                                backgroundColor: 'white',
                                // borderColor: 'black',
                                // borderWidth: .25,
                                borderRadius: 5,
                                textAlign: 'center',
                                alignSelf: 'center',

                                shadowColor: 'black',
                                shadowOpacity: 0.26,
                                shadowOffset: { width: 0, height: 2 },
                                shadowRadius: 8,
                                elevation: 5,
                            }}
                            placeholder={"Search..."}
                            placeholderTextColor='gray'
                            onChangeText={(value)=>{
                                setSearchQuery(value);   
                            }}
                        />
                    </View>
                </Card>
                <ScrollView
                    contentContainerStyle={{
                        ...((currentFilter === 'Brand') ? {flex: 1} : {}),
                        // borderColor: 'blue',
                        // borderWidth: 1,
                        // alignContent: 'center',
                        // alignItems: 'center',
                        // justifyContent: 'center',
                    }}
                    key={currentFilter}
                    refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
                >
                    {
                        processing ?
                        <ActivityIndicator size="large" color={COLORS.primaryRed}/> :
                        (isFilterBrandOrEntertainment ? renderEntertainmentOrBrands() : renderAccounts())
                    }
                </ScrollView>
        </Screen>
    )
}

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        // borderColor: 'red',
        // borderWidth: 1,
    },
    eventContainer: {
        marginTop: 10,
        marginBottom: 10,
        alignSelf: 'center',
    },
    controlContainer: {
        // height: SCREEN_SIZE.height * .10,
        flex: 1/5,
        width: SCREEN_SIZE.width,
        borderBottomColor: COLORS.primaryBlue,
        borderBottomWidth: 1,
        flexDirection: 'column',
        // justifyContent: 'space-between',
        // marginTop: 5,

        backgroundColor: 'white',

        shadowColor: 'black',
        shadowOpacity: 0.26,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 8,
        elevation: 5,

        // borderColor: 'red',
        // borderWidth: 1,
    },
    searchBarContainer: {
        // flex: 1,
        // display: 'none',
        // padding: 20,
        // alignSelf: 'center',
        // height: SCREEN_SIZE.height*.05,

        // borderColor: 'yellow',
        // borderWidth: 1,
    },
    imageContainer: {
        padding: 10,
    },
    eventImageStyle: {
        width: Dimensions.get("window").width * .8,
        height: Dimensions.get("window").height * .25
    },

})

export default ExploreAccounts