import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import Card from '../components/Card';
// import WebAccountProfile from '../webpages/WebAccountProfile';
import WebAccountProfile from '../screens/AccountProfile';
import WebFooter from '../components/WebFooter';
import { SCREEN_SIZE } from '../Constants';

const WebBackEndPage = (props: any)=>{
    const {navigation} = props;
    return(
        <View style={styles.container}>
            <Card style={{
                ...styles.signUpForm,
                justifyContent: 'center',
                alignItems: 'center', 
                padding: 10,
            }}>
                <View style={{
                    flex: 1,
                    // borderColor: 'red',
                    // borderWidth: 3,
                    justifyContent: 'space-around'
                }}>
                    <View style={{}}>
                        <Text style={{fontSize: 25, textAlign: 'center', fontWeight: '500'}}>This is what your profile will look like.</Text>
                        <Text style={{fontSize: 13, textAlign: 'center'}}>Tap each item below to change it</Text>
                    </View>
                    <Card style={{width: '100%', height: '85%', alignSelf: 'flex-end'}}>
                        <WebAccountProfile navigation={navigation} />
                    </Card>
                </View>
            </Card>
            {/* <WebFooter
            // style={{height: SCREEN_SIZE.height * .035}}
            /> */}
        </View>
    )
}

const styles = StyleSheet.create({
    container:{
        // borderWidth: 1,
        // borderColor: 'blue',
        // borderColor: 'red',
        // borderWidth: 3,

        flex: 1,
        backgroundColor: '#F8FAFC',
        alignItems: 'center',
        justifyContent: 'center',
    },
    signUpForm: {
        // flex: 1,
        height: '90%',
        width:'90%',
    }

})

export default WebBackEndPage