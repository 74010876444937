import { Image, Text } from "react-native";
import sendToProfileScreen from "../helpers/sendToProfileScreen";
import TouchableContainer from "./TouchableContainer";
import { Entypo } from "@expo/vector-icons";
import { IMAGEKIT_URL, SCREEN_SIZE } from "../Constants";
import makeExcerpt from "../helpers/makeExcerpt";
import scaleFontSize from "../helpers/scaleFontSize";

const UserSlideShowImage = (props: any)=>{
    const { user, push } = props;
    const {uid, displayName, profilePictureKey} = user;
    return(
        <TouchableContainer
            key={uid}
            style={{
                flex: .97/2,
                shadowColor: 'black',
                shadowOpacity: 0.26,
                shadowOffset: { width: 0, height: 2 },
                shadowRadius: 8,
                elevation: 5,
                borderRadius: 10,
                backgroundColor: 'white',
                height: SCREEN_SIZE.height*.20,
                minWidth: SCREEN_SIZE.width*.33,
                marginHorizontal: 10,
            }}
            onPress={()=>sendToProfileScreen(uid, push)}
        >
            <Image 
                source={{uri: `${IMAGEKIT_URL}/${profilePictureKey}`}} 
                style={{
                    // flex: 1,
                    // borderWidth: 2,
                    // borderColor: 'blue',
                    zIndex: 0,
                    height: '100%',
                    width: '100%',
                }} 
                resizeMode="cover" 
            />
            <Text style={{
                    backgroundColor: 'black',
                    color: 'white',
                    bottom: 0,
                    fontSize: scaleFontSize(15), fontWeight: '500',
                    textAlign:'center',
                    position: 'absolute',
                    width: '100%',
                }}
            >
                {makeExcerpt(displayName, 15)}
            </Text>
            <Entypo 
                style={{
                    zIndex: 1, 
                    position: 'absolute', 
                    top: 10, 
                    right: 10, 
                }} 
                name="info-with-circle" 
                size={24} 
                color="black" 
            />
        </TouchableContainer>
    )
}

export default UserSlideShowImage;