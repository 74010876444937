import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatRoom } from '../../Interfaces';


export const initialState: {[key: string]: ChatRoom} = {}

const chatDataSlice = createSlice({
  name: 'chatData',
  initialState,
  reducers: {
    setChatData: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    clearChatData: () => {
      return initialState;
    },
    // updateUserDataProperty: (state: any, action: PayloadAction<{ property: string; value: any }>) => {
    //   const { property, value } = action.payload;
    //   state[property] = value;
    // },
    // updateUserScheduleHasHours: (state: any, action: PayloadAction<{ day: string, hasHours: boolean }>) => {
    //   const { day, hasHours } = action.payload;
    //   state.schedule[day].hasHours = hasHours;
    // },
    // updateOpeningHours: (state: any, action: PayloadAction<{ day: string, hours: number }>) => {
    //   const { day, hours } = action.payload;
    //   if(!state.schedule[day].hours.openingHours) state.schedule[day].hours.openingHours = "";
    //   state.schedule[day].hours.openingHours = hours;
    // },
    // updateClosingHours: (state: any, action: PayloadAction<{ day: string, hours: number }>) => {
    //   const { day, hours } = action.payload;
    //   if(!state.schedule[day].hours.closingHours) state.schedule[day].hours.closingHours = "";
    //   state.schedule[day].hours.closingHours = hours;
    // },
  },
});

export const { setChatData, clearChatData, } = chatDataSlice.actions;

export default chatDataSlice.reducer;