import { View } from "react-native";
import CustomButton from "./CustomButton";
import { COLORS, errorMessages, regexPatterns } from "../Constants";
import ValidateInput from "./ValidateInput";
import cleanPhoneNumber from "../helpers/cleanPhoneNumber";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import Fire from "../Fire";
import scaleFontSize from "../helpers/scaleFontSize";
import { useEffect, useState } from "react";
import showToast from "../helpers/showToast";
import removeUSCountryCode from "../helpers/removeUSCountryCode";

const PhoneNumberEditor = (props: any) => {
    const userData = useSelector((state: RootState) => state.userData);
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {

        const onPhoneNumberChange = (phoneNumber: any) => {
            if(!phoneNumber) return;
            setPhoneNumber(removeUSCountryCode(phoneNumber));
        }

        let phoneNumberRef = Fire.listenToRoute(`phoneNumbers/${userData.uid}`, onPhoneNumberChange);
        return () => {
            Fire.off(phoneNumberRef, "value", onPhoneNumberChange);
        }
    }, []);

    const onChangePhoneNumber = async () => {
        if (!cleanPhoneNumber(phoneNumber)) {
            showToast("Phone number is not a valid US phone number", false); // Use showToast instead of alert
            return;
        }
        
        try {
            await Fire.updateRoute(`phoneNumbers`, { [`${userData.uid}`]: cleanPhoneNumber(phoneNumber) });
            showToast("Phone number updated successfully!", true);
        } catch (error) {
            showToast("Failed to update phone number!", false);
        }
    }

    return (
        <View style={{
            width: '100%',
        }}>
            <ValidateInput
                inputStyle={{
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    borderRadius: 10,
                }}
                style={{ marginBottom: 10 }}
                name={'Account Phone Number'}
                errorMessage={errorMessages.phoneNumberError}
                errorName={'phoneNumberErrorFlag'}
                onInputChange={setPhoneNumber}
                regex={regexPatterns.phoneNumber}
                defaultValue={phoneNumber}
                label={"Account Phone Number"}
            />
            <CustomButton
                onPress={onChangePhoneNumber}
                title={"Submit"}
                textStyle={{
                    color: 'white',
                    fontWeight: '500',
                    fontSize: scaleFontSize(12.5),
                }}
                style={{
                    backgroundColor: COLORS.primaryBlue,
                    alignSelf: 'flex-end',
                    padding: 10,
                    width: '25%',
                    minWidth: 100,
                    borderRadius: 10,
                }}
            />
        </View>
    )
}


export default PhoneNumberEditor;
