import { UserData } from "../Interfaces";

const generateInitUserData = (state: any, user: any, accountType: any)=>{
    const isAccount = accountType === 'account'
    const {username, email, phoneNumber} = state;
    let userData = {
        isAppAdmin: false,
        accountType: accountType,
        isWebsiteVisible: isAccount ? true : false,
        isEmailVisible: isAccount ? true : false,
        isPhoneNumberVisible: isAccount ? true : false,
        address: "",
        admins: false,
        bio: "",
        birthday: 0,
        birthdayUpdateCount: 0,
        coverPhotoKey: false,
        coords: {},
        dailySpecials: [],
        dealOne: {},
        dealTwo: {},
        displayName: username,
        email: email.trim(),
        emailVerified: true,
        entertainmentPictureKeys: {},
        entertainmentHidden: false,
        employees: false,
        employers: false,
        facebookUrl: "",
        inTheBiz: [],
        instagramUrl: "",
        monthlySpecials: [],
        monthlySpecialsImageKey: "",
        phoneNumber: phoneNumber,
        pushNotificationsOn: true,
        pictureKeys: {},
        profilePictureKey: false,
        schedule: {
            Sunday: {
                hours: {
                    openingHours: "",
                    closingHours: "",
                },
                hasHours: false,
            },
            Monday: {
                hours: {
                    openingHours: "",
                    closingHours: "",
                },
                hasHours: false,
            },
            Tuesday: {
                hours: {
                    openingHours: "",
                    closingHours: "",
                },
                hasHours: false,
            },
            Wednesday: {
                hours: {
                    openingHours: "",
                    closingHours: "",
                },
                hasHours: false,
            },
            Thursday: {
                hours: {
                    openingHours: "",
                    closingHours: "",
                },
                hasHours: false,
            },
            Friday: {
                hours: {
                    openingHours: "",
                    closingHours: "",
                },
                hasHours: false,
            },
            Saturday: {
                hours: {
                    openingHours: "",
                    closingHours: "",
                },
                hasHours: false,
            },
        },
        tiktokUrl: "",
        uid: user.uid,
        uidsFollowingUser: false,
        uidsUserAdmins: false,
        uidsUserFollows: false,
        username: username,
        weeklySpecials: [],
        zipcode: 0
    };
    
    return userData;
}

export default generateInitUserData;