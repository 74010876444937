import React, { useState, useReducer, useEffect } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Button,
  ImageBackground,
  ActivityIndicator,
} from 'react-native';
import { Callout } from 'react-native-maps';
import Fire from '../Fire';
import sendToProfileScreen from '../helpers/sendToProfileScreen';
import { scale } from 'react-native-size-matters/extend';
import { COLORS, IMAGEKIT_URL, S3_URL, SCREEN_SIZE } from '../Constants';
import { WebView } from 'react-native-webview';
import arePropertiesTruthy from '../helpers/arePropertiesTruthy';
import scaleFontSize from '../helpers/scaleFontSize';
import CustomButton from './CustomButton';
import TouchableContainer from './TouchableContainer';
import CalloutUserImage from './CalloutUserImage';
import { FontAwesome5 } from '@expo/vector-icons';



const MapMarkerPopUp = (props: any) => {

  const { userData, navigation } = props
  const { accountType, displayName, coverPhotoKey, description, profilePictureKey, pictureKeys, dealOne, dealTwo } = userData;
  const isAccount = accountType === 'account';
  const handleCalloutPress = ()=>{
    sendToProfileScreen(userData.uid, navigation.push);
  }
  const isDealOneTruthy = arePropertiesTruthy(dealOne);
  const isDealTwoTruthy = arePropertiesTruthy(dealTwo);
  const hasDeals = isDealOneTruthy || isDealTwoTruthy;
  const hasTwoDeals = isDealOneTruthy && isDealTwoTruthy;

  // console.log(`Rendering callout for ${displayName} as ${S3_URL}/${coverPhotoKey}`);
  const pictureKey = coverPhotoKey ? coverPhotoKey : (pictureKeys ? Object.values(pictureKeys)[0] : false);
  // console.log("Picture Key: ", pictureKey);
  if(isAccount){
    return (
      <Callout
          tooltip={true}
          onPress={handleCalloutPress}
          style={{
            ...styles.callout,
            backgroundColor: COLORS.primaryBlue,
            width: SCREEN_SIZE.width * .85,
            padding: 5,
            // borderRadius: 20,
          }}
      >
        <View style={{
          flex: 1, flexDirection: 'row',
          // borderColor: 'green', borderWidth: 1,
          alignContent: 'flex-start',
        }}>
          {/* <CalloutUserImage
            webViewStyle={{
              width: '100%',
              height: '100%',
              // height: '500px',
              // width: '500px',
              backgroundColor: COLORS.primaryBlue,
              // border: 'solid 10px green;'
            }}
            style={{
              // flex: 1,
              // width: SCREEN_SIZE.width *.3875,
              height: 10,
              width: 10,
              padding: 0,
              margin: 0,
              backgroundColor: COLORS.primaryBlue,
              // borderColor: 'red', borderWidth: 1,
              alignSelf: 'center'
            }}
            isVendor={isAccount}
            displayName={displayName}
            pictureKey={pictureKey}
          /> */}
          <View style={{flex: 1/3, alignContent: 'center', alignItems: 'center', justifyContent: 'center',}}>
            <Image
              resizeMode='cover'
              
              style={{
                flex: 1,
                objectFit: 'fill',
                // padding: 10,
                height: '50%',
                width: '100%',
                maxHeight: 125,
                  // position: 'absolute',
                  // bottom: 0,
                  // width: 200,
                  // height: 75,
                  alignSelf: 'center',
                  ...props.style
              }}
              source={{uri: `${IMAGEKIT_URL}/${profilePictureKey}`}}
          />
          </View>
          <View style={{
            flex: 2/3,
            // width: 195,
            paddingLeft: 5,
            // borderColor: 'orange', borderWidth: 1
            justifyContent: description ? 'flex-start' : 'center',
          }}>
            <Text style={{...styles.text, textAlign: 'left', fontWeight: '500', fontSize: scaleFontSize(25)}}>
              {`${displayName}`}
            </Text>
            {description && <Text style={{...styles.text, fontSize: scaleFontSize(13), textAlign: 'left'}}>
              {description}
            </Text>}
            {(hasDeals && isAccount) &&
              <Text
                style={{
                  color: '#fff',
                  marginVertical: 5,
                  textAlign: 'left',
                  fontSize: scaleFontSize(12),
                  fontWeight: '800',

                }}>
                  {hasTwoDeals ? 'Two deals available!' : 'One deal available!'}
              </Text>
            }
            {isAccount && <View style={styles.buttonContainer}>
              {/* <Button color={COLORS.primaryBlue} title={ "Visit Establishment" }/> */}
              <TouchableContainer
                style={{
                  flexDirection:'row',
                  alignItems: 'center',
                  alignContent: 'center',
                  alignSelf: 'flex-start',
                  backgroundColor: '#fff',
                  padding: 7.5,
                  borderRadius: 10,
                }}
               
              >
                <Text
                  style={{
                    color: COLORS.primaryBlue,
                    fontWeight: '800',
                    position: 'relative',
                    top: 5,
                    marginRight: 2.5,
                    // height: '100%',
                    // textAlignVertical: 'bottom',
                    // borderColor: 'red',
                    // borderWidth:1,
                    // textAlign:'center',
                  }}
                >
                  Visit
                </Text>
                <FontAwesome5 name="door-open" size={24} color={COLORS.primaryBlue} />
              </TouchableContainer>
            </View>}
          </View>
  
        </View>
        {/* <Image source={{uri: `${IMAGEKIT_URL}/${coverPhotoKey}`}} style={{height: 50, width: 50, borderColor: 'red', borderWidth: 1,}} /> */}
        {!isAccount && <View style={styles.buttonContainer}>
          <Button color={COLORS.primaryBlue} title={ "Visit Profile" }/>
        </View>}
      </Callout>
    );
  }
  else{
    return (
      <Callout
          onPress={handleCalloutPress}
          style={styles.callout}
      >
        <CalloutUserImage
          style={{ width: 100, height: 100,
            // borderColor: 'red', borderWidth: 1,
            alignSelf: 'center',}}
          isVendor={isAccount}
          displayName={displayName}
          pictureKey={pictureKey}
        />
        {/* <Image source={{uri: `${IMAGEKIT_URL}/${coverPhotoKey}`}} style={{height: 50, width: 50, borderColor: 'red', borderWidth: 1,}} /> */}
        <Text style={styles.text}>
          {`${displayName}`}
        </Text>
        <View style={styles.buttonContainer}>
          <Button color={COLORS.primaryBlue} title={isAccount ? "Visit Establishment" : "Visit Profile"}/>
          {(hasDeals && isAccount) && <Text style={{color: '#fff', textAlign: 'center', fontSize: scaleFontSize(12), fontWeight: '500'}}>
              {hasTwoDeals ? 'Two deals available!' : 'One deal available!'}
          </Text>}
        </View>
      </Callout>
    );
  }
};

const styles = StyleSheet.create({
  callout: {
    width: 150,
    // height: 150,
    // padding: scale(10),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'white',
  },
  text:{
    textAlign: 'center',
    color: '#fff',
  },
  buttonContainer: {
    paddingLeft: 5,
    alignSelf: 'flex-start',
  },
  image: {
    width: 50,
    height: 50,
    // margin: 0,
    // padding: scale(0),
    // display: 'none',
    // backgroundColor: 'white',
  },
  imageWrapper: {
/*     marginTop: -23,
    paddingBottom: 23, */
    // flex:1,
  },
});

MapMarkerPopUp.defaultProps = {
  localImage: null,
};

// const mapStateToProps = (state) =>{
//   return {
//     globalState: state,
//   };
// }

export default MapMarkerPopUp
// export default connect(mapStateToProps, { setGlobalUserData })(CustomCallout);
// export default React.memo(connect(mapStateToProps, { setGlobalUserData })(CustomCallout));
