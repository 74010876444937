import React, { useState, useCallback } from 'react';
import { View, Platform } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import CustomButton from './CustomButton';
import { COLORS, SCREEN_SIZE } from '../Constants';
import isMobile from '../helpers/isMobile';
import { Screen } from './Screen';
import { DatePickerModal, enGB, registerTranslation } from 'react-native-paper-dates';
import isWeb from '../helpers/isWeb';
registerTranslation("en", enGB)


const SetDateModal = (props: any)=>{
    const { onDateSelected, onDateChange, setShow, showModal, containerStyle} = props;
    const [date, setDate] = useState(new Date());
    const [open, setOpen] = useState(showModal);

    const onDismissSingle = useCallback(() => {
      setOpen(false);
      setShow(false);
    }, [setOpen, setShow]);
  

    const onConfirmSingle = useCallback(({ date }: any) => {
      setOpen(false);
      setDate(date);
      onDateSelected(date);
      setShow(false);
    }, [setOpen, setDate, onDateSelected, setShow]);
    // const onConfirmSingle = ({ date }: any) => {
    //   setOpen(false);
    //   setDate(date);
    //   console.log("Setting date to: ", date);
    //   onDateSelected(date);
    //   setShow(false);
    // }

    if(isWeb())  return (
      <Screen>
        <View style={{
          flex: 1,
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: SCREEN_SIZE.height * .25,
          height: SCREEN_SIZE.height * .35,
          width: SCREEN_SIZE.width * .75,
          backgroundColor: COLORS.primaryGray,
          ...containerStyle
        }}>
          <DatePickerModal
            locale="en"
            mode="single"
            visible={open}
            onDismiss={onDismissSingle}
            date={date}
            onConfirm={onConfirmSingle}
          />
          <CustomButton
            title={"Done"}
            style={{ width: '50%', marginLeft: 10, marginTop: 10, padding: 10, alignSelf: 'center' }}
            textStyle={{ color: 'white' }}
            onPress={() => {
              onDateSelected(date);
              setShow(false);
            }}
          />
        </View>
      </Screen>
    );
    return(
        <View style={{
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: SCREEN_SIZE.height *.25,
            height: SCREEN_SIZE.height * .35,
            width: SCREEN_SIZE.width * .75,
            backgroundColor: COLORS.primaryGray,
            ...containerStyle
        }}>
                {showModal && 
                    <DateTimePicker
                        testID="dateTimePicker"
                        // timeZoneOffsetInMinutes={0}
                        value={date}
                        // maximumDate={startDate}
                        mode={"date"}
                        is24Hour={false}
                        display="default"
                        onChange={(event, date)=>{
                            console.log("Date changed");
                            console.log("Event type: ", event.type);
                            if(Platform.OS === 'ios') return date && setDate(date);
                            if(event.type === 'dismissed') return setShow(false);
                            if(event.type === 'set'){
                                console.log("Set Show to false");
                                setShow(false);
                                onDateSelected(date);
                            }
                        }}
                    />
                }
                {(Platform.OS === 'ios' &&
                    <CustomButton
                        title={"Done"}
                        style={{width: '50%', marginLeft: 10, marginTop: 10, padding: 10, alignSelf: 'center',}}
                        textStyle={{color: 'white'}}
                        onPress={()=>{
                            onDateSelected(date);
                        }}
                    
                    />
                )}
                {(Platform.OS === 'android' &&
                    <CustomButton
                        title={"Done"}
                        style={{width: '50%', marginLeft: 10, marginTop: 10, padding: 10, alignSelf: 'center',}}
                        textStyle={{color: 'white'}}
                        onPress={()=> setShow(true)}
                    />
                )}
        </View>
        
    )
}

export default SetDateModal