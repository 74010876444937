import { Text, View } from "react-native"
import scaleFontSize from "../helpers/scaleFontSize"
import formatBusinessHours from "../helpers/formatBusinessHours"
import day from 'dayjs';
import { useEffect, useState } from "react";
import { UserData } from "../Interfaces";
import Fire from "../Fire";
import TouchableContainer from "./TouchableContainer";
import sendToProfileScreen from "../helpers/sendToProfileScreen";
import confirmDecision from "../helpers/confirmDecision";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { COLORS, IMAGEKIT_URL } from "../Constants";
import addNotificationToUser from "../helpers/addNotificationToUser";
import catchErrorForSentry from "../helpers/catchSentryError";
import OptionsModal from "./OptionsModal";
import Card from "./Card";
import showToast from "../helpers/showToast";
import openMapsApp from "../helpers/openMapsApp";

const formatDate = (date: Date)=>{
    return day(date).format('MM/DD');
}

function containsCompanyOrEntertainer(inputString: string): boolean {
    if(!inputString) return false;
    const searchString1 = 'Company';
    const searchString2 = 'Entertainer';
    return inputString.includes(searchString1) || inputString.includes(searchString2);
  }

const GigCard = (props: any)=>{
    const { onAccount , push, gigData, onEditGig} = props;
    const {date, start, end, employer, employee, type, location} = gigData;
    const [employeeData, setEmployeeData] = useState<UserData>();
    const [employerData, setEmployerData] = useState<UserData>();
    const userData = useSelector((state: RootState)=>state.userData);
    const isOwner = employer === userData.uid;
    const ownerIsFoodTruck = userData.establishmentType === 'Food Truck' && isOwner;

    const showPlaceholder = containsCompanyOrEntertainer(employee);
    const placeholderData = onAccount ? employee : employer;
    const locationName = location && location.name ? location.name : "";
    const locationToDisplay = location ? location.address : "";
    const nameToDisplay = (showPlaceholder ? placeholderData : 'Null');
    // console.log("location: ", location);
    const [gigEmployeeName, setGigEmployeeName] = useState<string>("...");
    const [gigEmployer, setGigEmployer] = useState<string>("...");
    const isInvolvedInGig = userData.uid === employee || userData.uid === employer;
    const [showOptionsModal, setShowOptionsModal] = useState(false);

    const isFoodGigWithLocation = type === 'food' && location//all food gigs have locations
    const isFoodGigWithoutLocation = type === 'food' && !location//all food gigs have locations
    const isFoodGig = (isFoodGigWithLocation || isFoodGigWithoutLocation);
    const isGigWithLocation = type === 'entertainment' && location;
    const isGigWithoutLocation = type === 'entertainment' && !location;
    const isEntertainmentGig = isGigWithLocation || isGigWithoutLocation;

    // const [edittingGig, setEdittingGig] = useState(false);
    // date: selectedDate.getTime(),
    // start: selectedStartTime,
    // end: selectedEndTime,
    // employer: selectedEmployer.uid,
    // entertainer: userData.uid
    // console.log("Location: ", location);
    useEffect(()=>{
        (async (gigData)=>{
            // const dataToGet = location ? gigData.employer : (onAccount ? gigData.employee : gigData.employer);

            // const employeeData = await (await Fire.getDataAtRoute(`publicUserData/${dataToGet}`)).val();
            const employeeData = await (await Fire.getDataAtRoute(`publicUserData/${gigData.employee}`)).val();
            const employerData = await (await Fire.getDataAtRoute(`publicUserData/${gigData.employer}`)).val();
            // console.log("Data display name: ", data.displayName);
            // console.log("Data username: ", data.username);
            setGigEmployeeName(employeeData ? (employeeData.displayName || employeeData.username) : "")
            setGigEmployer(employerData ? (employerData.displayName || employerData.username) : "")
            setEmployerData(employerData);
            setEmployeeData(employeeData);
        })(gigData)
    }, [])

    const onLocationPress = ()=>{
        if(location) return openMapsApp(location.latitude, location.longitude, location.address);
    }
    const onEmployerNamePress = ()=>{
        return sendToProfileScreen(employer, push);
    }
    const onEmployeeNamePress = ()=>{
        return sendToProfileScreen(employee, push);
    }

    const onEditGigInternal = ()=>{
        // setEdittingGig(true);
        setShowOptionsModal(false);
        onEditGig && onEditGig(gigData);
    }
    const updateEmploymentPartner = ()=>{
        if(!employerData) return;
        if(!employeeData) return;
        const gigPartnerUid = (employerData.uid === userData.uid) ? employeeData.uid : employerData.uid;
        const scheduleType = location ? 'food' : 'entertainment';
        //Adding employee/employer to the user who sent request
        Fire.updateRoute(`publicUserData/${gigPartnerUid}/${scheduleType}Schedule/`, {[`${gigData.id}`]: null})
        .then(()=>{
            const request = addNotificationToUser(gigPartnerUid, {
                id: `gig-${gigData.id}-canceled`,
                image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
                content: `${userData.displayName} canceled their gig with you.`,
                timeCreated: new Date().getTime(),
                read: false,
                //When notification is pressed it won't direct user to post screen.
                type: {request: userData.uid, type: `gig-cancellation`},
            });
            request.then((success)=>{
                setShowOptionsModal(false);
                showToast("Deleted gig successfully", true);
                // alert("Request has been sent");
            })
            .catch((error)=>{
                console.log(`Failed to send notify ${gigEmployer} of gig cancellation - Please try again`);
                catchErrorForSentry(error);
                showToast("Failed to delete gig gig successfully", false);
            })
            
        })
        .catch((error: any)=>{
            console.log(`Failed to remove gig of ${userData.uid} from user ${gigPartnerUid}`);
            showToast("Failed to delete gig gig successfully", false);
            catchErrorForSentry(error);
        })
    }
    const onDeleteGig = async ()=>{
        if(!employerData) return;
        const nameToDisplay = (employerData.uid === userData.uid) ? gigEmployeeName: gigEmployer;
        const titleBusiness = `Would you like to cancel your gig ${location ? "at: " : "with"} ${gigEmployer}?`;
        const textBusiness = !location ? `When you cancel the gig ${nameToDisplay} will be notified.` : "";
        const titleFoodTruck = `Are you sure you'd like to cancel your gig at ${locationToDisplay}?`;
        const textFoodTruck = `Once deleted, this gig cannot be recovered`;
        const title = ownerIsFoodTruck ? titleFoodTruck : titleBusiness;
        const text = ownerIsFoodTruck ? textFoodTruck : textBusiness;

        const result = await confirmDecision(title, text)
        if(!result){
            console.log("User disconfirmed decision");
            return;
        };
        if(!employeeData && !location){
            console.log("Data not available: ", employeeData);
            console.log("location not available: ", location);
            return;
        };

        const scheduleType = gigData.type
        console.log("gig ID: ", gigData.id);
        console.log("Schedule Type: ", scheduleType)
        Fire.deleteDataAtRoute(`publicUserData/${userData.uid}/${scheduleType}Schedule/${gigData.id}`)
        .then(()=>{

            if(location){
                setShowOptionsModal(false);
                showToast("Deleted gig successfully", true);
                return;
            }
            updateEmploymentPartner();
            
        })
        .catch((error: any)=>{
            showToast("Failed to delete gig gig successfully", false);
            catchErrorForSentry(error);
        })
        // Fire.updateRoute(`publicUserData/${userData.uid}/${scheduleType}Schedule/`, {[`${gigData.id}`]: null})
    }
    const LocationLabel = ()=>{
        // if( !locationToDisplay && isGigWithoutLocation )return(
        //     <Text
        //         onPress={onLocationPress}
        //         style={{
        //             textAlignVertical: 'center',
        //             fontSize: scaleFontSize(14),
        //     }}>
        //         <Text style={{fontWeight: '700'}}>Location: </Text>
        //         {gigEmployer}
        //     </Text>
        // )
        return (
            <Text
                onPress={onLocationPress}
                style={{
                    textAlignVertical: 'center',
                    fontSize: scaleFontSize(14),
                    color: COLORS.primaryBlue,
            }}>
                <Text style={{fontWeight: '700', color: '#000'}}>Location: </Text>
                {locationName || locationToDisplay}
            </Text>
        )
        
    }
    const EmployeeName = ()=>{
        // if(!onAccount) return;
        return(
            <Text
            onPress={onEmployeeNamePress}
            style={{
                // color: '#fff',
                // borderBottomColor: 'black',
                // borderBottomWidth: 0,
                textAlignVertical: 'center',
                fontSize: scaleFontSize(14),
                // text
            }}>

                <Text style={{fontWeight: '700'}}>
                    {isFoodGig ? 'Food Vendor' : 'Entertainment'}:
                </Text>
                {location ? gigEmployer : gigEmployeeName}
            </Text>
        )
    }

    const EmployerName = ()=>{
        const showLocationLabel = (isFoodGigWithoutLocation || isGigWithoutLocation);
        return(
            <Text
            onPress={onEmployerNamePress}
            style={{
                textAlignVertical: 'center',
                fontSize: scaleFontSize(14),
                color: showLocationLabel ? COLORS.primaryBlue : '#000',
            }}>

                {showLocationLabel && 
                    <>
                        <Text style={{fontWeight: '700', color: '#000'}}>Location: </Text>
                        {gigEmployer}
                    </>
                }
                {(isFoodGigWithLocation) && <><Text style={{fontWeight: '700'}}>Food Vendor: </Text>{gigEmployer}</>}
                {(isGigWithLocation) && <><Text style={{fontWeight: '700'}}>Entertainment: </Text>{gigEmployer}</>}
            </Text>
        )
    }
    return(
        <TouchableContainer
        disabled={!isInvolvedInGig}
        onLongPress={()=>setShowOptionsModal(true)}
        // onLongPress={onDeleteGig}
        style={{
            width: '100%',
            flexDirection: 'row',
            alignContent: 'flex-start',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 2.5,
            marginVertical: 2.5,
            // borderColor: 'red',
            // borderWidth: 1,
        }}>
            <OptionsModal
                showOptionsModal={showOptionsModal}
                setShowOptionsModal={setShowOptionsModal}
                containerStyle={{
                    alignContent: 'center',
                    alignItems: 'center',
                    // justifyContent: 'center',
                    alignSelf: 'center', flexDirection: 'row',
                    justifyContent: 'space-around',
                }}
            >
                {/* <Card style={{
                    height: '50%',
                    width: '95%',
                    alignSelf: 'center', flexDirection: 'row',
                    justifyContent: 'space-around',
                }}> */}
                    {onEditGig && <TouchableContainer
                        onPress={onEditGigInternal}
                        style={{
                            // flex: .80,
                            height: '40%',
                            width: '40%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            borderColor: COLORS.primaryBlue,
                            borderWidth: 1,
                            borderRadius: 10,
                        }}
                    >
                        <Text style={{textAlign: 'center', fontSize: scaleFontSize(18)}}>
                            Edit Gig
                        </Text>
                    </TouchableContainer>}
                    <TouchableContainer
                        onPress={()=>onDeleteGig()}
                        style={{
                            // flex: .80,
                            height: '40%',
                            width: '40%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            borderColor: COLORS.primaryBlue,
                            borderWidth: 1,
                            borderRadius: 10,
                        }}
                    >
                        <Text style={{textAlign: 'center', fontSize: scaleFontSize(18)}}>
                            Delete Gig
                        </Text>
                    </TouchableContainer>
                {/* </Card> */}
            </OptionsModal>
            <Card style={{
                    shadowOpacity: 0.15,
                    width: '98%', 
                    flexDirection: 'column',
                    padding: 10
                }}>

                <Text style={{fontSize: scaleFontSize(14)}}>
                    <Text style={{fontWeight: '700'}}>Date: </Text> 
                    {formatDate(new Date(date))}
                </Text>
                <Text style={{fontSize: scaleFontSize(14)}}>
                    <Text style={{ fontWeight: '700'}}>Start Time: </Text> 
                    {formatBusinessHours(start)}
                </Text>
                <Text style={{fontSize: scaleFontSize(14)}}>
                    <Text style={{ fontWeight: '700'}}>End Time: </Text> 
                    {formatBusinessHours(end)}
                </Text>
                {locationToDisplay && <LocationLabel/>}
                {!locationToDisplay && <EmployerName/>}
                <EmployeeName/>
            </Card>
        </TouchableContainer>
    )
}

export default GigCard;