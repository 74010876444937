import catchErrorForSentry from './catchSentryError';
import { GOOGLE_MAPS_API_KEY, SERVER_URL } from '../Constants';
// Location.setGoogleApiKey(GOOGLE_MAPS_API_KEY);
// const getLatLngFromAddress = async (address: string)=>{
//     let locationCoords = await Location.geocodeAsync(address);
//     if(!locationCoords.length){
//         alert("Error verifying your location, try modifying the formatting, or checking for typos");
//         return null;
//     }
//     return locationCoords[0];
// }

const getLatLngFromAddress = async (address: string) => {
    try {
      const response = await fetch(`${SERVER_URL}/api/get-latlng-from-address`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ address }),
      });
  
      const data = await response.json();
  
      if (!response.ok || !data.latitude || !data.longitude) {
        alert(data.error || 'Error verifying your location, try modifying the formatting, or checking for typos');
        return null;
      }
  
      return { latitude: data.latitude, longitude: data.longitude };
    } catch (error) {
      console.error('Error getting lat/lng from address:', error);
      catchErrorForSentry(error);
      return null;
    }
  };
  

export default getLatLngFromAddress

