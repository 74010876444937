import { StyleSheet, Text, View } from "react-native";
import TouchableContainer from "../TouchableContainer";
import { COLORS } from "../../Constants";
import isWeb from "../../helpers/isWeb";
const GigButtons = (props: any)=>{
    const {
        isNormalAccount,
        edittingGig,
        previousGigData,
        onAddGig,
        onEditGig,
        addingGig,
        isGigDataReady,
        cancelAddingGig,
    } = props;

    return(

        <View style={{marginVertical: 5, width: '100%', flexDirection: 'row', justifyContent: 'space-around'}}>
            <TouchableContainer
                disabled={isNormalAccount}
                onPress={
                    edittingGig && previousGigData ?
                    ()=>onEditGig(previousGigData) :
                    onAddGig
                }
                style={{
                    ...styles.btnContainer,
                    backgroundColor: isGigDataReady ? COLORS.slateGray : (addingGig ? COLORS.primaryRed : COLORS.primaryBlue)
                }}>
                <Text style={styles.textStyle}>
                    {isGigDataReady ? (edittingGig ? 'Confirm Edit' : 'Confirm Gig') : (addingGig ? 'Cancel' : (!isNormalAccount ? 'Add Gig' : `${isWeb() ? 'Click' : 'Tap'} and hold a gig above to edit or delete it`))}
                </Text>
            </TouchableContainer>
            {isGigDataReady && <TouchableContainer onPress={cancelAddingGig} style={{...styles.btnContainer, backgroundColor: COLORS.primaryRed}}>
                <Text style={styles.textStyle}>
                    Cancel
                </Text>
            </TouchableContainer>}
        </View>
    )
}

const styles = StyleSheet.create({
    btnContainer: {
        backgroundColor: COLORS.primaryBlue,
        borderRadius: 2.5,
        padding: 7.5
    },
    textStyle: {
        color: '#fff',
        fontWeight: '500'
    },
})

export default GigButtons;