import catchErrorForSentry from "./catchSentryError";
import Fire from "../Fire";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";

async function reauthenticateUser(currentPassword: string): Promise<void> {
    const user = Fire.getCurrentUser();
    if (user) {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        await reauthenticateWithCredential(user, credential);
    }
}

async function updateCurrentUserEmail(newEmail: string, currentPassword: string, onSuccess?: any, onFailure?: any): Promise<void> {
    const auth = Fire.getAuth();
    try {
        await Fire.updateEmail(newEmail);
        onSuccess && onSuccess();
    } catch (error: any) {
        if (error.code === 'auth/requires-recent-login') {
            try {
                await reauthenticateUser(currentPassword);
                await Fire.updateEmail(newEmail);
                onSuccess && onSuccess();
            } catch (reauthError: any) {
                catchErrorForSentry(reauthError);
                onFailure && onFailure(reauthError);
            }
        } else {
            catchErrorForSentry(error);
            onFailure && onFailure(error);
        }
    }
}

export default updateCurrentUserEmail;