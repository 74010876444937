import React, { useState } from 'react';
import { StyleSheet, View, Text, Modal, TouchableWithoutFeedback, Image, ActivityIndicator } from 'react-native';
import { IMAGEKIT_URL, SCREEN_SIZE, errorMessages, regexPatterns } from '../Constants';
// import { cloudFrontUrl, SCREEN_SIZE } from '../Constants';
import getCameraRollPermissionAsync from '../helpers/getCameraRollPermissionAsync';
import * as ImagePicker from 'expo-image-picker';
import CustomButton from './CustomButton';
import { Platform } from 'react-native';
// import uploadToStorage from '../helpers/uploadToStorage';
// import i18n from 'i18n-js';
import { COLORS } from '../Constants';
// import { connect } from 'react-redux';
// import Fire from '../Fire';
import { scale } from 'react-native-size-matters/extend';
import uploadToStorage from '../helpers/uploadToStorage';
import isMobile from '../helpers/isMobile';
import catchErrorForSentry from '../helpers/catchSentryError';
import ValidateInput from './ValidateInput';
import Card from './Card';
import reauthCurrentUser from '../helpers/reauthCurrentUser';
import scaleFontSize from '../helpers/scaleFontSize';
// const firebase = Fire.getFirebase()


const ReauthCurrentUserModal = (props: any)=>{

    const {
        onCloseModal,
        setShowModal,
        showModal,
        onReauthSuccess
    } = props

    const [state, setState] = useState({email: "", password: "", emailErrorFlag: false, passwordErrorFlag: false})
    

    return(
        <Modal
        style={{zIndex: 9999}}
        animationType="fade"
        transparent={true}
        visible={showModal}
        onRequestClose={()=>{
            console.log("Closing modal");
        }}
        >
        <View style={{
            // zIndex: 9999,
            flex: 1,
            backgroundColor: 'rgba(0, 0, 0, .6)',
            alignContent: 'center',
            justifyContent: 'center',
            height: SCREEN_SIZE.height,
            width: SCREEN_SIZE.width,
            }}>
                <TouchableWithoutFeedback
                    onPress={()=>{
                        if(onCloseModal) onCloseModal();
                        setShowModal(false)
                    }}
                >
                    <View style={{
                        height: '55%',
                        width: '100%',
                        position: 'absolute',
                        top: 0,
                    }}>

                    </View>
                </TouchableWithoutFeedback>
                <View style={Platform.OS === 'web' ? {...styles.webModalCard, width: isMobile() ? '90%' : '50%'} : styles.mobileModalCard}>
                    <Card
                        style={{
                            // borderWidth: 2,
                            // borderColor: 'red',
                            justifyContent: 'center',
                            alignContent: 'center',
                            width: '80%',
                            padding:10,
                        }}
                    >
                        <Text style={{fontSize: scaleFontSize(20), textAlign: 'center', alignSelf: 'center', margin: 15}}>
                            Please reauthenticate to change your email!
                        </Text>
                        <ValidateInput
                            style={{width: '75%', alignSelf: 'center'}}
                            inputStyle={{
                                borderWidth: 1,
                                borderColor: COLORS.primaryBlue,
                                borderRadius: 10,
                            }}
                            name={'Email'}
                            errorMessage={errorMessages.emailError}
                            errorName={'emailErrorFlag'}
                            onInputChange={(value: string)=>setState((prevState: any)=>{prevState.email = value; return prevState;})}
                            regex={regexPatterns.email}
                            defaultValue={state.email}
                            label={"Email"}
                        />
                        <ValidateInput
                            style={{width: '75%', alignSelf: 'center'}}
                            secureTextEntry={true}
                            inputStyle={{
                                borderWidth: 1,
                                borderColor: COLORS.primaryBlue,
                                borderRadius: 10,
                            }}
                            name={'Password'}
                            errorMessage={errorMessages.passwordError}
                            errorName={'passwordErrorFlag'}
                            onInputChange={(value: string)=>setState((prevState: any)=>{prevState.password = value; return prevState;})}
                            regex={regexPatterns.password}
                            defaultValue={state.password}
                            label={"Password"}
                        />
                        <CustomButton
                            onPress={()=>{
                                const {email, password} = state;
                                reauthCurrentUser(email, password)
                                .then((success)=>{
                                    alert("Reauthentication successful");
                                    setShowModal(false);
                                    onReauthSuccess();
                                })
                                .catch((error: any)=>{
                                    catchErrorForSentry(error);
                                    setShowModal(false);
                                })
                            }}
                            title={"Reauthenticate"}
                            textStyle={{
                                color: 'white',
                                fontWeight: '500', 
                                fontSize: scaleFontSize(12.5),
                            }}
                            style={{
                                backgroundColor: COLORS.primaryBlue,
                                alignSelf:'center',
                                // height: '25%',
                                marginTop: 10,
                                padding:10,
                                width: '25%',
                                minWidth: 100,
                                borderRadius: 10,
                            }}
                        />
                    </Card>

                </View>
            </View>
        </Modal>
    )
}

const styles = StyleSheet.create({
    btnTextStyle:{
        fontSize: scaleFontSize(25),
        color: '#fff',
    },
    uploadPictureBtn:{
        width: '50%',
        borderRadius: 10,
        padding: 10,
        alignSelf: 'center',
    },
    mobileModalCard: {
        borderTopLeftRadius: 50,
        borderTopRightRadius: 50,
        backgroundColor: '#fff',
        height: '45%',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    },
    webModalCard: {
        borderRadius: 50,
        backgroundColor: '#fff',
        height: '65%',
        position:'relative',
        bottom: 'default',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
})


  
export default ReauthCurrentUserModal