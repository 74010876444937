import getRndFloat from "./getRndFloat";

const blurLocation = (coords: {latitude: number, longitude: number})=>{
    const {latitude, longitude} = coords;
    const newLat = latitude + getRndFloat(0.01, -0.04);
    const newLng = longitude + getRndFloat(0.01, -0.04);
    return {coords: {
      latitude: newLat,
      longitude: newLng,
    }}
}

export default blurLocation