import Toast from "react-native-toast-message";
import isWeb from "./isWeb";

const showToast = (text: string, success: boolean, settings?: any)=>{

    isWeb()
    ?
    (()=>{
        alert(text)
    })()
    :
    (()=>{
        Toast.show(settings ? settings : {
            type: success ? 'success' : 'error',
            position: 'bottom',
            text1: success ? 'Success' : 'Error',
            text2: text,
            visibilityTime: 2000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
        });
    })()
}

export default showToast;