import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  'Sunday': true,
  'Monday': true,
  'Tuesday': true,
  'Wednesday': true,
  'Thursday': true,
  'Friday': true,
  'Saturday': true,
};

const timePickersSlice = createSlice({
  name: 'timePickers',
  initialState,
  reducers: {
    updateIsHours: (state: any, action: PayloadAction<{ day: string; value: any }>) => {
      const { day, value } = action.payload;
      state[day] = value;
    },
  },
});

export const { updateIsHours } = timePickersSlice.actions;

export default timePickersSlice.reducer;