import { Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import getMediaUploadUrl from './getMediaUploadUrl';
import catchErrorForSentry from './catchSentryError';
import { SERVER_URL } from '../Constants';

async function uploadFileWeb(
    pathToImageFile: string,
    imageKey: string,
    signedUrl: string,
    onSuccess?: Function,
    onFailure?: Function
  ) {
    // Fetch the file data from the local file URI
    const fileData = await fetch(pathToImageFile);
    const fileBlob = await fileData.blob();
    //I think this allows you to upload video as well
    //multipart/form-data technique...
    // const formData = new FormData();
    // formData.append("image", fileBlob)
    // formData.append("imageKey", imageKey);
  
    // Get the content type from the fileBlob
    const contentType = fileBlob.type;
    // console.log("Content type: ", contentType);
    // Upload the file using the Fetch API
    try {
      const response = await fetch(signedUrl, {
        method: 'PUT',
        // mode: 'cors', // add this line
        headers: {
          // "Content-Length": fileBlob.size,
          // contentType: 'multipart/form-data',
          'Content-Type': contentType,
          // 'Content-Type': 'multipart/form-data',
          // "Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
          // "Access-Control-Allow-Headers": "Content-Type",
        },
        body: fileBlob,
      });
  
      // Handle the response according to your API
      if (response.ok) {
        onSuccess && onSuccess(response);
        return true;
      } else {
        onFailure && onFailure(await response.text());
        // alert("Response text error");
        return false;
      }
    } catch (error) {
      onFailure && onFailure(error);
      // console.log("JSON stringified format: ", JSON.stringify(error)); 
      catchErrorForSentry(error);
      // alert("general error");
      return false;
    }
}

const uploadFileMobile = (pathToImageFile: string, signedUrl: string, onSuccess?: Function, onFailure?: Function)=>{
  //Not sure why this works for videos too, but apparently it does?
  let options: FileSystem.FileSystemUploadOptions = {
      mimeType: 'image/jpeg',
      httpMethod: 'PUT',
  };

  return FileSystem.uploadAsync(signedUrl, pathToImageFile, options)
  .then(async (response)=>{
      (onSuccess && onSuccess(response));
      return true;
  })
  .catch((error)=>{
      console.log("Error uploading image uploadFileMobile: ", JSON.stringify(error)); 
      catchErrorForSentry(error);
      (onFailure && onFailure(error));
      return false;
  })
}

const uploadToStorage = async (pathToImageFile: string, imageKey: string, objectType: 'image' | 'video', onSuccess?: Function, onFailure?: Function) => {
    const signedUrl = await getMediaUploadUrl(imageKey, objectType);
    // console.log("Signed Url: ", signedUrl);
    //When we have a policy encforced for security, we'll use this.
    // const policyRoute = `${SERVER_URL}/uploadImage`;
    // alert(`SignedUrl:  ${signedUrl}`);
    if(Platform.OS ==='web') return uploadFileWeb(pathToImageFile, imageKey, signedUrl, onSuccess, onFailure);
    return uploadFileMobile(pathToImageFile, signedUrl, onSuccess, onFailure);
    
}

export default uploadToStorage