import { useState, useEffect } from 'react';
import { Dimensions, Platform } from 'react-native';

export default function isMobileListener() {
  const getScreenWidth = () => {
    return Platform.OS === 'web' ? Dimensions.get('window').width : Dimensions.get('screen').width;
  };

  const [screenWidth, setScreenWidth] = useState(getScreenWidth());
  const isMobile = screenWidth < 980;

  useEffect(() => {
    const onChange = () => {
      setScreenWidth(getScreenWidth());
    };

    if (Platform.OS === 'web') {
      window.addEventListener('resize', onChange);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('resize', onChange);
      }
    };
  }, []);

  return isMobile;
}