import { FontAwesome5, SimpleLineIcons } from "@expo/vector-icons"
import Card from "./Card"
import { ScrollView, Text, View } from "react-native"
import { COLORS, DEBUG_EMAILS, SCREEN_SIZE } from "../Constants"
import UserCard from "./UserCard"
import { UserData } from "../Interfaces"
import UserSlideShowImage from "./UserSlideShowImage"
import scaleFontSize from "../helpers/scaleFontSize"

const LocalsYouFollow = (props: any)=>{
    let {isProfileOwner, locals, push, darkMode } = props;
    locals = locals.filter((data: any)=>!DEBUG_EMAILS.includes(data.email));
    const renderLocals = ()=>{
        if(!locals.length){
            return (
                <Card style={{height: SCREEN_SIZE.height*.20, width: SCREEN_SIZE.width*.9, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{fontSize: scaleFontSize(15), fontWeight: '400'}}>
                        {isProfileOwner ? "You're not following any locals yet!" : "They're not following any locals yet"}
                    </Text>
                </Card>
            )
        }
        //@ts-ignore
        const cards = Object.values(locals).map((account: UserData)=>{
            return(
                // <UserCard key={account.uid} profilePictureKey={account.profilePictureKey} uid={account.uid} />
                <UserSlideShowImage push={push} key={account.uid} user={account}/>
            )
        })
        return cards;
    }
    // console.log("Rendering Places You Follow: ", locals.map((account: UserData)=>account.displayName))
    return(
                <Card style={{borderRadius: 0, padding: 10, backgroundColor: darkMode ? COLORS.slateGray : '#fff'}}>
                    <View style={{flexDirection: 'row',}}>
                        <SimpleLineIcons color={darkMode ? "white" : "black"} name="people" style={{alignSelf: 'center'}} size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055}/>
                        <Text style={{fontSize: scaleFontSize(20), color: darkMode ? "white" : "black", marginLeft: 5,}}>
                            {isProfileOwner ? 'Locals You Follow' : 'Locals They Follow'}
                        </Text>
                    </View>
                    <View style={{flexDirection: 'row', justifyContent: 'space-around', padding: 2.5}}>
                    <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            contentContainerStyle={{
                                flexDirection: 'row', justifyContent: 'space-around',
                                padding: 2.5
                            }}>
                            {renderLocals()}
                    </ScrollView>
                    </View>
                </Card>
    )
}

export default LocalsYouFollow