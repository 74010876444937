import { StyleSheet, Text, View } from "react-native"
import scaleFontSize from "../../helpers/scaleFontSize"
import { COLORS, GOOGLE_MAPS_API_KEY, SCREEN_SIZE, SERVER_URL } from "../../Constants"
import { useRef } from "react"
import SetDateModal from "../SetDateModal"
import SetTimeModal from "../SetTimeModal"
import formatBusinessHours from "../../helpers/formatBusinessHours"
import { SelectCountry } from 'react-native-element-dropdown';
import { UserData } from "../../Interfaces"
import isWeb from "../../helpers/isWeb"
import CustomGooglePlacesAutocomplete from "../CustomGooglePlacesAutocomplete";

const GigCreator = (props: any)=>{
    const ref = useRef();
    const {

        selectedStartTime,
        selectedEndTime,
        selectedEmployer,
        selectedLocation,

        selectingDate,
        selectingStartTime,
        selectingEndTime,
        selectingEmployer,
        selectingLocation,
        employers,
        setSelectedEmployer,
        setSelectingEmployer,

        setSelectedEndTime,
        setSelectingEndTime,
        setSelectedStartTime,
        setSelectingStartTime,
        setSelectedDate,
        setSelectingDate,
        setSelectingLocation,
        setAddress,
        setSelectedLocation,
        


    } = props;

    const SelectLocation = (props: any) => {
        // console.log("Rendering select location");
        return (
            <View style={{
                flex: 1,
                width: '100%',
                marginVertical: 10,
                // justifyContent: 'center',
                // alignContent: 'center',
                // alignItems: 'center',
            }}>
                <Text style={{alignSelf: 'center', textDecorationLine: 'underline'}}>Enter location below</Text>
                <CustomGooglePlacesAutocomplete
                    //@ts-ignore
                    ref={ref}
                    //@ts-ignore
                    // keyboardShouldPersistTaps={true}
                    //@ts-ignore
                    placeholder={selectedLocation && selectedLocation.address ? selectedLocation.address :'Enter entertainment location...'}
                    // placeholder={'Enter vending location...'}
                    fetchDetails={true}
                    disableScroll={true}
                    listViewDisplayed={false}
                    onPress={(data = null, details = null) => {
                        // console.log("Details: ", details);
                        // return;
                        if (details) onAddressSelected(details);
                    }}
                    query={{
                        key: GOOGLE_MAPS_API_KEY, // replace with your Google API key
                        language: 'en',
                    }}
                    styles={{
                        textInputContainer: {
                            borderWidth: 1,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                            marginVertical: 10,
                            backgroundColor: 'white',
                        },
                        textInput: {
                            backgroundColor: 'transparent',
                            // borderColor: 'red',
                            // borderWidth: 1,
                            height: '100%',
                            width: '100%',
                        }
                    }}
                    requestUrl={isWeb() ? {
                        useOnPlatform: 'web',
                        url: `${SERVER_URL}/maps/api`,}
                        : undefined }
                />
            </View>
        );
    };
    const SelectEmployer = (props: any) => {
    
        return (
            <View>
                <SelectCountry
                    style={styles.dropdown}
                    selectedTextStyle={styles.selectedTextStyle}
                    placeholderStyle={styles.placeholderStyle}
                    imageStyle={styles.imageStyle}
                    iconStyle={styles.iconStyle}
                    maxHeight={200}
                    value={selectedEmployer}
                    //@ts-ignore
                    data={employers ? Object.values(employers) : []}
                    valueField="uid"
                    labelField="displayName"
                    // imageField="image"
                    placeholder={employers ? "Select Employer" : "None listed"}
                    searchPlaceholder="Search..."
                    onChange={(e: UserData) => {
                        setSelectedEmployer(e);
                        setSelectedLocation(undefined);
                        setSelectingEmployer(false);
                    }}
                />
                {/* <CustomButton
                    title={"Done"}
                    style={{width: '50%', marginLeft: 10, marginTop: 10, padding: 10, alignSelf: 'center',}}
                    textStyle={{color: 'white'}}
                    onPress={()=>{
                        console.log("Time selected: ", time);
                        onTimeSelected(time)
                        setShow(false)
                    }}
                
                /> */}
            </View>
        );
    };
    const onAddressSelected = (details: any)=>{
        // Object.keys(details).forEach((key: string)=>{
        //     console.log("Key: ", key);
        // });
        // console.log("Details: ", details.name);
        // console.log("Address Components: ", details.address_components);
        // console.log("Address Components Array: ", details.address_components[0]);

        const selectedAddress = details.formatted_address;
        const locationCoords = {
            latitude: details.geometry.location.lat,
            longitude: details.geometry.location.lng,
        };
        // console.log("Address selected: ", details.formatted_address);
        // console.log("Location: ", locationCoords);
        setSelectingLocation(false);
        setAddress(selectedAddress);
        setSelectedEmployer(undefined);
        setSelectedLocation({
            name: details.name,
            address: selectedAddress,
            coords: locationCoords,
        })
    }

    return(
        <>
            { (selectingLocation ) && <SelectLocation/>}
            { (selectingEmployer && employers) && <SelectEmployer/>}
            {(selectingDate || selectingStartTime || selectingEndTime) && <View style={{
                flexDirection: 'row',
                height: SCREEN_SIZE.height * .15,
                // borderColor: 'red', borderWidth: 1,
                alignContent: 'center',
                alignItems: 'center',
            }}>
                {selectingDate && <SetDateModal
                    containerStyle={{
                        position: 'relative',
                        height: '50%',
                        width: '50%',
                        top: 0,
                        backgroundColor: '#fff',

                        // borderColor: 'blue',
                        // borderWidth: 1,
                    }}
                    showModal={selectingDate}
                    setShow={setSelectingDate}
                    // onDateChange={(birthday: any)=>setBirthday(birthday)}
                    onDateSelected={(newDate: Date)=>{
                        // console.log("Changing birthday to: ", formatDate(newDate));
                        setSelectedDate(newDate);
                        setSelectingDate(false);
                        // onBirthdayChange(newDate);
                    }}
                />}
                {selectingStartTime && <SetTimeModal
                    containerStyle={{
                        position: 'relative',
                        height: '50%',
                        width: '50%',
                        top: 0,
                        backgroundColor: '#fff',

                        // borderColor: 'blue',
                        // borderWidth: 1,

                    }}
                    defaultTime={selectedStartTime || new Date()}
                    showModal={selectingStartTime}
                    setShow={setSelectingStartTime}
                    // onDateChange={(birthday: any)=>setBirthday(birthday)}
                    onTimeSelected={(newDate: Date)=>{
                        const time = formatBusinessHours(newDate.getTime());
                        setSelectedStartTime(newDate.getTime());
                        setSelectingStartTime(false);
                        console.log("Changing start time to: ", time);
                        // onBirthdayChange(newDate)/;
                    }}
                />}
                {selectingEndTime && <SetTimeModal
                    containerStyle={{
                        position: 'relative',
                        height: '50%',
                        width: '50%',
                        top: 0,
                        backgroundColor: '#fff',

                        // borderColor: 'blue',
                        // borderWidth: 1,

                    }}
                    defaultTime={selectedEndTime || new Date()}
                    showModal={selectingEndTime}
                    setShow={setSelectingEndTime}
                    // onDateChange={(birthday: any)=>setBirthday(birthday)}
                    onTimeSelected={(newDate: Date)=>{
                        const time = formatBusinessHours(newDate.getTime());
                        setSelectedEndTime(newDate.getTime());
                        setSelectingEndTime(false);
                        console.log("Changing end time to: ", time);
                        // onBirthdayChange(newDate)/;
                    }}
                />}

            </View>}
        </>
    )
}

const styles = StyleSheet.create({
    btnContainer: {
        backgroundColor: COLORS.primaryBlue,
        borderRadius: 2.5,
        padding: 7.5
    },
    textStyle: {
        color: '#fff',
        fontWeight: '500'
    },
    dropdown: {
        margin: 16,
        height: 50,
        width: 150,
        backgroundColor: '#EEEEEE',
        borderRadius: 22,
        paddingHorizontal: 8,
      },
      imageStyle: {
        display: 'none',
        width: 24,
        height: 24,
        borderRadius: 12,
      },
      placeholderStyle: {
        fontSize: scaleFontSize(14),
      },
      selectedTextStyle: {
        fontSize: scaleFontSize(14),
        marginLeft: 8,
        textAlign: 'center',
      },
      iconStyle: {
        // display: 'none',÷
        width: 20,
        height: 20,
      },
})

export default GigCreator;