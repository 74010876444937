import React from 'react';
import { View, Text, ScrollView, StyleSheet, Linking } from 'react-native';
import Card from '../components/Card';
import scaleFontSize from '../helpers/scaleFontSize';


const DataControlPage = () => {
    return (
        <View style={{ flex: 1, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <Card style={styles.container}>
                <ScrollView>
                    <Text style={{ ...styles.heading, alignSelf: 'center', fontSize: 20 }}>Request to Delete Data for AlwaysLocal App</Text>

                    <Text style={styles.paragraph}>At AlwaysLocal, we understand the importance of data privacy and the rights of our users. We respect your choice to manage, and if necessary, delete the data you’ve entrusted with us.</Text>

                    <Text style={styles.heading}>How to Request the Deletion of Your Account or Data:</Text>

                    <View style={styles.orderedList}>
                        <Text style={styles.listItem}><Text style={styles.bold}>1. Email Us:</Text> If you prefer, you can also send an email to our support team at <Text style={styles.link} onPress={() => Linking.openURL('mailto:ty@alwayslocal.net')}>ty@alwayslocal.net</Text> with the subject line “Deletion Request”. Please ensure you send the email from the address associated with your AlwaysLocal account to verify your identity.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>2. Wait Period:</Text> Once your request has been received, we will process it within 72 hours. During this time, our team might reach out to you for further verification to ensure the security of your account.</Text>
                    </View>

                    <Text style={styles.heading}>Types of Data Deleted and Retention Period:</Text>
                    <Text style={styles.paragraph}>Upon the successful processing of your deletion request, the requested data will be deleted immediately.</Text>

                    <Text style={styles.heading}>Data Retention:</Text>

                    <View style={styles.list}>
                        <Text style={styles.listItem}><Text style={styles.bold}>Backups:</Text> Your data may still exist in our backups for an additional 30 days. After this period, the data will be permanently erased from backups as well.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>Logs & Analytics:</Text> Any anonymized data or usage statistics might be retained, but they will not be personally identifiable.</Text>
                    </View>

                    <Text style={styles.heading}>Additional Information:</Text>
                    <Text style={styles.paragraph}>If you have any concerns or questions regarding the deletion process, please don’t hesitate to reach out to us. Our primary goal is to ensure you feel safe and in control of your data.</Text>
                </ScrollView>
            </Card>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '90%',
        padding: 15,
    },
    paragraph: {
        marginBottom: 10,
    },
    heading: {
        fontWeight: 'bold',
        fontSize: 16,
        marginVertical: 10,
    },
    list: {
        paddingLeft: 20,
    },
    orderedList: {
        paddingLeft: 25,
    },
    listItem: {
        marginBottom: 8,
    },
    bold: {
        fontWeight: 'bold',
    },
    link: {
        color: 'blue',
        textDecorationLine: 'underline',
    }
});

export default DataControlPage;
