import catchErrorForSentry from './catchSentryError';
import { SERVER_URL } from '../Constants';


const getLatLngFromZipCode = async (zipcode: string) => {
  try {
    const response = await fetch(`${SERVER_URL}/api/get-latlng-from-zipcode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ zipcode }),
    });
    
    const data = await response.json();
    if (!response.ok) {
      console.log("Error getting latlng from zipcode");
      alert(data.error || 'Error verifying your location');
      return null;
    }

    return { latitude: data.latitude, longitude: data.longitude };
  } catch (error) {
    console.error('Error getting lat/lng from zipcode:', error);
    catchErrorForSentry(error);
    return null;
  }
};

export default getLatLngFromZipCode

