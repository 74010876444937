import { useSelector } from "react-redux";
import Card from "./Card";
import GigDisplayer from "./GigDisplayer";
import { RootState } from "../store";
import scaleFontSize from "../helpers/scaleFontSize";
import { Text } from "react-native";
import { fakeGigData } from "../Constants";




const FoodTruckSchedule = (props: any) => {
    const userData = useSelector((state: RootState) => state.userData);
    const { profileData, navigation, foodSchedule } = props;
    const isProfileOwner = userData.uid === profileData.uid;


    const haveAllFoodTruckGigsPassed = () => {
        if (!foodSchedule || foodSchedule.length === 0) {
            return true;
        }
        const currentDate = new Date();
        return foodSchedule.every((gig: any) => {
            const gigDate = new Date(gig.date);
            return gigDate < currentDate;
        });
    };

    const allGigsPassed = haveAllFoodTruckGigsPassed();
    const emptyCondition = (isProfileOwner && allGigsPassed) || (isProfileOwner && (!foodSchedule || foodSchedule.length === 0));
    return (
        <Card style={{flex:1, alignItems: 'center', justifyContent: 'center', padding:5 }}>
            { emptyCondition && (
                <Text style={{ fontSize: scaleFontSize(12), textAlign: 'center', fontWeight: '500' }}>
                    {isProfileOwner ?
                    'This will be populated with your food gigs once your requests are accepted!' :
                    'No food truck stops scheduled yet'}
                </Text>
            )}
            { emptyCondition && (
                <>
                    <Text style={{ fontSize: scaleFontSize(10), textAlign: 'center'}}>
                        Here are some examples
                    </Text>
                    <GigDisplayer push={navigation.push} onAccount={true} gigs={fakeGigData} />
                </>
            )}
            {foodSchedule && foodSchedule.length > 0 && !allGigsPassed && (
                <GigDisplayer style={{maxHeight: undefined}} push={navigation.push} onAccount={true} gigs={foodSchedule}/>
            )}
            {allGigsPassed && !isProfileOwner && (
                <Text style={{ fontSize: scaleFontSize(12), textAlign: 'center', fontWeight: '500' }}>
                    No food truck stops scheduled yet
                </Text>
            )}
        </Card>
    );
};

export default FoodTruckSchedule