import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeHeader from './HomeHeader';
import WebBackEndPage from '../../webpages/WebBackEndPage';
import EmailCTAPage from '../../webpages/EmailCTAPage';
import PrivacyPage from '../../webpages/PrivacyPage';
import DataControlPage from '../../webpages/DataControlPage';
import SupportPage from '../../webpages/SupportPage';
import WebAuthPage from '../../webpages/WebAuthPage';
// import { Text, View } from 'react-native';
const Stack = createNativeStackNavigator();


const WebNavigator=({ isLoggedIn }: any)=>{
    return (
      <Stack.Navigator initialRouteName='Home'>
        <Stack.Screen initialParams={{ isLoggedIn }} name="Home" component={isLoggedIn ? WebBackEndPage : EmailCTAPage}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
        <Stack.Screen name="WebBackEndPage" component={WebBackEndPage}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
        <Stack.Screen name="PrivacyPage" component={PrivacyPage}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
        <Stack.Screen name="DataControlPage" component={DataControlPage}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
        <Stack.Screen name="SupportPage" component={SupportPage}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
        <Stack.Screen name="WebAuthPage" component={WebAuthPage}
          options={({ navigation, route }) => ({
            headerShown: true,
            header: ()=>(<HomeHeader route={route} navigation={navigation} isLoggedIn={isLoggedIn}/>)
        })}/>
      </Stack.Navigator>
    );
}

export default WebNavigator;