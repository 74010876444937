import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import catchErrorForSentry from './catchSentryError';
import { Platform } from 'react-native';
//@ts-ignore
const projectId = Constants.expoConfig.extra.eas.projectId;

const registerForPushNotificationsAsync = async () => {
    console.log("Registering push notifications");
    let token;
    try {
      if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
          const { status } = await Notifications.requestPermissionsAsync();
          finalStatus = status;
        }
        if (finalStatus !== 'granted') {
          alert('Failed to get push token for push notification!');
          return;
        }
        token = (await Notifications.getExpoPushTokenAsync({
          projectId
          })).data;
      } else {
        alert('Must use physical device for Push Notifications');
      }
  
      if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
          name: 'default',
          importance: Notifications.AndroidImportance.MAX,
          vibrationPattern: [0, 250, 250, 250],
          lightColor: '#FF231F7C',
        });
      }
    } catch (error) {
      console.error("Error during push notification registration:", error);
      catchErrorForSentry(error);
      // Handle the error as necessary in your app
      // For example, you might want to display a message to the user
    }
  
    return token;
}

export default registerForPushNotificationsAsync;