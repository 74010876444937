import { TouchableWithoutFeedback, View, Text, Image, StyleSheet, Dimensions } from "react-native";
import Card from "./Card";
import CacheImage from "./CacheImage";
import { IMAGEKIT_URL, SCREEN_SIZE } from "../Constants";
import scaleFontSize from "../helpers/scaleFontSize";
import sendToProfileScreen from "../helpers/sendToProfileScreen";

const AccountCard = (props: any)=>{
    const { account, distanceFromUser, push, showDistance = true } = props;
    const { coverPhotoKey, pictureKeys } = account;
    return(
        <Card key={account.username} style={{...styles.eventContainer, width: SCREEN_SIZE.width * .9}}>
            <TouchableWithoutFeedback onPress={async ()=>{
                // let freshEventData = await Fire.asyncQueryDataByID('events', event.name)
                // if(!freshEventData){
                //     alert(i18n.t('EventNotAvailable'));
                //     return;
                // }
                sendToProfileScreen(account.uid, push);
            }}>
            <View style={{paddingBottom: 5}}>
                <Image
                    //@ts-ignore
                    resizeMode={( (coverPhotoKey || pictureKeys) ? 'cover' : 'contain')}
                    source={(
                        coverPhotoKey ? {uri: `${IMAGEKIT_URL}/${coverPhotoKey}`} :
                        (account.pictureKeys ? {uri: `${IMAGEKIT_URL}/${Object.values(pictureKeys)[0]}`} : require('../assets/alwayslocal-logo.png'))
                    )}
                    // uri={account.coverPhotoKey}
                    // placeholderType={'logo'}
                    style={{...styles.eventImageStyle, alignSelf: 'center'}}
                />
                {/* {(event.eventType === 'COVID-Safe Jam' && <Image
                    resizeMode={'cover'}
                    source={ require('../assets/covid-safe-symbol.png')}
                    style={{position: 'absolute', left: '90%', height: 75, width: 75, alignSelf: 'center'}}
                />)} */}
                {/* <Text style={{marginTop: 5, marginBottom: 5, fontSize: scaleFontSize(14)}}> {event.startDate} </Text> */}
                {/* {(event.eventType !== 'COVID-Safe Jam' &&
                    <Text style={{marginTop: 5, marginBottom: 5, fontSize: scaleFontSize(14)}}>
                    {event.address}
                    </Text>
                )} */}
                <View style={{flexDirection: 'column'}}>
                    <Text style={{marginTop: 5, marginBottom: 5, fontWeight: "800", fontSize: scaleFontSize(15), textAlign:'center'}}> {account.displayName} </Text>
                    {(account.coords && showDistance) && <Text style={{textAlign: 'center', marginTop: 5, marginBottom: 5, fontSize: scaleFontSize(14)}}>
                    {distanceFromUser} miles away
                    </Text>}
                </View>
                {/* <View style={{
                    flexDirection: 'row',
                    alignContent: 'center',
                    justifyContent: 'space-around',
                    alignSelf: 'center',
                    width: '100%',
                    marginTop: 10,
                    borderTopWidth: 1.5,
                    borderTopColor: Colors.primaryGray
                    }}>
                        <Text style={{borderRightColor: Colors.primaryGray, borderRightWidth: 1, textAlign: 'center', fontSize: scaleFontSize(15), ...(event.usersNotGoing ? {width: '30%'} : {flex: 1} )}}>
                            {(event.usersGoing ? `${Object.keys(event.usersGoing).length} ${i18n.t('Going')}` : `0 ${i18n.t('Going')}`)}
                        </Text>
                        <Text style={{textAlign: 'center', fontSize: (15), ...(event.usersNotGoing ? {width: '30%'} : {flex: 1} ) }}>
                            {(event.usersInterested ? `${Object.keys(event.usersInterested).length} ${i18n.t('Interested')}` : `0 ${i18n.t('Interested')}`)}
                        </Text>
                        {(event.usersNotGoing && <Text style={{borderLeftColor: Colors.primaryGray, borderLeftWidth: 1, textAlign: 'center', fontSize: scaleFontSize(15), width: '30%'}}>
                            {`${Object.keys(event.usersNotGoing).length} ${i18n.t('NotGoing')}`}
                        </Text>)}
                    </View> */}
                </View>
            </TouchableWithoutFeedback>
        </Card>
    )
}

const styles = StyleSheet.create({
    eventContainer: {
        marginTop: 10,
        marginBottom: 10,
        alignSelf: 'center',
    },
    eventImageStyle: {
        width: '100%',
        // width: Dimensions.get("window").width * .8,
        height: Dimensions.get("window").height * .25
    },
})

export default AccountCard;
