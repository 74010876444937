import { Linking, Platform } from "react-native";
import { COLORS, SCREEN_SIZE } from "../Constants";
import CustomButton from "./CustomButton";
import isWeb from "../helpers/isWeb";
import scaleFontSize from "../helpers/scaleFontSize";
import openMapsApp from "../helpers/openMapsApp";

const DirectionsButton = (props: any)=>{
    const { label, location } = props;
    const {latitude, longitude} = location;
 
    return(
        <CustomButton
            title={"Get Directions"}
            onPress={()=>openMapsApp(latitude, longitude, label)}
            style={{
                width: "45%",
                minWidth: 150,
                height: SCREEN_SIZE.height * .040,
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: COLORS.primaryBlue,
                borderRadius: 4,
            }}
            textStyle={{textAlignVertical: 'center', fontSize: scaleFontSize(15), color: '#fff'}}
        />
    )
}

export default DirectionsButton