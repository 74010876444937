import { COLORS, SCREEN_SIZE } from '../Constants/';
// import Colors from '../Constants/Colors';
// COLORS
import { LinearGradient } from 'expo-linear-gradient';
import { Modal, Platform, Keyboard, View, StyleSheet, ScrollView, Text, TouchableOpacity } from 'react-native';
import React, { useState, useEffect, memo } from 'react';
import Card from '../components/Card';
// import { useFonts } from '@use-expo/font';
// import { useHeaderHeight } from 'react-navigation-stack';
import Constants from 'expo-constants';
import { Screen } from './Screen';
import scaleFontSize from '../helpers/scaleFontSize';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { deviceYearClass } from 'expo-device';
import { useNavigation } from '@react-navigation/native';

const DEFAULT_MODAL_SIZE = SCREEN_SIZE.height * .90;

const CustomModal = (props: any)=>{
    const {
        titleContainerStyle,
        submitBtnContainerStyle,
        gradient = false,
        visibilityFlag,
        visibilityControlFunc,
        onBack = null,
        title = "",
        onSubmit,
        confirmText,
        showSubmit = true,
        showBack = true,
        scroll = true,
        showTitle = true,
        responsive = true,
        // keyboardShouldPersistTaps = 'always',
        // keyboardDismissMode = null
    } = props;
    
    
    // let [fontsLoaded] = useFonts({
    //     'Nadia-Sofia': require('../assets/fonts/Nadia_Sofia.ttf'),
    // });
    // const Header = useHeaderHeight();

    //TODO: Fix these later!
    const fontsLoaded = true;
    const { top, bottom } =  useSafeAreaInsets();
    const Header = top + Constants.statusBarHeight

    const [ modalSize, setModalSize ] = useState<number>(DEFAULT_MODAL_SIZE);
    const [ keyboardShown, setKeyboardShown ] = useState(false);


    useEffect(() => {
        // Setting up event listeners
        const keyboardDidHideSubscription = Keyboard.addListener(
            'keyboardDidHide',
            handleKeyboardHidden
        );

        const keyboardDidShowSubscription = Keyboard.addListener(
            'keyboardDidShow',
            handleKeyboardShown
        );

        return () => {
            keyboardDidHideSubscription.remove();
            keyboardDidShowSubscription.remove();
        };
    }, []);



    const handleKeyboardHidden = ()=>{
        setModalSize(DEFAULT_MODAL_SIZE - Header);
        // setModalSize(DEFAULT_MODAL_SIZE - Header);
        setKeyboardShown(false);
    }

    //TODO: Give this a proper type
    const handleKeyboardShown = (e: any)=>{
        if(e){
            // setModalSize( (DEFAULT_MODAL_SIZE - e.endCoordinates.height - Header));
            setModalSize( (DEFAULT_MODAL_SIZE - e.endCoordinates.height));
            setKeyboardShown(true);
            // setModalSize( (DEFAULT_MODAL_SIZE + Header - e.endCoordinates.height));
        }
    }


    const renderTitle = ()=>{
        if(fontsLoaded && showTitle){
            if(gradient){
                return(
                    <LinearGradient
                        colors={[COLORS.primaryRed, COLORS.primaryGray]}
                        style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                        start={{x: 0, y: 0}}
                        end={{x: 1, y: 0}}
                    >
                        <Text style={{
                            textAlign: 'center',
                            fontSize: scaleFontSize(15),
                            borderBottomWidth: 2,
                            borderBottomColor: '#000',
                            padding: 5,
                            color: '#fff',
                            // fontFamily: 'Nadia-Sofia',
                        }}>
                            {title}
                        </Text>
                    </LinearGradient>
                )
            }
            else return(
                <View style={{...titleContainerStyle, width: '100%', backgroundColor: COLORS.primaryBlue}}>
                    <Text style={{
                        textAlign: 'center',
                        fontSize: scaleFontSize(15),
                        borderBottomWidth: 2,
                        borderBottomColor: '#000',
                        padding: 5,
                        color: '#fff',
                        // fontFamily: 'Nadia-Sofia',
                    }}>
                        {title}
                    </Text>
                </View>
            )
        }
    }

    const BackButton =()=>{
        return(
            <TouchableOpacity
                onPress={()=>{
                    visibilityControlFunc(false);
                    if(onBack){
                        onBack();
                    }
                }}>
                <Text style={{
                    padding: 5,
                    color: '#fff',
                    fontSize: scaleFontSize(15),
                    // fontFamily: 'Nadia-Sofia',
                    textAlign: 'center',
                }}>
                    Back
                </Text>
            </TouchableOpacity>
        )
    }

    const SubmitButton = ()=>{
        return(
            <TouchableOpacity
            onPress={async ()=>{
                onSubmit();
            }}>
            <Text style={{
                padding: 5,
                color: '#fff',
                fontSize: scaleFontSize(15),
                // fontFamily: 'Nadia-Sofia',
                textAlign: 'center',
            }}>
                {(confirmText ? confirmText : 'Submit')}
            </Text>
        </TouchableOpacity>
        )
    }

    const renderButtons = ()=>{
        return(
            <View style={{
                flexDirection: 'row',
                borderTopWidth: 2,
                borderTopColor: '#000',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
            }}>
                {( (fontsLoaded && gradient) &&
                    <LinearGradient
                        colors={[COLORS.primaryGray, COLORS.primaryRed]}
                        style={{borderBottomLeftRadius: 10, flex: 1}}
                        start={{x: 0, y: 0}}
                        end={{x: 1, y: 0}}
                    >
                        {showSubmit && <SubmitButton/>}
                        {showBack && <BackButton/>}
                    </LinearGradient>
                )}
                {( (fontsLoaded && !gradient) &&
                    <View style={{
                        ...submitBtnContainerStyle,
                        width: '100%', 
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        backgroundColor: COLORS.primaryBlue
                    }}>
                        {showSubmit && <SubmitButton/>}
                        {showBack && <BackButton/>}
                    </View>
                )}
            </View>
        )
    }

    return(
        <Modal
            // presentationStyle={'overFullScreen'}
            animationType="fade"
            transparent={true}
            visible={visibilityFlag}
            onRequestClose={()=>{
                // console.log("Closing modal");
            }}
        >
            <View style={{
                backgroundColor: 'rgba(0,0,0,.9)',
                flex: 1,
                // height: SCREEN_SIZE.height,
                // width: SCREEN_SIZE.width,
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                zIndex: 0,
                
                // borderColor: 'white',
                // borderWidth: 1,
            }}>
                <Card
                    style={{
                        backgroundColor: COLORS.primaryBgColor,
                        // marginTop: (responsive ? 0 : Constants.statusBarHeight * 2),
                        height: (responsive ? modalSize : SCREEN_SIZE.height - Header),
                        // height: (modalSize),
                        position: (Platform.OS === 'ios' && keyboardShown ? 'absolute' : 'relative'),
                        top: keyboardShown ? Header/2 : 0,
                        // top: keyboardShown ? Header : 0,
                        width: SCREEN_SIZE.width *.9,
                        zIndex: 1,
                        ...props.cardStyle
                        
                }}>
                    {renderTitle()}

                        {/* <View style={{flex: 1, borderColor: 'red', borderWidth: 1}}>
                            {props.children}
                        </View> */}
                    <ScrollView
                        maintainVisibleContentPosition={{
                            minIndexForVisible: 0,
                            autoscrollToTopThreshold: 10,
                        }}
                        contentContainerStyle={{
                            flexGrow: 1,
                            ...props.containerStyle
                        }}
                        keyboardShouldPersistTaps='handled'
                        // keyboardDismissMode='on-drag'
                    >
                        {props.children}
                    </ScrollView>
                    {renderButtons()}
                </Card>
            </View>
        </Modal>
    )
}

export default memo(CustomModal)