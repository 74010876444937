import { useDispatch, useSelector } from "react-redux"
import Card from "./Card"
import RadioButton from "./RadioButton"
import { RootState } from "../store"
import { updateUserDataProperty } from "../store/slices/userData"
import Fire from "../Fire"
import { SCREEN_SIZE, filterTypes } from "../Constants"
import { Text, View, ScrollView} from "react-native"
import capitalizeFirstLetter from "../helpers/capitalizeFirstLetter"
import confirmDecision from "../helpers/confirmDecision"
import { useState } from "react"
import catchErrorForSentry from "../helpers/catchSentryError"
import scaleFontSize from "../helpers/scaleFontSize"

const AccountTypeEditor = ()=>{
    const userData = useSelector((state: RootState)=>state.userData);
    const isAccount = userData.accountType === 'account';
    const [prevAccountType, setPrevAccountType] = useState(isAccount ? userData.establishmentType : userData.accountType);
    const dispatch = useDispatch();
    const onSelect = async (type: string)=>{
        setPrevAccountType(userData.accountType);
        const result = await confirmDecision(
            `Are you sure you'd like to change your account to ${capitalizeFirstLetter(type)}?`,
            `When your account type is changed, the layout of your account will follow suit. You can switch back at any time.`
        )
        if(!result) return;

        if(isAccount){
            await Fire.updateUserDataRoute(`${userData.uid}`, {establishmentType: type});
            dispatch(updateUserDataProperty({property: 'establishmentType', value:type}));
            return;
        }
        //Update user data
        await Fire.updateUserDataRoute(`${userData.uid}`, {accountType: type});
        //Copy data into new location
        // await Fire.setRoute(`public${capitalizeFirstLetter(type)}Data`, userData);
        //Delete old data
        // await Fire.deleteDataAtRoute(`public${capitalizeFirstLetter(prevAccountType)}Data`);
        //Update local state
        dispatch(updateUserDataProperty({property: 'accountType', value:type}));
    }

    const renderRadioButtons = ()=>{
        const types = ["user", "staff", "entertainment"];
        const TYPES = isAccount ? filterTypes : types;
        return TYPES.map((type: string)=>{
            return(
                <View key={type}
                    style={{
                        ...(isAccount ? {width: '20%', } : {flex: 1} ),
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',

                        // borderWidth: 1,
                        // borderColor: 'red'
                    }}>
                        <RadioButton
                            style={{alignSelf: 'center'}}
                            selected={isAccount ? userData.establishmentType === type : userData.accountType === type}
                            onSelect={()=>onSelect(type)}
                        />
                        <Text style={{flexWrap: "wrap", fontSize: isAccount ? scaleFontSize(10) : scaleFontSize(12), textAlignVertical: 'center', textAlign: 'center'}}>
                            {capitalizeFirstLetter(type)}
                        </Text>
                </View>
            )
        })
    }

    return(
            <Card style={{
                height: SCREEN_SIZE.height * .15,
                width: '100%',
                alignItems: 'center',
                padding: 10,
                marginVertical: 2.5,

                // borderColor: 'blue',
                // borderWidth: 1,
            }}>
                <Text style={{
                    alignSelf: 'center',
                    fontSize: scaleFontSize(20),
                    fontWeight: '500',
                    marginBottom: SCREEN_SIZE.height * .005
                }}>
                    Account Type
                </Text>
                {
                isAccount ?
                    <ScrollView horizontal={true}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        contentContainerStyle={{
                            width: '100%',
                            height: '100%',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',

                            // borderColor: 'red',
                            // borderWidth: 1
                        }}>
                        {renderRadioButtons()}
                    </ScrollView>
                    :
                    <View style={{
                        width: '100%', height: '100%',
                        flexDirection: 'row', justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        {renderRadioButtons()}
                    </View>
                }
            </Card>
    )
}

export default AccountTypeEditor