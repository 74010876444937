import { useState, useEffect } from 'react';
import { Dimensions, Platform } from 'react-native';

export default function useScreenSize() {
  const getScreenSize = () => {
    return Platform.OS === 'web' ? Dimensions.get('window') : Dimensions.get('screen');
  };

  const [screenSize, setScreenSize] = useState(getScreenSize());

  useEffect(() => {
    const onChange = () => {
      setScreenSize(getScreenSize());
    };

    if (Platform.OS === 'web') {
      window.addEventListener('resize', onChange);
    }

    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('resize', onChange);
      }
    };
  }, []);

  return screenSize;
}
