import { useHeaderHeight } from '@react-navigation/elements';
// import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { SafeAreaView, useSafeAreaInsets  } from 'react-native-safe-area-context';
import { EDGES, SCREEN_SIZE, isDeviceOld } from '../Constants';
import { Platform, View } from 'react-native';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import Constants from 'expo-constants';



export function Screen(props: any) {
  const {children, style, isHeaderShown = true} = props;
  const headerHeight = useHeaderHeight();
  const edges = ['left', 'right'];

  if (headerHeight === 0) edges.push('top');

  const insets = useSafeAreaInsets();
  const {top, bottom, left, right} = insets;
  const isiOS = Platform.OS === 'ios';

  const calculateMarginTop = ()=>{
    if(isiOS){
      const statusBarHeight = isDeviceOld ? Constants.statusBarHeight/2 : 0;
      return isDeviceOld ? (isHeaderShown ? top - statusBarHeight : 0) : top - Constants.statusBarHeight;
    }
    return isDeviceOld ? 0 : (isHeaderShown ? top - Constants.statusBarHeight/2 : 0);
  }

  return (
    <View style={{
      flex: 1,
      // ...(isDeviceOld ? {marginTop:  isHeaderShown ? top + statusBarHeight : 0} : {marginTop: 0}),
      //@ts-ignore
      marginTop: Platform.isPad ? top * 2 : calculateMarginTop(),
      // // marginTop: top - Constants.statusBarHeight/2,
      // paddingBottom: 0,
      // // paddingBottom: isDeviceOld ? 0 : bottom,
      backgroundColor: 'transparent',

      // borderColor: 'red',
      // borderWidth: 1,
      
      ...style
    }}>
      {children}
    </View>
  );
}