import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../../Interfaces';

export const initialState: UserData = {
  type: '',
  accountType: '',
  pushNotificationsOn: false,
  username: '',
  displayName: '',
  website: '',
  uid: '',
  email: '',
  bio: '',
  profilePictureKey: false,
  coverPhotoKey: false,
  uidsFollowingUser: {},
  uidsUserFollows: {},
  phoneNumber: '',
  facebookUrl: '',
  instagramUrl: '',
  pictureKeys: {},
  entertainmentPictureKeys: {},
  address: '',
  coords: {
      longitude: 0,
      latitude: 0,
  },
  dailySpecials: {},
  weeklySpecials: {},
  monthlySpecials: {},
  inTheBiz: {},
  dealOne: {},
  dealTwo: {},
  monthlySpecialsImageKey: '',
  schedule: {
      Sunday: {
          hours: {
              openingHours: 0,
              closingHours: 0,
          },
          hasHours: false,
      },
      Monday:  {
          hours: {
              openingHours: 0,
              closingHours: 0,
          },
          hasHours: false,
      },
      Tuesday:  {
          hours: {
              openingHours: 0,
              closingHours: 0,
          },
          hasHours: false,
      },
      Wednesday:  {
          hours: {
              openingHours: 0,
              closingHours: 0,
          },
          hasHours: false,
      },
      Thursday:  {
          hours: {
              openingHours: 0,
              closingHours: 0,
          },
          hasHours: false,
      },
      Friday:  {
          hours: {
              openingHours: 0,
              closingHours: 0,
          },
          hasHours: false,
      },
      Saturday:  {
          hours: {
              openingHours: 0,
              closingHours: 0,
          },
          hasHours: false,
      },
  }
}

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserData: (state: any, action: PayloadAction<any>) => {
      return action.payload;
    },
    clearUserData: () => {
      return initialState;
    },
    updateUserDataProperty: (state: any, action: PayloadAction<{ property: string; value: any }>) => {
      const { property, value } = action.payload;
      state[property] = value;
    },
    updateUidsUserAdmins: (state: any, action: PayloadAction<{ property: string; value: any }>) => {
      const { property, value } = action.payload;
      if (!state.uidsUserFollows) {
        state.uidsUserAdmins = {};
      }
      state.uidsUserAdmins[property] = value;
    },
    updateWhoUserFollows: (state: any, action: PayloadAction<{ property: string; value: any }>) => {
      const { property, value } = action.payload;
      if (!state.uidsUserFollows) {
        state.uidsUserFollows = {};
      }
      state.uidsUserFollows[property] = value;
    },
    updateUserScheduleHasHours: (state: any, action: PayloadAction<{ day: string, hasHours: boolean }>) => {
      const { day, hasHours } = action.payload;
      state.schedule[day].hasHours = hasHours;
    },
    updateOpeningHours: (state: any, action: PayloadAction<{ day: string, hours: number }>) => {
      const { day, hours } = action.payload;
      if(!state.schedule[day].hours.openingHours) state.schedule[day].hours.openingHours = "";
      state.schedule[day].hours.openingHours = hours;
    },
    updateClosingHours: (state: any, action: PayloadAction<{ day: string, hours: number }>) => {
      const { day, hours } = action.payload;
      if(!state.schedule[day].hours.closingHours) state.schedule[day].hours.closingHours = "";
      state.schedule[day].hours.closingHours = hours;
    },
  },
});

export const { setUserData, clearUserData, updateUserDataProperty, updateUidsUserAdmins, updateUserScheduleHasHours, updateOpeningHours, updateWhoUserFollows, updateClosingHours } = userDataSlice.actions;

export default userDataSlice.reducer;