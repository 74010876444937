import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import ensureProtocol from "../helpers/ensureProtocol";
import Fire from "../Fire";
import Toast from "react-native-toast-message";
import { updateUserDataProperty } from "../store/slices/userData";
import ValidateInput from "./ValidateInput";
import { View } from "react-native";
import scaleFontSize from "../helpers/scaleFontSize";
import { COLORS, errorMessages, regexPatterns } from "../Constants";
import CustomButton from "./CustomButton";
import { useState } from "react";
import showToast from "../helpers/showToast";

const DescriptionEditor = (props: any) => {
    const { platformName, state, setState, onSuccess=false, onFailure=false} = props;
    const userData = useSelector((state: RootState) => state.userData);
    //@ts-ignore
    const [description, setDescription] = useState(userData.description || "");
    const dispatch = useDispatch();

    const onUpdateDescription = () => {
        if (description.length > 100) {
            showToast(`Description can't be more than 100 characters.`, false);
            return;
        }

        //Updating database
        Fire.updateUserDataRoute(`${userData.uid}`, { description: description })
        .then((success: any) => {
            // Display success toast
            onSuccess && onSuccess();
           showToast(`Your description has been updated!`, true);
        })
        .catch((error) => {
            // Display error toast
            onFailure && onFailure();
            showToast(`Failed to update your description. Please try again.`, false);
        });

        //Updating Local Data
        dispatch(updateUserDataProperty({ property: 'description', value: description }));
    };

    return (
        <View style={{ width: '100%' }}>
            <ValidateInput
                multiline={true}
                inputStyle={{
                    height: 100,
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    borderRadius: 10,
                }}
                style={{marginBottom: 10}}
                errorMessage={"Your description cannot be longer than 100 characters"}
                errorName={'descriptionErrorFlag'}
                // onInputChange={(value: string)=>setState((prevState: any)=>{prevState.website = value; return prevState;})}
                // onInputChange={(value: string)=>setUrl(value)}
                regex={regexPatterns.description}
                placeholder={`Describe your establishment`}
                defaultValue={description}
                onInputChange={setDescription}
                // defaultValue={state[urlKey]}
                label={`Establishment Description`}
            />
            <CustomButton
                onPress={onUpdateDescription}
                title={"Submit"}
                textStyle={{
                    color: 'white',
                    fontWeight: '500', 
                    fontSize: scaleFontSize(12.5),
                }}
                style={{
                    backgroundColor: COLORS.primaryBlue,
                    alignSelf:'flex-end',
                    // height: '25%',
                    padding:10,
                    width: '25%',
                    minWidth: 100,
                    borderRadius: 10,
                }}
            />
        </View>
    );
};

export default DescriptionEditor;
