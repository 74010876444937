function formatPhoneNumber(phoneNumberString: any) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{1})?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      // removing '+1' from the beginning, so only returning the formatted part of the number
      return '(' + match[2] + ') ' + match[3] + ' ' + match[4];
    }
    return null;
}

export default formatPhoneNumber