import Fire from '../Fire';
// import getPublicUserDataAsync from '../queryFunctions/getPublicUserDataAsync';

const sendToProfileScreen = async (uid: string, push: Function)=>{
    const profileData = await Fire.getUserData(uid);
    // console.log("Profile UID: ", uid);
    // console.log("Profile Data: ", uid);

    let profileType = profileData.accountType === 'account' ? 'AccountProfile' : 'UserProfile';

    push(profileType, {profileData, displayName: profileData.displayName, pushed: true});
}

export default sendToProfileScreen