import { daysOfTheWeek } from "../Constants";
import formatBusinessHours from "./formatBusinessHours";
import getNextAvailableTime from "./getNextAvailableTime";
import isBusinessEverOpen from "./isBusinessEverOpen";
import isBusinessOpenNow from "./isBusinessOpenNow";
import testIsBusinessEverOpen from "./testIsBusinessEverOpen";

function getBusinessStatus(schedule: any, staff: boolean = false, businessName: string = "") {
    const day = new Date().getDay();
    const today = daysOfTheWeek[day];
    
    // Check if business has any set hours
    if(!isBusinessEverOpen(schedule)) return {status: null, text: `No ${staff ? 'working' : 'business'} hours set yet`};
    
    // Check if today's schedule is set
    if(!schedule[today]) return {status: false, text: `${staff ? 'Off' : 'Closed'} until ${getNextAvailableTime(schedule)}`};
    
    const { hasHours, hours } = schedule[today];
    
    // Check if today's hours are set
    if(!hours || !hours.openingHours || !hours.closingHours) return {status: false, text: `${staff ? 'Off' : 'Closed'} until ${getNextAvailableTime(schedule)}`};
    
    const {openingHours, closingHours} = hours;
    if (!hasHours) return {status: false, text: `${staff ? 'Off' : 'Closed'} until ${getNextAvailableTime(schedule)}`};

    const staffText = businessName ? `Working at ${businessName}` : 'Working';
    if(isBusinessOpenNow(openingHours, closingHours)) return {status: true, text: `${staff ? staffText : 'Open'} until ${formatBusinessHours(hours.closingHours)}`};
    return {status: false, text: `${staff ? 'Off' : 'Closed'} until ${getNextAvailableTime(schedule)}`}
}

export default getBusinessStatus;