import { Text, View } from "react-native";
import React from "react";
import { COLORS, SCREEN_SIZE, daysOfTheWeek } from "../Constants";
import Card from "./Card";
import addLeadingZero from "../helpers/addLeadingZero";
import getStandardHours from "../helpers/getStandardHours";
import isBusinessOpenNow from "../helpers/isBusinessOpenNow";
import isWeb from "../helpers/isWeb";
import scaleFontSize from "../helpers/scaleFontSize";


const BusinessHours = (props: any)=>{
    const {
        schedule,
        isStaff = false,
    } = props;

    const isBusinessEverOpen = React.useMemo(()=>{
        if(!schedule) return false;
        return daysOfTheWeek.some((day: string)=>{
            if(!schedule[day]) return false;
            if(!schedule[day].hasHours) return false;
            const {hasHours} = schedule[day];
            if(hasHours) return true;
            else return false;
        })
    }, [daysOfTheWeek, schedule])

    if(!isBusinessEverOpen){
        console.log("Business is never open");
        return(
            <Card style={{flex:1, alignContent: 'center', justifContent: 'center'}}>
                <Text style={{
                        fontWeight: '500',
                        fontSize: scaleFontSize(25)
                    }}>
                        This {isStaff ? "staff" : "business"} has not setup their hours yet!
                    </Text>
            </Card>
        )
    }

    const TimeDisplay=(props: any)=>{
        const {date} = props;
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const isPM = hours >= 12;
      
        const period = isPM ? 'PM' : 'AM';
        return(
            <Card style={{
                // flex: .80/2,
                // width: isWeb() ? 100 : 80,
                width: SCREEN_SIZE.width * .25,
                borderRadius: 0,
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center'
            }}>

                    <View style={{
                        height: '100%',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: 'center',
                    }}>
                        <Text style={{
                            textAlignVertical: 'center', textAlign: 'center', fontSize: scaleFontSize(20), fontWeight: '500',
                            // borderBottomWidth: isHours ? 1 : 0,
                            // borderBottomColor: 'black',
                            // paddingBottom: isHours ? 1 : 0,
                            // textDecorationLine: isHours ? 'underline' : 'none',
                        }}>
                            {getStandardHours(hours)}
                        </Text>
                        <Text style={{alignSelf: 'center', backgroundColor: COLORS.primaryBgColor}}>
                            :
                        </Text>
                    </View>
                    <Text style={{
                        textAlignVertical: 'center', textAlign: 'center', fontSize: scaleFontSize(20), fontWeight: '500'}}>
                        {addLeadingZero(minutes)}
                    </Text>
                    <Text style={{fontSize: scaleFontSize(20), fontWeight: '500', textAlign: 'center', marginLeft: 0}}>
                        {period}
                    </Text>
            </Card>
        )
    }

    return(
        <View style={{
            flex: 1,
            width: isWeb() ? '80%' : '100%',
            justifyContent: 'space-around',
            alignItems: 'center',
            alignSelf: 'center'
        }}>
            <View style={{
                // borderColor: 'red', borderWidth: 1,
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignItems: 'center',
                marginVertical:10
            }}>
                {/* <View style={{
                    flex: 2/5,
                    width: '100%',
                    borderColor: 'red',
                    borderWidth: 1,
                    flexDirection: 'row',
                }}> */}
                    <Text style={{
                        textAlign: 'center',
                        width: '100%',
                        // borderColor: 'red',
                        // borderWidth: 1,
                        fontWeight: '500',
                        fontSize: scaleFontSize(25),
                        marginBottom: 20,
                    }}>
                        {isStaff ? "Working Hours" : "Business Hours"}
                    </Text>
                {/* </View> */}
            </View>
            {
                daysOfTheWeek.map((day: any)=>{
                    const {hasHours, hours} = schedule ? schedule[day] : {hasHours: false, hours: null};
                    let openingTime: Date;
                    let closingTime: Date;
                    if (hours && hours.openingHours) openingTime = new Date(hours.openingHours);
                    else {
                        openingTime = new Date();
                        openingTime.setHours(9, 0);
                    }
                    if (hours && hours.closingHours) closingTime = new Date(hours.closingHours);
                    else {
                        closingTime = new Date();
                        closingTime.setHours(17, 0);
                    }
                    // const isOpen  = isBusinessOpenNow(openingTime.getTime(), closingTime.getTime());
                    return(
                        <View style={{flex: 1, width: '100%', flexDirection: 'row', alignItems: 'center', marginVertical:10}} key={day}>
                            <View style={{flex: 2/5, width: '100%', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center'}}>
                                <Text style={{width: 75, fontWeight: '500'}}>{isWeb() ? day : day.slice(0,3)}</Text>
                                <Text style={{
                                    width: 75,
                                    color: hasHours ? COLORS.greenAccent : COLORS.primaryRed
                                }}>
                                        {
                                            hasHours ?
                                                isStaff ? "Working" : "Open"
                                                :
                                                isStaff ? "Off" : "Closed"
                                        }
                                </Text>
                            </View>
                            <View style={{flex: 3/5, height: '100%', width: '100%', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'row'}}>
                                { hasHours ? 
                                    <>
                                        <TimeDisplay date={openingTime}/>
                                        <TimeDisplay date={closingTime}/>
                                    </>
                                :
                                    <></>
                                    // <View style={{flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                    //     <Text style={{color: '#999'}}>{isStaff ? "Off" : "Working"}</Text>
                                    // </View>
                                }
                            </View>
                        </View>
                    )
                })
            }
        </View>
    )
}


export default BusinessHours
