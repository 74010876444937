import catchErrorForSentry from './catchSentryError';
import { SERVER_URL } from '../Constants';

// Shared function to check if a given latitude and longitude is in Florida
const isLatLngInFlorida = async (latitude: number, longitude: number) => {
  try {
    const response = await fetch(`${SERVER_URL}/api/reverse-geocode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ latitude, longitude }),
    });

    if (!response.ok) {
      console.error('Failed to fetch reverse geocoding data');
      return false;
    }

    const data = await response.json();

    const stateComponent = data.addressComponents.find((component: any) =>
      component.types.includes('administrative_area_level_1')
    );

    if (stateComponent) {
      return stateComponent.long_name === 'Florida' || stateComponent.short_name === 'FL';
    } else {
      console.log("State not found in reverse geocoding response");
      return false;
    }
  } catch (error) {
    console.error("Error checking if lat/lng is in Florida:", error);
    catchErrorForSentry(error);
    return false;
  }
};

export default isLatLngInFlorida;