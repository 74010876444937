import { Text, View } from "react-native"
import Card from "../Card"
import scaleFontSize from "../../helpers/scaleFontSize"
import formatBusinessHours from "../../helpers/formatBusinessHours"
import day from 'dayjs'
import TouchableContainer from "../TouchableContainer"


const formatDate = (date: Date)=>{
    return day(date).format('MM/DD');
}

const GigAdder = (props: any)=>{
    const {
        onSelectDate,
        selectedDate,
        onSelectStartTime,
        selectedStartTime,
        onSelectEndTime,
        selectedEndTime,
        onSelectEmployer,
        selectedEmployer,
        onSelectLocation,
        selectedLocation,
    } = props;
    return(
        <Card key={Math.random()} style={{
            width: '100%',
            padding: 10,
        }}>
            <Text style={{
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: scaleFontSize(10),
            }}>
                {`Tap to select a date, time and employer or location`}
            </Text>
            <View style={{
                width: '100%',
                flexDirection: 'column',
                alignContent: 'flex-start',
                justifyContent: 'space-between',
                alignItems: 'center',
                // marginVertical: 5,
                padding: 5,
            }}>

                <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-around', marginVertical: 5}}>
                    <Text onPress={onSelectDate} style={{fontSize: scaleFontSize(14), textDecorationLine: 'underline'}}>
                        {selectedDate ? formatDate(selectedDate) : 'Date'}
                    </Text>
                    <Text onPress={onSelectStartTime} style={{fontSize: scaleFontSize(14), textDecorationLine: 'underline'}}>
                        {selectedStartTime ? formatBusinessHours(selectedStartTime) : 'Start'}
                    </Text>
                    <Text onPress={onSelectEndTime} style={{fontSize: scaleFontSize(14), textDecorationLine: 'underline'}}>
                        {selectedEndTime ? formatBusinessHours(selectedEndTime) : 'End'}
                    </Text>
                </View>
                <View style={{flexDirection: 'row', width: '100%', justifyContent: 'space-around', marginVertical: 5}}>
                    <TouchableContainer onPress={onSelectEmployer}>
                        <Text style={{
                            // color: '#fff',
                            textAlignVertical: 'center',
                            fontSize: scaleFontSize(14),
                            textDecorationLine: selectedEmployer ? 'none' : 'underline',
                        }}>
                            {selectedEmployer ? selectedEmployer.displayName || selectedEmployer.username : 'Employer'}
                        </Text>
                    </TouchableContainer>
                </View>
                <TouchableContainer style={{marginVertical: 5}} onPress={onSelectLocation}>
                    {
                        selectedLocation &&
                        <Text style={{alignSelf: 'center', fontSize: 14, textDecorationLine: 'underline'}}>
                            Selected Location
                        </Text>
                    }
                    <Text style={{
                        // color: '#fff',
                        // flex: 1, 
                        textAlignVertical: 'center',
                        fontSize: scaleFontSize(14),
                        textDecorationLine: selectedLocation ? 'none' : 'underline',
                        // width: '30%',
                    }}>
                        {( selectedLocation && selectedLocation.address) ? selectedLocation.address : 'Location'}
                    </Text>
                </TouchableContainer>
            </View>
        </Card>
    )
}

export default GigAdder;