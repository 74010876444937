// import { useFonts } from "expo-font"
import { Image, Linking, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import Card from "../../components/Card"
import { COLORS, SCREEN_SIZE } from "../../Constants"
// import Colors from "../../Constants/Colors"
import ActivityIndicator from '../../components/ActivityIndicator';
import CustomButton from "../../components/CustomButton";
import { SafeAreaView } from 'react-native-safe-area-context';
import React, { useEffect, useRef, useState } from 'react';
import Fire from "../../Fire";
import TouchableBackButton from "../../components/TouchableBackButton";
import catchErrorForSentry from "../../helpers/catchSentryError";
import { useDispatch } from "react-redux";
import { clearUserData, setUserData, updateUserDataProperty } from "../../store/slices/userData";
import TouchableContainer from "../../components/TouchableContainer";
import scaleFontSize from "../../helpers/scaleFontSize";
import { SimpleLineIcons, AntDesign, FontAwesome5, Ionicons, MaterialIcons } from "@expo/vector-icons";

import useScreenSize from "../../helpers/getScreenSize";
import isMobileListener from "../../helpers/isMobileListener";
import { SelectCountry } from "react-native-element-dropdown";
import { useHeaderHeight } from "@react-navigation/elements";
import { openURL } from "expo-linking";
import ensureProtocol from "../../helpers/ensureProtocol";
import isWeb from "../../helpers/isWeb";

// import Colors from "../../fonts/TT_Norms/TTNorms-"
const Header = (props: any) =>{
    const screenSize = useScreenSize();
    const isMobile = isMobileListener();
    const dispatch = useDispatch();
    const windowSize = useWindowDimensions();
    // const headerHeight = useHeaderHeight();
    const {navigation, isLoggedIn, route} = props
    const [processing, setProcessing] = React.useState(false);
    // let [fontsLoaded] = useFonts({
    //     'TT-Norms': require('../../fonts/TT_Norms/TTNorms-Regular.otf'),
    // });
    // if(!fontsLoaded) return <ActivityIndicator/>;
    const HeaderButtons = ()=>{
        const dropDownRef = useRef(null);
        const buttons = [
            {title: "Home",url: "https://alwayslocal.net/"},
            {title: "Establishments", url: "https://alwayslocal.net/establishments/"},
            {title: "Locals", url: "https://alwayslocal.net/locals/"},
            {title: "Brands", url: "https://alwayslocal.net/brands/"},
            {title: "About", url: "https://alwayslocal.net/about/"},
            {title: "Help", url: "https://alwayslocal.net/help/"}
        ];
        console.log("Is Mobile? : ", isMobile);
        if(isMobile){
            return(
                <TouchableContainer style={{
                    // borderColor: 'red', borderWidth: 1
                }} onPress={()=>{
                    //@ts-ignore
                    (dropDownRef.current && dropDownRef.current.open && dropDownRef.current.open())

                }}>
                    <SimpleLineIcons
                        style={{
                            marginRight: 10,
                        }}
                        name={"menu"}
                        size={25}
                        color={COLORS.primaryBlue}
                    />
                    <SelectCountry
                        ref={dropDownRef}
                            mode={isMobile ? "modal" : "default"}
                            style={{
                                // margin: 0,
                                // top: isMobile ? 100 : 0,
                                height: 1,
                                width: 1,
                                backgroundColor: 'transparent',
                                // borderRadius: 0,
                                // paddingHorizontal: 0,
                            }}
                            containerStyle={{
                                margin: 0,
                                maxHeight: 500,
                                flex: 1,
                                width: screenSize.width*.75,
                                position: 'absolute',
                                // bottom: isMobile ? '0%' : '33.25%',
                                top: windowSize.height * .135,
                                // backgroundColor: 'transparent',
                                // borderRadius: 0,
                                // paddingHorizontal: 0,
                            }}
                            selectedTextProps={{style: {display: 'none'}}}
                            selectedTextStyle={styles.selectedTextStyle}
                            placeholderStyle={styles.placeholderStyle}
                            imageStyle={styles.imageStyle}
                        //  iconStyle={styles.iconStyle}
                            iconStyle={{display: 'none'}}
                            maxHeight={200}
                            //@ts-ignore
                            value={{}}
                            //@ts-ignore
                            data={[...buttons, {title: "Signup/Login", url: ()=>navigation.navigate('WebAuthPage')} ]}
                            valueField="url"
                            labelField="title"
                            imageField=""
                            placeholder="Select Employer"
                            searchPlaceholder="Search..."
                            onChange={(e: any) => {
                                // if(typeof e.url === "string") return Linking.openURL(e.url);
                                if(typeof e.url === "string") return window.location.href = e.url;
                                e.url();
                            }}
                        />
                </TouchableContainer>
            )
        }
        return(
            <View style={{
                width: '33%',
                minWidth: 625,
                flexDirection: 'row',
                justifyContent: 'space-around',
                // borderColor: 'orange', borderWidth: 2
            }}>
                {
                    buttons.map((button: any)=>{
                        const {title, url} = button;
                        return(
                            <TouchableContainer
                            key={title}
                            onPress={()=>{
                                window.location.href = url;
                            }}
                            style={{}}>
                                    <Text style={{
                                        fontSize: scaleFontSize(20),
                                        //@ts-ignore
                                        '--poppins': 'Poppins',
                                        textAlign: 'center',
                                        fontWeight: '400',
                                        // color: title === 'Home' ? COLORS.primaryBlue :  '#3E4A59'
                                        color: '#3E4A59'
                                        // color: title === 'Home' ? COLORS.primaryBlue :  '#838383'
                                    }}>
                                        {title} 
                                    </Text>
                            </TouchableContainer>
                        )
                    })
                }
            </View>
        )
    }

    const WebsiteLogo = ()=>{
        return(
            <TouchableContainer
            style={{
                // height: screenSize.height * .1,
                // display: 'none',
                // width: screenSize.width * .15,
                // minHeight: 500,
                // minWidth: 75,
                alignSelf: 'flex-start',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flex: 2/10,
                width: '100%',
                height: '100%',
                left: isMobile ? '5%' : 0
            }}
            onPress={()=>window.location.href = 'https://alwayslocal.net'}>
                <Image
                    style={{
                        width: '100%',
                        height: '100%',
                        alignSelf: 'flex-start',
                        objectFit: 'contain',
                        right: '10%',
                    }}
                    resizeMode='contain'
                    source={require('../../assets/alwayslocal-banner.png')}
                />
            </TouchableContainer>
        )
    }
    const CreateAccountButton = ()=>{
        if(processing) return <ActivityIndicator size="large" color={COLORS.primaryRed}/>;
        return(
            <CustomButton
                style={{
                    ...styles.ctaBtnStyle,
                    backgroundColor: 'transparent',
                    marginLeft: 10
                }}
                textStyle={{
                    '--poppins': 'Poppins',
                    fontSize: scaleFontSize(20),
                    fontWeight: '400',
                    color: '#3E4A59'
                }}
                title={isLoggedIn ? "Logout" : "Signup/Login"}
                onPress={()=>{
                    // console.log("Logging in");
                    if(isLoggedIn)return onToggleLogin();
                    navigation.navigate('WebAuthPage');
                }}
            />
        )
    }
    const onToggleLogin = ()=>{
            // console.log("Logging in");
            const page = isLoggedIn ? "Home" : "WebAuthPage"
            if(!isLoggedIn) return navigation.push(page);
            setProcessing(true);
            Fire.getAuth().signOut().then(function() {
                // Sign-out successful.
                console.log("Sign Out successful");
                setProcessing(false);
                //flushing redux datanot working
                //Note I think the problem was not using props. as illustrated below
                // setGlobalCurrentUserData({});
                // setGlobalCurrentUserData({});
                // setGlobalChatData({});
                dispatch(clearUserData());
                window.location.href = ensureProtocol('https://alwayslocal.net');
                // navigation.push(page)
            }).catch(function(error) {
                console.log("Error: ", error);
                console.log("Error signing out user JSON: ", JSON.stringify(error)); 
                catchErrorForSentry(error);
                alert("Error signing you out!")
                // Sentry.captureException("Error signing out user: ");
                // An error happened.
            });
    }
    const ToggleLoginButton = ()=>{
        return(
            <CustomButton
                style={styles.ctaBtnStyle}
                textStyle={{
                    fontSize: 20,
                    fontWeight: '600',
                    color: 'white'
                }}
                title={isLoggedIn ? "Logout" : "Login"}
                onPress={onToggleLogin}
            />
        )
    }
    const SocialBar = ()=>{
        const onEmailPress = ()=>window.location.href = `mailto:info@alwayslocal.net`;
        const onInstagramPress = ()=>window.location.href = `https://www.instagram.com/AlwaysLocalApp/`;
        const onFacebookPress = ()=>window.location.href = `https://www.facebook.com/AlwaysLocalApp/`;
        return(
            <View style={{
                backgroundColor: COLORS.primaryBlue,
                width: windowSize.width,
                height: windowSize.height * .050,
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                // borderColor: 'red',
                // borderWidth: 1,
                // paddingLeft: windowSize.width * .005,
            }}>
                <View style={{
                     width: '90%',
                     height: '100%',
                     flexDirection: 'row',
                     alignContent: 'center',
                     alignItems: 'center',
                     justifyContent: 'space-between',
                    //  borderColor: 'green',
                    //  borderWidth: 1,
                }}>
                    <TouchableContainer style={{alignItems: 'center', marginLeft: screenSize.width * .015, flexDirection: 'row'}} onPress={onEmailPress}>
                        <MaterialIcons name="email" size={25} color="white" />
                        <Text style={{marginLeft: 5, fontWeight: '500', color: 'white', textAlignVertical: 'center'}}>
                            Info@alwayslocal.net
                        </Text>
                    </TouchableContainer>
                    <View style={{flexDirection: 'row'}}>
                        <TouchableContainer style={{marginLeft: screenSize.width * .01}} onPress={onFacebookPress}>
                            <FontAwesome5 name="facebook" size={25} color="white" />
                        </TouchableContainer>
                        <TouchableContainer style={{marginLeft: screenSize.width * .01}} onPress={onInstagramPress}>
                            <AntDesign name="instagram" size={25} color="white" />
                        </TouchableContainer>
                    </View>
                </View>
            </View>
        )
    }
    const WebHeader = ()=>{
        return(
            <Card
                style={{
                    // flex: 1,
                    flexDirection: 'row',
                    height: windowSize.height * .11,
                    width: windowSize.width,
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    borderRadius:0,
                    // paddingHorizontal: screenSize.width*.025,

                    // borderColor: 'red',
                    // borderWidth: 1,
                    // borderBottomRadius: 5,
                }}>
                    
                    <WebsiteLogo/>
                    <View style={{
                        flex: 8/10,
                        height: '100%',
                        // width: screenSize.width * .85,
                        // minWidth: 250,
                        flexDirection: 'row',
                        alignSelf: 'center',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        // borderWidth: 10,
                        // borderColor: 'black',
                        paddingRight: 15,
                    }}>
                        <HeaderButtons/>
                        
                        {!isMobile && <View style={{
                            // borderColor: 'green', borderWidth: 1,
                            flexDirection: 'row', justifyContent: 'space-around'
                        }}>
                            {/* <ToggleLoginButton/> */}
                            <CreateAccountButton/>
                        </View>}
                    </View>
            </Card>
        )
    }
    return(
        <View style={{
            flexDirection: 'column',
            width: windowSize.width,
            height: windowSize.height * .135,
            // display: 'none',
            // borderColor: 'purple',
            // borderWidth: 1,
            backgroundColor: 'transparent'
        }}>
            <SocialBar/>
            <WebHeader/>
        </View>

    )
}

const styles = StyleSheet.create({
    ctaBtnStyle: {
    //   borderRadius: 30,
    //   backgroundColor: COLORS.primaryBlue,
      width: 120,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    dropdoown: {
        margin: 16,
        height: 50,
        width: 150,
        backgroundColor: '#EEEEEE',
        borderRadius: 22,
        paddingHorizontal: 8,
      },
      imageStyle: {
        display: 'none',
        width: 24,
        height: 24,
        borderRadius: 12,
      },
      placeholderStyle: {
        fontSize: scaleFontSize(14),
      },
      selectedTextStyle: {
        paddingVertical: 10,
        fontSize: scaleFontSize(18),
        marginLeft: '5%',
        textAlign: 'left',
        fontFamily: "'Open Sans,Arial,sans-serif'",
        color: '#666666',
        borderBottomWidth: 1,
        borderColor: 'rgba(0,0,0,.03)',
        fontWeight: '500',
      },
      iconStyle: {
        // display: 'none',÷
        width: 20,
        height: 20,
      },
  });

export default Header