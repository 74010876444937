import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import Fire from "../Fire";
import Toast from "react-native-toast-message";
import { updateUserDataProperty } from "../store/slices/userData";
import ValidateInput from "./ValidateInput";
import { View } from "react-native";
import scaleFontSize from "../helpers/scaleFontSize";
import { COLORS, errorMessages, regexPatterns } from "../Constants";
import CustomButton from "./CustomButton";
import { useState } from "react";
import isZipCodeInFlorida from "../helpers/isZipCodeInFlorida";

const ZipCodeEditor = (props: any) => {
    const { onSuccess=false, onFailure=false } = props;
    const userData = useSelector((state: RootState) => state.userData);
    //@ts-ignore
    const [ZipCode, setZipCode] = useState(userData.zipcode || "");
    const dispatch = useDispatch();

    const onChangeZipcode = async () => {

        const isZipInFlorida = await isZipCodeInFlorida(`${ZipCode}`);
        if(!isZipInFlorida) return alert("Sorry, this app is currently only available in Florida!")

        //Updating database
        Fire.updateUserDataRoute(`${userData.uid}`, { zipcode: ZipCode })
        .then((success: any) => {
            // Display success toast
            onSuccess && onSuccess();
            Toast.show({
                type: 'success',
                position: 'bottom',
                text1: 'Success',
                text2: `Your zipcode has been updated!`,
                visibilityTime: 2000,
                autoHide: false,
                topOffset: 30,
                bottomOffset: 40,
            });
        })
        .catch((error) => {
            // Display error toast
            onFailure && onFailure();
            Toast.show({
                type: 'error',
                position: 'bottom',
                text1: 'Error',
                text2: `Failed to update zipcode. Please try again.`,
                visibilityTime: 2000,
                autoHide: true,
                topOffset: 30,
                bottomOffset: 40,
            });
        });

        //Updating Local Data
        dispatch(updateUserDataProperty({ property: "zipcode", value: ZipCode }));
    };

    return (
        <View style={{ width: '100%' }}>
            <ValidateInput
                inputStyle={{
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    borderRadius: 10,
                }}
                style={{marginBottom: 10}}
                errorMessage={errorMessages.zipcodeError}
                errorName={'zipcodeErrorFlag'}
                // onInputChange={(value: string)=>setState((prevState: any)=>{prevState.website = value; return prevState;})}
                // onInputChange={(value: string)=>setZipCode(value)}
                regex={regexPatterns.zipcode}
                placeholder={`Enter your zipcode`}
                defaultValue={ZipCode}
                onInputChange={setZipCode}
                // defaultValue={state[urlKey]}
                label={`Zipcode`}
            />
            <CustomButton
                onPress={onChangeZipcode}
                title={"Submit"}
                textStyle={{
                    color: 'white',
                    fontWeight: '500', 
                    fontSize: scaleFontSize(12.5),
                }}
                style={{
                    backgroundColor: COLORS.primaryBlue,
                    alignSelf:'flex-end',
                    // height: '25%',
                    padding:10,
                    width: '25%',
                    minWidth: 100,
                    borderRadius: 10,
                }}
            />
        </View>
    );
};

export default ZipCodeEditor;
