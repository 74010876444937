import { useEffect, useState } from "react";
import BirthdayEditor from "../components/BirthdayEditor";
import { Screen } from "../components/Screen";
import promptUser from "../helpers/promptUser";
import { Platform } from "react-native";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Constants from 'expo-constants';
import { isDeviceOld } from "../Constants";

const BirthdayScreen = (props: any)=>{
    const { navigation, route } = props;
    const userData = useSelector((state: RootState)=> state.userData);
    const noBirthday = userData.birthday ? false : true;
    const { top } = useSafeAreaInsets();
    useEffect(()=>{
        if (!noBirthday) navigation.setOptions({ headerShown: true });
    }, []);
    // if(!noBirthday){
    //     return <BirthdayEditor screenName={'Birthday'} showModal={true} navigation={props.navigation} noBirthday={noBirthday} />
    // }
    return(
        <Screen style={(isDeviceOld ? {marginTop: top + Constants.statusBarHeight} : {marginTop: top})}>
            <BirthdayEditor screenName={'Birthday'} showModal={true} navigation={props.navigation} noBirthday={noBirthday} />
        </Screen>
    )
}
export default BirthdayScreen;