import React, { useEffect, useState } from 'react';
import { StyleSheet, Platform, TouchableWithoutFeedback, View, Text } from 'react-native';
import { connect, useSelector } from 'react-redux';
import isVarDefined from "../helpers/isVarDefined";
import isObjEmpty from "../helpers/isObjEmpty";
import { COLORS, SCREEN_SIZE } from '../Constants/'
import Fire from '../Fire';
import { RootState } from '../store';
import { NotificationInterface } from '../Interfaces';
import scaleFontSize from '../helpers/scaleFontSize';
const firebase = Fire.getFirebase();


const NotificationCount = (props: any)=>{

    const userData = useSelector((state: RootState)=>state.userData);
    const switchData = useSelector((state: RootState) => state.switchData);
    const [notificationCount, setNotificationCount] = useState(0)

    useEffect(()=>{
        if(!userData.uid) return setNotificationCount(0);
        const onNotificationDataChange = (newNotiData: any)=>{
            if(!newNotiData) return setNotificationCount(0);
            let notiCount = getNumberOfUnreadNotifications(newNotiData);
            setNotificationCount(notiCount);
        }
        let notificationRef = Fire.listenToRoute(`userNotifications/${userData.uid}`, onNotificationDataChange)
        return(
            Fire.off(notificationRef, "value", onNotificationDataChange)
        )

    }, [userData])


    const getNumberOfUnreadNotifications = (notifications: {[key: string]: NotificationInterface})=>{
      let notificationCount = 0;
      if(isVarDefined(notifications) && !isObjEmpty(notifications)){
        Object.values(notifications).forEach((notification: NotificationInterface)=>{
          if(!notification.read) notificationCount+=1;
        })
        return notificationCount;
      }
      else{
        return 0;
      }
    }
    const countSize = SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055
    return(
        notificationCount !== 0 ? 
        <View style={{
          
          backgroundColor: COLORS.primaryRed,
          height: countSize, width: countSize,
          borderRadius: countSize/2,
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          left: '50%',
          // bottom: 1,
          position: 'relative',
          zIndex: 1,
        }}>
            <Text style={{fontSize: scaleFontSize(13), color: '#fff'}}>
                {notificationCount}
            </Text>
        </View>
        :
        <></>
    )
}


export default NotificationCount;