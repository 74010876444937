import { Text } from "react-native";
import TouchableContainer from "./TouchableContainer";
import { COLORS } from "../Constants";
import scaleFontSize from "../helpers/scaleFontSize";

const FollowButton = (props: any)=>{

    const { onFollowPress, following, containerStyle } = props

    return(
        <TouchableContainer
            style={{
                alignSelf: 'center',
                top: 10, right: 10,
                borderRadius: 5,
                padding: 5,
                width: '30%',
                backgroundColor: (following ? '#000' : COLORS.primaryBlue),
                position: 'absolute',
                flexDirection: 'row',
                justifyContent: 'space-around',
                alignContent: 'center',
                ...containerStyle,
            }}
            onPress={()=>{
                onFollowPress(!following);
            }}
        >
            <Text style={{fontSize: scaleFontSize(15), color: 'white', textAlign: 'center'}}>
                {following ? 'Following' : 'Follow'}
            </Text>
        </TouchableContainer> 
    )
}

export default FollowButton