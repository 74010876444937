import { daysOfTheWeek } from "../Constants";
import { UserData } from "../Interfaces"; //TODO: Should use this.
import formatBusinessHours from "./formatBusinessHours";


function getNextAvailableTime(schedule: any) {
    const now = new Date();
    const todayIdx = now.getDay();

    const todayDayOfWeek = daysOfTheWeek[todayIdx];
    const todaysDate = schedule[todayDayOfWeek]
    // console.log("TODAY DAY OF WEEK: ", todayDayOfWeek)
    // console.log("TODAY SCHEDULE DATA: ", todaysDate)

    for (let i = todayIdx; i < todayIdx + 7; i++) { // Changed loop condition to cover the entire week
        const dayOfWeek = daysOfTheWeek[i % 7];
        // console.log("i: ", i);
        // console.log("Today Idx: ", todayIdx);
        const isToday = todayDayOfWeek === dayOfWeek;
        
        if(!schedule[dayOfWeek] || !schedule[dayOfWeek].hasHours || !schedule[dayOfWeek].hours || !schedule[dayOfWeek].hours.openingHours) continue;
        // console.log("Day of week Post: ", dayOfWeek);
        const openingTime = schedule[dayOfWeek].hours.openingHours;
        
        if(isToday && (now.getTime() > openingTime) ) {
            // console.log("IT IS TODAY!");
            // console.log("Day of week: ", dayOfWeek);
            // console.log("Is today: ", isToday);
            return `${formatBusinessHours(openingTime)}`;
        } else if(!isToday) {
            // console.log("Day of week: ", dayOfWeek);
            // console.log("Is today: ", isToday);
            // console.log("i: ", i);
            // console.log("todayIdx: ", todayIdx);
            const dayDiff = i - todayIdx;
            if(dayDiff === 1) {
                return `${isToday ? 'today' : 'tomorrow'} at ${formatBusinessHours(openingTime)}`;
            } else {
                return `${dayOfWeek} at ${formatBusinessHours(openingTime)}`;
            }
        }
    }
    
    // If none of the days in the current week is open, 
    // return the opening time for the same day in the next week
    const nextAvailableDay = daysOfTheWeek[todayIdx];
    return `Next week ${nextAvailableDay} at ${formatBusinessHours(schedule[nextAvailableDay].hours.openingHours)}`;
}

export default getNextAvailableTime;