import React from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import Card from '../components/Card';
import scaleFontSize from '../helpers/scaleFontSize';


const PrivacyPolicy = () => {
    return (
        <View style={{flex: 1, alignContent: 'center', alignItems: 'center', justifyContent: 'center'}}>
            <Card style={styles.container}>
                <ScrollView>
                    <Text style={{...styles.heading, alignSelf: 'center', fontSize: 20}}>Privacy Policy</Text>
                    <Text style={{...styles.heading, alignSelf: 'center'}}>Effective Date: August 21, 2023</Text>

                    <Text style={styles.heading}>Data Collection and Usage:</Text>
                    <Text style={styles.paragraph}>At AlwaysLocal, we respect your privacy and strive to provide a transparent and secure environment. We collect specific personal data to enhance the functionality and security of the app, including:</Text>

                    <View style={styles.list}>
                        <Text style={styles.listItem}><Text style={styles.bold}>Real-time Location:</Text> We track the user’s real-time location only when they are signed into AlwaysLocal. This tracking is integral to the app’s core functionality, allowing users to view the real-time locations of other members and initiate conversations.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>Email and Phone Number:</Text> Your email address and phone number are essential identifiers, aiding in user account management. They ensure unique user identification, especially if we need to address issues like misuse, potential bans, or communicate concerning app-related activities. This data helps promote safety and prevents any misuse.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>Conversations:</Text> Messages and conversations you have within the app are stored to provide a seamless communication experience.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>Camera Access:</Text> AlwaysLocal requires permission to your device’s camera and camera roll to facilitate the uploading of images.</Text>
                    </View>

                    <Text style={styles.paragraph}>Your personal data will not be shared with any third-party entities. Only the authorized personnel of AlwaysLocal can access this information, prioritizing user security and privacy.</Text>

                    <Text style={styles.heading}>User Rights and Access:</Text>
                    <Text style={styles.paragraph}>Every user has the right to:</Text>

                    <View style={styles.list}>
                        <Text style={styles.listItem}><Text style={styles.bold}>Access:</Text> You can request a detailed overview of the data associated with your account at any time.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>Rectification:</Text> If you believe any of your data is incorrect or incomplete, you can ask for corrections.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>Erasure:</Text> Should you wish to delete your account, you can request the erasure of all associated data.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>Blocking:</Text> If there are specific concerns or discrepancies, users can request their data be temporarily blocked.</Text>
                    </View>

                    <Text style={styles.heading}>Updates to Privacy Policy:</Text>
                    <Text style={styles.paragraph}>We may update our privacy policy occasionally. In such cases:</Text>

                    <View style={styles.list}>
                        <Text style={styles.listItem}><Text style={styles.bold}>Email Notification:</Text> We will send an email alerting users about any changes or updates.</Text>
                        <Text style={styles.listItem}><Text style={styles.bold}>In-app Notification:</Text> Users will receive a notification within the AlwaysLocal app highlighting the updated terms.</Text>
                    </View>

                    <Text style={styles.paragraph}>Your continuous use of AlwaysLocal following any modifications signifies your acceptance of the updated privacy policy. We encourage users to periodically review this policy to stay informed.</Text>

                    <Text style={styles.paragraph}>Thank you for trusting AlwaysLocal with your data. Our commitment is to ensure your privacy and provide a safe platform for all users.</Text>
                </ScrollView>
            </Card>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '90%',
        padding: 15,
    },
    paragraph: {
        marginBottom: 10,
    },
    heading: {
        fontWeight: 'bold',
        fontSize: 16,
        marginVertical: 10,
    },
    list: {
        paddingLeft: 20,
    },
    listItem: {
        marginBottom: 8,
    },
    bold: {
        fontWeight: 'bold',
    }
});

export default PrivacyPolicy;
