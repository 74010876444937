
import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Text, TouchableHighlight } from 'react-native';
import UserImage from './UserImage';
import Card from './Card';
import Fire from '../Fire'
import makeExcerpt from '../helpers/makeExcerpt';
import { COLORS, SCREEN_SIZE } from '../Constants';
import { MessageInterface } from '../Interfaces';
import { setChatData } from '../store/slices/chatData';
import { useDispatch } from 'react-redux';
import scaleFontSize from '../helpers/scaleFontSize';
import TouchableContainer from './TouchableContainer';

const MessengerBox = (props: any)=>{
    const {
        renderNotRespondedNotification,
        handleLongPress,
        messengerBoxData,
        unread,
        navigation,
        currentUserData,
        chatRoomType,
        toFollowers,
    } = props

    const [displayName, setDisplayName] = useState();
    useEffect(()=>{
        (async ()=>{
            if(toFollowers) return setDisplayName(messengerBoxData.name)
            const displayName = await (await Fire.getDataAtRoute(`publicUserData/${messengerBoxData.uid}/displayName`)).val();
            if(displayName) setDisplayName(displayName);
        })()
    }, []);

    const dispatch = useDispatch();

    const isLastMessageFromCurrentUser = ()=>{
        if(!messengerBoxData.messages) return false;
        let messages: MessageInterface[] = Object.values(messengerBoxData.messages);
        if(!messages.length) return;
        // console.log("Messages: ", messages);
        let lastMessage = messages[messages.length-1];
        // console.log("Last Message: ", lastMessage);
        let doesUidMatch = lastMessage.senderUID === currentUserData.uid;
        let doesEmailMatch = lastMessage.sender.toLowerCase() === currentUserData.email.toLowerCase()
        if(doesEmailMatch || doesUidMatch) return true;
        else return false;
    }
    const getLastMessageContents = ()=>{
        if(!messengerBoxData.messages) return "";
        let messages: MessageInterface[] = Object.values(messengerBoxData.messages);
        if(!messages.length) return;
        let lastMessage = messages[messages.length-1];
        return lastMessage.message;
    }

    const renderChatExcerpt = ()=>{

        let isLastMessageFromMe = isLastMessageFromCurrentUser();
        let lastMessageContents = getLastMessageContents();
        //TODO prompt the user to spark a conversation if no messages have been sent yet.
        if(!lastMessageContents) return;
        let message = JSON.stringify(makeExcerpt(lastMessageContents, 30)).replace(/\\n/g, ' ').replace(/"/g, '');
        return(
            <View style={{
                flexDirection: (isLastMessageFromMe ? 'row-reverse' : 'row'),
                justifyContent: 'flex-start',
                width: '100%',
            }}>
                {/* 
                //@ts-ignore */}
                <UserImage
                    uri={''}
                    style={{width: 25, height: 25, borderRadius: 25/2, alignSelf: 'center', marginHorizontal: 10}}
                    uid={isLastMessageFromMe ? currentUserData.uid : messengerBoxData.uid }
                />
                <View 
                    style={{
                        backgroundColor: COLORS.primaryGray,
                        borderRadius: 20,
                        padding: 12.5,
                        borderColor: COLORS.primaryBlue,
                        borderWidth: .5,
                        // maxHeight: '75%'
                    }}>
                    <View style={{flex: 0, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={{...styles.standardMessengerBoxFont, }}>
                            {message}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }

    return(
        <Card
            style={{...styles.messengerBox,
                borderWidth: 2,
                borderColor: (unread ? 'rgb(22, 128, 144)' : '#fff'),
            }}
            key={displayName || messengerBoxData.name}>
            <TouchableContainer
                style={{
                    // borderWidth: 2,
                    // borderColor: '#000',
                    borderRadius: 10,
                    height: '100%',
                    width: '100%',
                    justifyContent: 'center',
                }}
                onLongPress={()=>
                    !toFollowers && handleLongPress(messengerBoxData)
                }
                onPress={
                    ()=>{
                        //Set messenger box to read on click
                        if(unread){
                            Fire.updateChatDataRoute(`${currentUserData.uid}/${messengerBoxData.uid}`, {status: "read"})
                        }
                        setChatData(messengerBoxData);
                        navigation.push("ChatScreen", {
                            ...messengerBoxData,
                            chatRoomType: "chatRooms",
                            toFollowers,
                        });
                    }}>
                <>
                    <View style={{alignSelf:'flex-start', flexDirection: 'row', alignItems: 'center'}}>
                        {/* 
                        //@ts-ignore */}
                        <UserImage
                            appLogo={toFollowers}
                            style={{width: 50, height: 50, borderRadius: 25, alignSelf: 'center', marginHorizontal: 10}}
                            uid={messengerBoxData.uid}
                            /* username={messengerBoxData.name} */
                        />
                        <Text style={styles.messengerBoxTitle}>
                            {displayName || messengerBoxData.name}
                        </Text>
                    </View>
                    {renderChatExcerpt()}
                    {/* {(!unread && renderNotRespondedNotification(messengerBoxData))} */}
                    {renderNotRespondedNotification(messengerBoxData)}
                </>
            </TouchableContainer>
        </Card>
    )
}

const styles = StyleSheet.create({
    messengerBox: {
        height: SCREEN_SIZE.height*.20,
        marginTop: 10,
        borderWidth: 1,
        borderColor: 'red',
        justifyContent: 'center',
        alignContent: 'center',
        // display: 'none',
    },
    messengerBoxTitle: {
        textAlign: 'center',
        fontSize: scaleFontSize(15)
    },
    standardMessengerBoxFont: {
        textAlign: 'center',
        color: '#000',
        fontSize: scaleFontSize(13),
    }
})

export default MessengerBox