import { View } from "react-native";
import Input from "./Input";
import CustomButton from "./CustomButton";
import { COLORS } from "../Constants";
import Fire from "../Fire";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { updateUserDataProperty } from "../store/slices/userData";
import scaleFontSize from "../helpers/scaleFontSize";
import showToast from "../helpers/showToast"; // Importing the toast helper function
import { useEffect, useState } from "react";

const ChangeAccountName = () => {
    const [accountName, setAccountName] = useState(""); // Local state
    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);

    useEffect(() => {
        setAccountName(userData.displayName);
    }, [userData.displayName]);

    const onChangeAccountName = async () => {
        if (userData.displayName === accountName) {
            showToast("You already have that name!", false);
            return;
        }
        try {
            // Updating database
            await Fire.updateUserDataRoute(`${userData.uid}`, { displayName: accountName });
            // Updating Local Data
            dispatch(updateUserDataProperty({ property: 'displayName', value: accountName }));
            showToast("Display name updated successfully!", true);
        } catch (error) {
            showToast("Failed to update display name.", false);
        }
    };

    return (
        <View style={{ width: '100%' }}>
            <Input
                label={"Display Name"}
                defaultValue={accountName}
                onInputChange={(value: string) => setAccountName(value)}
                style={{ marginBottom: 10 }}
                inputStyle={{ borderColor: COLORS.primaryBlue, borderRadius: 10, borderWidth: 1, }}
            />
            <CustomButton
                onPress={onChangeAccountName}
                title={"Submit"}
                textStyle={{
                    color: 'white',
                    fontWeight: '500',
                    fontSize: scaleFontSize(12.5),
                }}
                style={{
                    backgroundColor: COLORS.primaryBlue,
                    alignSelf: 'flex-end',
                    padding: 10,
                    width: '25%',
                    minWidth: 100,
                    borderRadius: 10,
                }}
            />
        </View>
    )
}

export default ChangeAccountName;
