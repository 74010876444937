import React from 'react';
import { Modal, View, TouchableWithoutFeedback } from 'react-native';
import { SCREEN_SIZE } from '../Constants/'

const OptionsModal = (props: any)=>{

    const {
        showOptionsModal,
        setShowOptionsModal,
        containerStyle,
    } = props;


    return(
        <Modal
            animationType="slide"
            transparent={true}
            visible={showOptionsModal}
            onRequestClose={()=>{
                console.log("Closing modal");
            }}
        >
            <View style={{
                backgroundColor: 'rgba(0, 0, 0, .6)',
                alignContent: 'center',
                justifyContent: 'center',
                height: SCREEN_SIZE.height,
                width: SCREEN_SIZE.width,
                flexDirection: 'column',
                zIndex: 0,
            }}>
                <TouchableWithoutFeedback
                    onPress={()=>{
                        setShowOptionsModal(false);
                        console.log("Close modal: ", );
                    }}
                >
                    <View style={{
                        height: SCREEN_SIZE.height * .55,
                        width: SCREEN_SIZE.width,
                        zIndex: 1,
                    }}/>
                </TouchableWithoutFeedback>
                <View style={{
                    borderTopLeftRadius: 50,
                    borderTopRightRadius: 50,
                    backgroundColor: '#fff',
                    height: SCREEN_SIZE.height * .45,
                    width: SCREEN_SIZE.width,
                    zIndex: 1,
                    ...containerStyle,
                }}>
                    {props.children}
                </View>
            </View>
        </Modal>
    )
}

export default OptionsModal;