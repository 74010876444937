import React, { useState } from 'react';
import { View, Text, TextInput, StyleSheet } from 'react-native';
import scaleFontSize from '../helpers/scaleFontSize';

const ValidateInput = (props: any) => {

    const { isRequired, showLabel = true, flagError } = props;

    const [isError, setIsError] = useState(false);
    
    // console.log(props)
    return (
        <View style={{...styles.formControl, ...props.containerStyle}}>
            {/* {console.log("props.defaultValue is: ", props.defaultValue)} */}
            {(showLabel && <Text style={{...styles.label, ...props.labelStyle}}>{props.label}</Text>)}
            <TextInput
                placeholder={props.placeholder ? props.placeholder : ""}
                {...props}
                style={{...styles.input, ...props.inputStyle}}
                defaultValue={props.value || props.defaultValue}
                onChangeText={(value)=>{
                  // console.log("Value from validate input: ", value);
                    props.onInputChange(value);
                    
                    //validating
                    if (!props.regex.test(value)){
                        // IS_ERROR = true;
                        // console.log(`${props.errorName} is not valid`);
                        if(flagError) flagError(true);
                        setIsError(true);
                        if(value === ""){
                          setIsError(false);
                          if(flagError) flagError(false);
                        }
                    }
                    else{
                        // console.log(`${props.errorName} is valid`);
                        // IS_ERROR = false;
                        if(flagError) flagError(false);
                        setIsError(false);

                    }
                }}
            />
            {isRequired  && <Text style={{...styles.errorMessage, ...props.errorMessageStyle}}>*is required</Text>}
            {isError && <Text style={{...styles.errorMessage, ...props.errorMessageStyle}}>{props?.errorMessage}</Text>}
        </View>
  );
};

const styles = StyleSheet.create({
  formControl: {
    width: '100%'
  },
  label: {
    marginVertical: 8
  },
  input: {
    paddingHorizontal: 2,
    paddingVertical: 5,
    borderBottomColor: '#ccc',
    borderBottomWidth: 1
  },
  errorMessage: {
    color: 'red',
    // TODO: scaleFontSize doesn't work here
    fontSize: scaleFontSize(10)
    // fontSize: scaleFontSize(10)
  }
});

export default ValidateInput;
