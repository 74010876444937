import { SERVER_URL } from './../Constants/index';
import { pictureBucket, videoBucket } from './../Constants';
import catchErrorForSentry from './catchSentryError';

const deleteFromStorage = (objectKey: string, objectType: 'image' | 'video')=>{    
    // console.log("Deleting object from storage: ", objectKey);
    //Prevent defaults from ever being deleted.
    if(objectKey === 'placeholder-picture.png') return;
    if(objectKey === 'alwayslocal-logo.png') return;

    let link = `${SERVER_URL}/deleteObjectFromBucket`;
    let status: any;
    return fetch(link, {
      method: 'POST',
      headers: {
        // Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({bucketName: objectType === 'image' ? pictureBucket : videoBucket, objectKey: `${objectKey}`})
    })
    .then(res => {
          status = res.status;
          return res.text();
    }).then(text => {
        if (status === 200) {
            console.log("Success making request");
            console.log("Response: ", JSON.stringify(text));
            return text;
        } else {
            console.log("Problem with response");
            // console.log("else - Response: ", text);
        }
    }).catch(error => {
        console.log("Error getting signed urls: ", error);
        // console.log("JSON stringified format: ", JSON.stringify(error)); 
        catchErrorForSentry(error);
    });
}

export default deleteFromStorage