import { MessageInterface } from "../Interfaces";


const createNewMessageData = ( message: string, currentUserId: string, currentUserEmail: string, key: string ): MessageInterface =>{
    //create newMessageData
    let today = new Date();
  
    let newMessageData = {
      sender: currentUserEmail,
      senderUID: currentUserId,
      date: today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(),
      time: today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds(),
      message: message,
      timestamp: today.getTime(),
      id: key,
    }
  
    return newMessageData;
}

export default createNewMessageData;