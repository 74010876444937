import { ActivityIndicator, Image } from "react-native";
import { COLORS, IMAGEKIT_URL } from "../Constants";
import WebView from "react-native-webview";
import { useEffect, useState } from "react";

const CalloutUserImage = ({isVendor, displayName, style, pictureKey, webViewStyle}: any)=> {
    const [imageUrl, setImageUrl] = useState("");
    const [returnPlaceholder, setReturnPlaceholder] = useState(false);
  
    //@ts-ignore
    useEffect(()=>{
  
          let mounted = true;
  
          let url = `${IMAGEKIT_URL}/${pictureKey}`
  
          Image.getSize(url, (width, height) => {

            if(mounted) setImageUrl(url);
          }, err => {
              if(mounted) setReturnPlaceholder(true);
          })
  
          return ()=> mounted = false;
  
    }, [])
  
    // if(returnPlaceholder){
    //   return(
    //     <Image source={require('../assets/placeholder-picture.png')} style={{...style}} />
    //   )
    // }
    // else if(imageUrl){
    //   return (
    //     <Image source={{uri: imageUrl}} style={{...style}} />
    //   )
    // }
    // else{
    //   return (
    //     <ActivityIndicator style={{alignSelf: 'center'}} size="large" color={COLORS.primaryPurple} />
    //   )
    // }
    if(returnPlaceholder){
      return(
        <WebView
            source={{
                html: `<img style="
                  background-size:cover;
                  src="${IMAGEKIT_URL}/placeholder-picture.png"/>
                `,
                // html: `<img style="background-size:cover; height: 350px; width: 350px;" src="${require('../assets/alwayslocal-logo.png')}"/>`,
            }}
            style={{...style}}
            originWhitelist={['*']}
        />
      )
    }
    else if(imageUrl){
      return (
            <WebView
              source={{
                // <div style="
                //   display: 'flex',
                //   height: 100vh;
                //   width: 100vw;
                //   border: solid 10px black;
                //   align-items: 'center';
                //   justify-content: 'center';
                // ">
                html: `

                <img style="
                padding: 0 0 0 0;
                margin: 0 0 0 0;
                background-size:cover;
                height: 100%;
                width: 100%;
                "
                src="${imageUrl}"/>
                `,
                // border: solid 0vh ${webViewStyle.backgroundColor};
                // height: ${webViewStyle.height};
                // width: ${webViewStyle.width};
            }}
            // border: ${webViewStyle.border};
              style={{...style}}
              originWhitelist={['*']}
            />
      )
    }
    else{
      return (
        <ActivityIndicator size="large" color={COLORS.primaryBlue} />
      )
    }

}

export default CalloutUserImage