import React, { useState, useCallback } from 'react';
import { View } from 'react-native';
import { TimePickerModal } from 'react-native-paper-dates';
import CustomButton from '../components/CustomButton';
import { COLORS, SCREEN_SIZE } from '../Constants';
import { SafeAreaProvider } from 'react-native-safe-area-context';

const SetTimeModal = (props: any) => {
  const { onTimeSelected, setShow, defaultTime, containerStyle } = props;
  const [time, setTime] = useState(defaultTime);
  const [visible, setVisible] = useState(true);

  const onDismiss = useCallback(() => {
    setVisible(false);
    setShow(false);
  }, [setVisible, setShow]);

  const onConfirm = useCallback(({ hours, minutes }: any) => {
    const selectedTime = new Date();
    selectedTime.setHours(hours);
    selectedTime.setMinutes(minutes);
    setTime(selectedTime);
    onTimeSelected(selectedTime);
    setVisible(false);
    setShow(false);
  }, [setVisible, setTime, onTimeSelected, setShow]);

  return (
    <SafeAreaProvider>
      <View style={{
        flex: 1,
        position: 'absolute',
        backgroundColor: COLORS.primaryGray,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        top: SCREEN_SIZE.height * .25,
        height: SCREEN_SIZE.height * .35,
        width: SCREEN_SIZE.width * .75,
        ...containerStyle
      }}>
        <TimePickerModal
          visible={visible}
          onDismiss={onDismiss}
          onConfirm={onConfirm}
          hours={time.getHours()} // optional, default: current hours
          minutes={time.getMinutes()} // optional, default: current minutes
          label="Select time" // optional, default 'Select time'
          animationType="fade" // optional, default is 'none'
        />
        <CustomButton
          title={"Done"}
          style={{ width: '75%', right: '5%', marginLeft: 10, marginTop: 10, padding: 10, alignItems: 'center', alignSelf: 'center' }}
          textStyle={{ color: 'white' }}
          onPress={() => {
            console.log("Time selected: ", time);
            onTimeSelected(time);
            setShow(false);
          }}
        />
      </View>
    </SafeAreaProvider>
  );
};

export default SetTimeModal;


// import React, { useState } from 'react';
// import { View, Platform } from 'react-native';
// import DateTimePicker from '@react-native-community/datetimepicker';
// import CustomButton from '../components/CustomButton';
// import { COLORS, SCREEN_SIZE } from '../Constants';

// const SetTimeModal = (props: any)=>{
//     const { onTimeSelected, setShow, defaultTime, containerStyle} = props;
//     const [time, setTime] = useState(defaultTime);

//     return(
//         <View style={{
//             flex: 1,
//             position: 'absolute',
//             backgroundColor: COLORS.primaryGray,
//             justifyContent: 'center',
//             alignContent: 'center',
//             alignItems: 'center',
//             top: SCREEN_SIZE.height *.25,
//             height: SCREEN_SIZE.height * .35,
//             width: SCREEN_SIZE.width * .75,
//             ...containerStyle
//         }}>
//                 <DateTimePicker
//                     testID="dateTimePicker"
//                     style={{
//                         flexDirection: 'column',
//                         right: '5%',
//                         // alignSelf: 'center',
//                         // borderWidth: 1, borderColor: 'red',
//                         // justifyContent: 'center',
//                         // alignItems: 'center',
//                         // alignContent: 'center'
//                     }}
//                     // timeZoneOffsetInMinutes={0}
//                     value={time}
//                     // maximumDate={startDate}
//                     mode={"time"}
//                     is24Hour={false}
//                     display="clock"
//                     onChange={(event, time)=>{
//                         if(Platform.OS === 'ios') time && setTime(time);
//                         if(event.type === 'dismissed'){
//                             time && setTime(time);
//                             // setShow(false);
//                         }
//                         if(event.type === 'set'){
//                             time && setTime(time);
//                             // onTimeSelected(time);s
//                             // setShow(false);÷
//                         }
//                     }}
//                 />
//                 <CustomButton
//                     title={"Done"}
//                     style={{width: '75%', right: '5%', marginLeft: 10, marginTop: 10, padding: 10, alignItems: 'center', alignSelf: 'center',}}
//                     textStyle={{color: 'white'}}
//                     onPress={()=>{
//                         console.log("Time selected: ", time);
//                         onTimeSelected(time)
//                         setShow(false)
//                     }}
                
//                 />
//         </View>
        
//     )
// }

// export default SetTimeModal