
import * as MediaLibrary from 'expo-media-library';
// import i18n from 'i18n-js'
const getCameraRollPermissionAsync = async () => {
    //@ts-ignore
    const { status, permissions } = await MediaLibrary.requestPermissionsAsync();
    // const { status } = await MediaLibrary.requestPermissionsAsync();
    // const { status, permissions } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    if (status !== 'granted') {
        alert("Sorry, we need camera roll permissions to make this work!")
        console.log("Status: ", status);
        console.log("Permissions: ", JSON.stringify(permissions));
        return false;
    }
    else{
        // console.log("Permission Status (Should be granted): ", status);
        return true;
    }
}

export default getCameraRollPermissionAsync;