import { Text, View } from "react-native";
import ToggleSwitch from "toggle-switch-react-native";
import { COLORS } from "../Constants";
import scaleFontSize from "../helpers/scaleFontSize";

const VisibilitySlider = (props: any)=>{
    const { isVisible, setUpdating, onToggle } = props;
    return(
        <View style={{flexDirection: 'row', width: 175}}>
            <Text style={{color: COLORS.primaryBlue, width: '75%', fontSize: scaleFontSize(14)}}>
                {isVisible ? 'Show' : 'Hide'} on profile
            </Text>
            <ToggleSwitch
                isOn={isVisible}
                onColor="#00D768"
                offColor={COLORS.primaryRed}
                size="small"
                onToggle={(isOn: boolean) => {
                    // console.log("Switch: ", isOn);
                    setUpdating(true);
                    // console.log("Is On? ", isOn);
                    onToggle(isOn);
                }}
            />
        </View>
    )
}

export default VisibilitySlider