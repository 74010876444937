import { Platform, Image, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import Card from "../../components/Card"
import { COLORS, EDGES, SCREEN_SIZE } from "../../Constants"
// import Colors from "../../Constants/Colors"
import ActivityIndicator from '../../components/ActivityIndicator';
import CustomButton from "../../components/CustomButton";
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context';
import React, { useEffect, useState } from 'react';
import Fire from "../../Fire";
import TouchableBackButton from "../../components/TouchableBackButton";
import catchErrorForSentry from "../../helpers/catchSentryError";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, updateUserDataProperty } from "../../store/slices/userData";
import { FontAwesome5, Ionicons, MaterialIcons } from "@expo/vector-icons";
import TouchableContainer from "../../components/TouchableContainer";
import NotificationCount from "../../components/NotificationCount";
import { DrawerActions } from "@react-navigation/native";
import scaleFontSize from "../../helpers/scaleFontSize";
import { NotificationInterface } from "../../Interfaces";
import { RootState } from "../../store";
import confirmDecision from "../../helpers/confirmDecision";
import showToast from "../../helpers/showToast";
// import { useNavigation } from "@react-navigation/native";
const firebase = Fire.getFirebase();
interface NotificationDataInterface {
    [key: string]: NotificationInterface
}
// import Colors from "../../fonts/TT_Norms/TTNorms-"
const NotificationsHeader = (props: any) =>{
    // const liveNavigation = useNavigation();
    const dispatch = useDispatch();
    const window = useWindowDimensions();
    const userData = useSelector((state: RootState)=>state.userData)
    const {navigation, isLoggedIn, route, openDrawer} = props
    const isWebLoginPage = route.name === 'WebLoginPage';
    const [processing, setProcessing] = useState(false);
    // let [fontsLoaded] = useFonts({
    //     'TT-Norms': require('../../fonts/TT_Norms/TTNorms-Regular.otf'),
    // });
    // if(!fontsLoaded) return <ActivityIndicator/>;
    // return(
    //     <></>
    // )

    const [notificationData, setNotificationData] = useState<NotificationDataInterface>({});
    const [loading, setLoading] = useState(true);


    useEffect(()=>{
        if(!userData.uid) return;
        const onNotificationDataChange = (newNotiData: any)=>{
            if(!newNotiData) return setLoading(false);
            console.log("Got new notification data");
            setNotificationData(newNotiData);
            setLoading(false);
        }
        let notificationRef = Fire.listenToRoute(`userNotifications/${userData.uid}`, onNotificationDataChange)
        return(
            Fire.off(notificationRef, "value", onNotificationDataChange)
        )

    }, [])

    const onDeleteAllNotifications = async ()=>{
        // console.log("notification data: ", Object.values(notificationData));
        const unreadNotifications = Object.values(notificationData)
            .filter(notification => notification.id)
            .filter(notification => !notification.read)

        // console.log("unreadNotifications: ", unreadNotifications.length);
        const confirmed = await confirmDecision("Deleting all notifications", "Are you sure you would like to delete all notifications?\nNotifications cannot be retrieved after they are deleted.");
        if(!confirmed) return false;
        // const updates: any = {};
        // for (let [key, notification] of Object.entries(unreadNotifications)){
        //     for (let [property, value] of Object.entries(notification)){
        //         updates[`${notification.id}/${property}`] = property === "read" ? true : value;
        //     }
        // }
        // console.log("Updates length: ", Object.values(updates).length);
        // console.log("UpdatesLength/unreadNotificationsLength: ", Object.values(updates).length/unreadNotifications.length);
        Fire.deleteDataAtRoute(`userNotifications/${userData.uid}`)
        .then(()=>{
            console.log("Succesfully Deleted all notifications");
            showToast("Successfully deleted all notifications", true);
            // const newNotifications = Object.values(notificationData).map(notification => notification.id);
            // Fire.updateRoute(`userNotifications/${userData.uid}/lastUpdateTime`, new Date().getTime());
        })
        .catch((error: any)=>{
            console.log("Failed to mark notifications as read");
            console.log("Error makes no sense, code seems to work");
            showToast("Failed to delete all notifications", false);
            // Fire.updateRoute(`userNotifications/${userData.uid}/lastUpdateTime`, new Date().getTime());
            // catchErrorForSentry(error);
        })
    }
    const onMarkNotificationsAsRead = async ()=>{
        // console.log("notification data: ", Object.values(notificationData));
        const unreadNotifications = Object.values(notificationData)
            .filter(notification => notification.id)
            .filter(notification => !notification.read)

        // console.log("unreadNotifications: ", unreadNotifications.length);
        const confirmed = await confirmDecision("Marking all notifications as read", "Are you sure you would like to mark all notifications as read?");
        if(!confirmed) return false;
        const updates: any = {};
        for (let [key, notification] of Object.entries(unreadNotifications)){
            for (let [property, value] of Object.entries(notification)){
                updates[`${notification.id}/${property}`] = property === "read" ? true : value;
            }
        }
        // console.log("Updates length: ", Object.values(updates).length);
        // console.log("UpdatesLength/unreadNotificationsLength: ", Object.values(updates).length/unreadNotifications.length);
        Fire.updateRoute(`userNotifications/${userData.uid}`, updates)
        .then(()=>{
            console.log("Succesfully marked notifications marked as read");
            // const newNotifications = Object.values(notificationData).map(notification => notification.id);
            Fire.updateRoute(`userNotifications/${userData.uid}/lastUpdateTime`, new Date().getTime());
        })
        .catch((error: any)=>{
            console.log("Failed to mark notifications as read");
            console.log("Error makes no sense, code seems to work");
            Fire.updateRoute(`userNotifications/${userData.uid}/lastUpdateTime`, new Date().getTime());
            // catchErrorForSentry(error);
        })
    }

    const {top} = useSafeAreaInsets();
    return(
        <View style={{ flex: 1, marginTop: top }}>
            <Card
            style={{
                // flex: 1,
                flexDirection: 'row',
                height: window.height * .05,
                // width: window.width,
                padding: 10,
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius:0,
                borderBottomRadius: 5,
                // backgroundColor: 'transparent'
                
                // borderWidth: 1, 
                // borderColor: 'red'

            }}>
                <TouchableBackButton
                    containerStyle={{
                        width: '33%',

                        // borderWidth: 1,
                        // borderColor: 'blue',
    
                    }}
                    size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055}
                    onPress={()=>navigation.goBack()}
                />
                <Text style={{
                    flex: 1,
                    // borderWidth: 1,
                    // borderColor: 'red',
                    
                    alignSelf: 'center',
                    fontSize: scaleFontSize(15), 
                    textAlign: 'center',
                }}>
                    Notifications
                </Text>
                <View style={{
                    // flex: 1,
                    height: '100%',
                    flexDirection: 'row',
                    alignSelf: 'center',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '15%',

                    // borderWidth: 1,
                    // borderColor: 'black',
                }}>
                    <TouchableContainer onPress={onDeleteAllNotifications}>
                        <Ionicons
                            name="trash-outline"
                            size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055}
                            color="black"
                        />
                    </TouchableContainer>

                    <TouchableContainer onPress={onMarkNotificationsAsRead}>
                        <MaterialIcons
                            name={"mark-email-read"}
                            size={SCREEN_SIZE.width*.055 > 50 ? 50 : SCREEN_SIZE.width*.055}
                            color={"black"}
                        />
                    </TouchableContainer>
                </View>
            </Card>
        </View>
    )
}

const styles = StyleSheet.create({
    ctaBtnStyle: {
      borderRadius: 5,
      backgroundColor: '#DD222E',
      width: 120,
      height: 50,
      justifyContent: 'center',
    }
  });

export default NotificationsHeader