import React from 'react';
import { View, Text, ScrollView, StyleSheet, Linking } from 'react-native';
import Card from '../components/Card';
import scaleFontSize from '../helpers/scaleFontSize';

const SupportPage = () => {
    return (
        <View style={{ flex: 1, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <Card style={styles.container}>
                <ScrollView>
                    <Text style={{ ...styles.heading, alignSelf: 'center', fontSize: 20 }}>AlwaysLocal Support</Text>

                    <Text style={styles.paragraph}>Thank you for using AlwaysLocal! Our team is dedicated to providing a seamless user experience. If you encounter any issues or have queries about the app, please follow the instructions below to get in touch with our support team.</Text>

                    <Text style={styles.heading}>How to Reach Support:</Text>

                    <View style={styles.list}>
                        <Text style={styles.listItem}><Text style={styles.bold}>Email:</Text> Reach out to our dedicated support team by sending an email to <Text style={styles.link} onPress={() => Linking.openURL('mailto:info@alwayslocal.net')}>info@alwayslocal.net</Text>. Our team typically responds within 24 hours.</Text>
                        {/* <Text style={styles.listItem}><Text style={styles.bold}>Help Center:</Text> Visit our <Text style={styles.link} onPress={() => Linking.openURL('https://www.alwayslocal.com/help-center')}>Help Center</Text> for FAQs, tutorials, and other resources.</Text> */}
                    </View>

                    <Text style={styles.heading}>Before Reaching Out:</Text>
                    <Text style={styles.paragraph}>Please ensure you have the following details at hand when reaching out to expedite the support process:</Text>

                    <View style={styles.list}>
                        <Text style={styles.listItem}>Your registered email address.</Text>
                        <Text style={styles.listItem}>A brief description of the issue or query.</Text>
                        <Text style={styles.listItem}>Screenshots, if applicable, to provide a visual context.</Text>
                    </View>

                    <Text style={styles.paragraph}>Our goal is to ensure a hassle-free experience. Your feedback and queries help us continually improve and serve you better. Thank you for your patience and understanding.</Text>

                    <Text style={styles.paragraph}>Best regards,</Text>
                    <Text style={styles.paragraph}>AlwaysLocal Support Team</Text>
                </ScrollView>
            </Card>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '90%',
        padding: 15,
    },
    paragraph: {
        marginBottom: 10,
    },
    heading: {
        fontWeight: 'bold',
        fontSize: 16,
        marginVertical: 10,
    },
    list: {
        paddingLeft: 20,
    },
    listItem: {
        marginBottom: 8,
    },
    bold: {
        fontWeight: 'bold',
    },
    link: {
        color: 'blue',
        textDecorationLine: 'underline',
    }
});

export default SupportPage;
