import React, { useState } from "react";
import { View } from "react-native";
import CustomButton from "./CustomButton";
import { COLORS, errorMessages, regexPatterns } from "../Constants";
import ValidateInput from "./ValidateInput";
import updateCurrentUserEmail from "../helpers/updateCurrentUserEmail";
import scaleFontSize from "../helpers/scaleFontSize";
import { useDispatch, useSelector } from "react-redux";
import Fire from "../Fire";
import { updateUserDataProperty } from "../store/slices/userData";
import catchErrorForSentry from "../helpers/catchSentryError";
import showToast from "../helpers/showToast";
import { RootState } from "../store";
import VisibilitySlider from "./VisibilitySlider";

const EmailEditor = (props: any) => {
    const userData = useSelector((state: RootState) => state.userData);
    const dispatch = useDispatch();

    const [email, setEmail] = useState(userData.email || "");
    const [isEmailVisible, setIsEmailVisible] = useState(userData.isEmailVisible);
    const [currentPassword, setCurrentPassword] = useState(""); // Add state for current password

    const onChangeEmailFailure = (error: any) => {
        console.log("Error code: ", error.code);
        if (error.code === 'auth/requires-recent-login') {
            alert("Sorry, we failed to update your email");
        } else {
            alert("Sorry, we failed to update your email - Please try again");
            catchErrorForSentry(error);
        }
    }

    const onToggleVisiblity = async (isVisible: boolean) => {
        const originalVisibility = userData.isEmailVisible;
        try {
            await Fire.setUserDataRoute(`${userData.uid}/isEmailVisible`, isVisible);
            dispatch(updateUserDataProperty({ property: 'isEmailVisible', value: isVisible }));
            showToast(`Email is now ${!isVisible ? 'hidden' : 'visible'}`, true);
        } catch (error) {
            dispatch(updateUserDataProperty({ property: 'isEmailVisible', value: originalVisibility }));
            showToast('Failed to toggle visibility', false);
            catchErrorForSentry(error);
        }
        setIsEmailVisible(isVisible);
    }

    const onChangeEmailSuccess = async () => {
        const originalEmail = email;
        try {
            await Fire.setUserDataRoute(`${userData.uid}/email`, email);
            dispatch(updateUserDataProperty({ property: 'email', value: email }));
            showToast('Successfully updated your email', true);
        } catch (error) {
            dispatch(updateUserDataProperty({ property: 'email', value: originalEmail }));
            showToast('Failed to properly update your email!', false);
            catchErrorForSentry(error);
        }
    }

    const onChangeEmail = async () => {
        await updateCurrentUserEmail(
            email,
            currentPassword, // Pass the current password
            onChangeEmailSuccess,
            onChangeEmailFailure
        )
    }

    return (
        <View style={{ width: '100%', ...props.containerStyle }}>
            <ValidateInput
                inputStyle={{
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    borderRadius: 10,
                    ...props.inputStyle
                }}
                style={{ marginBottom: 10 }}
                name={'Email'}
                errorMessage={errorMessages.emailError}
                errorName={'emailErrorFlag'}
                onInputChange={setEmail}
                regex={regexPatterns.email}
                defaultValue={email}
                label={"Email"}
            />
            <ValidateInput
                inputStyle={{
                    borderWidth: 1,
                    borderColor: COLORS.primaryBlue,
                    borderRadius: 10,
                    ...props.inputStyle
                }}
                style={{ marginBottom: 10 }}
                name={'Current Password'}
                errorMessage={errorMessages.passwordError}
                errorName={'passwordErrorFlag'}
                onInputChange={setCurrentPassword} // Add input for current password
                secureTextEntry={true}
                label={"Current Password"}
                regex={regexPatterns.password}
            />
            <View style={{ marginTop: 10, width: '100%', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'space-between' }}>
                <VisibilitySlider isVisible={isEmailVisible} setUpdating={() => { }} onToggle={onToggleVisiblity} />
                <CustomButton
                    onPress={onChangeEmail}
                    title={"Submit"}
                    textStyle={{
                        color: 'white',
                        fontWeight: '500',
                        fontSize: scaleFontSize(12.5),
                    }}
                    style={{
                        backgroundColor: COLORS.primaryBlue,
                        alignSelf: 'flex-end',
                        padding: 10,
                        width: '25%',
                        minWidth: 100,
                        borderRadius: 10,
                    }}
                />
            </View>
        </View>
    )
}

export default EmailEditor;