import React, { useState } from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Button } from 'react-native';
import { COLORS } from '../Constants';

const RadioButton = ({selected, onSelect, style, innerBubbleStyle, outerBubbleStyle}: any)=>{

    // const onPress = ()=>{
    //     console.log("Selected");
    //     // let newRadioButtonState = {};
    //     // radioButtonsNameArray.forEach((name)=>{
    //     //     if(name === title){
    //     //         newRadioButtonState[`${title}`] = true;
    //     //         return;
    //     //     }
    //     //     else{
    //     //         newRadioButtonState[`${name}`] = false;
    //     //     }
    //     // });
    //     // // console.log("New Radio Button State: ", newRadioButtonState);
    //     // setRadioButtonState(newRadioButtonState);

    // }

    return(
        <TouchableOpacity onPress={onSelect} style={{...styles.radioButton, ...style}}>
            <View style={{...styles.outerBubble, ...outerBubbleStyle}}>
                <View style={{ 
                        ...styles.innerBubble,
                        ...innerBubbleStyle,
                        ...( selected ? {backgroundColor: COLORS.primaryBlue } : {backgroundColor: 'transparent'})
                    }} >

                </View>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    radioButton: {
        height: 30,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start',
        flex: 0,
        // marginTop: 5,
        // marginBottom: 5,

    },
    textStyle: {
        alignSelf: 'center',
    },
    outerBubble: {
        height: 30,
        width: 30,
        borderRadius: 25,
        borderWidth: 2,
        borderColor: COLORS.primaryBlue,
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        // marginRight: 10,
    },
    innerBubble: {
        height: 20,
        width: 20,
        borderRadius: 25,
        // borderWidth: 2,
        alignSelf: 'center',
    }
});

export default RadioButton;