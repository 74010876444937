import { Ionicons } from "@expo/vector-icons"
import { Platform, Pressable, StyleSheet, TouchableNativeFeedback, View } from "react-native"

const TouchableBackButton = (props: any) => {
    let ButtonComponent = Platform.OS === 'web' ? Pressable : TouchableNativeFeedback;

    return (
        // <View style={{...props.containerStyle}}>
            <ButtonComponent style={{...props.containerStyle}} onPress={props.onPress} >
                <Ionicons
                    name={"chevron-back"}
                    style={styles.backBtnStyle}
                    size={props.size}
                    color={"#000"}
                />
            </ButtonComponent>
        // </View>
    )
}

const styles = StyleSheet.create({
    backBtnStyle: {
        // alignSelf: 'center',
        // textAlign: 'center',
        // paddingLeft: '5%'
    }
})

export default TouchableBackButton
