import { StyleSheet, Text, View } from "react-native"
import { useSelector } from "react-redux"
import { RootState } from "../../store"
import scaleFontSize from "../../helpers/scaleFontSize"
import TouchableContainer from "../TouchableContainer"
import { COLORS, GOOGLE_MAPS_API_KEY, IMAGEKIT_URL, SCREEN_SIZE, SERVER_URL } from "../../Constants"
import { useEffect, useRef, useState } from "react"
import SetDateModal from "../SetDateModal"
import SetTimeModal from "../SetTimeModal"
import formatBusinessHours from "../../helpers/formatBusinessHours"
import day from 'dayjs'
import { SelectCountry } from 'react-native-element-dropdown';
import getUsersForUserList from "../../helpers/getUsersForUserList"
import CustomButton from "../CustomButton"
import { UserData } from "../../Interfaces"
import { Gig, Location } from '../../types';
import confirmDecision from "../../helpers/confirmDecision"
import addNotificationToUser from "../../helpers/addNotificationToUser"
import catchErrorForSentry from "../../helpers/catchSentryError"
import GigCard from "../GigCard"
import GigDisplayer from "../GigDisplayer"
import Fire from "../../Fire"
import isWeb from "../../helpers/isWeb"
import CustomGooglePlacesAutocomplete from "../CustomGooglePlacesAutocomplete";
import Card from "../Card"
import GigAdder from "./GigAdder"
import GigCreator from "./GigCreator"
import GigButtons from "./GigButtons"
import showToast from "../../helpers/showToast"
import generateGigKey from "../../helpers/generateGigKey"
const Scheduler = (props: any)=>{
    const { navigation, containerStyle } = props;
    const userData = useSelector((state: RootState)=>state.userData);
    const scheduleType = (()=>{
        if(userData.accountType === 'entertainment') return 'entertainment'
        if(userData.accountType === 'account' && userData.establishmentType === 'Food Truck') return 'food'
        return 'entertainment'
    })()

    const isEntertainer =  userData.accountType === 'entertainment';
    const isFoodTruck =  scheduleType === 'food';
    const isNormalAccount =  userData.accountType === 'account' && userData.establishmentType !== 'Food Truck'
    
    const [addingGig, setAddingGig] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [selectedStartTime, setSelectedStartTime] = useState<number>();
    const [selectedEndTime, setSelectedEndTime] = useState<number>();
    const [selectingDate, setSelectingDate] = useState(false);
    const [selectingStartTime, setSelectingStartTime] = useState(false);
    const [selectingEndTime, setSelectingEndTime] = useState(false);
    const [selectingEmployer, setSelectingEmployer] = useState(false);
    const [selectedEmployer, setSelectedEmployer] = useState<UserData | undefined>();
    const [employers, setEmployers] = useState<any>(null);
    const [isGigDataReady, setIsGigDataReady] = useState(false);
    const [edittingGig, setEdittingGig] = useState(false);
    const [previousGigData, setPreviousGigData] = useState<Gig>();
    const [gigData, setGigData] = useState<Gig[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<Location>();
    const [address, setAddress] = useState();
    const [selectingLocation, setSelectingLocation] = useState(false);

    useEffect(()=>{
        const onGigDataChange = (gigData: any)=>{
            // console.log("NewChatRoomData: ", newChatRoomData);
            // console.log("chatRooms/${userData.uid}: ", `chatRooms/${userData.uid}`);
            // console.log("Noticed change in enetretainer: ", gigData);
            if(!gigData) return setGigData([]);
            setGigData(Object.values(gigData));
            // getAndSetBlockedByList(Object.keys(newChatRoomData));
        }
        const onEmployerChange=(employerData: any)=>{
            if(!employerData) return;
            const employerUids = Object.keys(employerData);
            if(!employerUids.length) return;
            //Grab each with snapshots thingy?
            (async ()=>{
                const employers = await getUsersForUserList(employerUids);
                if(employers[0] === null) return;
                setEmployers(employers);
            })()    
        }
        var gigDataRef = Fire.listenToRoute(`publicUserData/${userData.uid}/${scheduleType}Schedule`, onGigDataChange)
        var employerDataRef = Fire.listenToRoute(`publicUserData/${userData.uid}/employers`, onEmployerChange)

        return () =>{
           Fire.off(gigDataRef, "value", onGigDataChange);
           Fire.off(employerDataRef, "value", onEmployerChange);
        }
    }, [])

    useEffect(()=>{
        if(!userData.employers) return;
        const employerUids = Object.keys(userData.employers);
        if(!employerUids.length) return;
        //Grab each with snapshots thingy?
        (async ()=>{
            const employers = await getUsersForUserList(employerUids);
            if(employers[0] === null) return;
            setEmployers(employers);
        })()
    }, []);

    useEffect(()=>{
        const isGigDataReady = isNewGigDataReady();
        setIsGigDataReady(isGigDataReady);
    },[selectedDate, selectedStartTime, selectedEndTime, selectedEmployer, selectedLocation])

    const isNewGigDataReady = ()=>{
        // console.log("Editting gig: ", edittingGig);
        // console.log("PreviousGigData: ", previousGigData);
        if(edittingGig && previousGigData){
            // console.log("Editting gig doing checks");
            if(!selectedDate && !selectedStartTime &&  !selectedEndTime && !selectedEmployer) return false;
            if(selectedDate && (selectedDate.getTime() !== previousGigData.date)) return true;
            if(selectedStartTime !== previousGigData.start) return true;
            if(selectedEndTime !== previousGigData.end) return true;
            if(selectedEmployer && (selectedEmployer.uid !== previousGigData.employer) ) return true;
            return false;
        }
        if(selectingDate || selectingStartTime || selectingEndTime || selectingEmployer || selectingLocation) return false;
        if(!selectedDate || !selectedStartTime || !selectedEndTime || (!selectedEmployer && !selectedLocation) ) return false;
        return true;
    }

    
    const onAddLocationBasedGig = ()=>{
        setAddingGig(!addingGig);
        if(selectingDate || selectingStartTime || selectingEndTime || selectingLocation) return clearAllSelectionModes();
        if(!selectedDate || !selectedStartTime || !selectedEndTime || !selectedLocation) return;

        const newGig: Gig = {
            id: "",
            date: selectedDate.getTime(),
            start: selectedStartTime,
            end: selectedEndTime,
            location: selectedLocation,
            employer: userData.uid,
            employee: userData.uid,
            type: scheduleType,

        }

        updateGig(newGig);
    }

    const onEditLocationBasedGig = (gig: Gig)=>{
        if(!edittingGig){
            console.log("Setting editting gig to true");
            setEdittingGig(true);
        }
        if(!previousGigData){
            console.log("Setting previous gig data");
            setPreviousGigData(gig);
        }
        setAddingGig(!addingGig);
        const ready = isNewGigDataReady();
        // console.log("IS GIG READY: ", ready);
        if(!ready) return;
        const potentialDate = selectedDate ? selectedDate.getTime() : gig.date;
        const potentialStartTime = selectedStartTime || gig.start;
        const potentialEndTime = selectedEndTime || gig.end;
        const potentialLocation = selectedLocation || gig.location || "";

        const newGig: Gig = {
            id: "",
            date: potentialDate,
            start: potentialStartTime,
            end: potentialEndTime,
            location: potentialLocation,
            employer: userData.uid,
            employee: userData.uid,
            type: scheduleType,
        }
        // console.log("MODIFYING FOOD GIG! - ", newGig);

        //Simply edit the gig here.
        updateGig(newGig);
    }

    const onAddEmployerBasedGig = async ()=>{
        setAddingGig(!addingGig);
        if(selectingDate || selectingStartTime || selectingEndTime || selectingEmployer) return clearAllSelectionModes();
        if(!selectedDate || !selectedStartTime || !selectedEndTime || (!selectedEmployer && !selectedLocation)) return;
        const employerName = selectedEmployer ? (selectedEmployer.displayName || selectedEmployer.username) : (userData.displayName || userData.username);
        if(!selectedEmployer) return;
        const result = await confirmDecision(
            "Adding gig",
            `Are you sure you want to add this gig?\n Once ${employerName} accepts this gig, it will be added to both your schedules`
        );
        if(!result) return;

        const newGig: Gig = {
            id: "",
            date: selectedDate.getTime(),
            start: selectedStartTime,
            end: selectedEndTime,
            location: selectedLocation ? selectedLocation : "",
            employer: selectedLocation ? userData.uid : (selectedEmployer ? selectedEmployer.uid : ""),
            employee: userData.uid,
            
            type: scheduleType,
        }
        console.log("Adding gig! - ", newGig);

        const request = addNotificationToUser(selectedEmployer.uid, {
            id: `${userData.uid}-${userData.accountType}-${selectedDate.getTime()}-request`,
            image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
            content: `${userData.displayName} requested to add a gig for ${day(selectedDate).format('MM/DD')} at ${formatBusinessHours(selectedStartTime)}`,
            timeCreated: new Date().getTime(),
            read: false,
            //When notification is pressed it won't direct user to post screen.
            type: { request: userData.uid, type: `gig-request`, gig: newGig },
        });
        request.then((success)=>{
            alert("Gig request has been sent");
        })
        .catch((error)=>{
            alert("Failed to send your request - Please try again");
            catchErrorForSentry(error);
        })


        clearGigData();
    }

    const onEditEmployerBasedGig = async (gig: Gig)=>{
        if(!edittingGig) setEdittingGig(true);
        if(!previousGigData) setPreviousGigData(gig);
        setAddingGig(!addingGig);
        const ready = isNewGigDataReady();
        if(!ready) return;
        const potentialDate = selectedDate ? selectedDate.getTime() : gig.date;
        const potentialStartTime = selectedStartTime || gig.start;
        const potentialEndTime = selectedEndTime || gig.end;
        const potentialEmployer = selectedEmployer || gig.employer;
        const potentialLocation = selectedLocation || gig.location;

        const employerName = typeof potentialEmployer === 'object' ? potentialEmployer.displayName || potentialEmployer.username : potentialEmployer
        const result = await confirmDecision(
            "Modifying gig",
            `Are you sure you want to edit this gig?\n Once they accept this edit, both your schedules will be modified`
        );
        if(!result) return;
        
        const employerId = typeof potentialEmployer === 'object' ? potentialEmployer.uid : potentialEmployer;
        const newGig: Gig = {
            id: "",
            date: potentialDate,
            start: potentialStartTime,
            end: potentialEndTime,
            employer: employerId,
            employee: gig.employee,
            location: potentialLocation,
            type: gig.type,
        }
        console.log("Modifying entertainment gig! - ", newGig);

        const timeCreated = new Date().getTime();
        const request = addNotificationToUser(employerId, {
            id: `${userData.uid}-${userData.accountType}-${timeCreated}-request`,
            image: `${IMAGEKIT_URL}/${userData.profilePictureKey}`,
            content: `${userData.displayName} requested to modify a gig`,
            timeCreated: timeCreated,
            read: false,
            //When notification is pressed it won't direct user to post screen.
            type: { request: userData.uid, type: `gig-modify-request`, previousGig: gig, gig: newGig },
        });
        request.then((success)=>{
            alert("Gig request has been sent");
        })
        .catch((error)=>{
            alert("Failed to send your request - Please try again");
            catchErrorForSentry(error);
        })


        clearGigData();
    }

    const updateGig=async (gigData: Gig)=>{
        // const result = await confirmDecision(`Proceed with ${edittingGig ? "gig edit" : "new gig add"}?`);
        const newGigId = !!(edittingGig && previousGigData) ? previousGigData.id : generateGigKey(userData.uid, gigData);
        // console.log("New Gig Id: ", newGigId);
        // if(!result) return;
        const newGigData = {
            ...gigData,
            id: newGigId,
        }
        // return;
        //Adding gig to current user
        if(newGigData.id === "") throw new Error("Invalid Gig ID");
        Fire.updateRoute(`publicUserData/${userData.uid}/${scheduleType}Schedule/`, {[`${newGigData.id}`]: newGigData})
        .then(()=>{
            clearGigData();
            showToast("Succeded to update gig schedule!", true);
            setEdittingGig(false);
        })
        .catch((error: any)=>{
            // console.log("Error updating gigSchedule for user");
            console.log("Error updating food gig schedule for account");
            alert("Faield to update gig");
            catchErrorForSentry(error);
            showToast("Failed to update gig schedule", false);
            setEdittingGig(false);

        })
    }

    const onAddGig = async ()=>{
        if(selectedLocation) return onAddLocationBasedGig();
        return onAddEmployerBasedGig();
    }

    const onEditGig = async (gig: Gig)=>{
        if(gig.location) return onEditLocationBasedGig(gig);
        return onEditEmployerBasedGig(gig);
    }

    //SELECTING DATA
    const onSelectDate = ()=>{
        setSelectingDate(!selectingDate);
    }
    const onSelectStartTime = ()=>{
        setSelectingStartTime(!selectingStartTime);
    }
    const onSelectEndTime = ()=>{
        setSelectingEndTime(!selectingEndTime);
    }
    const onSelectEmployer = ()=>{
        setSelectingEmployer(!selectingEmployer);
    }
    const onSelectLocation = ()=>{
        setSelectingLocation(!selectingLocation);
    }

    //CLEARING DATA
    const clearAllSelectionModes = ()=>{
        setSelectingDate(false);
        setSelectingStartTime(false);
        setSelectingEndTime(false);
        setSelectingEmployer(false);
        setSelectingLocation(false);
    }

    const clearGigData = ()=>{
        setSelectedDate(undefined);
        setSelectedStartTime(undefined);
        setSelectedEndTime(undefined);
        setSelectedEmployer(undefined);
        setSelectedLocation(undefined);
    }

    const cancelAddingGig = ()=>{
        clearAllSelectionModes();
        clearGigData();
        setAddingGig(false);
    }
 
    return(
        <Card style={{width: '100%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', padding:5, ...containerStyle}}>
            <View style={{padding: 2.5, flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                <Text style={{fontSize: scaleFontSize(18), fontWeight: '500'}}>
                    Gigs
                </Text>
            </View>
            <GigDisplayer onEditGig={onEditGig} push={navigation.push} onAccount={false} gigs={gigData} />
            {addingGig && <GigAdder
                        onSelectDate={onSelectDate}
                        selectedDate={selectedDate}
                        onSelectStartTime={onSelectStartTime}
                        selectedStartTime={selectedStartTime}
                        onSelectEndTime={onSelectEndTime}
                        selectedEndTime={selectedEndTime}
                        onSelectEmployer={onSelectEmployer}
                        selectedEmployer={selectedEmployer}
                        onSelectLocation={onSelectLocation}
                        selectedLocation={selectedLocation}
            />}
            <GigCreator
                employers={employers}
                selectedDate={selectedDate}
                selectedStartTime={selectedStartTime}
                selectedEndTime={selectedEndTime}
                selectedEmployer={selectedEmployer}
                selectedLocation={selectedLocation}
                selectingDate={selectingDate}
                selectingStartTime={selectingStartTime}
                selectingEndTime={selectingEndTime}
                selectingEmployer={selectingEmployer}
                selectingLocation={selectingLocation}
                setSelectedEmployer={setSelectedEmployer}
                setSelectingEmployer={setSelectingEmployer}
                setSelectedEndTime={setSelectedEndTime}
                setSelectingEndTime={setSelectingEndTime}
                setSelectedStartTime={setSelectedStartTime}
                setSelectingStartTime={setSelectingStartTime}
                setSelectedDate={setSelectedDate}
                setSelectingDate={setSelectingDate}
                setSelectingLocation={setSelectingLocation}
                setAddress={setAddress}
                setSelectedLocation={setSelectedLocation}
            />
            <GigButtons
                isNormalAccount={isNormalAccount}
                edittingGig={edittingGig}
                previousGigData={previousGigData}
                onAddGig={onAddGig}
                onEditGig={onEditGig}
                addingGig={addingGig}
                isGigDataReady={isGigDataReady}
                cancelAddingGig={cancelAddingGig}
            />
        </Card>
    )
}

const styles = StyleSheet.create({
    btnContainer: {
        backgroundColor: COLORS.primaryBlue,
        borderRadius: 2.5,
        padding: 7.5
    },
    textStyle: {
        color: '#fff',
        fontWeight: '500'
    },
})

export default Scheduler;