import React, { useEffect, useState } from 'react';
import { StyleSheet, TouchableOpacity, ScrollView, Image, View, Text, ActivityIndicator } from 'react-native';
import Card from '../components/Card';
import { COLORS, SCREEN_SIZE } from '../Constants/';
import isObjEmpty from '../helpers/isObjEmpty';
import Notification from '../components/Notification';
import { connect, useSelector } from 'react-redux';
import Fire from '../Fire';
import isVarDefined from '../helpers/isVarDefined'; 
import { NotificationInterface } from '../Interfaces';
import { RootState } from '../store';
import LoginPrompt from '../components/LoginPrompt';
import scaleFontSize from '../helpers/scaleFontSize';
import { Screen } from '../components/Screen';
import { useHeaderHeight } from '@react-navigation/elements';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
const firebase = Fire.getFirebase();

const testNotificationData: NotificationDataInterface = {
    0: {
        id: 0,
        image: '',
        content: "Someone mentioned you in the event test123",
        timeCreated: 20,
        read: false,
        type: 'mention',
    },
    1: {
        id: 1,
        image: '',
        content: "You've been accepted to the event test123",
        timeCreated: 20,
        read: true,
        type: 'event_acceptance',
    },
    2: {
        id: 2,
        image: '',
        content: "An event has been created near you!",
        timeCreated: 42,
        read: false,
        type: 'event_created_near_you',
    },
}

interface NotificationDataInterface {
    [key: string]: NotificationInterface
}

const NotificationScreen = (props: any)=>{

    const userData = useSelector((state: RootState)=>state.userData)

    const [notificationData, setNotificationData] = useState<NotificationDataInterface>({});
    const [loading, setLoading] = useState(true);
    const headerHeight = useHeaderHeight();

    useEffect(()=>{
        if(!userData.uid) return;
        const onNotificationDataChange = (newNotiData: any)=>{
            if(!newNotiData){
                setNotificationData({})
                return setLoading(false);
            }
            console.log("Got new notification data - NOTI SCREEN");
            setNotificationData(newNotiData);
            setLoading(false);
        }
        let notificationRef = Fire.listenToRoute(`userNotifications/${userData.uid}`, onNotificationDataChange)
        return(
            Fire.off(notificationRef, "value", onNotificationDataChange)
        )

    }, [])

    const renderNotifications = ()=>{
        if(!isObjEmpty(notificationData) && isVarDefined(notificationData)){
            // console.log(notificationData.lastUpdateTime)
            // // console.log("Notification data: ", notificationData);
            // Object.entries(notificationData).forEach(([key, value])=>{
            //     console.log("Key: ", key);
            //     console.log("Value: ", value);
            // })
            // let i = 0;'
            const filteredNotifications = Object.values(notificationData)
            .filter((notification: any)=>typeof notification !== 'number')
            return filteredNotifications.sort((a: NotificationInterface, b: NotificationInterface)=>{
                return b.timeCreated - a.timeCreated
            }).map((notification)=>{
                // i+=1;
                // return <></>
                return (
                    <Notification
                        key={notification.id}
                        id={notification.id}
                        currentUserId={userData.uid}
                        image={notification.image}
                        content={notification.content}
                        timeCreated={notification.timeCreated}
                        read={notification.read}
                        type={notification.type}
                        push={props.navigation.push}
                    />
                )
            })
        }
        else if(isVarDefined(notificationData) && isObjEmpty(notificationData)){
            return(
                <>
                    <Text style={{fontSize: scaleFontSize(15), textAlign: 'center'}}>
                        No notifications found.
                    </Text>
                    <Text style={{fontSize: scaleFontSize(15), textAlign: 'center'}}>
                        Check back later!
                    </Text>
                </>
            )
        }
        else return <ActivityIndicator size="large" color={COLORS.primaryPurple} />
    }

    if(!userData.uid) return <LoginPrompt navigation={props.navigation} prompt={"receive notifications"}/>
    if(loading){
        return (
            <View style={{alignItems: 'center', flex: 1, justifyContent: 'center'}}>
                <ActivityIndicator size="large" color={COLORS.primaryPurple} />
            </View>
        )
    }
    return(
        <ScrollView key={Object.keys(notificationData).length} contentContainerStyle={{
            ...styles.screen,
            marginTop: headerHeight,
            justifyContent: (isObjEmpty(notificationData) ? 'center' : 'flex-start')
        }}>
            {renderNotifications()}
        </ScrollView>
    )
}


export default NotificationScreen;

const styles = StyleSheet.create({
    screen: {
        padding: 10,
        alignItems: 'center'
    }
})