import React, { memo, useState, useEffect, useRef } from 'react';
import { Image, Platform, ScrollView, Text, TextInput, Touchable, View } from 'react-native';
import * as Linking from 'expo-linking';
import AccountChoosePictures from '../components/AccountChoosePictures';
import CustomModal from '../components/CustomModal';
// import Slideshow from '../components/Slideshow';
import TouchableContainer from '../components/TouchableContainer';
import { useSelector, useDispatch } from 'react-redux';
import { COLORS, EDGES, GOOGLE_MAPS_API_KEY, IMAGEKIT_URL, SCREEN_SIZE, daysOfTheWeek, errorMessages, fakeGigData, regexPatterns } from '../Constants';
import { RootState } from '../store';
// import Slideshow from '../components/Slideshow';
import Fire from '../Fire';
import Swiper from 'react-native-web-swiper';
// import MapView from 'react-native-map-clustering';
// import MapView from 'react-native-maps';@alamoweb/react-native-web-mapview
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps';
import getLatLngFromAddress from '../helpers/getLatLngFromAddress';
import getMostAccurateLocation from '../helpers/getMostAccurateLocation';
import Card from '../components/Card';
import { MaterialCommunityIcons, MaterialIcons, AntDesign  } from '@expo/vector-icons'; 
import { FontAwesome5 } from '@expo/vector-icons'; 
import { SimpleLineIcons } from '@expo/vector-icons'
import { Entypo } from '@expo/vector-icons'; 
import CustomButton from '../components/CustomButton';
import UploadPictureModal from '../components/UploadPictureModal';
import confirmDecision from '../helpers/confirmDecision';
import AccountSelectProfilePicture from '../components/AccountSelectProfilePicture';
import { setUserData, updateUserDataProperty, updateWhoUserFollows } from '../store/slices/userData';
import ValidateInput from '../components/ValidateInput';
import isUsernameTaken from '../helpers/isUsernameTaken';
import Input from '../components/Input';
import isObjEmpty from '../helpers/isObjEmpty';
import addCopiesToArray from '../helpers/addCopiesToArray';
import arrayToObject from '../helpers/arrayToObject';
import isUrlValid from '../helpers/isUrlValid';
import deleteImageFromCache from '../helpers/deleteImageFromCache';
import catchErrorForSentry from '../helpers/catchSentryError';
import updateCurrentUserEmail from '../helpers/updateCurrentUserEmail';
import ReauthCurrentUserModal from '../components/ReuathCurrentUserModal';
// import VerifyPhoneNumberModal from '../components/VerifyPhoneNumberModal';
import formatPhoneNumber from '../helpers/formatPhoneNumber';
import ensureProtocol from '../helpers/ensureProtocol';
import isMobile from '../helpers/isMobile';
import cleanPhoneNumber from '../helpers/cleanPhoneNumber';
import ScheduleEditor from '../components/ScheduleEditor';
import capitalizeFirstLetter from '../helpers/capitalizeFirstLetter';
import BusinessHours from '../components/BusinessHours';
import isBusinessOpenNow from '../helpers/isBusinessOpenNow';
import testBusinessHours from '../tests/testBusinessHours';
import formatBusinessHours from '../helpers/formatBusinessHours';
import { Screen } from '../components/Screen';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useHeaderHeight } from '@react-navigation/elements';
import getBusinessStatus from '../helpers/getBusinessStatus';
import ChangeAccountName from '../components/ChangeAccountName';
import PhoneNumberEditor from '../components/PhoneNumberEditor';
import ChangeAddress from '../components/ChangeAddress';
import EmailEditor from '../components/EmailEditor';
import WebsiteEditor from '../components/WebsiteEditor';
import FacebookUrlEditor from '../components/FacebookUrlEditor';
import InstagramUrlEditor from '../components/InstagramUrlEditor';
import MapImageMarker from '../components/MapImageMarker';
import FollowButton from '../components/FollowButton';
import addNotificationToUser from '../helpers/addNotificationToUser';
import removeNotificationFromUser from '../helpers/removeNotificationFromUser';
import getUserDataPath from '../helpers/getUserDataPath';
import isWeb from '../helpers/isWeb';
import MessageButton from '../components/MessageButton';
import DirectionsButton from '../components/DirectionsButton';
import { UserData } from '../Interfaces';
import sendToProfileScreen from '../helpers/sendToProfileScreen';
import UserSlideShowImage from '../components/UserSlideShowImage';
import scaleFontSize from "../helpers/scaleFontSize";
import AccountTypeEditor from '../components/AccountTypeEditor';
import TiktokEditor from '../components/TiktokEditor';
import AccountDataEditor from './AccountDataEditor';
import GigDisplayer from '../components/GigDisplayer';
import InTheBizEditor from '../components/InTheBizEditor';
import UrlEditor from '../components/UrlEditor';
import MonthlySpecialsUrlEditor from '../components/MonthlySpecialsUrlEditor';
import { Gig } from '../types';
import FoodTruckSchedule from '../components/FoodTruckSchedule';
import getFoodTruckStatus from '../helpers/getFoodTruckStatus';
import ExpandPictureModal from '../components/ExpandPictureModal';
import { useNavigation } from '@react-navigation/native';


// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';



const AccountProfile = (props: any)=>{
    const headerHeight = useHeaderHeight();
    const hSCREEN_SIZE = SCREEN_SIZE.height - headerHeight;
    const { navigation, route } = props;
    const recaptchaVerifier = useRef();
    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.userData);
    const dataFromProfile = (route && route.params ? route.params.profileData : false) || userData;
    const [profileData, setProfileData] = useState(dataFromProfile);
    // console.log("ProfileData: ", profileData);
    const isProfileOwner = userData.uid === profileData.uid;

    const defaultUploadedPictures: string[] = (()=>{
        return profileData.pictureKeys ? Object.values(profileData.pictureKeys).map((key)=>{return `${IMAGEKIT_URL}/${key}`}) : [];
    })()
    const [uploadedPictures, setUploadedPictures] = useState<string[]>(defaultUploadedPictures);
    // console.log("Uploaded Pictures: ", uploadedPictures);

    

    useEffect(()=>{
        let defaultFollowingValue = (()=>{
            let uidsUserFollows = userData.uidsUserFollows;
            if(!uidsUserFollows || isObjEmpty(uidsUserFollows)) return false;
            // console.log("UidsUserFollows: ", uidsUserFollows);
            let isFollowingProfile = false;
            for (let [uid, status] of Object.entries(uidsUserFollows)){
                const isIncluded = Object.keys(uidsUserFollows).includes(profileData.uid);
                const isFollowing = status;
                const isProfileId = profileData.uid === uid;
                if(isProfileId && isIncluded && isFollowing ){
                    // console.log("Uid: ", uid);
                    // console.log("Is Included: ", isIncluded);
                    // console.log("is Following: ", status);
                    isFollowingProfile = true;
                    return true;
                }
                continue;
            }
            return isFollowingProfile;
        })()
        setFollowing(defaultFollowingValue);
    }, [userData])

    const [following, setFollowing] = useState<Boolean>(false);

    const defaultEntertainmentPictures: string[] = (()=>{
        return profileData.entertainmentPictureKeys ? Object.values(profileData.entertainmentPictureKeys).map((key)=>{return `${IMAGEKIT_URL}/${key}`}) : [];
    })()
    const [uploadedEntPictures, setUploadedEntPictures] = useState<string[]>(defaultEntertainmentPictures);

    const [edittingMode, setEdittingMode] = useState
    <
        "" | "coverPictures" |
        "selectProfilePicture" |
        "nameAndAddressChange" |
        "dailySpecialsChange" |
        "inTheBizChange" |
        "weeklySpecialsChange" |
        "monthlySpecialsChange" | 
        "dealsChange" | 
        "entertainmentChange" |
        "showBusinessHours"
    >("")
    const [entertainmentHidden, setEntertainmentHidden] = useState(profileData.entertainmentHidden);
    const [showModal, setShowModal] = useState(false)
    const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
    const [showReauthModal, setShowReauthModal] = useState(false);
    const [showVerifyPhoneNumberModal, setShowVerifyPhoneNumberModal] = useState(false);
    const [monthlySpecialsImageKey, setMonthlySpecialsImageKey] = useState<string>(profileData.monthlySpecialsImageKey);

    const [profilePictureKey, setProfilePictureKey] = useState(profileData.profilePictureKey);
    const [state, setState] = useState({
        accountName: profileData.displayName,
        email: profileData.email,
        address: profileData.address,
        monthlySpecialsUrl: profileData.monthlySpecialsUrl,
        website: profileData.websiteUrl || "",
        phoneNumber: profileData.phoneNumber,
        usernameError: false,
        coords: profileData.coords,
        facebookUrl: profileData.facebookUrl || "",
        tiktokUrl: profileData.tiktokUrl || "",
        instagramUrl: profileData.instagramUrl || "",
    });



    const [schedule, setSchedule] = useState<any>(isObjEmpty({...profileData.schedule}) ? {} : {...profileData.schedule})
    const [entertainmentSchedule, setEntertainmentSchedule] = useState<any>(isObjEmpty({...profileData.entertainmentSchedule}) ? undefined : Object.values(profileData.entertainmentSchedule))
    const [foodSchedule, setFoodSchedule] = useState<any>(isObjEmpty({...profileData.foodSchedule}) ? undefined : Object.values(profileData.foodSchedule))
    
    const [employeeData, setEmployeeData] = useState(isObjEmpty({...profileData.employees}) ? {} : {...profileData.employees})
    const isUserFoodTruck = userData.accountType === 'account' && userData.establishmentType === 'Food Truck';
    const isProfileFoodTruck = profileData.accountType === 'account' && profileData.establishmentType === 'Food Truck';
    const [showProfilePictureModal, setShowProfilePictureModal] = useState(false)
    const [showCoverPictureModal, setShowCoverPictureModal] = useState(false)
    const [selectedCoverPhoto, setSelectedCoverPhoto] = useState<string>("")

    const nav = useNavigation();
    useEffect(() => {
        const unsubscribe = nav.addListener('blur', () => {
            if (showModal) {
                //Leaving screen, clearing modal
                setShowModal(false);
                setEdittingMode("");
            }
        });

        return () => {
            unsubscribe();
        };
    }, [nav, showModal]);


    useEffect(()=>{
        const onEmployeesChange = (employees: any)=>{
            if(employees){
                const promises = Object.keys(employees).map((uid)=>{
                    return Fire.query(`publicUserData/${uid}`)
                })
                let employeeData: {[key: string]: UserData} = {};
                Promise.all(promises).then((snapshots: any)=>{
                    //Get userData from snapshots
                    snapshots.forEach(function(snapshot: any) {
                        let userData = snapshot.val();
                        employeeData[userData.uid] = userData;
                    });
                    setEmployeeData(employeeData);
                })
            }
            else setEmployeeData({})
        }
        const onPictureKeysChange = (pictureKeys: any)=>{
            if (pictureKeys) {
                const pictureData = Object.values(pictureKeys).map((key)=>{return `${IMAGEKIT_URL}/${key}`});
                setUploadedPictures(pictureData);
            }
            else setUploadedPictures([])
        }
        const onScheduleChange = (schedule: any)=>{
            if(schedule){
                setSchedule(schedule);
                dispatch(updateUserDataProperty({property: "schedule", value: schedule}));
            }
        }
        const onPhoneNumberChange = (phoneNumber: any)=>{
            if(phoneNumber) setState((prevState)=>{prevState.phoneNumber = phoneNumber; return prevState;});
        }
        const onAddressChange = (address: any)=>{
            if(address) setState((prevState)=>{prevState.address = address; return prevState;});
        }
        const onEntertainmentHiddenChange = (value: any)=>{
            if(value === false || value === true) setEntertainmentHidden(value);
        }
        const onProfileDataChange = (userData: any)=>{
            if(!userData) return;
            setProfileData(userData)
        }
        const onGigDataChange = (gigData: any)=>{
            // console.log("NewChatRoomData: ", newChatRoomData);
            // console.log("chatRooms/${userData.uid}: ", `chatRooms/${userData.uid}`);
            if(!gigData) return;
            //@ts-ignore TODO - Why does it complain idk
            const entertainmentGigs = Object.values(gigData).filter((gig: Gig)=>{
                return gig.type ? gig.type === 'entertainment' : true
            })
            setEntertainmentSchedule(entertainmentGigs);
            // getAndSetBlockedByList(Object.keys(newChatRoomData));
        }
        const onFoodGigDataChange = (gigData: any)=>{
            if(!gigData) return;
            //@ts-ignore TODO - Why does it complain idk
            const entertainmentGigs = Object.values(gigData).filter((gig: Gig)=>{
                return gig.type ? gig.type === 'food' : true
            })
            setFoodSchedule(entertainmentGigs);
            // getAndSetBlockedByList(Object.keys(newChatRoomData));
        }
        const onTopLevelDataChange = (newProfileData: any)=>{
            if(!newProfileData) return;
            setProfileData(newProfileData);
        }
        var gigDataRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/entertainmentSchedule`, onGigDataChange)
        var foodDataRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/foodSchedule`, onFoodGigDataChange)
        let profileDataRef = Fire.listenToRoute(`publicUserData/${profileData.uid}`, onProfileDataChange)
        let pictureKeysRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/pictureKeys`, onPictureKeysChange)
        let scheduleRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/schedule`, onScheduleChange)
        let phoneNumberRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/phoneNumber`, onPhoneNumberChange)
        let employeesRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/employees`, onEmployeesChange)
        let addressRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/address`, onAddressChange)
        let entertainmentHiddenRef = Fire.listenToRoute(`publicUserData/${profileData.uid}/entertainmentHidden`, onEntertainmentHiddenChange)
        let topLevelDataRef = Fire.listenToRoute(`publicUserData/${profileData.uid}`, onTopLevelDataChange)
        return () =>{
            Fire.off(gigDataRef, "value", onGigDataChange)
            Fire.off(foodDataRef, "value", onFoodGigDataChange)
            Fire.off(profileDataRef, "value", onProfileDataChange)
            Fire.off(scheduleRef, "value", onScheduleChange)
            Fire.off(phoneNumberRef, "value", onPhoneNumberChange)
            Fire.off(pictureKeysRef, "value", onPictureKeysChange)
            Fire.off(employeesRef, "value", onEmployeesChange)
            Fire.off(addressRef, "value", onAddressChange)
            Fire.off(entertainmentHiddenRef, "value", onEntertainmentHiddenChange)
            Fire.off(topLevelDataRef, "value", onTopLevelDataChange)
        }

    }, [])



    const BusinessStatus = ()=>{
        const foodTruckSchedule: Gig[] = foodSchedule ? Object.values(profileData.foodSchedule) : [];
        const {status, text} = isProfileFoodTruck ?  getFoodTruckStatus(foodTruckSchedule, "food"): getBusinessStatus(schedule);
        return(
            <Text onPress={()=>{
                setShowModal(true);
                setEdittingMode("showBusinessHours");
            }}
                style={{
                    marginVertical: 5,
                    // color: status ? COLORS.greenAccent : COLORS.primaryRed,
                    color: COLORS.primaryBlue,
                    fontSize: scaleFontSize(12),
                }}
            >
                {text}
            </Text>
        )
    }

    const onModifyPictures = ()=>{
        setShowModal(true);
        setEdittingMode("coverPictures");
    }

    const getTitle = ()=>{
        if(edittingMode === "coverPictures") return <Text style={{fontSize: scaleFontSize(20)}}>Upload your pictures here</Text>;
        if(edittingMode === "selectProfilePicture") return <Text style={{fontSize: scaleFontSize(20)}}>Select your profile picture</Text>
        if(edittingMode === "nameAndAddressChange") return ''
        if(edittingMode === "showBusinessHours") return(
            <Text style={{fontWeight: '700', fontSize: scaleFontSize(16)}}>
                {profileData.displayName || profileData.username}'s Schedule
            </Text>
        )
        
    }

    const onReauthSuccess = async ()=>{
        await updateCurrentUserEmail(
            state.email,
            ()=>alert("Your email has been updated!"), //On Success
            onChangeEmailFailure //On Failure
        )
    }

    const onChangeEmailFailure = (error: any)=>{
        console.log("Error code: ", error.code);
        if(error.code === 'auth/requires-recent-login'){
            setShowModal(false);
            setShowReauthModal(true);
        }
        else{
            catchErrorForSentry(error);
            alert("Sorry, we failed to update your email - Please try again");
        }
    }

    const onChangeEmailSuccess = (success: any)=>{
        //Updating database
        Fire.setUserDataRoute(`${profileData.uid}/email`, state.email);
        //Updating Local Data
        dispatch(updateUserDataProperty({property: 'email', value: state.email}));
    }



    const DealsEditor = (props: any)=>{
        const [dealOne, setDealOne] = useState(isObjEmpty({...profileData.dealOne}) ? {title: "", details: "", link: ""} : {...profileData.dealOne});
        const [dealTwo, setDealTwo] = useState(isObjEmpty({...profileData.dealTwo}) ? {title: "", details: "", link: ""} : {...profileData.dealTwo});    

        const onUpdateDeals = ()=>{
            if(dealOne.link && !isUrlValid(dealOne.link)) return alert("Your link for deal one is not valid! Please check the formatting")
            if(dealTwo.link && !isUrlValid(dealTwo.link)) return alert("Your link for deal two is not valid! Please check the formatting")

            // console.log("Deal one: ", dealOne);
            // console.log("Deal two: ", dealTwo);

            //Updating database
            Fire.setUserDataRoute(`${profileData.uid}/dealOne`, dealOne);
            Fire.setUserDataRoute(`${profileData.uid}/dealTwo`, dealTwo);

            //Updating Local Data
            dispatch(updateUserDataProperty({property: 'dealOne', value: dealOne}));
            dispatch(updateUserDataProperty({property: 'dealTwo', value: dealTwo}));

            setShowModal(false);

            alert("Your deals have been updated");
        }

        // const [test, setTest] = useState({...profileData.dealOne} || {title: "test 1", details: "test 2", link: "test 3"})

        const renderDealInputs = ()=>{
            let dealsInput = [];
            // console.log("deals: ", deals);
            for (let index = 0; index < 2; index+=1){
                const dealObj = ((index: number)=>{
                    if(index) return dealTwo
                    else return dealOne
                })(index)
                const dealStateFunction = ((index: number)=>{
                    if(index) return setDealTwo
                    else return setDealOne
                })(index)
                dealsInput.push(
                    <View key={index} style={{flex: 95/2, justifyContent: 'center', alignItems: 'center'}}>
                        <Text style={{
                            // marginLeft: '8%',
                            fontWeight: '500', fontSize: scaleFontSize(14), alignSelf: 'center'
                        }}>
                            Deal {index+1}
                        </Text>
                        <Input
                            label={`Title`}
                            style={{
                                alignSelf: 'center',
                                width: '80%',
                                // ...isWeb() ? {minWidth: 350} : {},
                            }}
                            inputStyle={{
                                width: '80%',
                                // ...isWeb() ? {minWidth: 350} : {},
                                borderColor: COLORS.primaryBlue,
                                borderRadius: 10,
                                borderWidth: 1,
                            }}
                            defaultValue={dealObj.title || ""}
                            onInputChange={(value:string)=>dealStateFunction((prevState: any)=>{prevState.title = value; return prevState})}
                        />
                        <Input
                            label={`Details`}
                            multiline={true}
                            style={{
                                width: '80%',
                                // ...isWeb() ? {minWidth: 350} : {},
                            }}
                            inputStyle={{
                                width: '80%',
                                // ...isWeb() ? {minWidth: 350} : {},
                                borderColor: COLORS.primaryBlue,
                                borderRadius: 10,
                                borderWidth: 1,
                            }}
                            defaultValue={dealObj.details || ""}
                            onInputChange={(value:string)=>dealStateFunction((prevState: any)=>{prevState.details = value; return prevState})}
                        />
                        <ValidateInput
                            errorMessage={"There is a problem with your link formatting!"}
                            errorName={"Link Error"}
                            regex={regexPatterns.url}
                            label={`Link`}
                            style={{
                                width: '80%',
                                // ...isWeb() ? {minWidth: 350} : {},
                            }}
                            inputStyle={{
                                width: '80%',
                                // ...isWeb() ? {minWidth: 350} : {},
                                borderColor: COLORS.primaryBlue,
                                borderRadius: 10,
                                borderWidth: 1,
                            }}
                            defaultValue={dealObj.link || ""}
                            onInputChange={(value:string)=>dealStateFunction((prevState: any)=>{prevState.link = value; return prevState})}

                            // regex={regexPatterns.username}
                            // errorMessage={'Usernames can only contain up to 30 of these characters: a-z, 0-9, _ and . (only in the middle)'}
                            // errorName={'usernameError'}
                            // defaultValue={state.accountName}
                            // onInputChange={(value:string)=>setState((prevState)=>{prevState.accountName = value; return prevState})}
                            // style={{marginBottom: 10}}
                            // inputStyle={{borderColor: COLORS.primaryBlue, borderRadius: 10, borderWidth: 1,}}
                            // state={state}
                            // setState={setState}
                        />
                    </View>
                )
            }
            return dealsInput
        }

        const SubmitButton = ()=>{
            return (
                <CustomButton
                        onPress={onUpdateDeals}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: scaleFontSize(12.5),
                        }}
                        style={{
                            backgroundColor: COLORS.primaryBlue,
                            alignSelf:'flex-start',
                            marginTop: 25,
                            // height: '25%',
                            padding:10,
                            width: 100,
                            // minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
            )
        }

        return(
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}>
                <View style={{
                    // flex: .95,
                    // height: '70%',
                    width: '90%',
                    // alignSelf: 'center',
                    // alignItems: 'center',
                    // alignContent: 'center',
                    flexDirection: isMobile() ? 'column' : 'row',
                    justifyContent: 'space-around'
                }}>
                    {renderDealInputs()}
                    {isMobile() && <SubmitButton/>}
                </View>
                {!isMobile() && <SubmitButton/>}
            </View>
        )
    }

    const SpecialsEditor = (props: any)=>{
        const [dailySpecials, setDailySpecials] = useState(isObjEmpty({...userData.dailySpecials}) ? {0: "", 1: "", 2: ""} : {...userData.dailySpecials});
        const [weeklySpecials, setWeeklySpecials] = useState(isObjEmpty({...userData.weeklySpecials}) ? {0: "", 1: "", 2: ""}  : {...userData.weeklySpecials});
        const [monthlySpecials, setMonthlySpecials] = useState(isObjEmpty({...userData.monthlySpecials}) ? {0: "", 1: "", 2: ""}  : {...userData.monthlySpecials});
        
        const { specialType } = props;

        const isMonthlySpecial = specialType === 'monthly';

        const specials: string[] = (()=>{
            if(specialType === 'daily' && userData.dailySpecials) return Object.values(userData.dailySpecials)
            if(specialType === 'weekly' && userData.weeklySpecials) return Object.values(userData.weeklySpecials)
            if(specialType === 'monthly' && userData.monthlySpecials) return Object.values(userData.monthlySpecials)
            return [];
        })()

        const stateFunction = (()=>{
            if(specialType === 'daily') return setDailySpecials
            if(specialType === 'weekly') return setWeeklySpecials
            if(specialType === 'monthly') return setMonthlySpecials
            return ()=>{}
        })()

          
        const onModifySpecial = ()=>{
            const specialState = (()=>{
                if(specialType === 'daily') return dailySpecials
                if(specialType === 'weekly') return weeklySpecials
                if(specialType === 'monthly') return monthlySpecials
            })()

            // console.log("Daily Specials: ", dailySpecials)
            // console.log("Weekly Specials: ", weeklySpecials)
            // console.log("Monthly Specials: ", monthlySpecials)

            //Updating database
            Fire.setUserDataRoute(`${userData.uid}/${specialType}Specials`, specialState);
            //Updating local data
            dispatch(updateUserDataProperty({property: `${specialType}Specials`, value: specialState}))
            alert("Your specials have been updated.");
        }
        return(
            <View style={{
                padding: 10,
                height: '100%',
                width: '100%',
                // flexDirection: isMonthlySpecial ? (!isWeb() ? 'column' : 'row') : 'column',
                // justifyContent: isMonthlySpecial && isWeb() ? 'space-around' : 'center',
                flexDirection: 'column',
                justifyContent: isMonthlySpecial && isWeb() ? 'space-around' : 'center',
                // ...(isMonthlySpecial ? {flexGrow: .40} : {}),
                // alignItems: isWeb() ? 'center' : 'flex-start',
                // justifyContent: 'center',
                // borderColor: 'blue',
                // borderWidth: 5,
                ...(isWeb() ? {alignItems: 'center'} : {}),
            }}>
                <View style={{
                    flex: isMonthlySpecial && isWeb() ? .45 : 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginVertical: 10,
                    ...( isWeb() ? {alignSelf: 'center'} : {}),
                    // borderColor: 'green',
                    // borderWidth: 1,
                    width: isWeb() ? '50%' : '100%',
                    ...(isWeb() ? {minWidth: 350} : {})
                    // paddingBottom: SCREEN_SIZE.height*.025
                }}>
                    {/* {renderSpecials()} */}
                    <Text style={{ fontSize: scaleFontSize(16), fontWeight: '500', marginBottom: 10 }}>
                        {`${specialType.charAt(0).toUpperCase() + specialType.slice(1)} specials`}
                    </Text>
                    <Input
                        multiline={true}
                        showLabel={false}
                        style={{
                            width: isWeb() ? '50%' : '100%',
                            ...(isWeb() ? {minWidth: 350} : {})
                            // minWidth: SCREEN_SIZE.width * .25
                        }}
                        inputStyle={{
                            // width: isWeb() ? '50%' : '100%',
                            ...(isWeb() ? {height: 100} : {}),
                            // ...(isWeb() && (specialType !== "monthly") ? {height: 100} : {}),
                            // minWidth: SCREEN_SIZE.width * .25,
                            borderColor: COLORS.primaryBlue,
                            borderRadius: 10,
                            borderWidth: 1,
                        }}
                        defaultValue={specials[0] ? specials[0] : ""}
                        onInputChange={(value:string)=>stateFunction((prevState: any)=>{prevState[0] = value; return prevState})}
                    />
                    <CustomButton
                        onPress={onModifySpecial}
                        title={"Submit"}
                        textStyle={{
                            color: 'white',
                            fontWeight: '500', 
                            fontSize: scaleFontSize(12.5),
                        }}
                        style={{
                            backgroundColor: COLORS.primaryBlue,
                            alignSelf:'center',
                            // height: '25%',
                            padding:10,
                            width: 100,
                            // minWidth: 100,
                            borderRadius: 10,
                        }}
                    />
                    {
                        isMonthlySpecial && <MonthlySpecialsUrlEditor
                            containerStyle={{
                                marginTop: 10,
                                width: isWeb() ? '50%' : '100%',
                                ...(isWeb() ? {minWidth: 350} : {})

                            }}
                        />
                    }
                </View>
                {
                isMonthlySpecial &&
                <Card style={{
                    // marginTop: SCREEN_SIZE.height*.025,
                    // flex: !isWeb() ? .45 : .45,
                    flexGrow: isWeb() ? .25 : .55,
                    // padding: 10,
                    // maxHeight: '75%',
                    // alignSelf: 'center',
                    // borderColor: 'red', 
                    // borderWidth: 1,
                    alignContent: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <TouchableContainer
                    style={{
                        // flexGrow: .1,
                        // alignItems: 'center',
                        // alignContent: 'center',
                        // justifyContent: 'center',
                    }}
                    onPress={()=>{
                        setShowModal(false);
                        setShowUploadPictureModal(true);
                    }}>
                        <Image 
                            source={monthlySpecialsImageKey ? {uri: `${IMAGEKIT_URL}/${monthlySpecialsImageKey}`}: require('../assets/alwayslocal-logo.png')} 
                            style={{
                                // ...(isWeb() ?
                                // {
                                //     height: SCREEN_SIZE.width*.25,
                                //     width: SCREEN_SIZE.width*.25,
                                //     // height: '25%',
                                //     // width: '25%',
                                //     // maxWidth:
                                // }
                                // :
                                // {
                                //     // flex: 1,
                                //     // height: "100%",
                                //     // height: SCREEN_SIZE.width*.25,
                                //     // width: SCREEN_SIZE.width*.25,
                                //     // height: '100%',
                                //     // width: '100%',
                                // }),
                                height: SCREEN_SIZE.width*.25,
                                width: SCREEN_SIZE.width*.25,
                                // borderWidth: 2,
                                // borderColor: 'red',
                                alignSelf: 'center',
                            }} 
                            resizeMode="contain" 
                        />
                        <Text style={{textAlign: 'center', fontSize: isWeb() ? scaleFontSize(14) : scaleFontSize(12), fontWeight: '500'}}>
                            {`${isWeb() ? "Click" : "Tap"} here to change your monthly special image`}
                        </Text>
                    </TouchableContainer>
                </Card>
                }
            </View>
        )
    }

    const renderDeals = ()=>{
        const {dealOne, dealTwo} = profileData
        const deals = [dealOne, dealTwo];
        if(!dealOne && !dealTwo) return(
            <View>
                <Text style={{fontSize: scaleFontSize(12), color: 'white', fontWeight: '800'}}>
                    {isProfileOwner ? 'Click here to create your deals' : 'No deals here yet!'}
                </Text>
            </View>
        )
        return Object.values(deals).filter((deal: any)=>{
            if(!deal.title|| !deal.details || !deal.link) return false;
            return true;
        }).map((deal: any, index: number)=>{
            const { title, details, link} = deal
            return (
                <View key={index} style={{flex: .95/2, width: '35%'}}>
                    <Card style={{flex: 1, padding: 10, marginBottom: 10}}>
                        {/* <View> */}
                            {/* Deal title */}
                            <View style={{alignItems: 'center'}}>
                                <Text style={{ fontSize: scaleFontSize(15), textAlign: 'center', textAlignVertical: 'center', fontWeight: '500'}}> {`${title}`} </Text>
                            </View>
                            {/* Deal details */}
                            <View style={{ alignItems: 'center'}}>
                                <Text style={{fontSize: scaleFontSize(12), textAlign: 'center', textAlignVertical: 'center'}}> {`${details}`} </Text> 
                            </View>
                        {/* </View> */}
                        {/* Who posted it? Label */}
                    </Card>
                    <View style={{alignSelf: 'flex-end', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                        {/* <Text style={{textAlign: 'center'}}> From: <Text> Park Tavern</Text> </Text>  */}
                        <CustomButton
                            onPress={(event: any)=>{
                                event.preventDefault();
                                console.log("Link: ", link);
                                Linking.openURL(ensureProtocol(link));
                            }}
                            title={
                                <View style={{flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}>
                                    <FontAwesome5 name="money-bill-alt" size={SCREEN_SIZE.width * .055 > 50 ? 50 : SCREEN_SIZE.width * .055} color="white" />
                                    <Text style={{
                                        fontSize: scaleFontSize(16),
                                        fontWeight: '600',
                                        textAlignVertical: 'center',
                                        textAlign: 'center',
                                        color: 'white',
                                    }}>
                                        REDEEM!
                                    </Text>
                                </View>
                            }
                            style={{
                                backgroundColor: COLORS.primaryBlue,
                                padding: 10,
                                borderRadius: 10,
                                width: '100%',
                                alignSelf: 'center',
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'center',
                                // margin: 5,
                            }}
                            textStyle={{
                                color: 'white'
                            }}
                        />
                    </View>
                </View>
            )
        })
    }

    const PinCoverPhotoButton = (props: any)=>{
        const { selectedImageKey } = props;
        const onPinCoverPhoto = ()=>{
            Fire.setUserDataRoute(`${profileData.uid}/coverPhotoKey`, selectedImageKey);
            dispatch(updateUserDataProperty({property: 'coverPhotoKey', value: selectedImageKey}))
            setEdittingMode("");
            setShowModal(false);
        }
        return(
            <CustomButton
                onPress={onPinCoverPhoto}
                style={{
                    width: '50%',
                    borderRadius: 10,
                    padding: 10,
                    alignSelf: 'center',
                    backgroundColor: COLORS.primaryBlue,
                    marginBottom: 10,
                }}
                textStyle={{
                    fontSize: scaleFontSize(15),
                    color: '#fff',
                }}
                title={'Pin as cover photo'}
            />
        )
    }

    const renderModalContent = ()=>{
        if(edittingMode === "coverPictures") return <AccountChoosePictures PinCoverPhotoButton={PinCoverPhotoButton}/>
        if(edittingMode === "selectProfilePicture") return <AccountSelectProfilePicture profilePictureKey={profilePictureKey} onPictureSelected={onPictureSelected}/>
        if(edittingMode === "dailySpecialsChange") return <SpecialsEditor specialType="daily"/>
        if(edittingMode === "weeklySpecialsChange") return <SpecialsEditor specialType="weekly"/>
        if(edittingMode === "monthlySpecialsChange") return <SpecialsEditor specialType="monthly"/>
        if(edittingMode === "inTheBizChange") return <InTheBizEditor/>
        if(edittingMode === "dealsChange") return <DealsEditor/>
        if(edittingMode === "entertainmentChange") return <AccountChoosePictures mode={"entertainment"}/>
        if(edittingMode === "showBusinessHours") return isProfileFoodTruck ? <FoodTruckSchedule foodSchedule={foodSchedule} profileData={profileData} navigation={navigation} /> : <BusinessHours schedule={schedule}/>
        if(edittingMode === "nameAndAddressChange") return <AccountDataEditor navigation={props.navigation} route={{params: {noBirthday: false}}}/>
    }

    const onPictureSelected = (newKey: string)=>{
        setProfilePictureKey(newKey);
        setShowModal(false);
    }
    
    const onAccountDataEditorPress = ()=>{
        !isWeb() ?
        navigation.push('AccountDataEditor') : 
        (()=>{
            setEdittingMode('nameAndAddressChange');
            setShowModal(true);
        })()
    }

    const onProfilePicturePress = ()=>{
        if(!uploadedPictures.length) return alert("Click your cover photo above before selecting a profile picture!");
        setEdittingMode('selectProfilePicture');
        setShowModal(true);
    }

    const onRemoveProfilePicture = async ()=>{

        let response = await confirmDecision(
            `Removing profile picture`,
            `Are you sure you would like to remove your profile picture?`
        );
            
        if(!response) return;
        // updateLocalPhotoBasedOnModalType(modalType, '');

        // Delete old photo from cache
        //@ts-ignore
        // deleteImageFromCache(profileData[`${keyProperty}`]);

        // deletePhotoFromStorageIfNotUsedInDating();
        //@ts-ignore
        let pictureKey = profileData[`${keyProperty}`];
        // deleteFromStorage(pictureKey, "image")

        //update database key
        // updateDatabaseKeyBasedOnModalType(modalType, '');
        //Update globalState
        // updateGlobalStateImageKey('');

        // setShowUploadPictureModal(false);
    }


    const isThereAValidSpecial = (obj: Object)=>{
        if(!obj) return false;
        const arr = Object.values(obj);
        if(arr.length) return true;
        else return false;
        // return arr.some((val: string)=>{
        //     if(val) return true;
        //     else return false;
        // })
    }

    const onPictureUploaded = (imageKey: string)=>{
        //updateFrontEnd
        // updateLocalPhotoBasedOnModalType(modalType, imageKey);
        dispatch(updateUserDataProperty({property: 'monthlySpecialsImageKey', value: imageKey}))

        //update database key
        Fire.setUserDataRoute(`${profileData.uid}/monthlySpecialsImageKey`, imageKey);


        //Update globalState
        setMonthlySpecialsImageKey(imageKey);

        // setShowUploadPictureModal(false);
    }

    const onRemoveMonthlySpecialImage = async ()=>{
        // let imageTypeName = (modalType === 'cover-photo' ? 'cover photo' : 'profile picture');
        // let keyProperty = (modalType === 'cover-photo' ? 'coverPhotoKey' : 'profilePictureKey');

        let response = await confirmDecision(
            `Removing monthly specials image`,
            `Are you sure you would like to proceed?`
        );
            
        if(!response) return;
        //Update local global state
        dispatch(updateUserDataProperty({property: 'monthlySpecialsImageKey', value: ""}))
        //update database key
        Fire.setUserDataRoute(`${profileData.uid}/monthlySpecialsImageKey`, "");

        // Delete old photo from cache
        //@ts-ignore
        deleteImageFromCache(monthlySpecialsImageKey);
        //Update monthlySpecialsImageKey
        setMonthlySpecialsImageKey("")

        // setShowUploadPictureModal(false);
    }

    const onFollowPress = (following: Boolean)=>{
        const { profilePictureKey } = userData;
        setFollowing(following);
        if(following){
            console.log(`Following ${profileData.displayName}`);
            //Add current user as a follower to profile owner
            Fire.updateRoute(`publicUserData/${profileData.uid}/uidsFollowingUser/`, {[`${userData.uid}`]: true})
            
            //Add profileData.uid to uidsUserFollows of current user.
            Fire.updateRoute(`publicUserData/${userData.uid}/uidsUserFollows/`, {[`${profileData.uid}`]: true})
            
            addNotificationToUser(profileData.uid, {
                id: `${userData.uid}-follow`,
                image: `${IMAGEKIT_URL}/${profilePictureKey}`,
                content: `${userData.displayName} started following you!`,
                timeCreated: new Date().getTime(),
                read: false,
                //When notification is pressed it won't direct user to post screen.
                type: {user: userData.uid},
            });

            dispatch(updateWhoUserFollows({property: profileData.uid, value: true}));

        }
        else{
            console.log(`Unfollowing ${profileData.displayName}`);

            Fire.updateRoute(`publicUserData/${profileData.uid}/uidsFollowingUser/`, {[`${userData.uid}`]: null});
            Fire.updateRoute(`publicUserData/${userData.uid}/uidsUserFollows/`, {[`${profileData.uid}`]: null});
            // //Remove current user as a follower to profile owner

            removeNotificationFromUser(profileData.uid, `${userData.uid}-follow`);

            //Modifying Local User Data
            dispatch(updateWhoUserFollows({property: profileData.uid, value: null}))
        }
    }

    const userType = ()=>{
        if(userData.accountType === 'staff') return 'Service worker';
        if(userData.accountType === 'entertainment') return 'Entertainer';
    }

    const sendEmploymentRequest = async ()=>{
        const response = await confirmDecision(
            `Requesting employment`,
            `Are you sure you would like to request that ${profileData.displayName} be listed as your employer?\n\nIf they accept your request you both will be added to eachother's profiles`
        )
        if(!response) return;
        addNotificationToUser(profileData.uid, {
            id: `${userData.uid}-${userData.accountType}-request`,
            image: `${IMAGEKIT_URL}/${profileData.profilePictureKey}`,
            content: `${userData.displayName} requested you as their employer!`,
            timeCreated: new Date().getTime(),
            read: false,
            //When notification is pressed it won't direct user to post screen.
            type: {request: userData.uid, type: `employer-request`},
        });
    }

    const terminateEmployment = async ()=>{
        const response = await confirmDecision(
            `Would you like to terminate your employment with ${profileData.displayName}?`,
            `You are currently employed by ${profileData.displayName} - Are you sure you would like to terminate your employment with them?\n\nThey will be notified of this event.`
        )
        if(!response) return;
        console.log("Employer: ", userData.uid);
        console.log("Employee: ", profileData.uid);
        Fire.deleteDataAtRoute(`publicUserData/${userData.uid}/employers/${profileData.uid}`)
        .then(()=>{
            //Adding employee/employer to the user who sent request
            Fire.deleteDataAtRoute(`publicUserData/${profileData.uid}/employees/${userData.uid}`)
            .then(()=>{
                const request = addNotificationToUser(profileData.uid, {
                    id: `${userData.uid}-${userData.accountType}-termination`,
                    image: `${IMAGEKIT_URL}/${profileData.profilePictureKey}`,
                    content: `${userData.displayName} terminated their employment with you.`,
                    timeCreated: new Date().getTime(),
                    read: false,
                    //When notification is pressed it won't direct user to post screen.
                    type: {request: userData.uid, type: `employer-termination`},
                });
                request.then((success)=>{
                    // alert("Request has been sent");
                })
                .catch((error)=>{
                    alert("Failed to send notify employer of employee termination - Please try again");
                    catchErrorForSentry(error);
                })
                
            })
            .catch((error: any)=>{
                console.log(`Failed to remove employer ${userData.uid} from user ${profileData.uid}`);
                catchErrorForSentry(error);
            })
        })
        .catch((error: any)=>{
            console.log(`Failed to remove employee ${profileData.uid} from employer ${userData.uid}`);
            catchErrorForSentry(error);
        })

      
    }

    const isEmployer = ()=>{
        if(!employeeData) return false;
        return Object.keys(employeeData).includes(userData.uid);
    }

    const RequestEmployementButton = ()=>{
        return(
            <TouchableContainer
                // onPress={()=>sendEmploymentRequest()}
                onPress={isEmployer() ? ()=>terminateEmployment() : ()=>sendEmploymentRequest()}
                style={{
                    flex: .10,
                    padding: 10,
                    alignItems: 'center',
                    justifyContent: isProfileOwner ? 'flex-start' : 'flex-end'
            }}>
            {/* <TouchableContainer style={{
                flexDirection: 'row',
                backgroundColor: 'black',
                justifyContent: 'center',
                padding: 5,
                borderRadius: 2.5,
                width: 200,
                height: 35,
            }}> */}
                <MaterialIcons name="work" size={SCREEN_SIZE.width * .055 > 50 ? 50 : SCREEN_SIZE.width * .055} color="black" />
                {/* <Text style={{color: 'white', fontSize: scaleFontSize(15)}}> Request Employment </Text> */}
            </TouchableContainer>
        )
    }
    const CoverPhoto = memo(()=>{
        return(
            <View style={{
                height: '30%',
                // borderWidth: 2, borderColor: 'blue'
            }}>
                {/* //@ts-ignore */}
                <Swiper
                    //@ts-ignore
                    key={uploadedPictures.length+profileData.coverPhotoKey}
                    containerStyle={{flex: 1}}
                    controlsEnabled={true}
                    controlsProps={{
                        nextTitle: "",
                        prevTitle: "",
                        //TODO: Why does this throw an error without as any?
                        NextComponent: NextButton as any,
                        PrevComponent: PrevButton as any,
                    }}
                >
                    {
                    uploadedPictures.length ?
                    reOrderUploadedPictures().map((item: any, index: number) => (
                            <TouchableContainer key={index} onPress={isProfileOwner ? onModifyPictures : ()=>{setSelectedCoverPhoto(item);setShowCoverPictureModal(true)}} style={{flex: 1}}>
                                <Image 
                                    source={{ uri: item }} 
                                    style={{
                                    flexGrow: 1,
                                    // borderWidth: 2,
                                    // borderColor: 'red',
                                    // height: 50,
                                    // width: scrollViewWidth
                                    }} 
                                    resizeMode="cover" 
                                />
                            </TouchableContainer>
                        ))
                    :
                        <TouchableContainer disabled={!isProfileOwner} onPress={onModifyPictures} style={{flex: 1, alignItems: 'center', alignContent: 'center'}}>
                            <Image 
                                source={require('../assets/alwayslocal-banner.png')} 
                                style={{
                                    flex: 1,
                                    // borderWidth: 2,
                                    // borderColor: 'red',
                                    // height: 50,
                                    // width: scrollViewWidth
                                }} 
                                resizeMode="contain" 
                            />
                        </TouchableContainer>

                    }
                </Swiper>
                {!isProfileOwner && <FollowButton following={following} onFollowPress={onFollowPress} />}
            </View>
        )
    })
    const AccountProfilePictureBar = memo(()=>{
        return(
            <Card style={{
                // ...(isWeb() ? {flex: 1,} : 
                // {height: SCREEN_SIZE.height * .20,}),
                height: SCREEN_SIZE.height * .20,
                minHeight: 165,
                flexDirection: 'row',
                borderRadius: 0,
                // borderColor: 'red', borderWidth: 1,
            }}>
                <TouchableContainer
                    style={{
                        flex: 1/3,
                        height: '100%',
                        width: '50%',
                        alignItems: 'flex-start',
                        // borderColor: 'red',
                        // borderWidth: 1,
                    }}
                    onPress={isProfileOwner ? onProfilePicturePress : ()=>setShowProfilePictureModal(true)}>
                    <Image 
                        source={profilePictureKey ? { uri: `${IMAGEKIT_URL}/${profilePictureKey}` } : require('../assets/placeholder-picture.png')} 
                        style={{
                            // flexGrow: 1,
                            height: '100%',
                            // height: SCREEN_SIZE.height * .20,
                            width: '100%',
                            // borderWidth: 1,
                            // borderColor: 'blue',
                            alignSelf: 'flex-start',
                            // flex: 1,
                            // aspectRatio: 1.5/2,
                            // borderTopLeftRadius: 10,
                            // borderBottomLeftRadius: 10,
                        }} 
                        resizeMode={profilePictureKey ? "cover" : "contain"}
                    />
                </TouchableContainer>

                <TouchableContainer
                    disabled={!isProfileOwner}
                    onPress={onAccountDataEditorPress}
                    style={{
                        flex: 2/3,
                        padding: 10,
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        // minHeight: 125,
                        // borderColor: 'purple',
                        // borderWidth: 1,
                    }}>
                    <View style={{height: '100%', width: '100%', justifyContent: 'space-around'}}>
                        <Text style={{marginBottom: 5, fontSize: scaleFontSize(14), fontWeight: '800', width: '80%'}}>
                            {state.accountName}
                        </Text>
                        <Text style={{marginVertical: 2, fontSize: scaleFontSize(12),width: '100%'}}>
                            {state.address}
                        </Text>
                        {(profileData.isPhoneNumberVisible && !!state.phoneNumber) &&
                            <TouchableContainer onPress={()=>Linking.openURL(`tel:${state.phoneNumber}`)}>
                                <Text style={{marginVertical: 2, fontSize: scaleFontSize(12),width: '100%', color: 'blue',}}>
                                    {formatPhoneNumber(state.phoneNumber)}
                                </Text>
                            </TouchableContainer>
                        }
                        <View style={{
                            marginTop: 5,
                            width: isWeb() ? '100%' : '40%',
                            alignSelf: 'flex-start',
                            // alignItems: 'baseline',
                            // flexDirection: 'column',
                            alignItems: !state.website && !state.facebookUrl && !state.instagramUrl ? 'baseline' : 'center',
                            flexDirection: !state.website && !state.facebookUrl && !state.instagramUrl ? 'column' : 'row',
                            justifyContent: 'flex-start'
                        }}>
                            {profileData.isEmailVisible && <TouchableContainer style={{marginRight: SCREEN_SIZE.width * .01}} onPress={()=>Linking.openURL(`mailto:${state.email}`)}>
                                <MaterialIcons name="email" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="black" />
                            </TouchableContainer>}
                            {(profileData.isWebsiteVisible && !!state.website) && <TouchableContainer style={{marginRight: SCREEN_SIZE.width * .01}} onPress={()=>{Linking.openURL(state.website)}}>
                                <MaterialCommunityIcons 
                                name="web" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="black" />
                            </TouchableContainer>}
                            {!!state.tiktokUrl && <TouchableContainer style={{marginRight: SCREEN_SIZE.width * .01}} onPress={()=>{Linking.openURL(state.tiktokUrl)}}>
                                <FontAwesome5 name="tiktok" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="black" />
                            </TouchableContainer>}
                            {!!state.facebookUrl && <TouchableContainer style={{marginRight: SCREEN_SIZE.width * .01}} onPress={()=>{Linking.openURL(state.facebookUrl)}}>
                                <FontAwesome5 name="facebook" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="black" />
                            </TouchableContainer>}
                            {!!state.instagramUrl && <TouchableContainer style={{marginRight: SCREEN_SIZE.width * .01}} onPress={()=>{Linking.openURL(state.instagramUrl)}}>
                                <AntDesign name="instagram" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="black" />
                            </TouchableContainer>}
                        </View>
                        <BusinessStatus/>
                            {!!state.address && <DirectionsButton
                                location={{
                                    latitude: state.coords.latitude,
                                    longitude: state.coords.longitude
                                }}
                                label={profileData.displayName}
                            />}
                        {/* <TouchableContainer
                            style={{
                                flex: .10,
                                height: '100%',
                                padding: 5,
                                alignItems: 'center',
                                justifyContent: isProfileOwner ? 'flex-start' : 'flex-end'
                        }}>
                            {
                                isProfileOwner ?
                                <FontAwesome5 onPress={onAccountDataEditorPress} name="edit" size={24} color="black" /> :
                                <View style={{
                                    flex: 1,
                                    // borderColor: 'red', borderWidth: 1,
                                    // padding: 10,
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    justifyContent: userIsAccount ? 'space-between' : 'flex-end'
                
                                }}>
                                    {(userData.accountType === 'account' && !isProfileOwner) && <RequestEmployeeButton/>}
                                    <MessageButton navigation={navigation} profileData={profileData} />
                                </View>
                            }
                        </TouchableContainer> */}
                    </View>
                </TouchableContainer>
                {
                    (
                        (
                            userData.accountType === 'staff' ||
                            (userData.accountType ==='entertainment') ||
                            (isUserFoodTruck)
                        ) && 
                        !isProfileOwner
                    ) &&
                    <RequestEmployementButton/>
                }
                {isProfileOwner && <FontAwesome5 onPress={onAccountDataEditorPress} name="edit" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="black" />}

            </Card>
        )
    })

    const DailySpecials = ()=>{
        return(
            <TouchableContainer disabled={!isProfileOwner} style={{flex: .97/2}} onPress={()=>{setShowModal(true); setEdittingMode("dailySpecialsChange")}}>
                <Card style={{flex: 1, padding: 5}}>
                    <Text style={{fontSize: scaleFontSize(15), alignSelf: 'center', fontWeight: '800', color: COLORS.primaryRed}}> DAILY SPECIALS </Text>
                    {isThereAValidSpecial(profileData.dailySpecials) ?
                        <View>
                            { profileData.dailySpecials[0] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.dailySpecials[0]}</Text> }
                        </View>
                        :
                        <Text style={{fontSize: scaleFontSize(12), fontWeight: '800'}}>
                            {!isProfileOwner ? 'No specials here yet!' : 'Click to create your daily specials'}
                        </Text>
                    }
                </Card>
            </TouchableContainer>
        )
    }

    const WeeklySpecials=()=>{
        return(
            <TouchableContainer disabled={!isProfileOwner} style={{flex: .97/2}} onPress={()=>{setShowModal(true); setEdittingMode("weeklySpecialsChange")}}>
                <Card style={{flex: 1, padding: 10}}>
                    <Text style={{fontSize: scaleFontSize(15), alignSelf: 'center', fontWeight: '800', color: COLORS.primaryRed}}> WEEKLY SPECIALS </Text>
                        {isThereAValidSpecial(profileData.weeklySpecials) ?
                            <View>
                                { profileData.weeklySpecials[0] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.weeklySpecials[0]}</Text> }
                                {/* { profileData.weeklySpecials[1] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.weeklySpecials[1]}</Text> }
                                { profileData.weeklySpecials[2] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.weeklySpecials[2]}</Text> } */}
                            </View>
                            :
                            <Text style={{fontSize: scaleFontSize(12), fontWeight: '800'}}>
                                {!isProfileOwner ? 'No specials here yet!' : 'Click to create your weekly specials'}
                            </Text>
                        }
                </Card>
            </TouchableContainer>
        )
    }

    const sendToMonthlySpecialsPage = ()=> Linking.openURL(state.monthlySpecialsUrl)
    

    const MonthlySpecials=()=>{
        return(
            <TouchableContainer
                disabled={!isProfileOwner}
                style={{minHeight: SCREEN_SIZE.height * .22, flexGrow: 1, padding: 2.5}}
                onPress={()=>{setShowModal(true); setEdittingMode("monthlySpecialsChange")}}>
                <Card style={{flex: 1, flexDirection: 'row', padding: 10}}>
                    <View style={{flex: 1.75/3}}>
                        <Text style={{flexDirection: 'row', fontSize: scaleFontSize(15), fontWeight: '800', color: COLORS.primaryRed}}> MONTHLY SPECIALS </Text>
                        {
                            isThereAValidSpecial(profileData.monthlySpecials)
                            ?
                            <View>
                                { profileData.monthlySpecials[0] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.monthlySpecials[0]}</Text> }
                                {/* { profileData.monthlySpecials[1] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.monthlySpecials[1]}</Text> }
                                { profileData.monthlySpecials[2] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.monthlySpecials[2]}</Text> } */}
                            </View>
                            :
                            <Text style={{ fontSize: scaleFontSize(12), fontWeight: "800"}}>
                                {isProfileOwner ? 'Click to create your monthly specials' : 'No specials here yet!'}
                            </Text>
                        }
                    </View>
                    <TouchableContainer
                        style={{
                            flex: 1.25/3,
                            height: '100%',
                            width: '50%',

                        }}
                        onPress={sendToMonthlySpecialsPage}
                    >
                        <Image 
                            source={monthlySpecialsImageKey ? {uri: `${IMAGEKIT_URL}/${monthlySpecialsImageKey}`} : require('../assets/alwayslocal-logo.png')} 
                            style={{
                                width: '100%',
                                height: '100%',
                                // borderColor: 'blue',
                                // borderWidth: 1,
                                // minHeight: 100,
                                // borderTopRightRadius: 10,
                                // borderBottomRightRadius: 10,
                            }} 
                            resizeMode="contain" 
                        />
                    </TouchableContainer>
                </Card>
            </TouchableContainer>
        )
    }

    const InTheBiz=()=>{
        return(
            <TouchableContainer disabled={!isProfileOwner} style={{flexGrow: 1, marginVertical: 2.5}} onPress={()=>{setShowModal(true); setEdittingMode("inTheBizChange")}}>
                <Card style={{flex: 1, padding: 10}}>
                    <Text style={{fontSize: scaleFontSize(15), alignSelf: 'flex-start', fontWeight: '800'}}> IN THE <Text style={{color: COLORS.primaryRed}}>BIZ!</Text></Text>
                        {isThereAValidSpecial(profileData.inTheBiz) ?
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                        }}>
                            <View style={{flex: .97/2}}>
                                { profileData.inTheBiz[0] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.inTheBiz[0]}</Text> }
                            </View>
                            <View style={{flex: .97/2}}>
                                { profileData.inTheBiz[1] !== "" && <Text style={{fontSize: scaleFontSize(12)}}>{profileData.inTheBiz[1]}</Text> }
                            </View>
                        </View>
                            :
                            <Text style={{fontSize: scaleFontSize(12), fontWeight: '800'}}>
                                {!isProfileOwner ? 'No special deals for you here yet!' : 'Click to create your in the biz specials'}
                            </Text>
                        }
                </Card>
            </TouchableContainer>
        )
    }

    const NextButton = ({ onPress }: any) => (
        <TouchableContainer onPress={onPress}>
            <AntDesign name="rightcircleo" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="white" />
        </TouchableContainer>
    );
    
    const PrevButton = ({ onPress }: any) => (
        <TouchableContainer onPress={onPress}>
            <AntDesign name="leftcircleo" size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} color="white" />
        </TouchableContainer>
    );

    const reOrderUploadedPictures = ()=>{
        if (!profileData || !profileData.coverPhotoKey) {
            return uploadedPictures;
        }
    
        let index = uploadedPictures.findIndex(url => url.includes(profileData.coverPhotoKey));
    
        if (index === -1) {
            return uploadedPictures;
        }
    
        let reorderedPictures = [...uploadedPictures];
        let [selectedPicture] = reorderedPictures.splice(index, 1);
        reorderedPictures.unshift(selectedPicture);
        return reorderedPictures;
    }

    const renderStaff = ()=>{
        //@ts-ignore
        const filteredEmployees: UserData[] = Object.values(employeeData).filter((employee: UserData)=>{
            if(!employee) return false;
            if(employee.accountType === 'entertainment' || employee.accountType === 'account') return false;
            return true;
        });
        if(!profileData.employees || !filteredEmployees.length) return(
            <>
                {isProfileOwner && <Card style={{flex: .97/2, padding:2.5}}>
                    <Image 
                        source={require('../assets/alwayslocal-logo.png')} 
                        style={{
                            // flex: 1,
                            // borderWidth: 2,
                            // borderColor: 'blue',
                            zIndex: 0,
                            height: 150,
                            width: '100%',
                        }} 
                        resizeMode="contain" 
                    />
                    <Entypo 
                        style={{
                            zIndex: 1, 
                            position: 'absolute', 
                            top: 10, 
                            left: 10, 
                        }} 
                        name="info-with-circle" 
                        size={SCREEN_SIZE.width * .055 > 35 ? 35 : SCREEN_SIZE.width * .055} 
                        color="black" 
                    />
                </Card>}
                <Card style={{flex:isProfileOwner ? .97/2 : 1, alignItems: 'center', justifyContent: 'center', padding:2.5 }}>
                    <Text style={{ fontSize: scaleFontSize(12), textAlign: 'center', fontWeight: '500' }}>
                        {isProfileOwner ? 'This will be populated with your staff once they join!' : 'No staff listed yet'}
                    </Text>
                </Card>
            </>
        )
        return filteredEmployees.map((employee: UserData)=>{
            return(
                <UserSlideShowImage key={employee.uid} push={navigation.push} user={employee} />
            )
        })
    }

    const renderEntertainment = ()=>{
        const ifOwnerHasNoSchedule = isProfileOwner && !entertainmentSchedule;
        return(
            <Card style={{flex:1, alignItems: 'center', justifyContent: 'center', padding:5 }}>
                {!entertainmentSchedule && <Text style={{ fontSize: scaleFontSize(12), textAlign: 'center', fontWeight: '500' }}>
                    {isProfileOwner ?
                    'This will be populated with your enertainment once they join and a gig is created!' :
                    'No entertainment listed yet'}
                </Text>}
                {ifOwnerHasNoSchedule && <>
                    <Text style={{ fontSize: scaleFontSize(10), textAlign: 'center'}}>
                        Here are some examples
                    </Text>
                    <GigDisplayer push={navigation.push} onAccount={true} gigs={fakeGigData} />
                </>}
                {entertainmentSchedule && <GigDisplayer push={navigation.push} onAccount={true} gigs={entertainmentSchedule}/>}
            </Card>
        )                      
    }

    const webContainerStyle = (()=>{
        if(isMobile()){
            // alert("Welcome to mobile");
            return(
                {
                    // flex: 1,
                    height: SCREEN_SIZE.height,
                    width: SCREEN_SIZE.width,
                    backgroundColor: COLORS.primaryBgColor,
                }
            )
        }
        else{
            return(
                {
                    flex: 1,
                    //TODO: Remove this, but it simulates a fixed size for web.
                    height: 1500,
                    width: 500,
                    backgroundColor: COLORS.primaryBgColor,
                }
            )
        }
    })()

    return(
            <View style={{
                ...(isWeb() ? {
                    ...webContainerStyle,
                } : {flex: 1}),

                // borderColor: 'red',
                // borderWidth: 1,

                justifyContent: 'center',
                alignContent: 'center',
            }}>
                {/* <VerifyPhoneNumberModal
                    phoneNumber={state.phoneNumber}
                    setShowModal={setShowVerifyPhoneNumberModal}
                    showModal={showVerifyPhoneNumberModal}
                    onCloseModal={()=>{}}
                    onVerifyPhoneNumberSuccess={()=>{}}
                /> */}
                <ExpandPictureModal
                    profilePictureKey={selectedCoverPhoto.replace(IMAGEKIT_URL, "")}
                    showProfilePictureModal={showCoverPictureModal}
                    setShowProfilePictureModal={setShowCoverPictureModal}
                />
                <ExpandPictureModal
                    profilePictureKey={profilePictureKey}
                    showProfilePictureModal={showProfilePictureModal}
                    setShowProfilePictureModal={setShowProfilePictureModal}
                />
                <ReauthCurrentUserModal
                    setShowModal={setShowReauthModal}
                    onReauthSuccess={onReauthSuccess}
                    showModal={showReauthModal}
                    onCloseModal={()=>{}}
                />
                <UploadPictureModal
                    showModal={showUploadPictureModal}
                    setShowModal={setShowUploadPictureModal}
                    onCloseModal={()=>setShowModal(true)}
                    onPictureUploaded={onPictureUploaded}
                    picturePrefix={`${profileData.uid}-monthly-special-image`}
                    selectedImageKey={monthlySpecialsImageKey}
                    onRemoveSelectedImage={onRemoveMonthlySpecialImage}
                />
                <CustomModal
                    onBack={()=>{
                        // setPostContentInput("");
                        setShowModal(false);
                        // setShowCommentsModal(true)
                    }}
                    gradient={false}
                    title={getTitle()}
                    visibilityFlag={showModal}
                    visibilityControlFunc={setShowModal}
                    showSubmit={false}
                    scroll={true}
                    containerStyle={{
                        alignSelf: 'center',
                        justifyContent: 'center',
                        // ...(isWeb() ? {height:  '80%' } : {}),
                        // height:  '80%',
                        // borderWidth: 1,
                        width: '90%',
                        // maxWidth: isWeb() ? '80%' : '100%',
                        // borderColor: 'red',
                    }}
                    cardStyle={{
                        // height: modalSize,
                        width: SCREEN_SIZE.width,
                        // position: (Platform.OS === 'ios' ? 'absolute' : 'relative'),
                        // top: (Platform.OS === 'ios' ? Header : 0),
                    }}
                >  
                    {renderModalContent()}
                </CustomModal>
                {/* Slideshow with profile pictures */}
                <CoverPhoto/>
                <ScrollView
                    style={{height: '70%'}}
                    contentContainerStyle={{
                        // flex: 1,
                        // height: hSCREEN_SIZE *.625,
                        // height: '70%',
                        backgroundColor: COLORS.primaryBgColor,
                        // borderColor: 'green',
                        // borderWidth: 2,
                        // padding: 2.5
                }}>
                    <AccountProfilePictureBar/>
                  
                    {/* Daily and Monthly Specials */}
                    <View style={{
                        flexDirection: 'row', justifyContent: 'space-around',
                        padding: 2.5,
                        height: SCREEN_SIZE.height * .22,
                        // padding: 1,
                        // borderColor: 'green',
                        // borderWidth: 1,
                    }}>
                        <DailySpecials/>
                        <WeeklySpecials/>
                    </View>
                    <MonthlySpecials/>

                    {
                        (isProfileOwner || userData.accountType === 'staff' ||
                        userData.accountType === 'entertainment')
                        &&
                        <InTheBiz/>
                    }

                    <TouchableContainer
                        style={{
                            // ...(isWeb() ? { height: SCREEN_SIZE.height * .22 } : {flexGrow: 1}),
                            // height: SCREEN_SIZE.height * .22,
                            flexGrow: 1,
                            // borderColor: 'red', borderWidth: 1
                        }}
                        disabled={!isProfileOwner} onPress={()=>{setShowModal(true); setEdittingMode("dealsChange")}}>
                        <Card style={{padding: 10, backgroundColor: COLORS.slateGray, borderRadius: 0}}>
                            <Text style={{fontSize: scaleFontSize(20), color: "white"}}>
                                <MaterialCommunityIcons name="pig-variant" size={SCREEN_SIZE.width * .055 > 50 ? 50 : SCREEN_SIZE.width * .055} color="white" /> Deals
                            </Text>
                            <View style={{flexDirection: 'row', justifyContent: 'space-around', padding: 2.5}}>
                                {renderDeals()}
                            </View>
                        </Card>
                    </TouchableContainer>

                    <View style={{
                        height: SCREEN_SIZE.height*.30, padding: 10,
                        // borderColor: 'red', borderWidth: 1
                    }}>
                        <Text style={{fontSize: scaleFontSize(20), color: "black", textAlignVertical: 'center'}}>
                            <SimpleLineIcons name="people" size={SCREEN_SIZE.width * .055 > 50 ? 50 : SCREEN_SIZE.width * .055} color="black" /> Staff
                        </Text>
                        <ScrollView
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            contentContainerStyle={{
                                flex: 1,
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                                padding: 2.5
                            }}
                        >
                            {renderStaff()}
                        </ScrollView>
                    </View>
                    {!entertainmentHidden && <View style={{padding: 10, height: 200, backgroundColor: COLORS.slateGray}}>
                        <Text style={{fontSize: scaleFontSize(20), color: "white", textAlignVertical: 'center'}}>
                            <SimpleLineIcons name="people" size={SCREEN_SIZE.width * .055 > 50 ? 50 : SCREEN_SIZE.width * .055} color="white" /> Entertainment
                        </Text>
                        {renderEntertainment()}
                    </View>}
                </ScrollView>
            </View>
    )
}

export default AccountProfile;

