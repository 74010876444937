import { Alert } from 'react-native';

const BlockConfirmationText = `When you block someone, you'll be able to see their last known location, but not their live location (If they're online).
\n Additionally, they will be unable to see either your live (If you're online) or last known location.
\n Finally, messaging between you and this user will be disabled.
\n FAM will not let them know that they've been blocked.
`

const UnblockConfirmText = `When you unblock someone, they will be able to message you and see your live (If you're online) and last known location.
\n Additionally, you will once again be able to see their live location (If they're online).
\n FAM will not let them know that they've been unblocked.
`

const blockConfirmationPrompt = (username: string, actionType: string)=>{
    //Returns true if action type is pressed, false if cancel is pressed.
    if(actionType==='block'){

        return new Promise((resolve)=>{
          Alert.alert(
            `Are you sure you would like to block ${username}?`,
            BlockConfirmationText,
            [

              {
                text: `Block`,
                onPress: () =>{
                    resolve(true);
                },
                // style: 'cancel',
              },
              { text: `Cancel`, onPress: () => {

                  resolve(false)
              }, style: 'cancel'},
            ],
            { cancelable: false }
          );
        })
    }
    else if(actionType === 'unblock'){
        return new Promise((resolve)=>{
            Alert.alert(
             `Are you sure you would like to unblock  ${username}?`,
              UnblockConfirmText,
              [
              //   { text: 'Ask me later', onPress: () => console.log('Ask me later pressed') },
                {
                  text: 'Unblock',
                  onPress: () =>{
                      resolve(true);
                  },
                  // style: 'cancel',
                },
                { text: `Cancel`, onPress: () => {
                    // Updates.reload();
                    resolve(false)
                }, style: 'cancel'},
              ],
              { cancelable: false }
            );
          })
    }
}

export default blockConfirmationPrompt;