import React, { useState } from "react";
import { TextInput, View, TouchableWithoutFeedback } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import Fire from '../Fire';
import createNewMessageData from "../helpers/createNewMessageData";
import { COLORS } from "../Constants";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const ChatInput = (props: any)=>{
    const userData = useSelector((state: RootState)=>state.userData);
    const {receiverData, onSubmit, reportMode, reportData} = props;
    const [text, setText] = useState("");

    const onChange = (message: string) => {
        setText(message);
    }

    const clearTextState = ()=>{
        setText("");
    }
    
    const onSubmitChatRoomMessage = ()=>{
        onSubmit(text)
        clearTextState();
    }
    
    const onSubmitReport = ()=>{
    if(text && text !== ""){

        let {uid, email} = userData

        
        let newMessageId = Fire.getUniqueKeyForRoute(reportData.route);
        if(!newMessageId){
        alert("Error creating identifier for your report - Please try again");
        return;
        }
        let newMessage = createNewMessageData(text, uid, email, newMessageId);

        Fire.setRoute(`${reportData.route}/${newMessageId}`, newMessage)
    //   firebase.database().ref(`${reportData.route}/${newMessageId}`).set(newMessage);

        clearTextState();
        onSubmit();

    }
    }

    return (
        <View style={{
          width: '100%',
          borderTopColor: COLORS.primaryBlue,
          borderTopWidth: 1,
          flexDirection: 'row',
          padding: 10,
        }}>
          <TextInput
            multiline={true}
            onChangeText={onChange}
            value={text}
            style={{
              flex: 1,
              backgroundColor: COLORS.primaryGray,
              minHeight: 30,
              borderRadius: 15,
              marginRight: 10,
              padding: 7.5,
            }}
            placeholder="Enter your message..."
            autoFocus={true}
          />
          <TouchableWithoutFeedback
              onPress={reportMode ? onSubmitReport : onSubmitChatRoomMessage}
          >
            <Ionicons
                style={{alignSelf: 'center'}}
                name={'md-send'}
                size={30}
                color={COLORS.primaryBlue}
            />
          </TouchableWithoutFeedback>
        </View>
      );
}


export default ChatInput;
