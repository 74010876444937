import { Alert } from "react-native";

const promptUser = (title: string, message: string)=>{
    return new Promise((resolve)=>{
        Alert.alert(
        title,
        message,
        [
        //   { text: 'Ask me later', onPress: () => console.log('Ask me later pressed') },
            {
            text: 'Okay',
            onPress: async () =>{
                resolve(true)
            },
            // style: 'cancel',
            },
        ],
        { cancelable: false }
        );
    })
}

export default promptUser;