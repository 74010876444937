import { daysOfTheWeek } from "../Constants";

const isBusinessEverOpen = (schedule: any)=>{
    if(!schedule) return false;
    return daysOfTheWeek.some((day: string)=>{
        if(!schedule[day]) return false;
        if(!schedule[day].hasHours) return false;
        const {hasHours} = schedule[day];
        if(hasHours) return true;
        else return false;
    })
}

export default isBusinessEverOpen