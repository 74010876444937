import { configureStore } from '@reduxjs/toolkit';
import userDataReducer from './slices/userData';
import guestDataReducer from './slices/guestData';
import switchDataReducer from './slices/switchData';
import allUserDataReducer from './slices/allUserData';
import chatDataReducer from './slices/chatData';
import timePickersReducer from './slices/timePickers';

const store = configureStore({
  reducer: {
    guestData: guestDataReducer,
    switchData: switchDataReducer,
    userData: userDataReducer,
    allUserData: allUserDataReducer,
    chatData: chatDataReducer,
    timePickers: timePickersReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;