export default {
    FirebaseConfig: {
        apiKey: "AIzaSyAkIkwrnU9q77LXMURVpZeqaQsGlhD_WfA",
        authDomain: "always-local-3430b.firebaseapp.com",
        projectId: "always-local-3430b",
        storageBucket: "always-local-3430b.appspot.com",
        messagingSenderId: "883841791218",
        appId: "1:883841791218:web:6a48ed8cff658436f28c6b",
        measurementId: "G-W843LQJSQG"
      },
    s3Keys: {
    }
}


