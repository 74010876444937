import catchErrorForSentry from './catchSentryError';
import getLatLngFromZipCode from './getLatLngFromZipCode';
import { SERVER_URL } from '../Constants';
import isLatLngInFlorida from './isLatLngInFlorida';

// const isZipCodeInFlorida = async (zipcode: string) => {
//   try {
//     const location = await getLatLngFromZipCode(zipcode);
//     if (!location) return false;

//     console.log("Location: ", location);
//     const response = await fetch(`${SERVER_URL}/api/reverse-geocode`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ latitude: location.latitude, longitude: location.longitude }),
//     });

//     if (!response.ok) {
//       console.error('Failed to fetch reverse geocoding data');
//       return false;
//     }

//     const data = await response.json();

//     const stateComponent = data.addressComponents.find((component: any) =>
//       component.types.includes('administrative_area_level_1')
//     );

//     if (stateComponent) {
//       return stateComponent.long_name === 'Florida' || stateComponent.short_name === 'FL';
//     } else {
//       console.log("State not found in reverse geocoding response");
//       return false;
//     }
//   } catch (error) {
//     console.error("Error checking if zipcode is in Florida:", error);
//     catchErrorForSentry(error);
//     return false;
//   }
// };

const isZipCodeInFlorida = async (zipcode: string) => {
  try {
    const location = await getLatLngFromZipCode(zipcode);
    if (!location) return false;

    return await isLatLngInFlorida(location.latitude, location.longitude);
  } catch (error) {
    console.error("Error checking if zipcode is in Florida:", error);
    catchErrorForSentry(error);
    return false;
  }
};

export default isZipCodeInFlorida;


// import * as Location from 'expo-location';
// import catchErrorForSentry from './catchSentryError';
// import getLatLngFromZipCode from './getLatLngFromZipCode';

// const isZipCodeInFlorida = async (zipcode: string) => {
//   try {
//     const location = await getLatLngFromZipCode(zipcode);
//     if (!location) return false;

//     const response = await Location.reverseGeocodeAsync({
//       latitude: location.latitude,
//       longitude: location.longitude
//     });

//     const firstResult = response[0];
//     if (firstResult && firstResult.region) {
//       // Check if the state is Florida
//       return firstResult.region === 'Florida' || firstResult.region === 'FL';
//     } else {
//       console.log("Region not found in reverse geocoding response");
//       return false;
//     }
//   } catch (error) {
//     console.error("Error checking if zipcode is in Florida:", error);
//     catchErrorForSentry(error);
//     return false;
//   }
// };

// export default isZipCodeInFlorida;
