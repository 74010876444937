import catchErrorForSentry from './catchSentryError';
import getLatLngFromAddress from './getLatLngFromAddress';
import { SERVER_URL } from '../Constants';
import isLatLngInFlorida from './isLatLngInFlorida';

const isAddressInFlorida = async (address: string) => {
  try {
    const location = await getLatLngFromAddress(address);
    if (!location) return false;

    return await isLatLngInFlorida(location.latitude, location.longitude);
  } catch (error) {
    console.error("Error checking if address is in Florida:", error);
    catchErrorForSentry(error);
    return false;
  }
};

export default isAddressInFlorida;
