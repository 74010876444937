import React from 'react';
import { View, Text, StyleSheet, TouchableNativeFeedback, Platform, TouchableWithoutFeedback, Pressable } from 'react-native';

const CustomButton = (props: any) => {
    let ButtonComponent;

    if (Platform.OS === 'android') {
        ButtonComponent = TouchableNativeFeedback;
    } else if (Platform.OS === 'web') {
        ButtonComponent = Pressable;
    } else {
        ButtonComponent = TouchableWithoutFeedback;
    }

    return (
        //@ts-ignore
        <ButtonComponent onPress={props.onPress}>
            <View style={{ ...styles.mainButton, ...props.style }}>
                <Text style={{ ...styles.text, ...props.textStyle }}>
                    {props.title}
                </Text>
            </View>
        </ButtonComponent>
    );
};

const styles = StyleSheet.create({
    mainButton: {
        width: '100%',
        backgroundColor: '#000',
    },
    text: {
        color: '#fff',
        textAlign: 'center',
    },
});

export default CustomButton;
