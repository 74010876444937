import { Platform, Pressable, StyleSheet, TouchableNativeFeedback, TouchableOpacity, TouchableWithoutFeedback } from "react-native"
const TouchableContainer = (props: any)=>{
    let {
        noFeedback = false
    } = props;
    if(Platform.OS === 'web'){
        return(
            <Pressable {...props} style={{...props.style}} onPress={props.onPress}>
                {props.children}
            </Pressable>
        )
    }
    if(Platform.OS === 'ios' && noFeedback){
        return(
            <TouchableWithoutFeedback {...props} style={{...props.style}} onPress={props.onPress}>
                {props.children}
            </TouchableWithoutFeedback>
        )
    }
    return(
        <TouchableOpacity {...props} style={{...props.style}} onPress={props.onPress} >
            {props.children}
        </TouchableOpacity>
    )
}

export default TouchableContainer